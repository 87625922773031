import {
  WidthType,
  AlignmentType,
  VerticalAlign,
  Document,
  Paragraph,
  TextRun,
  Table,
  TableRow,
  TableCell,
  TabStopType,
  BorderStyle,
} from "docx";

import { AcademicRankAbbrv, DegreeAbbrv } from "../../utils/abbreviation";
import { commonBorders } from "./helpers/commonNoneBorders";
export class StudentProjectAcceptListDocx {
  create = (data) => {
    const res = [];

    data.forEach((item, index) =>
      item.researchMembers.forEach((member, memberId) => {
        var children = [
          new TableCell({
            children: [
              new Paragraph({
                children: [new TextRun({ text: `${index + 1}`, size: 13 * 2 })],
              }),
            ],
            rowSpan: item.researchMembers.length,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [new TextRun({ text: item.name, size: 13 * 2 })],
              }),
            ],
            rowSpan: item.researchMembers.length,
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [new TextRun({ text: member.name, size: 13 * 2 })],
              }),
            ],
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({ text: `${member.department}, ${member.academicYear}, ${member.class}`, size: 13 * 2 }),
                ],
              }),
            ],
          }),
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item.instructors
                      .map((e) => `${AcademicRankAbbrv(e?.academicRank)}${DegreeAbbrv(e?.degree)}${e?.name}`)
                      .join(", "),
                    size: 13 * 2,
                  }),
                ],
              }),
            ],
            rowSpan: item.researchMembers.length,
          }),
        ];

        if (memberId !== 0) children = [children[2], children[3]];

        res.push(new TableRow({ children }));
      })
    );

    const document = new Document({
      sections: [
        {
          children: [
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [new TextRun({ text: "BỘ GIÁO DỤC VÀ ĐÀO TẠO", size: 11.5 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: "TRƯỜNG ĐẠI HỌC DEMO", bold: true, size: 11.5 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: "---------------------------", size: 11.5 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: "Số:         /QĐ-ĐHP-KHCN", size: 11.5 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      width: { size: 50, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                      borders: commonBorders,
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({ text: "CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM", bold: true, size: 11.5 * 2 }),
                          ],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: "Độc lập - Tự do - Hạnh phúc", bold: true, size: 11.5 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: "------------------------------", size: 11.5 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [
                            new TextRun({ text: "Hà Nội, ngày    tháng     năm    ", italics: true, size: 11.5 * 2 }),
                          ],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      width: { size: 50, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                      borders: {
                        top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                        bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                        right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                      },
                    }),
                  ],
                }),
              ],
            }),
            new Paragraph({ children: [new TextRun({ text: " ", size: 13 * 2 })] }),
            new Paragraph({
              children: [new TextRun({ text: "QUYẾT ĐỊNH", size: 14 * 2, bold: true })],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [
                new TextRun({ text: "Phê duyệt Danh mục các đề tài sinh viên NCKH", size: 13 * 2, bold: true }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [new TextRun({ text: "Trường Đại học Demo năm học …", size: 13 * 2, bold: true })],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [new TextRun({ text: "-----------------------------------", size: 13 * 2, bold: true })],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [new TextRun({ text: "HIỆU TRƯỞNG TRƯỜNG ĐẠI HỌC DEMO", size: 13 * 2, bold: true })],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "\tCăn cứ Quyết định số 1368/QĐ-TTg ngày 10/10/2007 của Thủ tướng Chỉnh phủ về việc thành lập Trường Đại học Thành Tây và Quyết định số 1609/QĐ-TTg ngày 21/11/2018 của Thủ tướng Chính phủ về việc đổi tên Trường Đại học Thành Tây thành Trường Đại học Demo;",
                  size: 13 * 2,
                  italics: true,
                }),
              ],
              tabStops: [{ type: TabStopType.LEFT, position: 1000 }],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "\tCăn cứ Nghị quyết số 12/NQ-ĐHP-HĐT ngày 18/9/2020 của Hội đồng Trường về việc ban hành Quy chế Tổ chức và hoạt động của Trường Đại học Demo;",
                  size: 13 * 2,
                  italics: true,
                }),
              ],
              tabStops: [{ type: TabStopType.LEFT, position: 1000 }],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "\tCăn cứ Quyết định số 468/QĐ-ĐHP-KHCN ngày 19/10/2020 của Hiệu trưởng Trường Đại học Demo về việc ban hành Quy chế quản lý hoạt động Khoa học & Công nghệ Trường Đại học Demo;",
                  size: 13 * 2,
                  italics: true,
                }),
              ],
              tabStops: [{ type: TabStopType.LEFT, position: 1000 }],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "\tCăn cứ Quyết định số 137/QĐ-ĐHP ngày 26 tháng 4 năm 2019 của Hiệu trưởng Trường Đại học Demo về việc ban hành Quy định về hoạt động nghiên cứu khoa học của sinh viên Trường Đại học Demo;",
                  size: 13 * 2,
                  italics: true,
                }),
              ],
              tabStops: [{ type: TabStopType.LEFT, position: 1000 }],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "\tTheo đề nghị của Trưởng phòng Khoa học Công nghệ.",
                  size: 13 * 2,
                  italics: true,
                }),
              ],
              tabStops: [{ type: TabStopType.LEFT, position: 1000 }],
            }),
            new Paragraph({
              children: [new TextRun({ text: "", size: 13 * 2, italics: true })],
              tabStops: [{ type: TabStopType.LEFT, position: 1000 }],
            }),
            new Paragraph({
              children: [new TextRun({ text: "QUYẾT ĐỊNH:", size: 14 * 2, bold: true })],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [
                new TextRun({ text: "\tĐiều 1. ", size: 13 * 2, bold: true }),
                new TextRun({
                  text: "Phê duyệt Danh mục      đề tài sinh viên NCKH Trường Đại học Demo năm học ... Danh mục kèm theo quyết định này;",
                  size: 13 * 2,
                }),
              ],
              tabStops: [{ type: TabStopType.LEFT, position: 1000 }],
            }),
            new Paragraph({
              children: [
                new TextRun({ text: "\tĐiều 2. ", size: 13 * 2, bold: true }),
                new TextRun({
                  text: "Giao Phòng Khoa học Công nghệ phối hợp với các Khoa thông báo cho sinh viên và tiến hành triển khai thực hiện các đề tài thuộc Danh mục nêu tại Điều 1.",
                  size: 13 * 2,
                }),
              ],
              tabStops: [{ type: TabStopType.LEFT, position: 1000 }],
            }),
            new Paragraph({
              children: [
                new TextRun({ text: "\tĐiều 3. ", size: 13 * 2, bold: true }),
                new TextRun({
                  text: "Trưởng phòng Khoa học Công nghệ, Trưởng các đơn vị và các cá nhân có liên quan chịu trách nhiệm thi hành Quyết định này.",
                  size: 13 * 2,
                }),
              ],
              tabStops: [{ type: TabStopType.LEFT, position: 1000 }],
            }),
            new Paragraph({ children: [new TextRun({ text: " ", size: 13 * 2 })] }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [new TextRun({ text: "Nơi nhận:", bold: true, size: 12 * 2 })],
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: "Như Điều 3;", size: 12 * 2 })],
                          bullet: { level: 0 },
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: "Lưu VT, KHCN.", size: 12 * 2 })],
                          bullet: { level: 0 },
                        }),
                      ],
                      width: { size: 50, type: WidthType.PERCENTAGE },
                      borders: {
                        top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                        bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                        right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                      },
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [new TextRun({ text: "HIỆU TRƯỞNG", bold: true, size: 12 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: " ", size: 12 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: " ", size: 12 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: " ", size: 12 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: " ", size: 12 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: "GS.TS. Phạm Thành Huy", bold: true, size: 12 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      width: { size: 50, type: WidthType.PERCENTAGE },
                      borders: {
                        top: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                        bottom: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                        left: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                        right: { style: BorderStyle.NONE, size: 0, color: "FFFFFF" },
                      },
                    }),
                  ],
                }),
              ],
              width: { size: 100, type: WidthType.PERCENTAGE },
            }),
            new Paragraph({
              children: [new TextRun({ text: "PHỤ LỤC 1", size: 13 * 2, bold: true })],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "DANH MỤC CÁC ĐỀ TÀI SINH VIÊN NCKH TRƯỜNG ĐẠI HỌC DEMO ",
                  size: 13 * 2,
                  bold: true,
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [new TextRun({ text: "NĂM HỌC … ĐƯỢC PHÊ DUYỆT", size: 13 * 2, bold: true })],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "(Kèm theo Quyết định số        /QĐ-ĐHP-KHCN ngày      tháng      năm    )",
                  size: 13 * 2,
                  italics: true,
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [new TextRun({ text: "TT", size: 13 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      width: { size: 5, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [new TextRun({ text: "Tên đề tài", bold: true, size: 13 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      width: { size: 25, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({ text: "Họ và tên sinh viên, nhóm sinh viên", bold: true, size: 13 * 2 }),
                          ],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      width: { size: 20, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [new TextRun({ text: "Khoa, khóa, lớp", bold: true, size: 13 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      width: { size: 25, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [new TextRun({ text: "Cán bộ hướng dẫn", bold: true, size: 13 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      width: { size: 20, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                    }),
                  ],
                  tableHeader: true,
                }),
                ...res,
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({ text: `(Ấn định danh sách ${data.length} đề tài)`, size: 13 * 2, italics: true }),
              ],
              alignment: AlignmentType.CENTER,
            }),
          ],
          properties: { page: { margin: { left: "2cm", right: "2cm", top: "3cm", bottom: "3cm" } } },
        },
      ],
    });

    return document;
  };
}
