import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DropzoneArea } from 'material-ui-dropzone';
import { createStyles, makeStyles } from '@material-ui/core/styles';

export default function FileUploadModal(props) {
  const useStyles = makeStyles(theme => createStyles({
    previewChip: {
      minWidth: 160,
      maxWidth: 500
    },
  }));

  const classes = useStyles();
  const [files, setFiles] = useState([])

  const handleUploadFile = () => {
    if (files.length < 1)
      alert('Hãy chọn 1 file để upload!')
    else
      if (props.handleUpload){
        props.handleUpload(files)
      } else {
        alert('Lỗi không upload được file, vui lòng thử lại sau!')
      }
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle>{"Chọn file để upload"}</DialogTitle>
      <DialogContent style={{ minWidth: 500 }}>
        {props.isImage? (
            <DropzoneArea
              acceptedFiles={['image/*']}
              dropzoneText={"Drag and drop an image here or click"}
              onChange={(files) => setFiles(files)}
              filesLimit={1}
              previewGridProps={{container: { spacing: 1, direction: 'row', alignItems: 'center', justify: 'center'}}}
              maxFileSize={50000000}
            />
          ) : (
            <DropzoneArea
              onChange={(files) => setFiles(files)}
              showPreviews={true}
              showPreviewsInDropzone={false}
              useChipsForPreview
              previewGridProps={{container: { spacing: 1, direction: 'row' }}}
              previewChipProps={{classes: { root: classes.previewChip } }}
              previewText="File đã chọn:"
              filesLimit={1}
              initialFiles={props.defaultFiles}
              maxFileSize={50000000}
            />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="secondary" variant="outlined">
          Hủy
        </Button>
        <Button onClick={handleUploadFile} color="primary" variant="contained" autoFocus>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}