import { fromJS } from 'immutable';

import TYPE from '../../constants/type';

const initialState = fromJS({
  departments: [],
  visibleDepartments: [],
  departmentProfiles: [],
  faculties: null,
  allProfiles: [],
  selectedDepartmentId: 0,
  isFetching: false,
});

export default function departmentReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.DEPARTMENT.GET_ALL_DEPARTMENTS_LOADING:
    case TYPE.DEPARTMENT.GET_ALL_VISIBLE_DEPARMENTS_BY_PROFILE_ID_LOADING:
    case TYPE.DEPARTMENT.INSERT_DEPARTMENT_LOADING:
    case TYPE.DEPARTMENT.UPDATE_DEPARTMENT_LOADING:
    case TYPE.DEPARTMENT.DELETE_DEPARTMENT_LOADING:
    case TYPE.DEPARTMENT.GET_ALL_PROFILE_LOADING:
    case TYPE.DEPARTMENT.GET_PROFILE_BY_DEPARTMENT_ID_LOADING:
    case TYPE.DEPARTMENT.INSERT_DEPARTMENT_PROFILE_LOADING:
    case TYPE.DEPARTMENT.DELETE_DEPARTMENT_PROFILE_LOADING:
    case TYPE.DEPARTMENT.UPDATE_DEPARTMENT_PROFILE_LOADING:
    case TYPE.DEPARTMENT.GET_ALL_FACULTIES_LOADING:
      return state.merge({
        isFetching: true,
      });

    case TYPE.DEPARTMENT.GET_ALL_DEPARTMENTS_FAILURE:
    case TYPE.DEPARTMENT.GET_ALL_VISIBLE_DEPARMENTS_BY_PROFILE_ID_FAILURE:
    case TYPE.DEPARTMENT.INSERT_DEPARTMENT_FAILURE:
    case TYPE.DEPARTMENT.UPDATE_DEPARTMENT_FAILURE:
    case TYPE.DEPARTMENT.DELETE_DEPARTMENT_FAILURE:
    case TYPE.DEPARTMENT.GET_ALL_PROFILE_FAILURE:
    case TYPE.DEPARTMENT.GET_PROFILE_BY_DEPARTMENT_ID_FAILURE:
    case TYPE.DEPARTMENT.INSERT_DEPARTMENT_PROFILE_FAILURE:
    case TYPE.DEPARTMENT.DELETE_DEPARTMENT_PROFILE_FAILURE:
    case TYPE.DEPARTMENT.UPDATE_DEPARTMENT_PROFILE_FAILURE:
    case TYPE.DEPARTMENT.GET_ALL_FACULTIES_FAILURE:
      return state.merge({
        isFetching: false,
      });

    case TYPE.DEPARTMENT.GET_ALL_DEPARTMENTS_SUCCESS:
      return state.merge({
        departments: [...action.payload],
        isFetching: false,
      });

    case TYPE.DEPARTMENT.GET_ALL_VISIBLE_DEPARMENTS_BY_PROFILE_ID_SUCCESS:
      return state.merge({
        visibleDepartments: [...action.payload],
        isFetching: false,
      });

    case TYPE.DEPARTMENT.GET_ALL_PROFILE_SUCCESS:
      return state.merge({
        allProfiles: [...action.payload],
        isFetching: false,
      });

    case TYPE.DEPARTMENT.GET_PROFILE_BY_DEPARTMENT_ID_SUCCESS:
      return state.merge({
        departmentProfiles: [...action.payload],
        isFetching: false,
      });

    case TYPE.DEPARTMENT.INSERT_DEPARTMENT_SUCCESS:
      return state.merge({
        departments: [...state.get('departments'), action.payload],
        isFetching: false,
      });

    case TYPE.DEPARTMENT.UPDATE_DEPARTMENT_SUCCESS: {
      const newList = state.get('departments');
      const index = newList.findIndex((e) => e.id === action.payload.id);
      newList.splice(index, 1, action.payload);
      return state.merge({
        departments: [...newList],
        isFetching: false,
      });
    }

    case TYPE.DEPARTMENT.DELETE_DEPARTMENT_SUCCESS: {
      const newList = state.get('departments');
      const index = newList.findIndex((e) => e.id === action.id);
      newList.splice(index, 1);
      return state.merge({
        departments: [...newList],
        isFetching: false,
      });
    }

    case TYPE.DEPARTMENT.INSERT_DEPARTMENT_PROFILE_SUCCESS:
      const { departmentId } = action.payload;
      const departments = state.get('visibleDepartments');
      const matchDepartmentIndex = departments.findIndex(
        (departmentElement) => {
          return departmentElement.id === departmentId;
        }
      );

      if (matchDepartmentIndex > -1) {
        const tempVisibleDepartments = [];
        departments.forEach((departmentElement, departmentIndex) => {
          if (departmentIndex === matchDepartmentIndex) {
            tempVisibleDepartments.push({
              ...departmentElement,
              departmentProfile: [
                ...departmentElement.departmentProfile,
                action.payload,
              ],
            });
          } else tempVisibleDepartments.push(departmentElement);
        });

        return state.merge({
          isFetching: false,
          visibleDepartments: tempVisibleDepartments,
        });
      };

        

      return state.merge({
        isFetching: false,
      });

    case TYPE.DEPARTMENT.DELETE_DEPARTMENT_PROFILE_SUCCESS: {
      /**
       *  remove profile from
       * "departmentReducer.departments.departmentProfile"
       *  and
       * "departmentReducer.profiles"
       *
       * departmentReducer: {
       *   departments: [
       *     {
       *       departmentProfile: []
       *     }
       *   ],
       *   allProfiles: [],
       *   departmentProfiles: [],
       * }
       */
      const { departmentId, departmentProfileId } = action.payload;
      const departments = state.get('visibleDepartments');
      const matchDepartment = departments.find((departmentElement) => {
        return departmentElement.id === departmentId;
      });

      const departmentProfileIndex = matchDepartment.departmentProfile.findIndex(
        (departmentProfileElement) => {
          return departmentProfileElement.id === departmentProfileId;
        }
      );

      if (departmentProfileIndex > -1) {
        return state.merge({
          visibleDepartments: departments.map((departmentElement) => {
            if (departmentElement.id === departmentId) {
              return {
                ...departmentElement,
                departmentProfile: departmentElement.departmentProfile.filter(
                  (_, index) => {
                    return departmentProfileIndex !== index;
                  }
                ),
              };
            }
            return departmentElement;
          }),
          allProfiles: state
            .get('allProfiles')
            .filter((departmentProfileElement) => {
              return departmentProfileElement.id !== departmentProfileId;
            }),
          departmentProfiles: state
            .get('departmentProfiles')
            .filter((departmentProfileElement) => {
              return departmentProfileElement.id !== departmentProfileId;
            }),
          isFetching: false,
        });
      }

      return state.merge({
        isFetching: false,
      });
    }

    case TYPE.DEPARTMENT.UPDATE_DEPARTMENT_PROFILE_SUCCESS: {
      const {
        id: departmentProfileId,
        departmentId,
        profileId,
        roleInDepartment,
      } = action.payload;
      const departments = state.get('departments');
      const matchDepartment = departments.find((departmentElement) => {
        return departmentElement.id === departmentId;
      });
      if (matchDepartment) {
        // const matchDepartmentProfileIndex = matchDepartment?.departmentProfile.findIndex(
        //   (departmentProfileElement) => {
        //     return departmentProfileElement.id === departmentProfileId;
        //   }
        // );


        // if (matchDepartmentProfileIndex > -1) {
          const newVisibleDepartments = state.get('visibleDepartments');
          const newDepartmentProfiles = state.get('departmentProfiles');


          return state.merge({
            // visibleDepartments: newVisibleDepartments.map((visibleDepartment) => {
            //   // if (visibleDepartment.id === departmentId) {
            //   //   return {
            //   //     ...visibleDepartment,
            //   //     // departmentProfile: visibleDepartment.departmentProfile.map(
            //   //     //   (departmentProfileElement) => {
            //   //     //     if (departmentProfileElement.profileId === profileId) {
            //   //     //       return action.payload;
            //   //     //     }
            //   //     //     return departmentProfileElement;
            //   //     //   }
            //   //     // ),
            //   //   };
            //   // }
            //   return visibleDepartment;
            // }),
            departments: departments.map((departmentElement) => {
              if (departmentElement.id === departmentId) {
                return {
                  ...departmentElement,
                  departmentProfile: departmentElement.departmentProfile?.map(
                    (departmentProfileElement) => {
                      if (departmentProfileElement.profileId === profileId) {
                        return action.payload;
                      }
                      return departmentProfileElement;
                    }
                  ),
                };
              }
              return departmentElement;
            }),
            departmentProfiles: newDepartmentProfiles.map(
              (departmentProfileElement) => {
                if (departmentProfileElement.id === profileId) {
                  return { ...departmentProfileElement, roleInDepartment };
                }
                return departmentProfileElement;
              }
            ),
            isFetching: false,
          });
        // }

        return state.merge({
          isFetching: false,
        });
      }

      return state.merge({
        isFetching: false,
      });
    }

    case TYPE.DEPARTMENT.GET_ALL_FACULTIES_SUCCESS:
      return state.merge({
        faculties: [...action.payload],
        isFetching: false,
      });

    case 'SET_SELECTED_DEPARTMENT_ID':
      return state.merge({
        selectedDepartmentId: action.id,
      });

    default:
      return state;
  }
}
