import { fromJS } from "immutable";

import TYPE from "../../constants/type";

const initialState = fromJS({
  list: [],
  listById: [],
  listFiltered: [],
  productContracts: [],
  patents: [],
  scientificResearchs: [],
  solutions: [],
  detail: null,
  isFetching: false,
  isFetchingAccepted: false,
});

export default function projectReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.PROJECT.GET_ALL_PROJECT_LOADING:
    case TYPE.PROJECT.GET_FILTERED_PROJECT_LOADING:
    case TYPE.PROJECT.GET_ALL_PROJECT_BY_PROFILE_ID_LOADING:
    case TYPE.PROJECT.INSERT_PROJECT_LOADING:
    case TYPE.PROJECT.UPDATE_PROJECT_LOADING:
    case TYPE.PROJECT.DELETE_PROJECT_LOADING:
    case TYPE.PROJECT.GET_PROFILE_INFO_BY_PROFILE_ID_LOADING:
      return state.merge({
        isFetching: true,
      });

    case TYPE.PROJECT.ACCEPT_PROJECT_LOADING:
      return state.merge({
        isFetchingAccepted: true,
      });

    case TYPE.PROJECT.GET_ALL_PROJECT_FAILURE:
    case TYPE.PROJECT.GET_FILTERED_PROJECT_FAILURE:
    case TYPE.PROJECT.GET_ALL_PROJECT_BY_PROFILE_ID_FAILURE:
    case TYPE.PROJECT.INSERT_PROJECT_FAILURE:
    case TYPE.PROJECT.UPDATE_PROJECT_FAILURE:
    case TYPE.PROJECT.DELETE_PROJECT_FAILURE:
    case TYPE.PROJECT.GET_PROFILE_INFO_BY_PROFILE_ID_FAILURE:
      return state.merge({
        isFetching: false,
      });

    case TYPE.PROJECT.ACCEPT_PROJECT_FAILURE:
      return state.merge({
        isFetchingAccepted: false,
      });

    case TYPE.PROJECT.GET_ALL_PROJECT_SUCCESS:
      return state.merge({
        list: action.payload || [],
        isFetching: false,
      });

    case TYPE.PROJECT.GET_FILTERED_PROJECT_SUCCESS:
      return state.merge({
        listFiltered: action.payload || [],
        isFetching: false,
      });

    case TYPE.PROJECT.GET_ALL_PROJECT_BY_PROFILE_ID_SUCCESS:
      return state.merge({
        listById: [...action.payload],
        isFetching: false,
      });

    case TYPE.PROJECT.GET_PROJECT_BY_ID_SUCCESS:
      return state.merge({
        detail: {...action.payload},
        isFetching: false,
      });

    case TYPE.PROJECT.INSERT_PROJECT_SUCCESS:
      const newlistById = state.get('listById');
      const index = action.payload.researchProjectProfile.findIndex((e) => e.profileId === action.id);
      if (index >= 0) newlistById.push({ researchProject: action.payload });
      return state.merge({
        listById: [...newlistById],
        listFiltered: [...state.get('listFiltered'), { researchProject: action.payload }],
        isFetching: false,
      });

    case TYPE.PROJECT.UPDATE_PROJECT_SUCCESS: {
      const newList = state.get('listFiltered');
      const index = newList.findIndex((e) => e.researchProject.id === action.id);
      newList.splice(index, 1, { researchProject: action.payload });

      const newList1 = state.get('listById');
      const index1 = newList1.findIndex((e) => e.researchProject.id === action.id);
      newList1.splice(index1, 1, { researchProject: action.payload });

      return state.merge({
        listFiltered: [...newList],
        listById: [...newList1],
        isFetching: false,
      });
    }

    case TYPE.PROJECT.DELETE_PROJECT_SUCCESS: {
      const newList = state.get('listById');
      const index = newList.findIndex((e) => e.researchProject.id === action.id);
      if (index >= 0) newList.splice(index, 1);

      const newList1 = state.get('listFiltered');
      const index1 = newList1.findIndex((e) => e.researchProject.id === action.id);
      if (index1 >= 0) newList1.splice(index1, 1);

      return state.merge({
        listById: [...newList],
        listFiltered: [...newList1],
        isFetching: false,
      });
    }
    
    case TYPE.PROJECT.ACCEPT_PROJECT_SUCCESS: {
      const newList = state.get('listById');
      const index = newList.findIndex((e) => e.researchProject.id === action.payload.id);
      const newResearchProject = { ...newList[index], researchProject: action.payload};
      newList.splice(index, 1, newResearchProject);

      const newList1 = state.get('listFiltered');
      const index1 = newList1.findIndex((e) => e.researchProject.id === action.payload.id);
      const newResearchProject1 = { ...newList1[index1], researchProject: action.payload};
      newList1.splice(index1, 1, newResearchProject1);

      return state.merge({
        listById: [...newList],
        listFiltered: [...newList1],
        isFetchingAccepted: false,
      });
    }

    case TYPE.PROJECT.GET_PROFILE_INFO_BY_PROFILE_ID_SUCCESS:
      return state.merge({
        contracts: action.payload.contracts,
        patents: action.payload.patents,
        productContracts: action.payload.productContracts,
        scientificResearchs: action.payload.scientificResearchs,
        solutions: action.payload.solutions,
        monographs: action.payload.monographs,
        isFetching: false,
      });

    default:
      return state;
  }
}
