import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Button,
  Popover,
  Checkbox,
  Typography,
  CircularProgress,
  FormGroup,
  FormLabel,
  FormControl,
  FormControlLabel,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination,
} from '@material-ui/core';
import { Drafts, FilterList } from '@material-ui/icons';
import Highlighter from 'react-highlight-words';

import styles from './index.module.css';
import { removeAccents } from '../utils';

function KPTable(props) {
  const { maxHeight } = props;

  const columns = props.columns.map((e) => ({
    ...e,
    onCell: e?.onCell || (() => null)
  }));

  // -- Table pagination -- //
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [datasource, setDatasource] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (_, newPage) => {
    if (props.onPageChange) {
      props.onPageChange(newPage);
    } else {
      setPage(newPage);
    }
  }
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };


  // -- Sort & Search -- //
  const [sort, setSort] = useState(null);
  const [search, setSearch] = useState(null);
  const [searchColumn, setSearchColumn] = useState([]);


  // -- Filter -- //
  const [filter, setFilter] = useState(false);
  const [checklist, setChecklist] = useState({});
  const [tempChecklist, setTempChecklist] = useState({});
  const [filterIndex, setFilterIndex] = useState(-1);
  const [anchorElFilter, setAnchorElFilter] = useState(null);


  useEffect(() => {
    if (filter) setPage(0);
  }, [filter]);


  
  useEffect(() => {
    setSearchColumn(columns.filter((e) => e?.searchable).map((e) => e?.key));
  }, [rowsPerPage, page]);

  useEffect(() => setPage(0), [props.rows?.length]);

  useEffect(() => {
    let rows = [...props.rows || []];

    if (sort?.sortElm) {
      const asc = !!sort?.sortUp;
      const column = columns.find((e) => e?.key === sort?.sortElm);
      if (asc) rows.sort(column.sorter); else rows.sort(column.sorter).reverse();
    }

    if (search) {
      rows = rows?.filter((row) => !!searchColumn.find((column) => (
        removeAccents(row?.[column]).includes(removeAccents(search))
      )));
    }

    if (filter) {
      const filterFunc = columns[filterIndex]?.onFilter;
      rows = rows?.filter((row) => filterFunc(row, checklist));
    }

    setTotalRows(rows.length);
    
    if (isNaN(props.page)) {
      setDatasource(rows?.slice(rowsPerPage * page, rowsPerPage * (page + 1)));
    } else {
      setDatasource(rows)
    }
  }, [props.rows, search, filter, rowsPerPage, page]);

  useEffect(() => {
    if (props.filterObj?.sort?.sortElm !== sort?.sortElm || props.filterObj?.sort?.sortUp !== sort?.sortUp) {
      setPage(0);
      setSort(props.filterObj?.sort);
    }
  }, [props.filterObj?.sort]);

  useEffect(() => {
    if (props.filterObj?.search !== search) {
      setPage(0);
      setSearch(props.filterObj?.search);
    }
  }, [props.filterObj?.search]);


  const activeFilter = (active) => {
    setFilter(active);
    setAnchorElFilter(null);

    if (active) {
      setChecklist(tempChecklist);
    } else {
      setChecklist({});
      setTempChecklist({});
    }
  }

  return (
    <Box style={{ position: 'relative' }}>
      <TableContainer style={{ maxHeight: maxHeight || 'auto' }}>
        <Table
          {...props.tableProps}
          stickyHeader
          size='small'
        >
          <TableHead>
            <TableRow>
              {columns.map((column, columnId) => (
                <TableCell
                  className={column.sticky && styles.stickyColumnHeader}
                  size='small'
                  variant='head'
                  sortDirection='asc'
                  key={column?.key}
                  align={column?.align || 'left'}
                  style={{
                    width: column?.width || 'auto',
                    minWidth: column?.width || 'auto',
                    maxWidth: column?.width || 'auto',
                    borderLeft: props?.border ? (columnId === 0 ? null : 'solid 1px #e0e0e0') : null,
                    left: column.sticky && columns.reduce((total, curr, id) => (
                      total += (id < columnId) ? (curr.width + 32 + 1) : 0
                    ), 0)
                  }}
                >
                  <Box
                    display='flex'
                    width={column?.onFilter ? 'calc(100% + 32px)' : 'auto'}
                    marginLeft={column?.onFilter ? -2 : 0}
                  >
                    <Box
                      flexGrow={1}
                      padding='6px 0'
                      textAlign={column?.align || 'left'}
                      paddingLeft={column?.onFilter ? 2 : 0}
                    >
                      {column.title}
                    </Box>
                    {column?.onFilter && (
                      <Box
                        className={styles.button}
                        onClick={(event) => {
                          setFilterIndex(columnId);
                          setAnchorElFilter(event.currentTarget);
                        }}
                      >
                        <FilterList
                          fontSize='small'
                          color={filter ? 'primary' : 'disabled'}
                          style={{ lineHeight: '20px' }}
                        />
                      </Box>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {datasource?.length !== 0 ? datasource.map((row, rowId) => (
              <TableRow className={styles.row} key={`row-${rowId}`}>
                {columns?.map((column, columnId) => (
                  <React.Fragment key={`cell-${rowId}-${columnId}`}>
                    {column?.onCell(row)?.rowSpan === 0 ? (
                      null
                    ) : (
                      <TableCell
                        {...column?.onCell(row)}
                        className={column.sticky && styles.stickyColumn}
                        align={column?.align || 'left'}
                        style={{
                          width: column?.width || 'auto',
                          minWidth: column?.width || 'auto',
                          maxWidth: column?.width || 'auto',
                          borderLeft: props?.border ? (columnId === 0 ? null : 'solid 1px #e0e0e0') : null,
                          left: column.sticky && columns.reduce((total, curr, id) => (
                            total += (id < columnId) ? (curr.width + 32 + 1) : 0
                          ), 0)
                        }}
                      >
                        {column.render ? (
                          column.render(row[column?.key], row, rowId)
                        ) : (
                          <Highlighter
                            autoEscape={true}
                            searchWords={(column.searchable && search) ? [search] : []}
                            textToHighlight={String(row[column?.key])}
                          />
                        )}
                      </TableCell>
                    )}
                  </React.Fragment>
                ))}
              </TableRow>
            )
            ) : (
              <TableRow style={{ height: '100%' }}>
                <TableCell colSpan={props.columns?.length} style={{ textAlign: 'center' }}>
                  <Drafts color='disabled' style={{ fontSize: 128 }} />
                  <Typography variant='h5' style={{ color: 'rgba(0, 0, 0, 0.26)' }}>
                    <b>{props?.loading ? 'Đang tải dữ liệu' : 'Không có dữ liệu'}</b>
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Popover
        open={!!anchorElFilter}
        anchorEl={anchorElFilter}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => {
          setAnchorElFilter(null);
          setTempChecklist(checklist);
        }}
        PaperProps={{
          style: {
            padding: 8,
            boxShadow: 'rgba(0, 0, 0, 0.075) 0px 6px 24px 0px, rgba(0, 0, 0, 0.2) 0px 0px 0px 1px'
          }
        }}
      >
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <b>{'Lọc theo '}</b>
            {columns[filterIndex]?.title}
          </FormLabel>
          <FormGroup>
            {columns[filterIndex]?.filters.map((item) => (
              <FormControlLabel
                key={item?.value}
                label={item?.text}
                control={
                  <Checkbox
                    name={item?.value}
                    color='primary'
                    checked={tempChecklist?.[item?.value] || false}
                    onChange={(e) => {
                      setTempChecklist({ ...tempChecklist, [item?.value]: e.target.checked });
                    }}
                  />
                }
              />
            ))}
            
          </FormGroup>
          <Grid container spacing={1} style={{ margin: 0, width: '100%' }}>
            <Grid item xs={6}>
              <Button fullWidth size='small' variant='outlined' onClick={() => activeFilter(false)}>Bỏ</Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth size='small' variant='contained' color='primary' onClick={() => activeFilter(true)}>Lọc</Button>
            </Grid>
          </Grid>
        </FormControl>
      </Popover>
      {!props?.disablePagination && (
        <TablePagination
          page={props.page ?? page}
          component={Box}
          count={props.totalData || totalRows || 0}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage='Số dòng trên một trang:'
          // rowsPerPageOptions={[rowsPerPage]}
          rowsPerPageOptions={[10, 20, 50]}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={
            function defaultLabelDisplayedRows({ from, to, count }) {
              return `${from}–${to} (Toàn bộ: ${count})`;
            }
          }
        />
      )}

      {
        props.loading && <Box className={styles.box}><CircularProgress /></Box>
      }
    </Box>
  );
}

export default KPTable;
