import React from 'react';

import ProjectTable from '../../containers/Project/ProjectTable';
import ProjectButton from '../../containers/Project/ProjectButton';

function Project(props) {
  const list = window.location.href.split('/');
  const lastValueList = list[list.length - 1].split('=');
  const selectedProfileId = (lastValueList.length > 1) ? lastValueList[lastValueList.length - 1] : null;

  return (
    <div style={{ padding: 8, minHeight: 'calc(100vh - 178px)', position: 'relative' }}>
      <ProjectButton type={props.type} department={props.department} selectedProfileId={selectedProfileId} handleReload={props.handleReload}/>
      <ProjectTable adminTab={props.adminTab} type={props.type} department={props.department} selectedProfileId={selectedProfileId} handleReload={props.handleReload}/>
    </div>
  );
}

export default Project;
