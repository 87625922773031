import {
  Box,
  Grid,
  Button,
  Divider,
  Tooltip,
  TextField,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Add, Delete, DeleteForever } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import select from "../../../../utils/select";
import {
  deleteStudentResearch,
  insertStudentResearch,
  updateStudentResearch,
  updateStudentResearchStatus,
} from "../../../../reducer/studentResearch/action";
import { STUDENT_RESEARCH } from "../../../../constants/selectOptions";
import { getAllFaculties } from "../../../../reducer/department/action";
import { KPTextfield, KPTextform } from "../../../../components/KPTComponents";

function MemberForm(props) {
  const andDisabled =
    props?.id !== "member-form" && props?.id !== "edit-member";
  const orDisabled = props?.id === "member-form" || props?.id === "edit-member";

  const dispatch = useDispatch();

  const { members, setMembers } = props;

  const faculties = useSelector((state) =>
    select(state, "departmentReducer", "faculties")
  );

  const fieldList = [
    { key: "name", label: "Tên thành viên", xs: 3, required: true },
    {
      key: "phoneNumber",
      label: "SĐT",
      xs: 3,
      required: "only_main",
      type: "tel",
    },
    {
      key: "departmentId",
      label: "Khoa",
      xs: 6,
      type: "autoComplete",
      options: faculties,
      required: true,
    },
    { key: "class", label: "Lớp", xs: 2, required: true },
    { key: "email", label: "Email", xs: 4, required: "only_main" },
    { key: "academicYear", label: "Khóa", xs: 2, required: true },
    // { key: 'gpa', label: 'GPA', xs: 2, type: 'number', min: 0, max: 4, step: 0.01 },
    { key: "studentId", label: "MSSV", xs: 2, required: true },
  ];

  useEffect(() => {
    dispatch(getAllFaculties());
  }, []);

  useEffect(() => {
    if (props?.id === "member-form" && members?.length === 0) {
      const data = {};
      fieldList.forEach((e) => (data[e.key] = ""));
      setMembers([{ ...data, researchRole: "main" }]);
    }
  }, [props?.id, members?.length]);

  const textForm = [
    {
      key: "box1",
      xs: 12,
      type: "render",
      render: () => (
        <>
          {members?.map((member, index) => (
            <Box
              key={`member-${index}`}
              padding={2}
              borderRadius={4}
              marginTop={index === 0 ? 0 : 2}
              display="flex"
              position="relative"
              alignItems="flex-end"
              border="solid 1px #bcbcbc"
              borderTop="solid 4px #bcbcbc"
            >
              <Grid container spacing={2}>
                {fieldList.map((field) => {
                  return (
                    <Grid
                      key={`member-${index}-${field.key}`}
                      item
                      xs={field.xs}
                    >
                      <KPTextfield
                        disabled={andDisabled}
                        {...field}
                        required={
                          field.required === "only_main"
                            ? member.researchRole === "main"
                            : field.required
                        }
                        value={
                          field?.key === "departmentId"
                            // ? field?.options?.find(
                            ? faculties?.find(
                                (e) => e.id === member?.departmentId
                              )
                            : member?.[field?.key]
                        }
                        getOptionLabel={(option) => option?.name || ""}
                        id={`members.${index}.${field?.key}`}
                        handleChangeForm={handleChangeForm}
                      />
                    </Grid>
                  );
                })}
                <Grid item xs={2}>
                  <KPTextfield
                    type="select"
                    disabled={andDisabled}
                    required={true}
                    label="Vai trò"
                    options={STUDENT_RESEARCH.researchRoleMemberOptions}
                    value={member?.researchRole}
                    id={`members.${index}.researchRole`}
                    handleChangeForm={handleChangeForm}
                  />
                </Grid>
                {member?.researchRole === "main" && (
                  <>
                    <Grid item xs={3}>
                      <KPTextfield
                        disabled={andDisabled}
                        required={true}
                        label="Số CMND/CCCD"
                        value={member?.identityNumber}
                        id={`members.${index}.identityNumber`}
                        handleChangeForm={handleChangeForm}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <KPTextfield
                        disabled={andDisabled}
                        // required={true}
                        label="STK ngân hàng"
                        value={member?.bankAccNum}
                        id={`members.${index}.bankAccNum`}
                        handleChangeForm={handleChangeForm}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <KPTextfield
                        disabled={andDisabled}
                        // required={true}
                        label="Chi nhánh ngân hàng"
                        value={member?.bankBranch}
                        id={`members.${index}.bankBranch`}
                        handleChangeForm={handleChangeForm}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Box
                top={-1}
                right={-1}
                width={andDisabled ? 48 : 110}
                height={36}
                bgcolor="#bcbcbc"
                color="#fff"
                position="absolute"
                justifyContent="flex-end"
                display="flex"
                alignItems="center"
                style={{
                  borderTopRightRadius: 4,
                  clipPath: "polygon(0 1%, 100% 0, 100% 100%, 20% 100%)",
                }}
              >
                <b style={{ paddingBottom: 3, paddingRight: 16, fontSize: 22 }}>
                  {index + 1}
                </b>
                {orDisabled && (
                  <>
                    <Divider orientation="vertical" style={{ height: 36 }} />
                    <Tooltip
                      title={
                        member?.researchRole !== "main"
                          ? "Xoá thành viên"
                          : "Không thể xóa chủ nhiệm đề tài"
                      }
                    >
                      <span>
                        <IconButton disabled={member?.researchRole === "main"}>
                          {member?.researchRole === "main" ? (
                            <DeleteForever style={{ color: "#fff" }} />
                          ) : (
                            <Delete
                              style={{ color: "#fff" }}
                              onClick={() =>
                                setMembers(
                                  members.filter((e, id) => id !== index)
                                )
                              }
                            />
                          )}
                        </IconButton>
                      </span>
                    </Tooltip>
                  </>
                )}
              </Box>
            </Box>
          ))}

          {orDisabled && (
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              startIcon={<Add />}
              disabled={members?.length >= 5}
              onClick={() =>
                setMembers([
                  ...members,
                  { researchRole: members.length === 0 ? "main" : "other" },
                ])
              }
              style={{ marginTop: 16 }}
            >
              Thêm thành viên
            </Button>
          )}
        </>
      ),
    },
  ];

  const handleChangeForm = (key, val) => {
    const keyList = key.split(".");
    const index = parseInt(keyList[1]);
    var fieldKey = keyList?.[2];
    const _value = [...members];
    var _val = val;

    if (fieldKey === "departmentId") _val = val.id;

    _value.splice(index, 1, { ..._value[index], [fieldKey]: _val });

    setMembers(_value);
  };

  const updateMembers = () => {
    dispatch(
      updateStudentResearch({
        ...props.detail,
        researchMembers: members.map((e, id) => ({
          ...e,
          gpa: 0,
          researchId: props.detail.id,
          departmentId:
            typeof e?.departmentId === "number"
              ? e?.departmentId
              : e?.departmentId?.id,
        })),
        status: "member_default",
      })
    );
  };

  const acceptMembers = () => {
    dispatch(updateStudentResearchStatus(props?.detail.id, "member_accepted"));
  };

  const handleSubmit = () => {
    props.handleClose();
    switch (props.id) {
      case "member-form":
        updateMembers();
        break;
      case "accept-member-form":
        acceptMembers();
        break;
      case "edit-member":
        updateMembers();
        break;
      default:
        break;
    }
  };

  return (
    <KPTextform
      id={props.id}
      textForm={textForm}
      members={members}
      setMembers={setMembers}
      handleSubmit={handleSubmit}
    />
  );
}

export default MemberForm;
