import dayjs from 'dayjs';
import moment from 'moment';

const stringToDate = (value) => {
  const dateForm = (value || moment().format('YYYY-MM-DD'))?.replaceAll('/', '');
  const date = dateForm?.substr(0, 2);
  const month = dateForm?.substr(2, 2);
  const year = dateForm?.substr(-4);
  return(value ? dayjs(`${month}/${date}/${year}`).format('YYYY-MM-DDTHH:mm:ss') + 'Z' : null);
}


const dateToString = (value) => dayjs(value)?.format('DD-MM-YYYY')?.replaceAll('-', '/');


const seperatorPoint = (value) => {
	return (value.toString().replace(/\D/g,'').replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, '.'));
}


const _deep_sanitize = (object) => {
	Object.entries(object).forEach(([k, v], index) => {
		if (v && typeof v === 'object') {
			_deep_sanitize(v);
		}
		if (typeof v === 'string') return object;
		if ((v && typeof v === 'object' && !Object.keys(v).length) || !v) {
			if (Array.isArray(object)) {
				object.splice(index, 1);
			} else {
				delete object[k];
			}
		}
	});
	return object;
};


function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const pathToInfo = (path) => {
  try {
    const temp = path?.split('][');
    const list = [
      temp[0],
      temp[1],
      temp.slice(2).join('][')
    ];
  
    const size = list[2]?.split(']')[0];
    const fullname = list[2]?.substring(size.length + 1, list[2].length);
    const typeList = fullname?.split('.');
    const name = typeList?.slice(0, typeList?.length - 1).join('.');
    const type = typeList[typeList?.length - 1];
  
    return ({ fullname, name, type, size });
  } catch {
    return ({ fullname: null, name: null, type: null, size: null });
  }
}

export const valueFormat = {
  _deep_sanitize,
  stringToDate,
  dateToString,
  seperatorPoint,
  formatBytes,
  pathToInfo,
};