// Bằng cấp a.k.a Học vị
export const DegreeAbbrv = (degree) => {
  if (!degree) return "";

  degree = degree.replace(/[,.]/g, "").replace(/\s{2,}/g, " ").toLowerCase();

  switch (degree) {
    case "tiến sĩ": return "TS. ";
    case "thạc sĩ": return "ThS. ";
    case "phó giáo sư tiến sĩ": return "PGS.TS. ";
    case "giáo sư tiến sĩ": return "GS.TS. ";
    default: return "";
  }
};


// Học hàm
export const AcademicRankAbbrv = (rank) => {
  if (!rank) return "";

  switch (rank) {
    case "Giáo sư": return "GS.";
    case "Phó Giáo Sư": return "PGS.";
    // case "Tiến sĩ": return "TS.";
    default: return "";
  }
};
