import React, {  } from 'react';
import { Info, Group } from '@material-ui/icons';
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography } from '@material-ui/core';

import theme from '../../../../theme';
import styles from './index.module.css';
import select from "../../../../utils/select";

import { uploadMultiFile } from "../../../../reducer/file/action";
import { STUDENT_RESEARCH } from '../../../../constants/selectOptions';
import { KPTextfield, KPTextform } from '../../../../components/KPTComponents';
import { updateStudentResearch } from "../../../../reducer/studentResearch/action";

function SubmitForm(props) {
  const dispatch = useDispatch();

  const {
    values, setValues,
    big, bigger
  } = props;

  const councilMemberInfo = values?.councilMemberInfo;

  const profileList = useSelector(state => select(state, 'profileReducer', 'list'));
  const profileDetail = useSelector((state) => select(state, "profileReducer", "detail"));

  const fieldKey = [
    { key: 'researchTarget', height: 2, label: 'Mục tiêu đề tài', xs: 12, rows: 3, required: true, disabled: true },
    { key: 'improvement', height: 3, label: 'Tính mới và sáng tạo', xs: 12, rows: 3, required: true, disabled: props.id !== 'submit-form' && props.id !== 'submit-edit-form' },
    { key: 'result', height: 3, label: 'Kết quả nghiên cứu', xs: 12, rows: 3, required: true, disabled: props.id !== 'submit-form' && props.id !== 'submit-edit-form' },
    { key: 'practicability', label: 'Khả năng ứng dụng', xs: 12, rows: 3, required: true, disabled: props.id !== 'submit-form' && props.id !== 'submit-edit-form' },
    { key: 'instructorReview', height: 3, label: 'Nhận xét của giảng viên hướng dẫn', xs: 12, rows: 3, required: true, disabled: props.id !== 'submit-form' && props.id !== 'submit-edit-form' },
    { key: 'finalFileLink', type: 'dragzone', label: 'File báo cáo tổng kết', xs: 12, required: true, disabled: props.id !== 'submit-form' && props.id !== 'submit-edit-form' },
  ];

  const textForm = [
    { key: 'box1', xs: 12, type: 'render', render: () => (
      <>
        <Box display='flex' width='100%'>
          <Box
            maxHeight='calc(100vh - 216px)'
            width={`calc(${(big || bigger) ? 5 : 10}0% - 72px)`}
            padding='16px 24px'
            margin='8px 0'
            style={{ overflow: 'auto' }}
          >
            <Box border='solid 1px #bcbcbc' borderRadius={4}>
              <Box className={styles.header}>
                <Info style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }} />
                <Typography variant='h6' style={{ color: theme.palette.primary.main }}><b>NỘP ĐỀ TÀI</b></Typography>
              </Box>
              <Box padding={2}>
                <Grid container spacing={1}>
                  {fieldKey.map((e) => (
                    <Grid item xs={12}>
                      <KPTextfield
                        id={e.key}
                        value={values?.[e.key]}
                        label={e?.label}
                        height={2}
                        type={e?.type}
                        required={true}
                        disabled={e.disabled}
                        handleChangeForm={handleChangeForm}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Box>
          {big && (
            <Box
              maxHeight='calc(100vh - 216px)'
              width='calc(50% - 48px)'
              padding='16px 24px'
              margin='8px 0'
              style={{ overflow: 'auto' }}
            >
              <Box border='solid 1px #bcbcbc' borderRadius={4}>
                <Box
                  display='flex'
                  bgcolor='#ececec'
                  padding='8px 16px'
                  alignItems='center'
                  borderBottom='solid 1px #bcbcbc' 
                  style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
                >
                  <Group style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }} />
                  <Typography variant='h6' style={{ color: theme.palette.primary.main }}><b>DANH SÁCH HỘI ĐỒNG NGHIỆM THU</b></Typography>
                </Box>
                <Box padding={1}>
                  <Grid
                    container
                    spacing={1}
                    alignItems='center'
                    style={{
                      padding: '6px 0',
                      backgroundColor: '#fafafa',
                      borderBottom: 'solid 1px #e0e0e0'
                    }}
                  >
                    <Grid item xs={7}>
                      <Box display='flex'>
                        <Box width='152px' marginRight={2} />
                        <Box width='calc(100% - 170px)'>
                          <Typography variant='body1'><b>Tên thành viên</b></Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant='body1'><b>Học vị</b></Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant='body1'><b>Vị trí</b></Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} style={{ padding: '12px 0', borderBottom: 'solid 1px #e0e0e0' }}>
                    {STUDENT_RESEARCH.councilFieldKey.map((role) => {
                      const { key, title } = role;
                      const isPhenikaa = councilMemberInfo?.[`${key}Pid`];
                      return (
                        <React.Fragment key={key}>
                          <Grid item xs={7}>
                            <Box display='flex'>
                              <Box width={152} minWidth={152} maxWidth={152} marginRight={1}>
                                <KPTextfield
                                  value={isPhenikaa || false}
                                  id={`${key}IsPhenikaa`}
                                  type='checkbox'
                                  name='GV trong trường'
                                  disabled={true}
                                  handleChangeForm={handleChangeForm}
                                />
                              </Box>
                              <Box width='calc(100% - 152px)'>
                                <KPTextfield
                                  type={isPhenikaa ? 'autoComplete' : null}
                                  options={isPhenikaa ? profileList : null}
                                  getOptionLabel={(option) => option.name}
                                  value={
                                    isPhenikaa
                                      ? profileList.find((e) => e.id === councilMemberInfo?.[`${key}Pid`])
                                      : councilMemberInfo?.[`${key}Name`]
                                  }
                                  id={`${key}Name`}
                                  disabled={true}
                                  handleChangeForm={handleChangeForm}
                                  required={true}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={3}>
                            <KPTextfield
                              value={councilMemberInfo?.[`${key}Degree`]}
                              disabled={true}
                              id={`${key}Degree`}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <KPTextfield value={title} disabled={true} />
                          </Grid>
                        </React.Fragment>
                      )
                    })}
                  </Grid>
                </Box>
              </Box>
              
              {props.id !== 'submit-form' && props.id !== 'default-detail-form' && (
                <Box border='solid 1px #bcbcbc' borderRadius={4} marginTop={4}>
                  <Box
                    display='flex'
                    bgcolor='#ececec'
                    padding='8px 16px'
                    alignItems='center'
                    borderBottom='solid 1px #bcbcbc' 
                    style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4  }}
                  >
                    <Info style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }} />
                    <Typography variant='h6' style={{ color: theme.palette.primary.main }}><b>NGHIỆM THU ĐỀ TÀI</b></Typography>
                  </Box>
                  <Box padding={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <KPTextfield
                          id='point'
                          value={values?.point}
                          // value={values?.point.toFixed(2)}
                          label='Tổng điểm'
                          type='number'
                          step={0.01}
                          min={0}
                          max={100}
                          required={!values?.editRequest}
                          disabled={props.id !== 'submit-accept-form' && props.id !== 'submit-accept-again-form'}
                          // disabled={!!values?.editRequest || (props.id !== 'submit-accept-form')}
                          handleChangeForm={handleChangeForm}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <KPTextfield
                          id='eligible'
                          value={values?.eligible}
                          name='Đủ điều kiện tham gia Hội nghị SV NCKH cấp trường'
                          type='checkbox'
                          handleChangeForm={handleChangeForm}
                          style={{ marginTop: '25.5px' }}
                          disabled={props.id !== 'submit-accept-form' && props.id !== 'submit-accept-again-form'}
                          // disabled={!!values?.editRequest || (props.id !== 'submit-accept-form')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <KPTextfield
                          id='editRequest'
                          xs={12}
                          height={4}
                          label='Yêu cầu chỉnh sửa, bổ sung'
                          value={values?.editRequest || ''}
                          handleChangeForm={handleChangeForm}
                          disabled={props.id !== 'submit-accept-form' && props.id !== 'submit-accept-again-form'}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <KPTextfield
                          // id='file'
                          id={(props.id !== 'submit-accept-form' || props.id === 'submit-edit-form' || props.id === 'submit-accept-again-form')
                            ? 'estimateFileLink'
                            : 'file'}
                          required={true}
                          value={(props.id !== 'submit-accept-form' || props.id === 'submit-edit-form' || props.id === 'submit-accept-again-form')
                            ? values?.estimateFileLink
                            : values?.file}
                          label='File Phiếu đánh giá nghiệm thu'
                          type='dragzone'
                          disabled={props.id !== 'submit-accept-form' && props.id !== 'submit-accept-again-form'}
                          handleChangeForm={handleChangeForm}
                          style={{ marginTop: '25.5px' }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </>
    )},
  ];

  const handleChangeForm = (key, val, type) => {
    if (type === 'file') {
      setValues({ ...values, [key]: val?.[0] })
    } else setValues({ ...values, [key]: val });
  }

  console.log(values?.file, typeof values?.file !== 'string');

  const request = () => {
    if (typeof values?.file !== 'string') {
      dispatch(uploadMultiFile(profileDetail?.id, { estimateFile: values?.file })).then((res) => {
        if (res.success) {
          const files = res.data;

          dispatch(updateStudentResearch({
            ...props.detail,
            point: values.point,
            eligible: values.eligible,
            estimateFileLink: files[0],
            status: 'adjust_request',
            editRequest: values?.editRequest,
            parentResearch: undefined,
          }));
        }
      });
    } else {
      dispatch(updateStudentResearch({
        ...props.detail,
        point: values.point,
        eligible: values.eligible,
        status: 'adjust_request',
        editRequest: values?.editRequest,
        parentResearch: undefined,
      }));
    }
  }

  const submit = () => {
    if (typeof values?.finalFileLink !== 'string') {
      dispatch(uploadMultiFile(profileDetail?.id, { finalFile: values?.finalFileLink })).then((res) => {
        if (res.success) {
          const files = res.data;
  
          var statusList = props.detail.status.map((e) => e.value);
          statusList[0] = 'submitted';
  
          const data = {
            ...props.detail,
            status: statusList.join(','),
            finalFileLink: files[0],
            parentResearch: undefined,
          };
          fieldKey.slice(0, fieldKey.length - 1).forEach((field) => data[field.key] = values?.[field.key]);
          dispatch(updateStudentResearch(data));
        }
      });
    } else {
      const data = {
        ...props.detail,
        status: 'submitted_council_accepted',
        parentResearch: undefined,
      };
      fieldKey.slice(0, fieldKey.length - 1).forEach((field) => data[field.key] = values?.[field.key]);
      dispatch(updateStudentResearch(data));
    }
  }

   const submitAccept = () => {
    if (typeof values?.file !== 'string') {
      dispatch(uploadMultiFile(profileDetail?.id, { estimateFile: values?.file })).then((res) => {
        if (res.success) {
          const files = res.data;

          const data = {
            ...props.detail,
            point: values.point,
            eligible: values.eligible,
            estimateFileLink: files[0],
            status: values.eligible ? 'accepted,eligible' : 'accepted,not_eligible',
            parentResearch: undefined,
          };
          dispatch(updateStudentResearch(data));
        }
      });
    } else {
      const data = {
        ...props.detail,
        point: values.point,
        eligible: values.eligible,
        status: values.eligible ? 'accepted,eligible' : 'accepted,not_eligible',
        parentResearch: undefined,
      };
      dispatch(updateStudentResearch(data));
    }
  }

  const handleSubmit = (e) => {
    props.handleClose();
    switch (props.id) {
      case 'submit-form':
      case 'submit-edit-form':
        submit();
        break;
      case 'submit-accept-form':
      case 'submit-accept-again-form':
        var eventName = e.nativeEvent.submitter.name;
        if (eventName === 'accept') submitAccept(); else request();
        break;
      default:
        break;
    }
  }

  return (
    <Box margin='-16px 0' width='calc(100% + 24px)' height='calc(100% + 100px)'>
      <KPTextform
        id={props.id}
        textForm={textForm}
        values={values}
        setValues={setValues}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
}

export default SubmitForm;
