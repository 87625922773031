import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Button, Typography } from "@material-ui/core";
import {
  Add,
  Info,
  SupervisedUserCircle,
  Comment,
  Group,
} from "@material-ui/icons";

import {
  deleteStudentResearch,
  insertStudentResearch,
  updateStudentResearch,
  updateStudentResearchStatus,
} from "../../../../reducer/studentResearch/action";

import theme from "../../../../theme";
import styles from "./index.module.css";
import select from "../../../../utils/select";

import { STUDENT_RESEARCH } from "../../../../constants/selectOptions";
import { KPTextfield, KPTextform } from "../../../../components/KPTComponents";

function DefaultForm(props) {
  const dispatch = useDispatch();

  const {
    values,
    members,
    instructors,
    big,
    bigger,
    setValues,
    setMembers,
    setInstructors,
  } = props;

  const councilMemberInfo = values?.councilMemberInfo;

  const profileList = useSelector((state) =>
    select(state, "profileReducer", "list")
  );
  const faculties = useSelector((state) =>
    select(state, "departmentReducer", "faculties")
  );
  const profileDetail = useSelector((state) =>
    select(state, "profileReducer", "detail")
  );
  const departmentList = useSelector(state => 
    select(state, 'departmentReducer', 'departments')
  );

  const placeholder = "Tóm tắt bằng các gạch đầu dòng";
  const oneRowProperties = {
    xs: 12,
    disabled: !props?.open?.insert && props.id !== "edit-form",
    required: true,
    height: 1,
  };
  const multiRowProperties = {
    xs: 12,
    disabled: !props?.open?.insert && props.id !== "edit-form",
    required: true,
    height: 3,
    placeholder,
  };

  const box1 = [
    { key: "name", label: "Tên đề tài", ...oneRowProperties },
    {
      key: "departmentId",
      label: "Tên đơn vị quản lý sinh viên thực hiện đề tài",
      xs: 12,
      type: "autoComplete",
      getOptionLabel: (option) => option?.name || "",
      options: faculties?.filter((item) => item.id !== 0),
      required: true,
      value: faculties?.find((f) => f.id === values?.departmentId),
      handleChangeForm: (key, val) => {
        setValues({ ...values, [key]: val.id });
      },
      ...oneRowProperties,
    },
    {
      key: "researchTarget",
      label: "Mục tiêu nghiên cứu",
      placeholder,
      ...multiRowProperties,
    },
    {
      key: "expectedResult",
      label: "Dự kiến sản phẩm, kết quả",
      placeholder,
      ...multiRowProperties,
    },
    {
      key: "expectedCompetition",
      label: "Dự kiến tham gia giải thưởng sinh viên NCKH các cấp",
      placeholder,
      ...multiRowProperties,
    },
  ];

  var box2 = [];
  instructors?.forEach((instructor, index) => {
    box2.push(
      {
        key: `instructors.${index}.isPhenikaa`,
        value: instructor.isPhenikaa,
        name: "GV trong trường",
        type: "checkbox",
        disabled:
          (instructor.researchRole === "main" || !props?.open?.insert) &&
          props.id !== "edit-form",
        style: { marginTop: "25.5px" },
      },
      {
        key: `instructors.${index}${instructor.isPhenikaa ? null : ".name"}`,
        value: instructor.isPhenikaa
          ? profileList.find((e) => e.id === instructor?.profileId)
          : instructor?.name,
        xs: 5,
        label: "Tên giảng viên",
        type: instructor.isPhenikaa ? "autoComplete" : null,
        options: instructor.isPhenikaa ? profileList : null,
        getOptionLabel: (option) => option?.name,
        disabled:
          ((instructor.researchRole === "main" && props.type === "personal") ||
            !props?.open?.insert) &&
          props.id !== "edit-form",
        required: true,
      },
      {
        key: `instructors.${index}.email`,
        value: instructor.email,
        xs: 4,
        label: "Email",
        disabled:
          (!props?.open?.insert || instructor.isPhenikaa) &&
          props.id !== "edit-form",
        required: true,
      },
      {
        key: `instructors.${index}.researchRole`,
        value: instructor.researchRole,
        xs: 3,
        label: "Vai trò",
        type: "select",
        disabled: props.id !== "edit-form",
        required: true,
        options: STUDENT_RESEARCH.researchRoleInstructorOptions,
      },
      {
        key: `instructors.${index}.delete`,
        type: "button",
        disabled:
          instructor.researchRole === "main" && props.id !== "edit-form",
        style: { marginLeft: 8, marginTop: "25.5px", height: 35 },
        onClick: () =>
          setInstructors(instructors.filter((e, id) => id !== index)),
      }
    );
  });
  box2 = (() => {
    const res = [];
    box2.forEach((e, index) => {
      if (e.key.includes("isPhenikaa")) {
        res.push({
          checkbox: e,
          textfield: [box2[index + 1], box2[index + 2], box2[index + 3]],
          button: box2[index + 4],
        });
      }
    });
    return res;
  })();

  const memberFieldList = [
    { key: "name", label: "Tên thành viên", xs: 3, required: true },
    {
      key: "phoneNumber",
      label: "SĐT",
      xs: 3,
      required: "only_main",
      type: "tel",
    },
    {
      key: "departmentId",
      label: "Khoa",
      xs: 6,
      type: "autoComplete",
      options: faculties,
      required: true,
    },
    { key: "class", label: "Lớp", xs: 2, required: true },
    { key: "email", label: "Email", xs: 4, required: "only_main" },
    { key: "academicYear", label: "Khóa", xs: 2, required: true },
    // { key: 'gpa', label: 'GPA', xs: 2, type: 'number', min: 0, max: 4, step: 0.01 },
    { key: "studentId", label: "MSSV", xs: 2, required: true },
  ];

  const textForm = [
    {
      key: "box1",
      xs: 12,
      type: "render",
      render: () => (
        <Box display="flex" width="100%">
          <Box
            maxHeight="calc(100vh - 216px)"
            width={`calc(${big || bigger ? 6 : 10}0% - 72px)`}
            padding="16px 24px"
            margin="8px 0"
            style={{ overflow: "auto" }}
          >
            {props.id !== "detail-only-members-form" && (
              <Box border="solid 1px #bcbcbc" borderRadius={4}>
                <Box className={styles.header}>
                  <Info
                    style={{
                      marginRight: 12,
                      marginTop: 2,
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{ color: theme.palette.primary.main }}
                  >
                    <b>THÔNG TIN CƠ BẢN</b>
                  </Typography>
                </Box>
                <Box padding={2}>
                  <Grid container spacing={1}>
                    {box1.map((elm) => (
                      <Grid item key={elm.key} xs={elm.xs}>
                        <KPTextfield
                          id={elm.key}
                          value={values?.[elm.key] || ""}
                          handleChangeForm={handleChangeForm}
                          {...elm}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            )}
            {props.id !== "detail-only-info-form" && (
              <Box
                border="solid 1px #bcbcbc"
                borderRadius={4}
                marginTop={props.id !== "detail-only-members-form" ? 4 : 0}
              >
                <Box className={styles.header}>
                  <SupervisedUserCircle
                    style={{
                      marginRight: 12,
                      marginTop: 2,
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{ color: theme.palette.primary.main }}
                  >
                    <b>GIẢNG VIÊN HƯỚNG DẪN</b>
                  </Typography>
                </Box>
                <Box padding={2}>
                  {box2.map((item, index) => (
                    <Box
                      key={`instructor-${index}`}
                      display="flex"
                      marginTop={parseInt(index !== 0 ? 1 : 0) * 2}
                    >
                      <Box
                        width={152}
                        minWidth={152}
                        maxWidth={152}
                        marginRight={1}
                      >
                        <KPTextfield
                          id={item.checkbox.key}
                          value={values?.[item.checkbox.key] || ""}
                          handleChangeForm={handleChangeForm}
                          {...item.checkbox}
                        />
                      </Box>
                      <Grid container spacing={1}>
                        {item.textfield.map((elm) => (
                          <Grid
                            item
                            key={`instructor-${index}-${elm.key}`}
                            xs={elm.xs}
                          >
                            <KPTextfield
                              id={elm.key}
                              value={values?.[elm.key] || ""}
                              handleChangeForm={handleChangeForm}
                              {...elm}
                            />
                          </Grid>
                        ))}
                      </Grid>
                      {props.id === "insert-form" && (
                        <Button
                          color="primary"
                          variant="contained"
                          className={
                            item.button.disabled ? null : "error-button"
                          }
                          disabled={item.button.disabled}
                          {...item.button}
                        >
                          Xóa
                        </Button>
                      )}
                    </Box>
                  ))}
                  {props.id === "insert-form" && (
                    <Button
                      fullWidth
                      color="primary"
                      variant="outlined"
                      startIcon={<Add />}
                      disabled={instructors.length >= 2}
                      onClick={() => {
                        setInstructors([
                          ...instructors,
                          { isPhenikaa: false, researchRole: "other" },
                        ]);
                      }}
                      style={{ marginTop: 16, background: "white" }}
                    >
                      Thêm giảng viên hướng dẫn phụ
                    </Button>
                  )}
                </Box>
              </Box>
            )}
            {(props.open?.acceptInfo ||
              values?.status?.find((e) =>
                e.value.includes("info_rejected")
              )) && (
              <Box border="solid 1px #bcbcbc" borderRadius={4} marginTop={4}>
                <Box className={styles.header}>
                  <Comment
                    style={{
                      marginRight: 12,
                      marginTop: 2,
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{ color: theme.palette.primary.main }}
                  >
                    <b>NHẬN XÉT CỦA TRƯỞNG KHOA</b>
                  </Typography>
                </Box>
                <Box padding={2}>
                  <KPTextfield
                    id="leaveReason"
                    xs={12}
                    height={3}
                    label="Lý do huỷ đề tài"
                    disabled={props.open?.detail}
                    value={values?.leaveReason || ""}
                    handleChangeForm={handleChangeForm}
                  />
                </Box>
              </Box>
            )}
            {members?.length > 0 && props.id !== "detail-only-info-form" && (
              <Box border="solid 1px #bcbcbc" borderRadius={4} marginTop={4}>
                <Box className={styles.header}>
                  <Info
                    style={{
                      marginRight: 12,
                      marginTop: 2,
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{ color: theme.palette.primary.main }}
                  >
                    <b>THÀNH VIÊN THAM GIA</b>
                  </Typography>
                </Box>
                <Box padding={2}>
                  {members?.map((member, index) => (
                    <React.Fragment key={`member-${index}`}>
                      {member?.leaveReason === "" && (
                        <Box
                          key={`member-${index}`}
                          padding={2}
                          borderRadius={4}
                          bgcolor="#fcfcfc"
                          marginTop={index === 0 ? 0 : 2}
                          display="flex"
                          position="relative"
                          alignItems="flex-end"
                          border={`solid 1px ${theme.palette.primary.main}`}
                          borderTop
                          // borderTop={`solid 1px ${theme.palette.primary.main}`}
                          style={{
                            borderTopWidth: 4,
                            boxShadow:
                              "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                          }}
                        >
                          <Grid container spacing={1}>
                            {memberFieldList.map((field) => (
                              <Grid
                                key={`member-${index}-${field.key}`}
                                item
                                xs={field.xs}
                              >
                                <KPTextfield
                                  disabled={
                                    !props?.open?.report || !member.isNew
                                  }
                                  {...field}
                                  required={
                                    field.required === "only_main"
                                      ? member.researchRole === "main"
                                      : field.required
                                  }
                                  value={
                                    field?.key === "departmentId"
                                      ? field?.options?.find(
                                          (e) => e.id === member?.[field?.key]
                                        )
                                      : member?.[field?.key]
                                  }
                                  getOptionLabel={(option) =>
                                    option?.name || ""
                                  }
                                  id={`members.${index}.${field?.key}`}
                                  handleChangeForm={handleChangeForm}
                                />
                              </Grid>
                            ))}
                            <Grid item xs={2}>
                              <KPTextfield
                                type="select"
                                disabled={true}
                                required={true}
                                label="Vai trò"
                                options={
                                  STUDENT_RESEARCH.researchRoleMemberOptions
                                }
                                value={member?.researchRole}
                                id={`members.${index}.researchRole`}
                                handleChangeForm={handleChangeForm}
                              />
                            </Grid>
                            {member?.researchRole === "main" && (
                              <>
                                <Grid item xs={3}>
                                  <KPTextfield
                                    disabled={
                                      !props?.open?.report || !member?.isNew
                                    }
                                    required={true}
                                    label="Số CMND/CCCD"
                                    value={member?.identityNumber}
                                    id={`members.${index}.identityNumber`}
                                    handleChangeForm={handleChangeForm}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <KPTextfield
                                    disabled={
                                      !props?.open?.report || !member?.isNew
                                    }
                                    // required={true}
                                    label="STK ngân hàng"
                                    value={member?.bankAccNum}
                                    id={`members.${index}.bankAccNum`}
                                    handleChangeForm={handleChangeForm}
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <KPTextfield
                                    disabled={
                                      !props?.open?.report || !member?.isNew
                                    }
                                    // required={true}
                                    label="Chi nhánh ngân hàng"
                                    value={member?.bankBranch}
                                    id={`members.${index}.bankBranch`}
                                    handleChangeForm={handleChangeForm}
                                  />
                                </Grid>
                              </>
                            )}
                          </Grid>
                          <Box
                            top={-1}
                            right={-1}
                            width={48}
                            height={36}
                            // bgcolor='#bcbcbc'
                            bgcolor={theme.palette.primary.main}
                            color="#fff"
                            position="absolute"
                            justifyContent="flex-end"
                            display="flex"
                            alignItems="center"
                            style={{
                              borderTopRightRadius: 4,
                              clipPath:
                                "polygon(0 1%, 100% 0, 100% 100%, 20% 100%)",
                            }}
                          >
                            <b
                              style={{
                                paddingBottom: 3,
                                paddingRight: 16,
                                fontSize: 22,
                              }}
                            >
                              {index -
                                members
                                  .slice(0, Math.max(index, 0))
                                  .filter((e) => e?.leaveReason !== "").length +
                                1}
                            </b>
                          </Box>
                        </Box>
                      )}
                    </React.Fragment>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
          {big && (
            <Box
              maxHeight="calc(100vh - 216px)"
              width="calc(40% - 48px)"
              padding="16px 24px"
              margin="8px 0"
              style={{ overflow: "auto" }}
            >
              <Box border="solid 1px #bcbcbc" borderRadius={4}>
                <Box
                  display="flex"
                  bgcolor="#ececec"
                  padding="8px 16px"
                  alignItems="center"
                  borderBottom="solid 1px #bcbcbc"
                  style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
                >
                  <Group
                    style={{
                      marginRight: 12,
                      marginTop: 2,
                      color: theme.palette.primary.main,
                    }}
                  />
                  <Typography
                    variant="h6"
                    style={{ color: theme.palette.primary.main }}
                  >
                    <b>DANH SÁCH HỘI ĐỒNG XÉT CHỌN</b>
                  </Typography>
                </Box>
                <Box padding={1}>
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    style={{
                      padding: "6px 0",
                      backgroundColor: "#fafafa",
                      borderBottom: "solid 1px #e0e0e0",
                    }}
                  >
                    <Grid item xs={7}>
                      <Box display="flex">
                        <Box width="152px" marginRight={2} />
                        <Box width="calc(100% - 170px)">
                          <Typography variant="body1">
                            <b>Tên thành viên</b>
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body1">
                        <b>Học vị</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1">
                        <b>Vị trí</b>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={1}
                    style={{
                      padding: "12px 0",
                      borderBottom: "solid 1px #e0e0e0",
                    }}
                  >
                    {STUDENT_RESEARCH.councilFieldKey.map((role) => {
                      const { key, title } = role;
                      const isPhenikaa = councilMemberInfo?.[`${key}Pid`];
                      return (
                        <React.Fragment key={key}>
                          <Grid item xs={7}>
                            <Box display="flex">
                              <Box
                                width={152}
                                minWidth={152}
                                maxWidth={152}
                                marginRight={1}
                              >
                                <KPTextfield
                                  value={isPhenikaa || false}
                                  id={`${key}IsPhenikaa`}
                                  type="checkbox"
                                  name="GV trong trường"
                                  disabled={true}
                                  handleChangeForm={handleChangeForm}
                                />
                              </Box>
                              <Box width="calc(100% - 152px)">
                                <KPTextfield
                                  type={isPhenikaa ? "autoComplete" : null}
                                  options={isPhenikaa ? profileList : null}
                                  getOptionLabel={(option) => option.name}
                                  value={
                                    isPhenikaa
                                      ? profileList.find(
                                          (e) =>
                                            e.id ===
                                            councilMemberInfo?.[`${key}Pid`]
                                        )
                                      : councilMemberInfo?.[`${key}Name`]
                                  }
                                  id={`${key}Name`}
                                  disabled={true}
                                  handleChangeForm={handleChangeForm}
                                  required={true}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={3}>
                            <KPTextfield
                              value={councilMemberInfo?.[`${key}Degree`]}
                              disabled={true}
                              id={`${key}Degree`}
                              handleChangeForm={handleChangeForm}
                              required={true}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <KPTextfield value={title} disabled={true} />
                          </Grid>
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      ),
    },
  ];

  // if (members?.length > 0) {
  //   textForm.push({ key: 'box3', xs: 12, type: 'render', render: () => (
  //     <Box border='solid 1px #bcbcbc' borderRadius={4} marginTop={4}>
  //       <Box className={styles.header}>
  //         <Info style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }} />
  //         <Typography variant='h6' style={{ color: theme.palette.primary.main }}><b>THÀNH VIÊN THAM GIA</b></Typography>
  //       </Box>
  //       <Box padding={2}>
  //         {members?.map((member, index) => (
  //           <React.Fragment key={`member-${index}`}>
  //             {member?.leaveReason === '' && (
  //               <Box
  //                 key={`member-${index}`}
  //                 padding={2}
  //                 borderRadius={4}
  //                 marginTop={index === 0 ? 0 : 2}
  //                 display='flex'
  //                 position='relative'
  //                 alignItems='flex-end'
  //                 border='solid 1px #bcbcbc'
  //                 borderTop='solid 4px #bcbcbc'
  //               >
  //                 <Grid container spacing={1}>
  //                   {memberFieldList.map((field) => (
  //                     <Grid key={`member-${index}-${field.key}`} item xs={field.xs}>
  //                       <KPTextfield
  //                         disabled={!props?.open?.report || !member.isNew}
  //                         {...field}
  //                         required={field.required === 'only_main'
  //                           ? member.researchRole === 'main'
  //                           : field.required
  //                         }
  //                         value={
  //                           field?.key === 'departmentId' ? (
  //                             field?.options?.find((e) => e.id === member?.[field?.key])
  //                           ) : member?.[field?.key]
  //                         }
  //                         getOptionLabel={(option) => option?.name || ''}
  //                         id={`members.${index}.${field?.key}`}
  //                         handleChangeForm={handleChangeForm}
  //                       />
  //                     </Grid>
  //                   ))}
  //                   <Grid item xs={2}>
  //                     <KPTextfield
  //                       type='select'
  //                       disabled={true}
  //                       required={true}
  //                       label='Vai trò'
  //                       options={STUDENT_RESEARCH.researchRoleMemberOptions}
  //                       value={member?.researchRole}
  //                       id={`members.${index}.researchRole`}
  //                       handleChangeForm={handleChangeForm}
  //                     />
  //                   </Grid>
  //                   {(member?.researchRole === 'main') && (
  //                     <>
  //                       <Grid item xs={3}>
  //                         <KPTextfield
  //                           disabled={!props?.open?.report || !member?.isNew}
  //                           // required={true}
  //                           label='Số CMND/CCCD'
  //                           value={member?.identityNumber}
  //                           id={`members.${index}.identityNumber`}
  //                           handleChangeForm={handleChangeForm}
  //                         />
  //                       </Grid>
  //                       <Grid item xs={3}>
  //                         <KPTextfield
  //                           disabled={!props?.open?.report || !member?.isNew}
  //                           // required={true}
  //                           label='STK ngân hàng'
  //                           value={member?.bankAccNum}
  //                           id={`members.${index}.bankAccNum`}
  //                           handleChangeForm={handleChangeForm}
  //                         />
  //                       </Grid>
  //                       <Grid item xs={6}>
  //                         <KPTextfield
  //                           disabled={!props?.open?.report || !member?.isNew}
  //                           // required={true}
  //                           label='Chi nhánh ngân hàng'
  //                           value={member?.bankBranch}
  //                           id={`members.${index}.bankBranch`}
  //                           handleChangeForm={handleChangeForm}
  //                         />
  //                       </Grid>
  //                     </>
  //                   )}
  //                 </Grid>
  //                 <Box
  //                   top={-1}
  //                   right={-1}
  //                   width={48}
  //                   height={36}
  //                   bgcolor='#bcbcbc'
  //                   color='#fff'
  //                   position='absolute'
  //                   justifyContent='flex-end'
  //                   display='flex'
  //                   alignItems='center'
  //                   style={{
  //                     borderTopRightRadius: 4,
  //                     clipPath: 'polygon(0 1%, 100% 0, 100% 100%, 20% 100%)'
  //                   }}
  //                 >
  //                   <b style={{ paddingBottom: 3, paddingRight: 16, fontSize: 22 }}>
  //                     {index - members.slice(0, Math.max(index, 0)).filter((e) => e?.leaveReason !== '').length + 1}
  //                   </b>
  //                 </Box>
  //               </Box>
  //             )}
  //           </React.Fragment>
  //         ))}

  //       </Box>
  //     </Box>
  //   )})
  // }

  const getMainInstructor = () => ({
    isPhenikaa: true,
    researchRole: "main",
    name: props.type === "personal" ? profileDetail.name : "",
    email: props.type === "personal" ? profileDetail.mail : "",
    profileId: props.type === "personal" ? profileDetail.id : "",
  });

  const handleChangeForm = (key, val, type) => {
    if (key.includes("instructors")) type = "instructorField";

    if (type === "instructorField") {
      const keyList = key.split(".");
      const index = parseInt(keyList[1]);
      const fieldKey = keyList?.[2] || "all";
      const value = [...instructors];

      if (fieldKey === "all")
        value.splice(index, 1, {
          ...value[index],
          name: val?.name,
          email: val?.mail,
          profileId: val?.id,
        });
      else if (fieldKey === "isPhenikaa")
        value.splice(index, 1, {
          ...value[index],
          [fieldKey]: val,
          name: undefined,
          email: undefined,
        });
      else value.splice(index, 1, { ...value[index], [fieldKey]: val });

      setInstructors(value);
    } else {
      setValues({ ...values, [key]: val });
    }
  };

  const insertProject = () => {
    const mainProfileId = instructors.find(
      (e) => e.researchRole === "main"
    )?.profileId;
    const mainDepartmentProfile = profileList.find(
      (e) => e.id === mainProfileId
    )?.departmentProfile;
    const faculty = mainDepartmentProfile.find(
      (department) => department.department?.isFaculty
    );

    dispatch(
      insertStudentResearch({
        ...values,
        instructors,
        status: "info_default",
        schoolYear: props.year,
        departmentId: faculty?.department?.id,
      })
    );
  };

  const rejectProject = () => {
    dispatch(
      updateStudentResearch({
        ...props.detail,
        leaveReason: values?.leaveReason,
        status: "info_rejected",
      })
    );
  };

  const acceptInfo = () =>
    dispatch(updateStudentResearchStatus(props.detail.id, "info_accepted"));

  const acceptAll = () =>
    dispatch(updateStudentResearchStatus(props.detail.id, "conducted"));

  const handleSubmit = (e) => {
    props.handleClose();
    switch (props.id) {
      case "insert-form":
        insertProject();
        break;
      case "accept-info-form":
        var eventName = e.nativeEvent.submitter.name;
        if (eventName === "rejected") rejectProject();
        else acceptInfo();
        break;
      case "accept_info-form":
        acceptAll();
        break;
      // case 'accept-info-again-form':
      //   eventName = e.nativeEvent.submitter.name;
      //   if (eventName === 'info_rejected') rejectInfo(); else acceptInfo();
      //   break;
      // case 'detail-council-form':
      //   updateReview('conducted');
      //   break;
      case "edit-form":
        dispatch(
          updateStudentResearch({
            ...values,
            status: "info_default",
          })
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (props.open?.insert && profileDetail)
      setInstructors([{ ...getMainInstructor() }]);
  }, [props.open?.insert, props.type, profileDetail]);

  if (!profileList?.length || !faculties?.length) return null;

  return (
    <Box margin="-16px 0" width="calc(100% + 24px)" height="calc(100% + 100px)">
      <KPTextform
        id={props.id}
        textForm={textForm}
        values={values}
        setValues={setValues}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
}

export default DefaultForm;
