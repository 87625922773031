const STUDENT_RESEARCH = {
  researchRoleInstructorOptions: [
    { key: "main", value: "main", title: "HD chính" },
    { key: "other", value: "other", title: "HD phụ" },
  ],
  researchRoleMemberOptions: [
    { key: "main", value: "main", title: "Chủ nhiệm" },
    { key: "other", value: "other", title: "Thành viên" },
  ],
  councilFieldKey: [
    { key: "chairman", title: "Chủ tịch", required: true },
    { key: "secretary", title: "Thư ký", required: true },
    { key: "firstReviewer", title: "Phản biện 1", required: true },
    { key: "secondReviewer", title: "Phản biện 2", required: true },
    // { key: "commissioner", title: "Uỷ viên", required: true },
    { key: 'firstCommissioner', title: 'Uỷ viên 1', required: true },
    { key: 'secondCommissioner', title: 'Uỷ viên 2', required: false },
    { key: 'thirdCommissioner', title: 'Uỷ viên 3', required: false },
    { key: 'fourthCommissioner', title: 'Uỷ viên 4', required: false },
    { key: 'fifthCommissioner', title: 'Uỷ viên 5', required: false },
    { key: 'sixthCommissioner', title: 'Uỷ viên 6', required: false },
    { key: 'seventhCommissioner', title: 'Uỷ viên 7', required: false },
  ],
  status: [
    { key: "giai-doan-0" },
    { key: "info_default" },
    { key: "info_accepted" },
    { key: "info_rejected" },
    { key: "member_default" },
    { key: "giai-doan-1" },
    { key: "member_accepted" },
    { key: "conducted" },
    { key: "rejected" },
    { key: "report_default" },
    { key: "report_submitted" },
    { key: "report_stop_default" },
    { key: "giai-doan-2" },
    { key: "report_stop_accepted" },
    { key: "report_adjust_accepted" },
    { key: "report_ensure" },
    { key: "default" },
    { key: "council_default" },
    { key: "submitted" },
    { key: "giai-doan-3" },
    { key: "council_accepted" },
    { key: "adjust_request" },
    { key: "giai-doan-4" },
    { key: "research_completed" },
    { key: "accepted" },
    { key: "giai-doan-5" },
  ],
};

const INSTITUTIONAL_PROJECT = {
  type: [
    { key: "basic", value: "basic", title: "Đề tài cơ bản" },
    { key: "applicational", value: "applicational", title: "Đề tài ứng dụng" },
  ],
  roleOptions: [
    { key: "main", value: "main", title: "Chủ nhiệm" },
    { key: "other", value: "other", title: "Thành viên" },
  ],
};

export { STUDENT_RESEARCH, INSTITUTIONAL_PROJECT };
