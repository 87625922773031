import React from 'react';
import {
  Box,
  Grid,
  TextField,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import { Info, DoubleArrow } from '@material-ui/icons';
import { useDispatch, useSelector } from "react-redux";

import theme from '../../../../theme';
import select from "../../../../utils/select";

import { KPTextfield, KPTextform } from '../../../../components/KPTComponents';

import { uploadMultiFile } from "../../../../reducer/file/action";
import {
  updateInternalResearch,
} from '../../../../reducer/internalResearch/action';

function SubmitForm(props) {
  const dispatch = useDispatch();

  const { values, setValues } = props;

  const profileDetail = useSelector(state => select(state, 'profileReducer', 'detail'));

  const valueFormat = (value) => value.replace(/\D/g,'').replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, '.');

  const handleChangeForm = (key, val, type) => {
    if (type === 'file') {
      setValues({ ...values, [key]: val?.[0] });
    } else {
      setValues({ ...values, [key]: val });
    }
  }

  const fieldKey = [
    {
      key: 'vietnameseName',
      label: 'Tên đề tài tiếng Việt',
      xs: 12,
      height: 1,

    },
    {
      key: 'englishName',
      label: 'Tên đề tài tiếng Anh',
      xs: 12,
      height: 1,
    },
    {
      key: 'researchCode',
      label: 'Mã số đề tài',
      xs: 2,
    },
  ]

  const textForm = [
    { key: 'box1', xs: 12, type: 'render', render: () => (
      <Box border='solid 1px #bcbcbc' borderRadius={4}>
        <Box
          display='flex'
          bgcolor='#ececec'
          padding='8px 16px'
          alignItems='center'
          borderBottom='solid 1px #bcbcbc' 
          style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
        >
          <Info style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }} />
          <Typography variant='h6' style={{ color: theme.palette.primary.main }}><b>THÔNG TIN CƠ BẢN</b></Typography>
        </Box>
        <Box padding={2}>
          <Grid container spacing={2}>
            {fieldKey.map((e) => (
              <Grid item key={e.key} xs={e?.xs}>
                <KPTextfield
                  id={e.key}
                  value={values?.[e.key]}
                  label={e?.label}
                  height={e?.height}
                  type={e?.type}
                  required={true}
                  disabled={true}
                  handleChangeForm={handleChangeForm}
                />
              </Grid>
            ))}
            <Grid item xs={3}>
              <KPTextfield
                id='cost'
                xs={12}
                height={1}
                value={values?.cost}
                label='Kinh phí'
                handleChangeForm={handleChangeForm}
                required={true}
                type='render'
                render={() => (
                  <TextField
                    fullWidth
                    size='small'
                    variant='outlined'
                    disabled={true}
                    value={valueFormat(values?.cost?.toString() || '')}
                    InputProps={{ endAdornment: <InputAdornment position='end'>VND</InputAdornment> }}
                    inputProps={{ style: { padding: 8 } }}
                    onChange={(event) => handleChangeForm('cost', event.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={7}>
              <Box display='flex' alignItems='center'>
                <Box width='calc((100% - 48px) / 2)'>
                  <KPTextfield
                    id='startTime'
                    type='date'
                    disabled={true}
                    value={values?.startTime}
                    label='Thời gian bắt đầu'
                    handleChangeForm={handleChangeForm}
                    required={true}
                  />
                </Box>
                <Box width={48}>
                  <DoubleArrow style={{ fontSize: 28, marginTop: 32, padding: '0 10px', color: "rgba(0, 0, 0, 0.30)" }} />
                </Box>
                <Box width='calc((100% - 48px) / 2)'>
                  <KPTextfield
                    id='endTime'
                    type='date'
                    disabled={true}
                    value={values?.endTime}
                    label='Thời gian kết thúc'
                    handleChangeForm={handleChangeForm}
                    required={true}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <KPTextfield
                id='finalFileLink'
                xs={12}
                value={values?.finalFileLink}
                label='File báo cáo tổng kết'
                handleChangeForm={handleChangeForm}
                required={true}
                type='dragzone'
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    )},
  ];

  if (props.id === 'submit-accept-form' || props.id === 'submit-detail') {
    textForm.push({ key: 'box2', xs: 12, type: 'render', render: () => (
      <Box border='solid 1px #bcbcbc' borderRadius={4}>
        <Box
          display='flex'
          bgcolor='#ececec'
          padding='8px 16px'
          alignItems='center'
          borderBottom='solid 1px #bcbcbc' 
          style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
        >
          <Info style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }} />
          <Typography variant='h6' style={{ color: theme.palette.primary.main }}><b>NGHIỆM THU ĐỀ TÀI</b></Typography>
        </Box>
        <Box padding={2}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <KPTextfield
                id='point'
                value={values?.point}
                label='Tổng điểm'
                type='number'
                step={0.01}
                min={0}
                max={100}
                required={true}
                disabled={props.id === 'submit-detail'}
                handleChangeForm={handleChangeForm}
              />
            </Grid>
            <Grid item xs={8}>
              <KPTextfield
                id='rank'
                value={values?.rank}
                label='Xếp loại'
                type='select'
                options={[
                  { key: 'passed', value: 'passed', title: 'Đạt' },
                  { key: 'failed', value: 'failed', title: 'Không đạt' },
                ]}
                required={true}
                handleChangeForm={handleChangeForm}
                disabled={props.id === 'submit-detail'}
                style={{ marginTop: '25.5px' }}
              />
            </Grid>
            <Grid item xs={12}>
              <KPTextfield
                id='editRequest'
                value={values?.editRequest}
                label='Yêu cầu chỉnh sửa bổ sung'
                height={3}
                disabled={props.id === 'submit-detail'}
                handleChangeForm={handleChangeForm}
              />
            </Grid>
            <Grid item xs={12}>
              <KPTextfield
                id='review'
                value={values?.review}
                label='File Phiếu đánh giá nghiệm thu'
                type='dragzone'
                required={true}
                disabled={props.id === 'submit-detail'}
                handleChangeForm={handleChangeForm}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    )});
  }

  const request = () => {
    if (typeof values?.review === 'string' && values?.review.includes('storage')) {
      dispatch(uploadMultiFile(profileDetail?.id, { review: values?.review })).then((res) => {
        if (res.success) {
          const files = res.data;

          dispatch(updateInternalResearch({
            ...props.detail,
            status: 'adjust_request',
            point: values.point,
            rank: values.rank,
            review: files[0],
            endTime: props.detail.endTime,
            startTime: props.detail.startTime,
          }));
        }
      });
    } else {
      dispatch(updateInternalResearch({
        ...props.detail,
        status: 'adjust_request',
        point: values.point,
        rank: values.rank,
        endTime: props.detail.endTime,
        startTime: props.detail.startTime,
      }));
    }
  }

  const submit = () => {
    var statusList = props.detail.status.map((e) => e.value);
    statusList[0] = 'submitted';

    if (typeof values?.finalFileLink === 'string' && values?.finalFileLink.includes('storage')) {
      dispatch(updateInternalResearch({
        ...props.detail,
        status: statusList.join(','),
        endTime: props.detail.endTime,
        startTime: props.detail.startTime,
      }));
    } else {
      dispatch(uploadMultiFile(profileDetail?.id, { finalFileLink: values?.finalFileLink })).then((res) => {
        if (res.success) {
          const files = res.data;
          dispatch(updateInternalResearch({
            ...props.detail,
            status: statusList.join(','),
            finalFileLink: files[0],
            endTime: props.detail.endTime,
            startTime: props.detail.startTime,
          }));
        }
      });
    }
  }

  const submitAccept = () => {
    if (typeof values?.review === 'string' && values?.review.includes('storage')) {
      dispatch(updateInternalResearch({
        ...props.detail,
        status: 'accepted',
        point: values.point,
        rank: values.rank,
        endTime: props.detail.endTime,
        startTime: props.detail.startTime,
      }));
    } else {
      dispatch(uploadMultiFile(profileDetail?.id, { review: values?.review })).then((res) => {
        if (res.success) {
          const files = res.data;
          dispatch(updateInternalResearch({
            ...props.detail,
            status: 'accepted',
            point: values.point,
            rank: values.rank,
            review: files[0],
            endTime: props.detail.endTime,
            startTime: props.detail.startTime,
          }));
        }
      });
    }
  }

  const handleSubmit = (e) => {
    props.handleClose();
    switch (props.id) {
      case 'submit-form':
      case 'submit-edit-form':
        submit();
        break;
      // case 'submit-accept-form':
      //   submitAccept();
      //   break;
      case 'submit-accept-form':
        var eventName = e.nativeEvent.submitter.name;
        if (eventName === 'accept') submitAccept(); else request();
        break;
      default:
        break;
    }
  }

  return (
    <KPTextform
      id={props.id}
      textForm={textForm}
      values={values}
      setValues={setValues}
      handleChangeForm={handleChangeForm}
      handleSubmit={handleSubmit}
    />
  );
}

export default SubmitForm;
