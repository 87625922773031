import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Divider,
  Typography,
} from '@material-ui/core';
import { Add, Replay } from '@material-ui/icons';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import { getAllProfile } from '../../../reducer/profile/action';
import { getAllSolution, getAllSolutionById, getFilteredSolution } from '../../../reducer/solution/action';

import SolutionInsertModal from '../SolutionInsertModal';

function SolutionButton(props) {
  const { getAllProfile, getAllSolutionById, getFilteredSolution } = props;

  const [inserting, setInserting] = useState(false);

  let profileId = props.selectedProfileId;
  if (!profileId) profileId = props.profile?.id;

  useEffect(() => {
    if (props.listProfile.length === 0) getAllProfile();
  }, [getAllProfile, props.listProfile.length]);

  useEffect(() => {
    if (props.type !== 'all' && profileId) getAllSolutionById(profileId);
  }, [profileId]);

  useEffect(() => {
    if (props.type === 'all' && props.listFilteredSolution.length === 0 && !props.isFetching) getFilteredSolution(1, -1, {departmentId: props.department});
  }, []);

  useEffect(() => {
    if (props.type === 'all' && !props.isFetching && !props.isFetchingAccepted) getFilteredSolution(1, -1, {departmentId: props.department})
  }, [props.department])

  const handleReload = () => {
    if (props.type === 'all') {
      getFilteredSolution(1, -1, {departmentId: props.department})
      props.handleReload();
    } else getAllSolutionById(profileId);
      props.handleReload();
  }

  const handleInsert = () => setInserting(true);

  return (
    <>
      <Grid container>
        <Grid item xs={6}><Typography variant="h5" style={{ lineHeight: '36px' }}>Giải pháp hữu ích</Typography></Grid>
        <Grid item xs={6}>
          <div style={{ float: 'right' }}>
            <Button variant="outlined" startIcon={<Replay />}  onClick={handleReload}>Tải lại</Button>
            {
              (props.type !== 'all') &&
              <Button variant="contained" color="primary" startIcon={<Add />} style={{ marginLeft: 8 }} onClick={handleInsert}>Thêm</Button>
            }
          </div>
        </Grid>
      </Grid>
      <Divider style={{ margin: '8px 0' }} />
      <SolutionInsertModal inserting={inserting} setInserting={setInserting} />
    </>
  );
}

const mapStateToProps = (state) => ({
  isFetching: select(state, 'solutionReducer', 'isFetching'),
  isFetchingAccepted: select(state, 'solutionReducer', 'isFetchingAccepted'),

  profile: select(state, 'profileReducer', 'detail'),
  listProfile: select(state, 'profileReducer', 'list'),
  listSolution: select(state, 'solutionReducer', 'list'),
  listSolutionById: select(state, 'solutionReducer', 'listById'),
  listFilteredSolution: select(state, 'solutionReducer', 'listFiltered'),
});

const mapDispatchToProps = (dispatch) => ({
  getAllProfile: () => dispatch(getAllProfile()),
  getAllSolution: () => dispatch(getAllSolution()),
  getAllSolutionById: (id) => dispatch(getAllSolutionById(id)),
  getFilteredSolution: (page, pageSize, payload) => dispatch(getFilteredSolution(page, pageSize, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(SolutionButton));
