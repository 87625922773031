/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  TextField,
  Typography,
} from '@material-ui/core';

import toJs from '../../../../hoc/toJS';
import select from '../../../../utils/select';

import KPTable from '../../../../components/KPTable';

import { updateEarningsTable } from '../../../../reducer/earningsTable/action';

function RewardInformationTable(props) {
  const [editting, setEditting] = useState(null);

  const valueFormat = (value) => value.replace(/\D/g,'').replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, '.')

  const handleSubmit = () => {
    props.updateEarningsTable(editting.id, editting);
    setEditting(null);
  }

  const handleChange = (value) => setEditting({
    ...editting,
    value: parseInt(value.replaceAll('.', '')),
  });

  return (
    <KPTable
      shadow="none"
      rowsPerPage={100}
      maxHeight='calc(100vh - 288px)'
      columns={[
        {
          title: <b>ID</b>,
          key: 'id',
          width: '10%',
        },
        {
          title: <b>Loại</b>,
          key: 'type',
          width: '20%',
          filters: [
            {
              text: 'Bài báo khoa học',
              value: 'scientificResearch',
            },
            {
              text: 'Bằng sáng chế',
              value: 'patent',
            },
            {
              text: 'Giải pháp hữu ích',
              value: 'solution',
            }
          ],
          onFilter: (value, row) => row.filter((e) => e.type === value),
          render: (value) => {
            if (value === 'scientificResearch') return 'Bài báo khoa học';
            if (value === 'patent') return 'Bằng sáng chế';
            if (value === 'solution') return 'Giải pháp hữu ích';
            return value
          }
        },
        {
          title: <b>Chỉ tiêu đăng ký</b>,
          key: 'condition',
          width: '20%',
        },
        {
          title: <b>Mức thu nhập hàng tháng</b>,
          key: 'value',
          width: '20%',
          render: (value, row) => {
            if (editting?.id === row.id) {
              return (
                <form onSubmit={handleSubmit}>
                  <TextField
                    autoFocus
                    fullWidth
                    size='small'
                    variant='outlined'
                    value={valueFormat(editting?.value.toString() || '')}
                    onChange={(event) => handleChange(event.target.value)}
                  />
                </form>
              )
            }
            return(valueFormat(value.toString()));
          }
        },
        {
          title: <b>Tác vụ</b>,
          key: 'action',
          width: '20%',
          render: (value, row) => (
            <>
              {(editting?.id !== row.id) ? (
                <>
                  <Typography className='table-action-buton' variant='caption' color='primary' onClick={() => setEditting(row)}>Sửa</Typography>
                  {/* <Typography variant='caption'> | </Typography>
                  <Typography className='table-action-buton' variant='caption' color='secondary'>Xoá</Typography> */}
                </>
              ) : (
                <>
                  <Typography className='table-action-buton' variant='caption' color='primary' onClick={handleSubmit}>Lưu</Typography>
                  <Typography className='table-action-buton' variant='caption' color='secondary' onClick={() => setEditting(null)}>Huỷ</Typography>
                </>
              )}
            </>
          )
        }
      ]}
      rows={props.list.filter((e) => e.condition !== 'chapterEnglish' && e.condition !== 'chapterOther' && e.condition !== 'monographEnglish' && e.condition !== 'monographOther')}
    />
  );
}

const mapStateToProps = (state) => ({
  list: select(state, 'earningsTableReducer', 'list'),
});

const mapDispatchToProps = (dispatch) => ({
  updateEarningsTable: (id, payload) => dispatch(updateEarningsTable(id, payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(RewardInformationTable));
