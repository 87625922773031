import React from 'react';

import PatentTable from '../../containers/Patent/PatentTable';
import PatentButton from '../../containers/Patent/PatentButton';

function Patent(props) {
  const list = window.location.href.split('/');
  const lastValueList = list[list.length - 1].split('=');
  const selectedProfileId = (lastValueList.length > 1) ? lastValueList[lastValueList.length - 1] : null;

  return (
    <div style={{ padding: 8, minHeight: 'calc(100vh - 200px)', maxHeight: 'calc(100vh - 200px)' }}>
      <PatentButton type={props.type} department={props.department} selectedProfileId={selectedProfileId} handleReload={props.handleReload}/>
      <PatentTable adminTab={props.adminTab} type={props.type} department={props.department} selectedProfileId={selectedProfileId} handleReload={props.handleReload}/>
    </div>
  );
}

export default Patent;
