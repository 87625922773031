import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
} from "@material-ui/core";
import dayjs from "dayjs";

function DepartmentProfileModal(props) {
  const { member, setMember } = props;

  const handleCloseModal = () => {
    setMember(null);
  };

  return (
    <Dialog
      fullWidth
      keepMounted
      maxWidth="md"
      open={member !== null}
      onClose={handleCloseModal}
    >
      <DialogTitle>Chi tiết hồ sơ</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <Box textAlign="right" pr={2}>
              <strong>ID:</strong>
            </Box>
          </Grid>
          <Grid item xs={8}>
            {member?.userId}
          </Grid>
          <Grid item xs={4}>
            <Box textAlign="right" pr={2}>
              <strong>Họ tên:</strong>
            </Box>
          </Grid>
          <Grid item xs={8}>
            {member?.name}
          </Grid>
          <Grid item xs={4}>
            <Box textAlign="right" pr={2}>
              <strong>Mã PU:</strong>
            </Box>
          </Grid>
          <Grid item xs={8}>
            {member?.code}
          </Grid>
          <Grid item xs={4}>
            <Box textAlign="right" pr={2}>
              <strong>Số CMND:</strong>
            </Box>
          </Grid>
          <Grid item xs={8}>
            {member?.userId}
          </Grid>
          <Grid item xs={4}>
            <Box textAlign="right" pr={2}>
              <strong>Ngày sinh:</strong>
            </Box>
          </Grid>
          <Grid item xs={8}>
            {dayjs(member?.birth).isValid()
              ? dayjs(member?.birth).format("DD/MM/YYYY")
              : ""}
          </Grid>
          <Grid item xs={4}>
            <Box textAlign="right" pr={2}>
              <strong>Giới tính:</strong>
            </Box>
          </Grid>
          <Grid item xs={8}>
            {member?.gender}
          </Grid>

          <Grid item xs={4}>
            <Box textAlign="right" pr={2}>
              <strong>Địa chỉ:</strong>
            </Box>
          </Grid>
          <Grid item xs={8}>
            {member?.address}
          </Grid>
          <Grid item xs={4}>
            <Box textAlign="right" pr={2}>
              <strong>Điện thoại:</strong>
            </Box>
          </Grid>
          <Grid item xs={8}>
            {member?.phone}
          </Grid>
          <Grid item xs={4}>
            <Box textAlign="right" pr={2}>
              <strong>Email:</strong>
            </Box>
          </Grid>
          <Grid item xs={8}>
            {member?.mail}
          </Grid>
          <Grid item xs={4}>
            <Box textAlign="right" pr={2}>
              <strong>Học vị:</strong>
            </Box>
          </Grid>
          <Grid item xs={8}>
            {member?.degree}
          </Grid>
          <Grid item xs={4}>
            <Box textAlign="right" pr={2}>
              <strong>Học hàm:</strong>
            </Box>
          </Grid>
          <Grid item xs={8}>
            {member?.academicRank}
          </Grid>
          <Grid item xs={4}>
            <Box textAlign="right" pr={2}>
              <strong>Chuyên ngành:</strong>
            </Box>
          </Grid>
          <Grid item xs={8}>
            {member?.major}
          </Grid>
          <Grid item xs={4}>
            <Box textAlign="right" pr={2}>
              <strong>Năm tốt nghiệp PhD:</strong>
            </Box>
          </Grid>
          <Grid item xs={8}>
            {member?.graduatedYearPhd}
          </Grid>
          <Grid item xs={4}>
            <Box textAlign="right" pr={2}>
              <strong>Chuyên môn:</strong>
            </Box>
          </Grid>
          <Grid item xs={8}>
            {member?.profession}
          </Grid>
          <Grid item xs={4}>
            <Box textAlign="right" pr={2}>
              <strong>Số sinh viên hướng dẫn cấp trường:</strong>
            </Box>
          </Grid>
          <Grid item xs={8}>
            {member?.numGuideStudentSchool}
          </Grid>
          <Grid item xs={4}>
            <Box textAlign="right" pr={2}>
              <strong>Số sinh viên hướng dẫn cấp bộ:</strong>
            </Box>
          </Grid>
          <Grid item xs={8}>
            {member?.numGuideStudentMinistry}
          </Grid>
        </Grid>
        <Box py={2} />
      </DialogContent>
    </Dialog>
  );
}

export default DepartmentProfileModal;
