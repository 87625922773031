import { fromJS } from "immutable";

import TYPE from "../../constants/type";

const initialState = fromJS({
  list: [],
  listById: [],
  listFiltered: [],
  detail: null,
  isFetching: false,
  isFetchingAccepted: false,
});

export default function contractReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.CONTRACT.GET_ALL_CONTRACT_LOADING:
    case TYPE.CONTRACT.GET_FILTERED_CONTRACT_LOADING:  
    case TYPE.CONTRACT.GET_ALL_CONTRACT_BY_PROFILE_ID_LOADING:
    case TYPE.CONTRACT.INSERT_CONTRACT_LOADING:
    case TYPE.CONTRACT.UPDATE_CONTRACT_LOADING:
    case TYPE.CONTRACT.DELETE_CONTRACT_LOADING:
      return state.merge({
        isFetching: true,
      });

    case TYPE.CONTRACT.ACCEPT_CONTRACT_LOADING:
      return state.merge({
        isFetchingAccepted: true,
      });

    case TYPE.CONTRACT.GET_ALL_CONTRACT_FAILURE:
    case TYPE.CONTRACT.GET_FILTERED_CONTRACT_FAILURE:
    case TYPE.CONTRACT.GET_ALL_CONTRACT_BY_PROFILE_ID_FAILURE:
    case TYPE.CONTRACT.INSERT_CONTRACT_FAILURE:
    case TYPE.CONTRACT.UPDATE_CONTRACT_FAILURE:
    case TYPE.CONTRACT.DELETE_CONTRACT_FAILURE:
      return state.merge({
        isFetching: false,
      });

    case TYPE.CONTRACT.ACCEPT_CONTRACT_FAILURE:
      return state.merge({
        isFetchingAccepted: false,
      });

    case TYPE.CONTRACT.GET_ALL_CONTRACT_SUCCESS:
      return state.merge({
        list: action.payload || [],
        isFetching: false,
      });

    case TYPE.CONTRACT.GET_FILTERED_CONTRACT_SUCCESS:
      return state.merge({
        listFiltered: action.payload || [],
        isFetching: false,
      });

    case TYPE.CONTRACT.GET_ALL_CONTRACT_BY_PROFILE_ID_SUCCESS:
      return state.merge({
        listById: [...action.payload],
        list: action.payload || [],
        isFetching: false,
      });

    case TYPE.CONTRACT.GET_CONTRACT_BY_ID_SUCCESS:
      return state.merge({
        detail: {...action.payload},
        isFetching: false,
      });

    case TYPE.CONTRACT.INSERT_CONTRACT_SUCCESS:
      const newlistById = state.get('listById');
      const index = action.payload.productContractProfile.findIndex((e) => e.profileId === action.id);
      if (index >= 0) newlistById.push({ productContract: action.payload });
      return state.merge({
        listById: [...newlistById],
        listFiltered: [...state.get('listFiltered'), { productContract: action.payload }],
        isFetching: false,
      });

    case TYPE.CONTRACT.UPDATE_CONTRACT_SUCCESS: {
      const newList = state.get('listFiltered');
      const index = newList.findIndex((e) => e.productContract?.id === action?.id);
      newList.splice(index, 1, { productContract: action.payload });

      const newList1 = state.get('listById');
      const index1 = newList1.findIndex((e) => e.productContract?.id === action?.id);
      newList1.splice(index1, 1, { productContract: action.payload });

      return state.merge({
        listFiltered: [...newList],
        listById: [...newList1],
        isFetching: false,
      });
    }

    case TYPE.CONTRACT.DELETE_CONTRACT_SUCCESS: {
      const newList = state.get('listFiltered');
      const index = newList.findIndex((e) => e.productContract?.id === action?.id);
      if (index !== -1) newList.splice(index, 1);

      const newList1 = state.get('listById');
      const index1 = newList1.findIndex((e) => e.productContract?.id === action?.id);
      if (index1 !== -1) newList1.splice(index1, 1);

      return state.merge({
        listFiltered: [...newList],
        listById: [...newList1],
        isFetching: false,
      });
    }
  
    case TYPE.CONTRACT.ACCEPT_CONTRACT_SUCCESS: {
      const newList = state.get('listById');
      const index = newList.findIndex((e) => e.productContract?.id === action.payload?.id);
      const newproductContract = { ...newList[index], productContract: action.payload};
      newList.splice(index, 1, newproductContract);

      const newList1 = state.get('listFiltered');
      const index1 = newList1.findIndex((e) => e.productContract?.id === action.payload?.id);
      const newproductContract1 = { ...newList1[index1], productContract: action.payload};
      newList1.splice(index1, 1, newproductContract1);

      return state.merge({
        listById: [...newList],
        listFiltered: [...newList1],
        isFetchingAccepted: false,
      });
    }

    default:
      return state;
  }
}
