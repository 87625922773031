import {
  AlignmentType,
  Document,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TabStopType,
  TextRun,
  VerticalAlign,
  WidthType,
} from "docx";
import { AcademicRankAbbrv, DegreeAbbrv } from "../abbreviation";
import { commonBorders } from "./helpers/commonNoneBorders";

export class StudentProjectStopDecisionDocx {
  create = (data) => {
    const renderPhuLuc1 = [];
    const renderPhuLuc2 = [];

    var counter = 0
    data.filter((e) => e.isStop).forEach((item, index) => {
      counter++;
      item.researchMembers.forEach((member, memberId) => {
        // Nếu row là chủ nhiệm của đề tài thì render cái này ra
        const isMain = member.researchRole === "main";
        var renderMain = [];
        if (isMain) {
          renderMain.push(
            new Paragraph({
              alignment: AlignmentType.CENTER,
              spacing: { after: 60 },
              children: [new TextRun({ text: "Chủ nhiệm đề tài", size: 11 * 2 })],
            })
          );
        }
        //
        var children = [
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                verticalAlign: VerticalAlign.CENTER,
                spacing: { after: 60, before: 60 },
                children: [new TextRun({ text: `${index + 1}`, size: 11 * 2 })],
              }),
            ],
            rowSpan: item.researchMembers.length,
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                verticalAlign: VerticalAlign.CENTER,
                spacing: { after: 60, before: 60 },
                children: [new TextRun({ text: item.name, size: 11 * 2 })],
              }),
            ],
            rowSpan: item.researchMembers.length,
          }),
          new TableCell({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                spacing: { after: 60, before: 60 },
                children: [new TextRun({ text: `${member.name} - ${member.academicYear} - ${member.class}`, size: 11 * 2 })],
              }),
              ...renderMain,
            ],
          }),
          new TableCell({
            rowSpan: item.researchMembers.length,
            spacing: { after: 60, before: 60 },
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                verticalAlign: VerticalAlign.CENTER,
                children: [
                  new TextRun({
                    text: item.instructors
                      .map((e) => `${AcademicRankAbbrv(e?.academicRank)}${DegreeAbbrv(e?.degree)}${e?.name}`)
                      .join(", "),
                    size: 11 * 2,
                  }),
                ],
              }),
            ],
          }),
        ];

        if (memberId !== 0) children = [children[2]];

        renderPhuLuc1.push(new TableRow({ children }));
      })
    });

    var counter2 = 0
    data.forEach((item, index) =>
      item.researchMembers.forEach((member, memberId) => {
        // Nếu row nghỉ thì render cái dòng này ra
        if (member?.leaveReason !== "") {
          counter2++;
          var children = [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  verticalAlign: VerticalAlign.CENTER,
                  spacing: { after: 60, before: 60 },
                  children: [new TextRun({ text: `${index + 1}`, size: 11 * 2 })],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  verticalAlign: VerticalAlign.CENTER,
                  spacing: { after: 60, before: 60 },
                  children: [new TextRun({ text: member.name, size: 11 * 2 })],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  spacing: { after: 60, before: 60 },
                  children: [new TextRun({ text: member.class, size: 11 * 2 })],
                }),
              ],
            }),
          ];

          renderPhuLuc2.push(new TableRow({ children }));
        }
      })
    );

    const document = new Document({
      sections: [
        {
          children: [
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: { line: 276 },
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                              text: "BỘ GIÁO DỤC VÀ ĐÀO TẠO",
                              size: 11.5 * 2,
                            }),
                          ],
                        }),
                        new Paragraph({
                          spacing: { line: 276 },
                          children: [
                            new TextRun({
                              text: "TRƯỜNG ĐẠI HỌC DEMO",
                              bold: true,
                              size: 11.5 * 2,
                            }),
                          ],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          spacing: { line: 276 },
                          children: [
                            new TextRun({
                              text: "---------------------------",
                              size: 11.5 * 2,
                            }),
                          ],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          spacing: { line: 276 },
                          children: [
                            new TextRun({
                              text: "Số:         /QĐ-ĐHP-KHCN",
                              size: 11.5 * 2,
                            }),
                          ],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      width: { size: 40, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                      borders: commonBorders,
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: { line: 276 },
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                              text: "CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM",
                              bold: true,
                              size: 11.5 * 2,
                            }),
                          ],
                        }),
                        new Paragraph({
                          spacing: { line: 276 },
                          children: [
                            new TextRun({
                              text: "Độc lập - Tự do - Hạnh phúc",
                              bold: true,
                              size: 11.5 * 2,
                            }),
                          ],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          spacing: { line: 276 },
                          children: [
                            new TextRun({
                              text: "------------------------------",
                              size: 11.5 * 2,
                            }),
                          ],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          spacing: { line: 276 },
                          children: [
                            new TextRun({
                              text: "Hà Nội, ngày       tháng        năm 20..",
                              italics: true,
                              size: 11.5 * 2,
                            }),
                          ],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      width: { size: 50, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                      borders: commonBorders,
                    }),
                  ],
                }),
              ],
            }),
            new Paragraph({
              children: [new TextRun({ text: " ", size: 13 * 2 })],
            }),
            new Paragraph({
              spacing: { before: 120, after: 60 },
              children: [new TextRun({ text: "QUYẾT ĐỊNH", size: 14 * 2, bold: true })],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Phê duyệt Danh mục các đề tài sinh viên NCKH Khoa...",
                  size: 13 * 2,
                  bold: true,
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Trường Đại học Demo năm học 2022 - 2023 dừng triển khai thực hiện và",
                  size: 13 * 2,
                  bold: true,
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "danh sách các sinh viên dừng tham gia thực hiện đề tài",
                  size: 13 * 2,
                  bold: true,
                }),
              ],
            }),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: "-----------------------------------",
                  size: 13 * 2,
                  bold: true,
                }),
              ],
            }),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              spacing: {
                after: 120,
              },
              children: [
                new TextRun({
                  text: "HIỆU TRƯỞNG TRƯỜNG ĐẠI HỌC DEMO",
                  size: 13 * 2,
                  bold: true,
                }),
              ],
            }),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              spacing: {
                line: 312,
              },
              tabStops: [{ type: TabStopType.LEFT, position: 428.571428 }],
              children: [
                new TextRun({
                  text: `\tCăn cứ Quyết định số 1368/QĐ-TTg ngày 10/10/2007 của Thủ tướng Chính phủ về việc thành lập Trường Đại học Thành Tây và Quyết định số 1609/QĐ-TTg ngày 21/11/2018 của Thủ tướng Chính phủ về việc đổi tên Trường Đại học Thành Tây thành Trường Đại học Demo;`,
                  size: 13 * 2,
                  italics: true,
                }),
              ],
            }),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              spacing: {
                line: 312,
              },
              tabStops: [{ type: TabStopType.LEFT, position: 428.571428 }],
              children: [
                new TextRun({
                  text: "\tCăn cứ Nghị quyết số 12/NQ-ĐHP-HĐT ngày 18/9/2020 của Hội đồng Trường về việc ban hành Quy chế Tổ chức và hoạt động của Trường Đại học Demo;",
                  size: 13 * 2,
                  italics: true,
                }),
              ],
            }),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              spacing: {
                line: 312,
              },
              tabStops: [{ type: TabStopType.LEFT, position: 428.571428 }],
              children: [
                new TextRun({
                  text: "\tQuyết định số 468/QĐ-ĐHP-KHCN ngày 19/10/2020 của Hiệu trưởng Trường Đại học Demo về việc ban hành Quy chế quản lý hoạt động Khoa học & Công nghệ Trường Đại học Demo;",
                  size: 13 * 2,
                  italics: true,
                }),
              ],
            }),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              spacing: {
                line: 312,
              },
              tabStops: [{ type: TabStopType.LEFT, position: 428.571428 }],
              children: [
                new TextRun({
                  text: "\tCăn cứ Quyết định số 1111/QĐ-ĐHP-KHCN ngày 5/9/2022 của Hiệu trưởng Trường Đại học Demo về việc ban hành Quy định về hoạt động nghiên cứu khoa học của sinh viên Trường Đại học Demo;",
                  size: 13 * 2,
                  italics: true,
                }),
              ],
            }),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              spacing: {
                line: 312,
              },
              tabStops: [{ type: TabStopType.LEFT, position: 428.571428 }],
              children: [
                new TextRun({
                  text: "\tCăn cứ thông báo số 158/TB-ĐHP-KHCN ngày 06 tháng 09 năm 2022 về việc triển khai đề tài sinh viên NCKH năm học 2022 - 2023;",
                  size: 13 * 2,
                  italics: true,
                }),
              ],
            }),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              spacing: {
                line: 312,
              },
              tabStops: [{ type: TabStopType.LEFT, position: 428.571428 }],
              children: [
                new TextRun({
                  text: "\tCăn cứ Quyết định số… /QĐ-ĐHP-KHCN ngày …/…/2022 của Hiệu trưởng Trường Đại học Demo về việc phê duyệt Danh mục các đề tài sinh viên NCKH Khoa… Trường Đại học Demo năm học 2022 – 2023;",
                  size: 13 * 2,
                  italics: true,
                }),
              ],
            }),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              spacing: {
                line: 312,
              },
              tabStops: [{ type: TabStopType.LEFT, position: 428.571428 }],
              children: [
                new TextRun({
                  text: "\tTheo đề nghị của Trường phòng Khoa học Công nghệ.",
                  size: 13 * 2,
                  italics: true,
                }),
              ],
            }),

            new Paragraph({
              spacing: {
                before: 100,
                after: 100,
                line: 360,
              },
              children: [new TextRun({ text: "QUYẾT ĐỊNH:", size: 13 * 2, bold: true })],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              spacing: {
                line: 312,
              },
              children: [
                new TextRun({ text: "\tĐiều 1. ", size: 13 * 2, bold: true }),
                new TextRun({
                  text: `Phê duyệt Danh mục ${data.length} đề tài sinh viên NCKH Khoa… dừng triển khai thực hiện (Phụ lục 1), Danh sách … sinh viên dừng tham gia thực hiện đề tài sinh viên NCKH (Phụ lục 2).`,
                  size: 13 * 2,
                }),
              ],
              tabStops: [{ type: TabStopType.LEFT, position: 428.571428 }],
            }),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              spacing: {
                line: 312,
              },
              children: [
                new TextRun({ text: "\tĐiều 2. ", size: 13 * 2, bold: true }),
                new TextRun({
                  text: "Giao Phòng Khoa học Công nghệ phối hợp với Khoa… thông báo cho các sinh viên có tên trong các Danh mục nêu tại Điều 1 về việc dừng triển khai thực hiện đề tài.",
                  size: 13 * 2,
                }),
              ],
              tabStops: [{ type: TabStopType.LEFT, position: 428.571428 }],
            }),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              spacing: {
                line: 312,
              },
              children: [
                new TextRun({ text: "\tĐiều 3. ", size: 13 * 2, bold: true }),
                new TextRun({
                  text: "Trưởng phòng Khoa học Công nghệ, Trưởng các đơn vị và các cá nhân có liên quan chịu trách nhiệm thi hành Quyết định này.",
                  size: 13 * 2,
                }),
              ],
              tabStops: [{ type: TabStopType.LEFT, position: 428.571428 }],
            }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Nơi nhận:",
                              bold: true,
                              size: 12 * 2,
                            }),
                          ],
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: "Như Điều 3;", size: 12 * 2 })],
                          bullet: { levels: [{ text: "-" }], level: 0, character: String.fromCharCode(0x2010) },
                        }),
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "Lưu VT, KHCN.",
                              size: 12 * 2,
                            }),
                          ],
                          bullet: { level: 0 },
                        }),
                      ],
                      width: { size: 50, type: WidthType.PERCENTAGE },
                      borders: commonBorders,
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "HIỆU TRƯỞNG",
                              bold: true,
                              size: 12 * 2,
                            }),
                          ],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: " ", size: 12 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: " ", size: 12 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: " ", size: 12 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: " ", size: 12 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: " ", size: 12 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: " ", size: 12 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: " ", size: 12 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: " ", size: 12 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [
                            new TextRun({
                              text: "GS.TS. Phạm Thành Huy",
                              bold: true,
                              size: 12 * 2,
                            }),
                          ],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: " ", size: 12 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                        new Paragraph({
                          children: [new TextRun({ text: " ", size: 12 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      width: { size: 50, type: WidthType.PERCENTAGE },
                      borders: commonBorders,
                    }),
                  ],
                }),
              ],
              width: { size: 100, type: WidthType.PERCENTAGE },
            }),
            new Paragraph({
              children: [],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [new TextRun({ text: "PHỤ LỤC 1", size: 13 * 2, bold: true })],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "DANH MỤC CÁC ĐỀ TÀI SINH VIÊN NCKH KHOA... TRƯỜNG ĐẠI HỌC DEMO NĂM HỌC 2022 - 2023 DỪNG TRIỂN KHAI THỰC HIỆN",
                  size: 13 * 2,
                  bold: true,
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              spacing: {
                before: 60,
                after: 60,
              },
              children: [
                new TextRun({
                  text: "(Kèm theo Quyết định số        /QĐ-ĐHP-KHCN ngày       tháng      năm 20…)",
                  size: 13 * 2,
                  italics: true,
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: {
                            line: 312,
                          },
                          children: [new TextRun({ text: "TT", bold: true, size: 11 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      width: { size: 5, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: {
                            line: 312,
                          },
                          alignment: AlignmentType.CENTER,
                          children: [new TextRun({ text: "Tên đề tài", bold: true, size: 11 * 2 })],
                        }),
                      ],
                      width: { size: 20, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: {
                            before: 60,
                            line: 312,
                          },
                          alignment: AlignmentType.CENTER,
                          children: [new TextRun({ text: "Sinh viên thực hiện", bold: true, size: 11 * 2 })],
                        }),
                        new Paragraph({
                          spacing: {
                            line: 312,
                          },
                          alignment: AlignmentType.CENTER,
                          children: [new TextRun({ text: "Đơn vị", bold: true, size: 11 * 2 })],
                        }),
                      ],
                      width: { size: 20, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: {
                            line: 312,
                          },
                          alignment: AlignmentType.CENTER,
                          children: [new TextRun({ text: "Cán bộ hướng dẫn", bold: true, size: 11 * 2 })],
                        }),
                      ],
                      width: { size: 20, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                    }),
                  ],
                  tableHeader: true,
                }),
                ...renderPhuLuc1,
              ],
            }),
            new Paragraph({
              spacing: {
                before: 60,
              },
              children: [
                new TextRun({
                  text: `(Ấn định danh sách ${counter} đề tài)`,
                  size: 12 * 2,
                  italics: true,
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [new TextRun({ text: "PHỤ LỤC 2", size: 13 * 2, bold: true })],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "DANH MỤC CÁC SINH VIÊN DỪNG THAM GIA THỰC HIỆN ĐỀ TÀI NCKH KHOA... TRƯỜNG ĐẠI HỌC DEMO NĂM HỌC 2022 - 2023",
                  size: 13 * 2,
                  bold: true,
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Paragraph({
              spacing: {
                before: 60,
                after: 60,
              },
              children: [
                new TextRun({
                  text: "(Kèm theo Quyết định số        /QĐ-ĐHP-KHCN ngày       tháng      năm 20…)",
                  size: 13 * 2,
                  italics: true,
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: {
                            before: 60,
                            after: 60,
                          },
                          children: [new TextRun({ text: "TT", bold: true, size: 11 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      width: { size: 5, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: {
                            before: 60,
                            after: 60,
                          },
                          alignment: AlignmentType.CENTER,
                          children: [new TextRun({ text: "Họ và tên sinh viên", bold: true, size: 11 * 2 })],
                        }),
                      ],
                      width: { size: 20, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          spacing: {
                            before: 60,
                            after: 60,
                          },
                          children: [new TextRun({ text: "Đơn vị", bold: true, size: 11 * 2 })],
                          alignment: AlignmentType.CENTER,
                        }),
                      ],
                      width: { size: 20, type: WidthType.PERCENTAGE },
                      verticalAlign: VerticalAlign.CENTER,
                    }),
                  ],
                  tableHeader: true,
                }),
                ...renderPhuLuc2,
              ],
            }),
            new Paragraph({
              spacing: {
                before: 60,
              },
              children: [
                new TextRun({
                  text: `(Ấn định danh sách ${counter2} sinh viên)`,
                  size: 12 * 2,
                  italics: true,
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
          ],
          properties: {
            page: {
              margin: { left: "3cm", right: "2cm", top: "2cm", bottom: "2cm" },
            },
          },
        },
      ],
    });

    return document;
  };
}
