import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { Formik, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import dayjs from "dayjs";

import toJS from "../../../hoc/toJS";
import { API_URLS } from "../../../constants/api";
import { apiCall } from "../../../utils/api";

import { notification } from "../../../reducer/home/action";

const humanResourceValidationSchema = yup.object().shape({
  name: yup.string().required("Không được để trống"),
  birth: yup.date().required("Không được để trống"),
  gender: yup.string(),
  code: yup.string().required("Không được để trống"),
  identityNumber: yup.string(),
  address: yup.string(),
  phone: yup.string(),
  mail: yup
    .string()
    .email("Email không hợp lệ")
    .required("Không được để trống"),
  degree: yup.string().required("Không được để trống"),
  graduatedYearPhd: yup.string(),
  profession: yup.string(),
  major: yup.string(),
});

// const transformHumanResourceInitialValues = (initialValues) => {
//   if (initialValues === null || initialValues === undefined) {
//     return {
//       name: '',
//       birth: '',
//       code: '',
//       gender: '',
//       identityNumber: '',
//       address: '',
//       phone: '',
//       mail: '',
//       degree: '',
//       graduatedYearPhd: '',
//       profession: '',
//       major: '',
//     };
//   }

//   return { ...initialValues, birth: dayjs(initialValues?.birth).format('YYYY-MM-DD') }
// };

function HumanResourceUpdateModal(props) {
  const { updating, setUpdating, notification } = props;
  const [disabled, setDisabled] = useState(true);
  const [initialValues, setInitialValues] = useState({
    name: "",
    birth: "",
    code: "",
    gender: "",
    identityNumber: "",
    address: "",
    phone: "",
    mail: "",
    degree: "",
    academicRank: "",
    graduatedYearPhd: "",
    profession: "",
    major: "",
  });

  const handleCloseHumanResourceUpdateModal = () => {
    setDisabled(true);
    setUpdating(null);
  };

  useEffect(() => {
    if (updating !== null) {
      (async () => {
        const apiGetProfileByUserId = API_URLS.PROFILE.getProfileFromUserId(
          updating.id
        );
        const { response, error } = await apiCall({ ...apiGetProfileByUserId });
        if (!error && response.status === 200) {
          setInitialValues({
            name: response.data.data?.name ?? "",
            code: response.data.data?.code ?? "",
            birth: dayjs(response.data.data?.birth ?? "").format("YYYY-MM-DD"),
            gender: response.data.data?.gender ?? "",
            identityNumber: response.data.data?.identityNumber ?? "",
            address: response.data.data?.address ?? "",
            phone: response.data.data?.phone ?? "",
            mail: response.data.data?.mail ?? "",
            degree: response.data.data?.degree ?? "",
            academicRank: response.data.data?.academicRank ?? "",
            graduatedYearPhd: response.data.data?.graduatedYearPhd ?? "",
            profession: response.data.data?.profession ?? "",
            major: response.data.data?.major ?? "",
          });
        } else {
          setInitialValues({
            name: "",
            birth: "",
            gender: "",
            identityNumber: "",
            address: "",
            phone: "",
            mail: "",
            degree: "",
            academicRank: "",
            graduatedYearPhd: "",
            profession: "",
            major: "",
          });
        }
      })();
    }
  }, [updating]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={updating != null}
      onClose={handleCloseHumanResourceUpdateModal}
    >
      <DialogTitle>Chi tiết hồ sơ</DialogTitle>
      <Formik
        enableReinitialize
        validationSchema={humanResourceValidationSchema}
        initialValues={initialValues}
        onSubmit={async (values) => {
          if (updating) {
            const apiGetProfileByUserId = API_URLS.PROFILE.getProfileFromUserId(
              updating.id
            );
            const { response: profileResponse, error: profileError } =
              await apiCall({ ...apiGetProfileByUserId });

            if (!profileError && profileResponse.data.success) {
              const profileId = profileResponse.data.data.id;
              const apiUpdateProfile =
                API_URLS.PROFILE.updateProfile(profileId);
              const payload = {
                ...profileResponse.data.data,
                ...values,
                birth: dayjs(values.birth).format("YYYY-MM-DDThh:mm:ss[Z]"),
                userId: updating.id,
              };
              const { response, error } = await apiCall({
                ...apiUpdateProfile,
                payload: payload,
              });
              if (!error && response.data.success) {
                notification({
                  type: "success",
                  message: "Cập nhật hồ sơ thành công",
                });
              } else {
                notification({
                  type: "error",
                  message: "Cập nhật hồ sơ thất bại",
                });
              }
              handleCloseHumanResourceUpdateModal();
            } else {
              notification({
                type: "error",
                message: "Lấy hồ sơ thất bại",
              });
            }
          }
        }}
      >
        {(formikProps) => {
          const { handleSubmit } = formikProps;

          return (
            <>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field name="name">
                      {(fieldProps) => {
                        return (
                          <TextField
                            {...fieldProps.field}
                            fullWidth
                            variant="outlined"
                            disabled={disabled}
                            size="small"
                            label="Tên"
                            helperText={<ErrorMessage name="name" />}
                            FormHelperTextProps={{ error: true }}
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="code">
                      {(fieldProps) => {
                        return (
                          <TextField
                            {...fieldProps.field}
                            fullWidth
                            disabled={disabled}
                            variant="outlined"
                            size="small"
                            label="Mã PU"
                            helperText={<ErrorMessage name="code" />}
                            FormHelperTextProps={{ error: true }}
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="birth">
                      {(fieldProps) => {
                        return (
                          <TextField
                            {...fieldProps.field}
                            type="date"
                            fullWidth
                            variant="outlined"
                            disabled={disabled}
                            size="small"
                            label="Ngày sinh"
                            helperText={<ErrorMessage name="birth" />}
                            FormHelperTextProps={{ error: true }}
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="gender">
                      {(fieldProps) => {
                        return (
                          <TextField
                            {...fieldProps.field}
                            fullWidth
                            select
                            variant="outlined"
                            disabled={disabled}
                            size="small"
                            label="Giới tính"
                            helperText={<ErrorMessage name="gender" />}
                            FormHelperTextProps={{ error: true }}
                            InputLabelProps={{ shrink: true }}
                          >
                            <MenuItem value={"male"}>Nam</MenuItem>
                            <MenuItem value={"female"}>Nữ</MenuItem>
                            <MenuItem value={"other"}>Khác</MenuItem>
                          </TextField>
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="identityNumber">
                      {(fieldProps) => {
                        return (
                          <TextField
                            {...fieldProps.field}
                            fullWidth
                            variant="outlined"
                            disabled={disabled}
                            size="small"
                            label="Số CMND"
                            helperText={<ErrorMessage name="identityNumber" />}
                            FormHelperTextProps={{ error: true }}
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="address">
                      {(fieldProps) => {
                        return (
                          <TextField
                            {...fieldProps.field}
                            fullWidth
                            variant="outlined"
                            disabled={disabled}
                            size="small"
                            label="Địa chỉ"
                            helperText={<ErrorMessage name="address" />}
                            FormHelperTextProps={{ error: true }}
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="phone">
                      {(fieldProps) => {
                        return (
                          <TextField
                            {...fieldProps.field}
                            fullWidth
                            variant="outlined"
                            disabled={disabled}
                            size="small"
                            label="Điện thoại"
                            helperText={<ErrorMessage name="phone" />}
                            FormHelperTextProps={{ error: true }}
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="mail">
                      {(fieldProps) => {
                        return (
                          <TextField
                            {...fieldProps.field}
                            fullWidth
                            variant="outlined"
                            disabled
                            size="small"
                            label="Email"
                            helperText={<ErrorMessage name="mail" />}
                            FormHelperTextProps={{ error: true }}
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="degree">
                      {(fieldProps) => {
                        return (
                          <TextField
                            {...fieldProps.field}
                            select
                            fullWidth
                            size="small"
                            label="Bằng cấp"
                            variant="outlined"
                            disabled={disabled}
                            helperText={<ErrorMessage name="degree" />}
                            FormHelperTextProps={{ error: true }}
                            InputLabelProps={{ shrink: true }}
                          >
                            <MenuItem key={0} value={"Tiến sĩ"}>
                              Tiến sĩ
                            </MenuItem>
                            <MenuItem key={1} value={"Thạc sĩ"}>
                              Thạc sĩ
                            </MenuItem>
                            <MenuItem key={2} value={"Cử nhân - Kỹ sư"}>
                              Cử nhân - Kỹ sư
                            </MenuItem>
                            <MenuItem key={3} value={"Khác"}>
                              Khác
                            </MenuItem>
                          </TextField>
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="academicRank">
                      {(fieldProps) => {
                        return (
                          <TextField
                            {...fieldProps.field}
                            select
                            fullWidth
                            size="small"
                            label="Học hàm"
                            variant="outlined"
                            disabled={disabled}
                            helperText={<ErrorMessage name="academicRank" />}
                            FormHelperTextProps={{ error: true }}
                            InputLabelProps={{ shrink: true }}
                          >
                            <MenuItem key={0} value={"Giáo Sư"}>
                              Giáo Sư
                            </MenuItem>
                            <MenuItem key={1} value={"Phó Giáo Sư"}>
                              Phó Giáo Sư{" "}
                            </MenuItem>
                          </TextField>
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="graduatedYearPhd">
                      {(fieldProps) => {
                        return (
                          <TextField
                            {...fieldProps.field}
                            fullWidth
                            type={"number"}
                            variant="outlined"
                            disabled={disabled}
                            size="small"
                            label="Năm tốt nghiệp"
                            helperText={
                              <ErrorMessage name="graduatedYearPhd" />
                            }
                            FormHelperTextProps={{ error: true }}
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="profession">
                      {(fieldProps) => {
                        return (
                          <TextField
                            {...fieldProps.field}
                            fullWidth
                            variant="outlined"
                            disabled={disabled}
                            size="small"
                            label="Chuyên môn"
                            helperText={<ErrorMessage name="profession" />}
                            FormHelperTextProps={{ error: true }}
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="major">
                      {(fieldProps) => {
                        return (
                          <TextField
                            {...fieldProps.field}
                            fullWidth
                            variant="outlined"
                            disabled={disabled}
                            size="small"
                            label="Chuyên ngành"
                            helperText={<ErrorMessage name="major" />}
                            FormHelperTextProps={{ error: true }}
                            InputLabelProps={{ shrink: true }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  onClick={handleCloseHumanResourceUpdateModal}
                >
                  Hủy
                </Button>
                {disabled ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setDisabled(false)}
                  >
                    Chỉnh sửa
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Xác nhận
                  </Button>
                )}
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  notification: (payload) => dispatch(notification(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(HumanResourceUpdateModal));
