import {
  Box,
  Paper,
  IconButton,
  Dialog,
  DialogContent,
  TextField,
} from '@material-ui/core';
import { Event } from '@material-ui/icons';
import React, { useRef, useEffect, useState } from 'react';

import styles from './index.module.css';
import KPTimePickerForm from './KPTimePickerForm';

function KPTimePicker(props) {
  const formRef = useRef();
  
  const { disabled } = props;

  const [pos, setPos] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (formRef.current?.getBoundingClientRect()) setPos(formRef.current?.getBoundingClientRect())
  }, [formRef.current, open]);

  const PaperComponent = (props) => {
    const width = Math.max(pos?.width, 360);
    return (
      <Paper {...props} style={{
        position: 'absolute',
        right: pos?.right,
        left: pos?.left - width / 2 + pos?.width / 2,
        top: pos?.top + pos?.height + 8,
        bottom: pos?.bottom,
        width,
        margin: 0,
        maxHeight: 'fit-content'
      }} />
    );
  }

  const handleClose = () => setOpen(false);
  const handleClick = () => { if (!disabled) setOpen(true); }

  const handleChangeForm = (value) => {
    props.handleChangeForm(value);
    formRef.current.blur();
    handleClose();
  }
  
  return (
    <>
      <TextField
        ref={formRef}
        fullWidth
        size='small'
        variant='outlined'
        value={props.value || ''}
        placeholder='dd/mm/yyyy'
        disabled={props.disabled}
        required={props.required}
        inputProps={{ style: { padding: 8, caretColor: 'transparent' } }}
        onClick={handleClick}
        InputProps={{
          endAdornment: (
            <IconButton size='small' onClick={handleClick} disabled={props.disabled}>
              <Event fontSize='small' />
            </IconButton>
          )
        }}
      />

      <Dialog
        open={open}
        keepMounted
        PaperComponent={PaperComponent}
      >
        <DialogContent style={{ padding: '16px 8px', maxHeight: 'auto' }}>
          <KPTimePickerForm
            open={open}
            disabledDate={props.disabledDate}
            value={props.value || "dd/mm/yyyy"}
            handleChangeForm={handleChangeForm}
            handleClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default KPTimePicker;
