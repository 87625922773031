export const compareTwoArray = (array1, array2, ignore) => {
  if (array1?.length !== array2?.length) return false;

  for (var i = 0; i < array1.length; i++) {
    const objInArray1 = array1[i];
    const objInArray2 = array2[i];
    if (typeof(objInArray1) !== typeof(objInArray2)) return false;

    for (var property in objInArray1) {
      if (!ignore.find((item) => property === item))
        if (objInArray1?.[property] !== objInArray2?.[property]) return false;
    }
  }

  return true;
};
