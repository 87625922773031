import { fromJS } from "immutable";

import TYPE from "../../constants/type";

const initialState = fromJS({
  list: [],
  listById: [],
  listFiltered: [],
  detail: {},
  isFetching: false,
  isFetchingAccepted: false,
});

export default function solutionReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.SOLUTION.GET_ALL_SOLUTION_LOADING:
    case TYPE.SOLUTION.GET_FILTERED_SOLUTION_LOADING:
    case TYPE.SOLUTION.GET_ALL_SOLUTION_BY_ID_LOADING:
    case TYPE.SOLUTION.INSERT_SOLUTION_LOADING:
    case TYPE.SOLUTION.UPDATE_SOLUTION_LOADING:
    case TYPE.SOLUTION.DELETE_SOLUTION_LOADING:
      return state.merge({
        isFetching: true,
      });

    case TYPE.SOLUTION.ACCEPT_SOLUTION_LOADING:
      return state.merge({
        isFetchingAccepted: true,
      });

    case TYPE.SOLUTION.GET_ALL_SOLUTION_FAILURE:
    case TYPE.SOLUTION.GET_FILTERED_SOLUTION_FAILURE:  
    case TYPE.SOLUTION.GET_ALL_SOLUTION_BY_ID_FAILURE:
    case TYPE.SOLUTION.INSERT_SOLUTION_FAILURE:
    case TYPE.SOLUTION.UPDATE_SOLUTION_FAILURE:
    case TYPE.SOLUTION.DELETE_SOLUTION_FAILURE:
      return state.merge({
        isFetching: false,
      });

    case TYPE.SOLUTION.ACCEPT_SOLUTION_FAILURE:
      return state.merge({
        isFetchingAccepted: false,
      });

    case TYPE.SOLUTION.GET_ALL_SOLUTION_SUCCESS:
      return state.merge({
        list: [...action.payload],
        isFetching: false,
      });

    case TYPE.SOLUTION.GET_FILTERED_SOLUTION_SUCCESS:
      return state.merge({
        listFiltered: action.payload || [],
        isFetching: false,
      });

    case TYPE.SOLUTION.GET_ALL_SOLUTION_BY_ID_SUCCESS:
      return state.merge({
        listById: [...action.payload],
        isFetching: false,
      });

    case TYPE.SOLUTION.GET_SOLUTION_BY_ID_SUCCESS:
      return state.merge({
        detail: {...action.payload},
        isFetching: false,
      });

    case TYPE.SOLUTION.INSERT_SOLUTION_SUCCESS: {
      const newlistById = state.get('listById');
      const index = action.payload.solutionProfile.findIndex((e) => e.profileId === action.id);
      if (index >= 0) newlistById.push({solution: action.payload});
      return state.merge({
        listById: [...newlistById],
        listFiltered: [...state.get('listFiltered'), {solution: action.payload}],
        isFetching: false,
      });
    }

    case TYPE.SOLUTION.UPDATE_SOLUTION_SUCCESS: {
      const newList = state.get('listFiltered');
      const index = newList.findIndex((e) => e.solution.id === action.id);
      newList.splice(index, 1, {solution: action.payload});

      const newList1 = state.get('listById');
      const index1 = newList1.findIndex((e) => e.solution.id === action.id);
      newList1.splice(index1, 1, {solution: action.payload});

      return state.merge({
        listFiltered: [...newList],
        listById: [...newList1],
        isFetching: false,
      });
    }

    case TYPE.SOLUTION.DELETE_SOLUTION_SUCCESS: {
      const newList = state.get('listFiltered');
      const index = newList.findIndex((e) => e.solution.id === action.id);
      if (index !== -1) newList.splice(index, 1);

      const newList1 = state.get('listById');
      const index1 = newList1.findIndex((e) => e.solution.id === action.id);
      if (index1 !== -1) newList1.splice(index1, 1);

      return state.merge({
        listFiltered: [...newList],
        listById: [...newList1],
        isFetching: false,
      });
    }
  
    case TYPE.SOLUTION.ACCEPT_SOLUTION_SUCCESS: {
      const newList = state.get('listById');
      const index = newList.findIndex((e) => e.solution.id === action.payload.id);
      const newSolution = { ...newList[index], solution: action.payload};
      newList.splice(index, 1, newSolution);

      const newList1 = state.get('listFiltered');
      const index1 = newList1.findIndex((e) => e.solution.id === action.payload.id);
      const newSolution1 = { ...newList1[index1], solution: action.payload};
      newList1.splice(index1, 1, newSolution1);

      return state.merge({
        listById: [...newList],
        listFiltered: [...newList1],
        isFetchingAccepted: false,
      });
    }

    default:
      return state;
  }
}
