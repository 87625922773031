import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Divider,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import toJs from '../../../../hoc/toJS';
import select from '../../../../utils/select';

import { getDOI } from '../../../../reducer/scientificResearch/action';
import { getSearchFilteredJournal, getJournalByIdOnScientificResearch, getSearchFilteredJournalAfterDOI } from '../../../../reducer/journal/action';

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  },[value, delay]);
  return debouncedValue;
}

function Other(props) {
  const { values, setValues, handleChangeForm } = props;

  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 300);

  useEffect(() => {
    if (values?.publication?.title) setSearchText(values?.publication?.title);
  }, [props.updating, values?.publication?.title]);

  useEffect(() => {
    if (debouncedSearchText !== 0) props.getSearchFilteredJournal(1, 100, {title: debouncedSearchText})
  }, [debouncedSearchText]);

  useEffect(() => {
    // // // console.log(props.DOI)
    if (!(Object.keys(props.DOI).length === 0 && props.DOI.constructor === Object)){
      props.getSearchFilteredJournalAfterDOI(1, 100, {
        title: props.DOI?.['container-title'].replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''),
        issn: (props.DOI?.ISSN?.[0] || props.DOI?.ISBN?.[0])?.replace('-', ''),
      }, props.DOI)
    }
  }, [props.isNeedReload]);

  useEffect(() => {
    if (props.listSearchAfterDOI && props.listSearchAfterDOI.length !== 0 && props.infoFromDoiLink && props.infoFromDoiLink.length !== 0){
      let tempListAuthor = '';
      (props.infoFromDoiLink?.author || [])?.forEach((author) => { 
        const tempAuthor = `${author.given} ${author.family}`;
        if (tempListAuthor === '') tempListAuthor += tempAuthor; else tempListAuthor += `, ${tempAuthor}`; 
      });

      // Lây ngày nộp đơn từ issued
      const tempSubmissionDay = new Date(2021, 0, 1);
      const datePart = props.infoFromDoiLink.issued['date-parts'][0];
      if (datePart.length >= 1) tempSubmissionDay.setFullYear(datePart[0]);

      setValues({
        ...values,
        doi: `https://doi.org/${props.infoFromDoiLink.DOI}`,
        title: props.infoFromDoiLink.title,
        issn: props.infoFromDoiLink?.ISSN?.[0] || props.infoFromDoiLink?.ISBN?.[0],
        issue: props.infoFromDoiLink.issue === undefined? '' : props.infoFromDoiLink.issue,
        listAuthor: tempListAuthor,
        page: props.infoFromDoiLink.page === undefined? '' : props.infoFromDoiLink.page,
        publisher: props.infoFromDoiLink.publisher === undefined? '' : props.infoFromDoiLink.publisher,
        volume: props.infoFromDoiLink.volume === undefined? '' : props.infoFromDoiLink.volume,
        numOfMember: props.infoFromDoiLink.author?.length,
        journalId: props.listSearchAfterDOI[0].id,
        year: parseInt(props.infoFromDoiLink.issued['date-parts']),
        otherTitle: props.infoFromDoiLink['container-title'],
        submissionDay: new Date(tempSubmissionDay),
        acceptedDay: new Date(tempSubmissionDay),
      })
    }
  }, [props.isFetchingAfterDOI]);

  useEffect(() => {
    const { journalDetail } = props;
    if (values?.journalId !== 0)
      setValues({
        ...values,
        sjrBestQuartile: journalDetail?.sjrBestQuartile,
        rank: journalDetail?.rank,
        topRank: journalDetail?.topRank,
        coreCollection: journalDetail?.coreCollection,
        impactFactor: journalDetail?.impactFactor,
        impactFactorFiveYear: journalDetail?.impactFactorFiveYear,
        submissionToAcceptance: journalDetail?.submissionToAcceptance,
      })
  }, [props.isFetchingJournalDetail]);

  // const handleGetDataFromDOI = () => props.getDOI(values?.doi);
  const handleGetDataFromDOI = () => {
    const type1 = 'https://doi.org/';
    const type2 = 'doi.org/';
    if (values?.doi?.substr(0, type1.length) === type1) {
      props.getDOI(values?.doi);
    } else if (values?.doi?.substr(0, type2.length) === type2) {
      props.getDOI(`https://${values?.doi}`);
    } else {
      props.getDOI(`https://doi.org/${values?.doi}`);
    }
  }

  const handleGetRankFromJournal = () => {
    if (values?.journalId === 0) {
      props.notification({ type: 'error', message: 'Vui lòng nhập tạp chí' });
    } else { 
      props.getJournalByIdOnScientificResearch(values?.journalId);
    }
  }

  return (
    <>
      <Grid item xs={9}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label='Link DOI'
          variant='outlined'
          value={values?.doi || ''}
          onChange={(event) => handleChangeForm('doi', event.target.value)}
          helperText={!values?.doi? <div style={{ color: 'red' }}>Vui lòng upload file minh chứng nếu công bố không có link DOI</div> : null}
        />
      </Grid>
      <Grid item xs={3}> 
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => handleGetDataFromDOI()}
          disabled={props.disabled || props.isFetchingDOI || props.isFetchingAfterDOI || (values?.locked === 'kpi' || values?.locked === 'reward') || values?.lockedForTimeResearch}
        >
          {(props.isFetchingDOI || props.isFetchingAfterDOI) ? (
            <CircularProgress size={24} />
          ) : (
            'Nhập từ link DOI'
          )}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label='Tên công bố'
          variant='outlined'
          value={values?.title || ''}
          onChange={(event) => handleChangeForm('title', event.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth 
          size='small'
          disabled={props.disabled}
          label='Tác giả'
          variant='outlined'
          value={values?.listAuthor || ''}
          multiline
          rows={4}
          onChange={(event) => handleChangeForm('listAuthor', event.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label='Issue'
          variant='outlined'
          value={values?.issue || ''}
          onChange={(event) => handleChangeForm('issue', event.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label='Volume'
          variant='outlined'
          value={values?.volume || ''}
          onChange={(event) => handleChangeForm('volume', event.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label='Page'
          variant='outlined'
          value={values?.page || ''}
          onChange={(event) => handleChangeForm('page', event.target.value)}
        />
      </Grid>
      {/* <Grid item xs={3}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label="Năm"
          variant="outlined"
          type="number"
          value={values?.year || ''}
          onChange={(event) => handleChangeForm('year', parseInt(event.target.value), 10)}
        />
      </Grid> */}
      <Grid item xs={3}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label='Năm nộp'
          variant='outlined'
          type='number'
          InputProps={{ inputProps: { min: 1900, max: 3000 } }}
          value={new Date(values?.submissionDay).getFullYear() || ''}
          onChange={(event) => {
            let newDate = values?.submissionDay;
            let newYear = parseInt(event.target.value);
            if (newDate === null || newDate === undefined) newDate = new Date(2021, 0, 1);
            newDate.setFullYear(newYear);
            handleChangeForm('submissionDay', newDate);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label='Năm chấp nhận'
          variant='outlined'
          type='number'
          InputProps={{ inputProps: { min: 1900, max: 3000 } }}
          value={new Date(values?.acceptedDay).getFullYear() || ''}
          onChange={(event) => {
            let newDate = values?.acceptedDay;
            let newYear = parseInt(event.target.value);
            if (newDate === null || newDate === undefined) newDate = new Date(2021, 0, 1);
            newDate.setFullYear(newYear);
            handleChangeForm('acceptedDay', newDate);
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label="Số lượng tác giả"
          variant="outlined"
          type="numOfMember"
          value={values?.numOfMember || ''}
          onChange={(event) => handleChangeForm('numOfMember', parseInt(event.target.value), 10)}
          error={!(values?.numOfMember > 0)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label='Số lượng tác giả chính'
          variant='outlined'
          type='number'
          value={values?.numberMainAuthor || ''}
          onChange={(event) => handleChangeForm('numberMainAuthor', parseInt(event.target.value, 10))}
          error={!(values?.numberMainAuthor > 0)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          select
          size='small'
          disabled={props.disabled || (values?.locked === 'kpi' || values?.locked === 'reward')}
          label='Mục đích'
          variant='outlined'
          value={values?.purposeId ?? 'none'}
          SelectProps={{ native: true }}
          onChange={(event) => handleChangeForm('purposeId', parseInt(event.target.value, 10))}
        >
          {props.purposes.filter((e) => e.name !== 'kpi').map((e) => (
            <option key={e.id} value={e.id}>{e.description}</option>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label="Tên tạp chí/hội thảo"
          variant="outlined"
          value={values?.otherTitle || ''}
          onChange={(event) => handleChangeForm('otherTitle', event.target.value)}
        />
      </Grid>
      {/* <Grid item xs={6}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label="Tên sách"
          variant="outlined"
          value={values?.bookTitle || ''}
          onChange={(event) => handleChangeForm('bookTitle', event.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label="Editors"
          variant="outlined"
          value={values?.editors || ''}
          onChange={(event) => handleChangeForm('editors', event.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label="Ngôn ngữ"
          variant="outlined"
          value={values?.languague || ''}
          onChange={(event) => handleChangeForm('languague', event.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label="Quốc gia"
          variant="outlined"
          value={values?.nation || ''}
          onChange={(event) => handleChangeForm('nation', event.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          inputProps={{ min: 0, max: 1, step: 0.01 }}
          type="number"
          label="Score"
          variant="outlined"
          value={values?.score || ''}
          onChange={(event) => handleChangeForm('score', event.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          size='small'
          disabled={props.disabled}
          label="URL"
          variant="outlined"
          value={values?.url || ''}
          onChange={(event) => handleChangeForm('url', event.target.value)}
        />
      </Grid> */}
      {/* <Grid item xs={4}>
        <TextField
          fullWidth
          size='small'
          type='date'
          variant='outlined'
          disabled={props.disabled}
          label='Ngày nộp đơn'
          value={values?.submissionDay || null}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => handleChangeForm('submissionDay', event.target.value)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          size='small'
          type='date'
          variant='outlined'
          disabled={props.disabled}
          label='Ngày chấp nhận đơn'
          value={values?.acceptedDay || null}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => handleChangeForm('acceptedDay', event.target.value)}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          size='small'
          type='date'
          variant='outlined'
          disabled={props.disabled}
          label='Ngày xuất bản'
          value={values?.publishAt || null}
          InputLabelProps={{ shrink: true }}
          onChange={(event) => handleChangeForm('publishAt', event.target.value)}
        />
      </Grid> */}
    </>
  );
}
         
const mapStateToProps = (state) => ({
  DOI: select(state, 'scientificResearchReducer', 'DOI'),
  purposes: select(state, 'scientificResearchReducer', 'purposes'),
  listSearch: select(state, 'journalReducer', 'listSearch'),
  journalDetail: select(state, 'journalReducer', 'detailOnScientificResearch'),
  infoFromDoiLink: select(state, 'journalReducer', 'infoFromDoiLink'),
  listSearchAfterDOI: select(state, 'journalReducer', 'listSearchAfterDOI'),

  isNeedReload: select(state, 'scientificResearchReducer', 'isNeedReload'),
  isFetchingDOI: select(state, 'scientificResearchReducer', 'isFetchingDOI'),
  isFetchingJournal: select(state, 'journalReducer', 'isFetching'),
  isFetchingAfterDOI: select(state, 'journalReducer', 'isFetchingAfterDOI'),
  isFetchingJournalDetail: select(state, 'journalReducer', 'isFetchingDetailOnScientificResearch'),
});

const mapDispatchToProps = (dispatch) => ({
  getDOI: (url) => dispatch(getDOI(url)),
  getJournalByIdOnScientificResearch: (id) => dispatch(getJournalByIdOnScientificResearch(id)),
  getSearchFilteredJournal: (page, pageSize, payload) => dispatch(getSearchFilteredJournal(page, pageSize, payload)),
  getSearchFilteredJournalAfterDOI: (page, pageSize, payload, doiInfo) => dispatch(getSearchFilteredJournalAfterDOI(page, pageSize, payload, doiInfo)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(Other));
