import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Chip,
  Tooltip,
  TextField,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import KPTable from '../../../components/KPTable';
import MonographUpdateModal from '../MonographUpdateModal';
import MonographDeleteModal from '../MonographDeleteModal';

import { updateMonograph, acceptMonograph } from '../../../reducer/monograph/action';
import { statisticUnaccepted } from '../../../reducer/profile/action';

function MonographTable(props) {
  const [editting, setEditting] = useState(null);
  const [updating, setUpdating] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleUpdate = (e) => setUpdating(e);
  const handleEditYear = (e) => setEditting(e);

  const handleDelete = (event, e) => {
    setDeleting(e.id);
    setAnchorEl(event.currentTarget);
  }

  const handleChange = (value) => setEditting({ ...editting, dashboardYear: value });
  
  const handleSubmit = (e) => {
    e.preventDefault();
    props.updateMonograph(editting.id, editting);
    setEditting(null);
  }

  const handleClear = () => {
    props.updateMonograph(editting.id, {...editting, dashboardYear: null});
    setEditting(null);
  }

  const acceptMonograph = async (e) => {
    let result = await props.acceptMonograph(e.id, !e.accepted);
    return result;  
  }

  const handleAccept = (e) => {
    acceptMonograph(e)
    .then((success) => {
      if (success) {
        props.statisticUnaccepted({ departmentId: 0, profileId: props.profile?.id });
        if (props.role === 'sudo-admin') props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
      }
    });
  }

  var list = [];
  if (props.type === 'all') props.listMonograph.forEach((e) => list.push(e.monograph));
  else props.listMonographById.forEach((e) => list.push(e.monograph));

  const columns = [
    // {
    //   title: <b>ID</b>,
    //   key: 'id',
    //   width: '10%',
    //   sorter: (a, b) => a.id - b.id,
    // },
    {
      title: <b>Tên sách chuyên khảo</b>,
      key: 'title',
      width: '30%',
      searchable: true,
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: <b>Phân loại</b>,
      key: 'type',
      width: '20%',
      type: 'include',
      filters: [
        {
          text: 'Chương sách',
          value: 'chapter',
        },
        {
          text: 'Chuyên khảo',
          value: 'monograph',
        },
        {
          text: 'Tham  khảo',
          value: 'reference',
        },
        {
          text: 'Giáo trình',
          value: 'curriculum',
        }
      ],
      onFilter: (value, row) => row.filter((e) => value.includes(e.type)),
      render: (value) => {
        if (value === 'chapter') return('Chương sách');
        if (value === 'monograph') return('Chuyên khảo');
        if (value === 'reference') return('Tham  khảo');
        if (value === 'curriculum') return('Giáo trình');
        return('-');
      }
    },
    {
      title: <b>Năm xuất bản</b>,
      key: 'year',
      width: '12%',
      sorter: (a, b) => a.year - b.year,
    },
    (props.role === 'sudo-admin' && props.type === 'all') ? {
      title: <b>Năm hiển thị</b>,
      key: 'dashboardYear',
      width: '12%',
      searchable: true,
      render: (value, row) => (
        (row?.id !== editting?.id) ? (
          <Tooltip title='Click để chỉnh sửa năm'>
            <div style={{ cursor: 'pointer' }} onClick={() => handleEditYear(row)}>{value > 0 ? value : "Chưa có năm hiển thị"}</div>
          </Tooltip>
        ) : (
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              size='small'
              type='tel'
              variant='outlined'
              value={editting?.dashboardYear || ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position='end'
                    style={{ cursor: 'pointer' }}
                    onClick={handleClear}
                  >
                    <Clear style={{ fontSize: 18 }} />
                  </InputAdornment>
                )
              }}
              onChange={(e) => handleChange(parseInt(e.target.value))}
            />
          </form>
        )
      )
    } : {
      key: 'dashboardYear',
      width: '0%',
      render: () => null,
    },
    {
      title: <b>Tình trạng</b>,
      key: 'accepted',
      width: '20%',
      render: (value) => (
        <Chip
          size='small'
          color={value ? 'primary' : 'default'}
          label={value ? 'Đã được duyệt' : 'Chưa được duyệt'}
          variant='outlined'
        />
      ),
      type: "include",
      filters: [
        {
          text: 'Đã được duyệt',
          value: true,
        },
        {
          text: 'Chưa được duyệt',
          value: false,
        }
      ],
      onFilter: (value, row) => row.filter((e) => value.includes(e.accepted)),
    },
    {
      title: <b>Mục đích</b>,
      key: 'purposeId',
      width: '15%',
      type:'include',
      filters: props.purposes.map((e) => ({value: e.id, text: e.description})),
      onFilter: (value, row) => row.filter((e) => value.includes(e.purposeId)),
      render: (value) => props.purposes.find((e) => e.id === value)?.description,
    },
    {
      title: <b>Tác vụ</b>,
      key: 'action',
      width: '20%',
      render: (value, row) => (
        (row?.id !== editting?.id) ? (
          <>
            <Typography className='table-action-buton' variant='caption' color='primary' onClick={() => handleUpdate(row)}>Chi tiết</Typography>
            {(row.locked) ? (
              <Typography className='table-action-buton' variant='caption' color='textPrimary'>
                {`Đã khóa ${
                  (row.locked === 'kpi' && 'KPI') ||
                  (row.locked === 'reward' && 'thưởng') ||
                  (row.locked === 'timeResearch' && 'giờ nghiên cứu')
                }`}
              </Typography>
            ) : (
              <>
                {(props.type !== 'all' || props.role === 'sudo-admin') && (
                  <Typography
                    className='table-action-buton'
                    variant='caption'
                    color='secondary'
                    onClick={(event) => handleDelete(event, row)}
                  >
                    Xoá
                  </Typography>
                )}
                {(props.role === 'sudo-admin' && props.filter !== 'workingPaper' && row.purposeId !== 4) && (
                  <>
                    <Typography
                      className='table-action-buton'
                      variant='caption'
                      color='primary'
                      onClick={(event) => handleAccept(row)}
                    >
                      {row.accepted? 'Hủy xác nhận' : 'Xác nhận'}
                    </Typography>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Typography className='table-action-buton' variant='caption' color='primary' onClick={handleSubmit}>Lưu</Typography>
            <Typography className='table-action-buton' variant='caption' color='secondary' onClick={() => setEditting(null)}>Huỷ</Typography>
          </>
        )
      ),
    }
  ];

  if (props.volumnType === 'all') columns.splice(2, 1);

  return (
    <>
      <KPTable
        shadow="none"
        rowsPerPage={10}
        maxHeight='calc(100vh - 288px)'
        columns={columns}
        rows={list.sort((a) => !a.accepted ? -1 : 1)}
        loading={props.isFetching}
      />
      <MonographUpdateModal adminTab={props.adminTab} updating={updating} setUpdating={setUpdating} />
      <MonographDeleteModal deleting={deleting} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </>
  );
}

const mapStateToProps = (state) => ({
  role: select(state, 'userReducer', 'role'),
  profile: select(state, 'profileReducer', 'detail'),
  isFetching: select(state, 'monographReducer', 'isFetching'),
  listProject: select(state, 'projectReducer', 'list'),
  listMonograph: select(state, 'monographReducer', 'listFiltered'),
  listMonographById: select(state, 'monographReducer', 'listById'),
  purposes: select(state, 'scientificResearchReducer', 'purposes'),
});

const mapDispatchToProps = (dispatch) => ({
  acceptMonograph: (id, accept) => dispatch(acceptMonograph(id, accept)),
  statisticUnaccepted: (payload) => dispatch(statisticUnaccepted(payload)),
  updateMonograph: (id, payload) => dispatch(updateMonograph(id, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(MonographTable));
