export const PREFIX = {
  API_CALLING: '[api calling]',
  API_SUCCESS: '[api success]',
  API_FAILURE: '[api failure]',
  
  HOME: '[home]',
  USER: '[user]',
  FILE: '[file]',
  PATENT: '[patent]',
  REWARD: '[reward]',
  JOURNAL: '[journal]',
  PROJECT: '[project]',
  PROFILE: '[profile]',
  CONTRACT: '[contract]',
  SOLUTION: '[solution]',
  MONOGRAPH: '[monograph]',
  STATISTIC: '[statistic]',
  CONFERENCE: '[conference]',
  NOTIFICATION: '[notification]',
  HUMANRESOURCE: '[humanresource]',
  JOURNAL_DOMESTIC: '[JOURNAL_DOMESTIC]',
  SCIENTIFIC_RESEARCH: '[scientific research]',
  DEPARTMENT: '[department]',
  EMPLOYMENT_CONTRACT: '[employment_contract]',

  RANK_TABLE: '[rank_table]',
  TIME_RESEARCH_INFORMATION: '[TIME_RESEARCH_INFORMATION]',
};
