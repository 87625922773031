
import {
  Grid,
  Button,
  Backdrop,
  Snackbar,
  FormControl,
  OutlinedInput,
  CircularProgress,
  Card,
  CardHeader,
  CardContent,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { connect } from 'react-redux';
import { JSEncrypt } from 'js-encrypt';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Person, Lock, Visibility, VisibilityOff } from '@material-ui/icons';


import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';
import ROUTER from '../../../constants/router';
import Background from '../../../assets/background_image.jpg';
// import Background from '../../../assets/background_image.png';
import LogoMediumPhenikaa from '../../../assets/phenikaa_logo_full.png';

import { login, loginWithGoogle } from '../../../reducer/user/action';
import { notification, createHistory } from '../../../reducer/home/action';

const publicKey = `
    -----BEGIN PUBLIC KEY-----
    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA9lustteMn4Kfq96tu2YQ
		cWEKBrTXIAjPNBjNZmXaIJpHxdtHFrofWCx8yo2to78g5iC6WULnNEyOYpXjRJq/
		PxeD8nMqFV0G43o/hv7srbAREybQSdimmQDqRi+joGhByZhE/Mq+DN2VcMGqv82M
		FtQbGAEFI5Sf+2WP2rnnTZOtIr5JocDpzxgdirRfx7qxBW7Eqo0Rh6jDaT4dGy3E
		wgBB31mQD91FFY4gsz6KmVMqPgJDFzs5U3H+RYTI3C3S3cWfdlX0yYtHm6dbmgY+
		/r0hfxyToz8fAvzi1fl/hI3wxCtNqxWxJv1nlK0vub+F0AXCRuxeLu6AYyMOnRO5
		awIDAQAB
    -----END PUBLIC KEY-----`;

const encrypt = new JSEncrypt();
encrypt.setPublicKey(publicKey);
function Login(props) {
  const history = useHistory();
  const { noti, notification, history: propsHistory, createHistory } = props;

  const [values, setValue] = useState({
    showPassword: false,
  });

  useEffect(() => {
    if (propsHistory === null) createHistory(history);
  }, [history, propsHistory, createHistory]);

  useEffect(() => {
    if (props.history !== null && localStorage.getItem('access_token')) props.history.push(ROUTER.HOME);
  }, [props.history]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isNaN(values.username)) {
      props.login({
        username: values.username,
        password: values.password
        // password: encrypt.encrypt(values.password) || ''
      }).then((success) => { if (success) props.history.push(ROUTER.USER); }).catch();
    }

  }

  const handleClickShowPassword = () => {
    setValue({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const responseGoogleSuccess = (response) => {
    props.loginWithGoogle({ token: response.accessToken })
      .then((success) => { if (success) props.history.push(ROUTER.USER); })
      .catch();
  }

  const responseGoogleFailure = (response) => {
    if (response.error !== 'popup_closed_by_user')
      notification({
        type: 'error',
        message: 'Đăng nhập bằng Google thất bại!'
      })
  }

  const params = new URLSearchParams(window.location.search);
  const visible_default_form = params.get('default') == '1';

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        background: `linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url(${Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
      }}
    >
      <Grid container>
        <Grid item lg={3} md={6} sm={8} xs={10} style={{ margin: 'auto' }}>
          <Card style={{
            width: '100%',
            boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'
          }}>
            <CardHeader
              title={
                <div style={{ textAlign: 'center' }}>
                  <img style={{ maxWidth: 128, maxHeight: 128 }} src={LogoMediumPhenikaa} alt="" />
                </div>
              }
              style={{ paddingBottom: 0 }} 
            />
            <CardContent style={{ paddingTop: 0, paddingBottom: 8 }}>
              <div style={{ textAlign: 'center', paddingLeft: 10, paddingRight: 10 }}>
                <h2>Chào mừng bạn đến với<br/>Hệ thống quản lý Nghiên cứu Khoa học</h2>
                <i style={{ fontSize: 15 }}>Vui lòng đăng nhập bằng tài khoản nội bộ đã được cấp!</i>
              </div>
              
              <div style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>
              {/* {visible_default_form && */}
                <form onSubmit={(e) => handleSubmit(e)}>
                  <FormControl style={{ width: '100%' }}>
                    <OutlinedInput
                      required
                      value={values?.username || ''}
                      startAdornment={<Person position="start" />}
                      inputProps={{ style: { fontSize: 18, paddingLeft: 10, marginLeft: 10 } }}
                      onChange={(e) => setValue({ ...values, username: e.target.value })}
                      style={{ height: 40 }}
                    />
                  </FormControl>
                  <FormControl style={{ width: '100%', padding: '12px 0' }}>
                    <OutlinedInput
                      required
                      type={values.showPassword ? 'text' : 'password'}
                      value={values?.password || ''}
                      startAdornment={<Lock position="start" />}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      inputProps={{ style: { fontSize: 18, paddingLeft: 10, marginLeft: 10 } }}
                      onChange={(e) => setValue({ ...values, password: e.target.value })}
                      style={{ height: 40 }}
                    />
                  </FormControl>
                  <FormControl style={{ display: 'inline', width: '100%' }}>
                    <Button variant="contained" color="primary" type="submit" style={{ width: '100%' }}> Đăng nhập</Button>
                  </FormControl>
                  <Grid container justifyContent='space-between' style={{ marginTop: 8 }}>
                    <a href='https://sso.phenikaa-uni.edu.vn/adfs/portal/updatepassword'>Đổi mật khẩu</a>
                    <a href='https://it-support.phenikaa-uni.edu.vn/ho-tro-xu-li-su-co/sự-cố-tài-khoản-truy-cập-nội-bộ'>Quên mật khẩu?</a>
                  </Grid>
                </form>
              </div>

              {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: 32 }}>
                All rights reserved PDT JSC
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 8 }}>
                <div style={{ display: 'flex', alignItems: 'end', fontSize: 12 }}>
                  Copyright © 2022
                </div>
              </div> */}
             
            </CardContent>
          </Card>
        </Grid>

        <Backdrop open={props.isFetching} style={{ zIndex: 10 }}><CircularProgress /></Backdrop>

        <Snackbar
          // anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={noti !== null}
          autoHideDuration={noti?.duration || 3000}
          onClose={() => notification(null)}
          style={{ marginTop: 58 }}
        >
          <Alert variant="filled" severity={noti?.type} onClose={() => notification(null)}>{noti?.message}</Alert>
        </Snackbar>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  noti: select(state, 'homeReducer', 'noti'),
  history: select(state, 'homeReducer', 'history'),
  isFetching: select(state, 'userReducer', 'isFetching'),
});

const mapDispatchToProps = (dispatch) => ({
  login: (payload) => dispatch(login(payload)),
  loginWithGoogle: (payload) => dispatch(loginWithGoogle(payload)),
  notification: (payload) => dispatch(notification(payload)),
  createHistory: (payload) => dispatch(createHistory(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(Login));
