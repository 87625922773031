import {
  Box,
  Grid,
  Button,
  Divider,
  Tooltip,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Close } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';

import { render } from '../utils/render';
import { valueFormat } from '../../../utils/valueFormat';

function StudentProjectModal(props) {
  const { open, setOpen, year, type, detail } = props;

  const [step, setStep] = useState(0);
  const [files, setFiles] = useState(null);
  const [values, setValues] = useState(null);
  const [council, setCouncil] = useState(null);
  const [councilApp, setCouncilApp] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [modalProperties, setModalProperties] = useState(null);

  const handleClose = () => setOpen(null);

  useEffect(() => {
    if (open) {
      const _modalProperties = render(open);
      setModalProperties(_modalProperties);
      setFiles(null);
      setValues(null);
      setParticipants([]);
      setCouncil(null);
      setCouncilApp(null);
      setStep((open?.reportDetail || open?.reportAccept) ? 1 : 0);
    }
  }, [open]);

  useEffect(() => {
    if (props.detail) {
      const needAdjust = props?.detail?.status?.find(
        (e) => e?.value?.includes('info_adjust_request')
      ) || props?.open?.detailAdjust;

      setParticipants(props?.detail?.participants.map((e) => ({ ...e, isPhenikaa: !!e.profileId})));
      setValues({
        ...props.detail,
        participants: undefined,
        // presentationFileLink: needAdjust ? undefined : props.detail.presentationFileLink,
        // statisticPlanFileLink: needAdjust ? undefined : props.detail.statisticPlanFileLink,
        endTime: valueFormat.dateToString(props.detail.endTime),
        startTime: valueFormat.dateToString(props.detail.startTime),
      });
      // if (props.detail.status.find((e) => e.value.includes('default') || e.value.includes('submit') || e.value.includes('accept'))) {
      if (props.detail.status.find((e) => e === 'default' || e === 'submit' || e === 'accept')) {
        setCouncil({ ...props.detail?.internalCouncilInfos?.sort((a, b) => b.id - a.id)?.find((e) => e?.name === 'accept-project') });
        setCouncilApp({ ...props.detail?.internalCouncilInfos?.sort((a, b) => b.id - a.id)?.find((e) => e?.name === 'accept-application') });
      } else {
        setCouncil({ ...props.detail?.internalCouncilInfos?.sort((a, b) => b.id - a.id)?.find((e) => e?.name === 'project') });
        setCouncilApp({ ...props.detail?.internalCouncilInfos?.sort((a, b) => b.id - a.id)?.find((e) => e?.name === 'application') });
      }
      // setCouncil({ ...props.detail?.internalCouncilInfos?.sort((a, b) => b.id - a.id)?.find((e) => e?.name === 'project') });
      // setCouncilApp({ ...props.detail?.internalCouncilInfos?.sort((a, b) => b.id - a.id)?.find((e) => e?.name === 'application') });
    }
  }, [props.detail]);

  const renderProps = {
    open,
    type,
    year,
    step,
    detail,
    files,
    values,
    participants,
    council,
    councilApp,
    setStep,
    setFiles,
    setValues,
    setParticipants,
    setCouncil,
    setCouncilApp,
    handleClose,
  }

  return (
    <Dialog fullWidth maxWidth={modalProperties?.size || 'md'} open={!!props.open}>
      <DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
        <Grid container>
          <Grid item xs={5} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant='h5'><b>{modalProperties?.title}</b></Typography>
          </Grid>
          <Grid item xs={7}>
            <Box display='flex' alignItems='center'>
              <Box flexGrow={1}>
                {modalProperties?.reportHeader && (
                  <>{modalProperties?.reportHeader(step)}</>
                )}
              </Box>
              <IconButton onClick={handleClose}><Close /></IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent style={modalProperties?.style}>
        {modalProperties?.form(renderProps)}
      </DialogContent>
      <DialogActions style={{ padding: "8px 24px" }}>
        {modalProperties?.actionButtons(renderProps).map((item, index) => (
          <>
            {item?.closeButton ? (
              <Button variant="outlined" startIcon={<Close />} onClick={handleClose}>Đóng</Button>
            ) : (
              <>
                {item?.disabledText ? (
                  <Tooltip key={index} disableHoverListener={!item?.disabledText} title={item?.disabledText || 'N/A'}>
                    <span>
                      <Button disabled={!!item?.disabledText} {...item.props}>{item.content}</Button>
                    </span>
                  </Tooltip>
                ) : (
                  <Button {...item.props}>{item.content}</Button>
                )}
              </>
            )}
          </>
        ))}
      </DialogActions>
    </Dialog>
  );
}

export default StudentProjectModal;
