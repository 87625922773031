import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Divider,
  Typography,
  TextField, 
  MenuItem
} from '@material-ui/core';
import { Add, Replay } from '@material-ui/icons';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import { getAllConference } from '../../../reducer/conference/action';

import ConferenceInsertModal from '../ConferenceInsertModal';

function ConferenceButton(props) {
  const { getAllConference } = props;

  const [inserting, setInserting] = useState(false);

  useEffect(() => {
    if (props.listConference.length === 0) getAllConference();
  }, [getAllConference, props.listConference.length]);

  const handleReload = () => getAllConference();
  const handleInsert = () => setInserting(true);

  return (
    <>
      <Grid container justifyContent="space-between" style={{textAlign: 'center'}}>
        <Grid item xs={3}>
          <Typography variant="h5" style={{ lineHeight: '36px', float: 'left' }}>
            {`Hội thảo ${props.level === 'international' ? 'quốc tế' : 'trong nước'}`}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <TextField
            select
            variant='outlined'
            size='small'
            style={{width: 175, marginRight: 10}}
            label='Năm'
            value={props.year}
            onChange={(event) => {props.handleChangeYear(event.target.value)}}
          >
            <MenuItem value={0}>Tất cả các năm</MenuItem>
            <MenuItem value={2019}>{2019}</MenuItem>
            <MenuItem value={2020}>{2020}</MenuItem>
            <MenuItem value={2021}>{2021}</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <div style={{ float: 'right' }}>
            <Button variant="outlined" startIcon={<Replay />} onClick={handleReload} style={{ marginRight: 8, float: 'right' }}>Tải lại</Button>
            {props.role === 'sudo-admin' && props.level === 'domestic' && (
              <Button variant="contained" color="primary" startIcon={<Add />} onClick={handleInsert}>Thêm</Button>
            )}
          </div>
        </Grid>
      </Grid>
      <Divider style={{ margin: '8px 0' }} />
      <ConferenceInsertModal inserting={inserting} setInserting={setInserting} level={props.level} />
    </>
  );
}

const mapStateToProps = (state) => ({
  listConference: select(state, 'conferenceReducer', 'list'),
  role: select(state, 'userReducer', 'role'),
});

const mapDispatchToProps = (dispatch) => ({
  getAllConference: () => dispatch(getAllConference()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(ConferenceButton));
