import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import toJs from '../../../hoc/toJS';

import { insertJournalDomestic } from '../../../reducer/journalDomestic/action';

function JournalDomesticInsertModal(props) {
  const { inserting, setInserting } = props;

  const [values, setValues] = useState(null);

  const handleChangeForm = (name, value) => setValues({ ...values, [name]: value });

  const handleSubmit = () => {
    setInserting(false);
    props.insertJournalDomestic(values);
    setValues(null);
  }

  return (
    <Dialog
      fullWidth
      keepMounted
      maxWidth='md'
      open={inserting}
      onClose={() => setInserting(false)}
    >
      <DialogTitle>Thêm tạp chí trong nước</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              label='Tên tạp chí'
              value={values?.title || ''}
              onChange={(event) => handleChangeForm('title', event.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              label='ISSN/ISBN'
              value={values?.issn || ''}
              onChange={(event) => handleChangeForm('issn', event.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              label='Loại'
              value={values?.type || ''}
              onChange={(event) => handleChangeForm('type', event.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              label='Ngành'
              value={values?.major || ''}
              onChange={(event) => handleChangeForm('major', event.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              label='Nhà xuất bản'
              value={values?.publisher || ''}
              onChange={(event) => handleChangeForm('publisher', event.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              label='Điểm GS-PGS'
              value={values?.score || ''}
              onChange={(event) => handleChangeForm('score', event.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: '0 24px 8px 24px' }}>
        <div style={{ float: 'right' }}>
          <Button variant="outlined" color="secondary" onClick={() => setInserting(false)} style={{ marginRight: 4 }}>Huỷ</Button>
          <Button variant="contained" color="primary" onClick={() => handleSubmit()}>Xác nhận</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  insertJournalDomestic: (payload) => dispatch(insertJournalDomestic(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(JournalDomesticInsertModal));
