import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Button,
  Dialog,
  Select,
  Divider,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  Typography,
  FormControl,
  FormHelperText,
  MenuItem,
} from "@material-ui/core";
import dayjs from "dayjs";
import moment from "moment";
import { Autocomplete } from "@material-ui/lab";
import { CheckBoxOutlineBlank, CheckBox } from "@material-ui/icons";

import toJs from "../../../hoc/toJS";
import select from "../../../utils/select";

import FileUploadModal from "../../File/FileUploadModal";
import Other from "../ScientificResearchInsertForm/Other";
import Chapter from "../ScientificResearchInsertForm/Chapter";
import Conference from "../ScientificResearchInsertForm/Conference";
import WorkingPaper from "../ScientificResearchInsertForm/WorkingPaper";
import JournalDomestic from "../ScientificResearchInsertForm/JournalDomestic";
import ConferenceDomestic from "../ScientificResearchInsertForm/ConferenceDomestic";
import JournalInternational from "../ScientificResearchInsertForm/JournalInternational";
import EmploymentContractTable from "../../../components/EmployContractModal/EmploymentContractTable";
import EmploymentContractDetailTable from "../../../components/EmployContractModal/EmploymentContractDetailTable";
import { uploadFile } from "../../../reducer/file/action";
import { resetDetail } from "../../../reducer/journal/action";
import { statisticUnaccepted } from "../../../reducer/profile/action";
import {
  insertScientificResearchOther,
  updateScientificResearchOther,
} from "../../../reducer/scientificResearchOther/action";
import { getAllEmploymentContractByProfileId } from "../../../reducer/employmentContract/action";
import {
  insertScientificResearch,
  updateScientificResearch,
} from "../../../reducer/scientificResearch/action";
import { getTimeResearchInformation } from "../../../reducer/timeResearchInformation/action";
import { notification } from "../../../reducer/home/action";

function SolutionInsertModal(props) {
  const { inserting, setInserting, getTimeResearchInformation } = props;

  const [detail, setDetail] = useState(null);
  const [values, setValues] = useState({ purposeId: 3 });
  const [profileList, setProfileList] = useState([]);
  const [helperText, setHelperText] = useState(false);
  const [timeResearchYear, setTimeResearchYear] = useState(2023);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const dateFormat = (value) =>
    value ? dayjs(value).format("YYYY-MM-DDTHH:mm:ssZ") : null;

  // Tự gán bản thân vào danh sách tác giả
  useEffect(() => {
    if (props.profile?.id && profileList.length === 0) {
      setProfileList([
        {
          profileId: props.profile.id,
          roleInPaper: "main",
        },
      ]);
    }
  }, [props.inserting, props.profile?.id]);

  // Đổi filter thì reset giá trị
  useEffect(() => {
    setDetail(null);
    setValues({ purposeId: 3 });
    setProfileList([]);
  }, [props.filter]);

  // Bỏ KPI bỏ luôn contract
  useEffect(() => {
    if (!values?.profileIdForKpi) handleChangeForm("contractIdForKpi", null);
  }, [values?.profileIdForKpi]);

  useEffect(() => {
    if (
      props.timeResearchInformationList.slice(0, 1)?.shift()?.year !==
        timeResearchYear &&
      !props.isFetchingTimeResearchInformation
    )
      getTimeResearchInformation(0, timeResearchYear);
  }, [timeResearchYear]);

  const handleChangeForm = (name, value) => {
    setValues({ ...values, [name]: value });
    if (name === "purposeId") {
      setValues({
        ...values,
        purposeId: value,
        profileIdForKpi: null,
        contractIdForKpi: null,
        profileIdForReward: null,
      });
    }
  };

  const handleChangeProfileList = (index, name, value) => {
    const temp = profileList;
    const tempElm = profileList[index];
    temp.splice(index, 1, { ...tempElm, [name]: value });
    setProfileList([...temp]);
  };

  const handleInsert = () => {
    const temp = profileList;
    temp.push({
      profileId: null,
      roleInPaper: "main",
    });
    setProfileList([...temp]);
  };

  const handleRemove = (index) => {
    if (profileList[index].profileId === values?.profileIdForKpi)
      handleChangeForm("profileIdForKpi", null);

    const temp = profileList.filter((e, id) => id !== index);
    setProfileList([...temp]);
  };

  const handleClose = () => {
    setInserting(false);
    props.resetDetail();
  };

  const handleSubmit = (e) => {
    props.resetDetail();
    if (profileList.findIndex((e) => e.profileId === null) < 0) {
      setInserting(false);
      const d = new Date();

      const tempListAuthor = values?.listAuthor
        ?.split(",")
        .map((author) => author.trimStart());

      if (props.filter !== "other") {
        if (props.filter === "conference") {
          // if (values.conferenceId) {
          props
            .insertScientificResearch({
              ...values,
              dashboardYear: new Date(
                dateFormat(values?.acceptedDay || moment().format("YYYY-MM-DD"))
              ).getFullYear(),
              listAuthor: tempListAuthor,
              journalId: values?.journalId,
              journalDomesticId: values?.journalDomesticId,
              conferenceId:
                props.filter === "conference"
                  ? values.conferenceId
                  : props.filter === "conferenceDomestic"
                  ? values.conferenceDomesticId
                  : null,
              conferenceDomesticId: null,
              profileId: props.profile?.id,
              scientificResearchProfile: profileList,
              type: props.type,
              acceptedDay: dateFormat(
                values?.acceptedDay || moment().format("YYYY-MM-DD")
              ),
              submissionDay: dateFormat(
                values?.submissionDay || moment().format("YYYY-MM-DD")
              ),
              accepted: props.type === "working" ? true : false,
              page: typeof(values.page) === "number" ? values.page.toString() : values.page
              // type: props.filter === 'chapter' ? 'book' : 'scientificResearch', // working
            })
            .then((response) => {
              if (response.success && files.length !== 0) {
                props
                  .uploadFile(
                    response.data.scientificResearch.id,
                    "scientificResearch",
                    files
                  )
                  .then((responseUpload) => {
                    if (responseUpload.success)
                      props.updateScientificResearch(
                        response.data.scientificResearch.id,
                        {
                          ...response.data.scientificResearch,
                          fileUrl: responseUpload.data,
                        }
                      );
                  })
                  .catch();
              }
              props.statisticUnaccepted({
                departmentId: 0,
                profileId: props.profile?.id,
                other: props.other,
              });
              if (props.role === "sudo-admin")
                props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
            })
            .catch();
          // } else {
          //   // // console.log("safadfsd");
          //   props.pushNoti({
          //     type: "error",
          //     message:
          //       "Không có dữ liệu hội thảo. Bạn vui lòng nhập ở mục tạp chí khác.",
          //   });
          // }
        }
        if (props.filter === "conferenceDomestic") {
          // if (values.conferenceDomesticId) {
          props
            .insertScientificResearch({
              ...values,
              dashboardYear: new Date(
                dateFormat(values?.acceptedDay || moment().format("YYYY-MM-DD"))
              ).getFullYear(),
              listAuthor: tempListAuthor,
              journalId: values?.journalId,
              journalDomesticId: values?.journalDomesticId,
              conferenceId:
                props.filter === "conference"
                  ? values.conferenceId
                  : props.filter === "conferenceDomestic"
                  ? values.conferenceDomesticId
                  : null,
              conferenceDomesticId: null,
              profileId: props.profile?.id,
              scientificResearchProfile: profileList,
              type: props.type,
              acceptedDay: dateFormat(
                values?.acceptedDay || moment().format("YYYY-MM-DD")
              ),
              submissionDay: dateFormat(
                values?.submissionDay || moment().format("YYYY-MM-DD")
              ),
              accepted: props.type === "working" ? true : false,
              page: typeof(values.page) === "number" ? values.page.toString() : values.page
              // type: props.filter === 'chapter' ? 'book' : 'scientificResearch', // working
            })
            .then((response) => {
              if (response.success && files.length !== 0) {
                props
                  .uploadFile(
                    response.data.scientificResearch.id,
                    "scientificResearch",
                    files
                  )
                  .then((responseUpload) => {
                    if (responseUpload.success)
                      props.updateScientificResearch(
                        response.data.scientificResearch.id,
                        {
                          ...response.data.scientificResearch,
                          fileUrl: responseUpload.data,
                        }
                      );
                  })
                  .catch();
              }
              props.statisticUnaccepted({
                departmentId: 0,
                profileId: props.profile?.id,
                other: props.other,
              });
              if (props.role === "sudo-admin")
                props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
            })
            .catch();
          // } else {
          //   props.pushNoti({
          //     type: "warning",
          //     message:
          //       "Không có dữ liệu hội thảo. Bạn vui lòng nhập ở mục tạp chí khác.",
          //   });
          // }
        }
        if (
          props.filter !== "conferenceDomestic" &&
          props.filter !== "conference"
        ) {
          props
            .insertScientificResearch({
              ...values,
              dashboardYear: new Date(
                dateFormat(values?.acceptedDay || moment().format("YYYY-MM-DD"))
              ).getFullYear(),
              listAuthor: tempListAuthor,
              journalId: values?.journalId,
              journalDomesticId: values?.journalDomesticId,
              conferenceId:
                props.filter === "conference"
                  ? values.conferenceId
                  : props.filter === "conferenceDomestic"
                  ? values.conferenceDomesticId
                  : null,
              conferenceDomesticId: null,
              profileId: props.profile?.id,
              scientificResearchProfile: profileList,
              type: props.type,
              acceptedDay: dateFormat(
                values?.acceptedDay || moment().format("YYYY-MM-DD")
              ),
              submissionDay: dateFormat(
                values?.submissionDay || moment().format("YYYY-MM-DD")
              ),
              accepted: props.type === "working" ? true : false,
              page: typeof(values.page) === "number" ? values.page.toString() : values.page
              // type: props.filter === 'chapter' ? 'book' : 'scientificResearch', // working
            })
            .then((response) => {
              if (response.success && files.length !== 0) {
                props
                  .uploadFile(
                    response.data.scientificResearch.id,
                    "scientificResearch",
                    files
                  )
                  .then((responseUpload) => {
                    if (responseUpload.success)
                      props.updateScientificResearch(
                        response.data.scientificResearch.id,
                        {
                          ...response.data.scientificResearch,
                          fileUrl: responseUpload.data,
                        }
                      );
                  })
                  .catch();
              }
              props.statisticUnaccepted({
                departmentId: 0,
                profileId: props.profile?.id,
                other: props.other,
              });
              if (props.role === "sudo-admin")
                props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
            })
            .catch();
        }
      } else {
        // change scientific other
        props
          .insertScientificResearch({
            ...values,
            score: values.score || 0,
            listAuthor: tempListAuthor,
            scientificResearchOtherProfile: profileList,
            // publishAt: dateFormat(values?.publishAt || moment().format("YYYY-MM-DD")),
            acceptedDay: dateFormat(
              values?.acceptedDay || moment().format("YYYY-MM-DD")
            ),
            submissionDay: dateFormat(
              values?.submissionDay || moment().format("YYYY-MM-DD")
            ),
            dashboardYear: new Date(
              dateFormat(values?.acceptedDay || moment().format("YYYY-MM-DD"))
            ).getFullYear(),
            type: "scientificResearchOther",
            page: typeof(values.page) === "number" ? values.page.toString() : values.page
          })
          .then((response) => {
            if (response.success && files.length !== 0) {
              // // console.log(response.data, files.length);
              props
                .uploadFile(response.data.id, "scientificResearchOther", files)
                .then((responseUpload) => {
                  if (responseUpload.success)
                    props.updateScientificResearch(
                      response.data.scientificResearch.id,
                      {
                        ...response.data.scientificResearch,
                        fileUrl: responseUpload.data,
                      }
                    );
                })
                .catch();
            }
            props.statisticUnaccepted({
              departmentId: 0,
              profileId: props.profile?.id,
              other: props.other,
            });
            if (props.role === "sudo-admin")
              props.statisticUnaccepted({ departmentId: 0, profileId: 0 });
          })
          .catch();
      }

      setValues(null);
      setProfileList([]);
      setHelperText(false);
    } else {
      setHelperText(true);
    }
  };

  const handleOpenUploadModal = () => setUploadModalOpen(true);
  const handleCloseUploadModal = () => setUploadModalOpen(false);

  const handleUpload = (files) => {
    setFiles(files);
    handleCloseUploadModal();
  };

  const getTypeAsTitle = (type) => {
    switch (type) {
      case "journalInternational":
        return "trên tạp chí quốc tế";
      case "journalDomestic":
        return "trên tạp chí trong nước";
      case "conference":
        return "trên hội thảo quốc tế";
      case "conferenceDomestic":
        return "trên hội thảo trong nước";
      case "chapter":
        return "là chương sách";
      case "workingPaper":
        return "đang thực hiện";
      case "other":
        return "trên tạp chí khác";
      default:
        return "";
    }
  };

  const contractList = [];
  props?.employmentContractListById.forEach((e) =>
    contractList.push(e.contract)
  );

  const profileListFromProps = [{ id: -1, name: "" }];
  props.profileList.forEach((e) => profileListFromProps.push(e));

  var timeResearchDetailList =
    props.timeResearchInformationList?.find((e) => e.year === timeResearchYear)
      ?.timeResearchDetail || [];
  timeResearchDetailList = timeResearchDetailList.filter(
    (e) => !e.allowedUpdateNum && e.type === "scientificResearch"
  );

  const yearList = [];
  props.timeResearchInformationList
    .sort((a, b) => b.year - a.year)
    .forEach((e) => {
      yearList.push(e.year);
    });

  const propsValues = {
    values: values,
    profileList: profileList,
    setValues: setValues,
    handleInsert: handleInsert,
    handleRemove: handleRemove,
    handleChangeForm: handleChangeForm,
    handleChangeProfileList: handleChangeProfileList,
  };

  return (
    <Dialog
      fullWidth
      maxWidth={
        values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0
          ? "xl"
          : "md"
      }
      open={inserting}
      onClose={() => setInserting(false)}
    >
      <DialogTitle>{`Thêm bài báo ${getTypeAsTitle(
        props.filter
      )}`}</DialogTitle>
      <DialogContent style={{ display: "flex", overflowX: "hidden" }}>
        <div
          style={{
            display: "flex",
            paddingRight: 16,
            minWidth:
              values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0
                ? "50%"
                : "100%",
            maxWidth:
              values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0
                ? 960
                : "100%",
          }}
        >
          <Grid
            container
            justifyContent="center"
            spacing={2}
            style={{ marginBottom: 10 }}
          >
            {props.filter === "journalInternational" && (
              <JournalInternational {...propsValues} />
            )}
            {props.filter === "journalDomestic" && (
              <JournalDomestic {...propsValues} />
            )}
            {props.filter === "conference" && <Conference {...propsValues} />}
            {props.filter === "conferenceDomestic" && (
              <ConferenceDomestic {...propsValues} />
            )}
            {props.filter === "chapter" && <Chapter {...propsValues} />}
            {props.filter === "workingPaper" && (
              <WorkingPaper {...propsValues} />
            )}
            {props.filter === "other" && <Other {...propsValues} />}

            {props.filter !== "workingPaper" && values?.purposeId === 5 && (
              <>
                <Grid item xs={3}>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    value={timeResearchYear}
                    SelectProps={{ native: true }}
                    label="Năm học tính giờ nghiên cứu"
                    variant="outlined"
                    onChange={(event) => {
                      setTimeResearchYear(parseInt(event.target.value));
                      handleChangeForm("timeResearchDetailCode", null);
                    }}
                  >
                    {yearList.map((e) => (
                      <option key={`${e} - ${e + 1}`} value={parseInt(e)}>
                        {e} - {e + 1}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label="Nhiệm vụ tính giờ nghiên cứu"
                    variant="outlined"
                    value={values?.timeResearchDetailCode || null}
                    onChange={(event) =>
                      handleChangeForm(
                        "timeResearchDetailCode",
                        event.target.value
                      )
                    }
                    InputLabelProps={{
                      shrink: !(
                        values?.timeResearchDetailCode === undefined ||
                        values?.timeResearchDetailCode === null
                      ),
                    }}
                    error={!values?.timeResearchDetailCode}
                  >
                    {timeResearchDetailList.length > 0 ? (
                      timeResearchDetailList?.map((e) => (
                        <MenuItem
                          key={e.code}
                          value={e.code}
                        >{`${e.description} - ${e.value}(h)`}</MenuItem>
                      ))
                    ) : (
                      <MenuItem value={null} disabled>
                        Chưa có dữ liệu
                      </MenuItem>
                    )}
                  </TextField>
                  {!values?.timeResearchDetailCode && (
                    <FormHelperText error>
                      Phải chọn một nhiệm vụ!
                    </FormHelperText>
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label="Ghi chú"
                variant="outlined"
                value={values?.note || ""}
                multiline
                rows={3}
                onChange={(event) =>
                  handleChangeForm("note", event.target.value)
                }
              />
            </Grid>

            <Grid item xs={5} style={{ margin: "auto" }}>
              <Divider />{" "}
            </Grid>
            <Grid item xs={2}>
              <Typography
                style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.30)" }}
              >
                Thành viên
              </Typography>
            </Grid>
            <Grid item xs={5} style={{ margin: "auto" }}>
              <Divider />{" "}
            </Grid>

            <Grid item xs={12}>
              {profileList.map((e, index) => (
                <Grid key={`list-${index}`} container spacing={2}>
                  <Grid
                    item
                    xs={
                      props.filter === "workingPaper" ||
                      (values?.purposeId !== 1 &&
                        values?.purposeId !== 99999999)
                        ? 5
                        : 4
                    }
                  >
                    <Autocomplete
                      disableClearable
                      options={profileListFromProps}
                      getOptionLabel={(option) => option.name}
                      value={
                        profileListFromProps?.find(
                          (profile) => profile.id === e.profileId
                        ) || profileListFromProps[0]
                      }
                      onChange={(event, profile) => {
                        if (
                          profileList[index]?.profileId ===
                          values?.profileIdForKpi
                        )
                          handleChangeForm("profileIdForKpi", null);
                        handleChangeProfileList(
                          index,
                          "profileId",
                          parseInt(profile?.id)
                        );
                        if (parseInt(profile?.id) >= 0) setHelperText(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={helperText && e.profileId === null}
                          size="small"
                          label="Thành viên"
                          variant="outlined"
                        />
                      )}
                    />
                    {helperText && e.profileId === null && (
                      <FormHelperText style={{ color: "red" }}>
                        Không được để trống
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={
                      props.filter === "workingPaper" ||
                      (values?.purposeId !== 1 &&
                        values?.purposeId !== 99999999)
                        ? 5
                        : 3
                    }
                  >
                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel
                        id="roleInPaper-label"
                        style={{ padding: "0 8px", backgroundColor: "white" }}
                      >
                        Vai trò
                      </InputLabel>
                      <Select
                        native
                        fullWidth
                        labelId="roleInPaper-label"
                        value={e?.roleInPaper || "main"}
                        onChange={(event) =>
                          handleChangeProfileList(
                            index,
                            "roleInPaper",
                            event.target.value
                          )
                        }
                      >
                        <option value="main">Tác giả chính</option>
                        <option value="member">Đồng tác giả</option>
                        <option value="mainOther">Tác giả chính tính hộ</option>
                        <option value="memberOther">
                          Đồng tác giả tính hộ
                        </option>
                      </Select>
                    </FormControl>
                  </Grid>
                  {values?.purposeId === 1 && (
                    <Grid
                      item
                      xs={3}
                      style={{
                        display:
                          props.filter === "workingPaper" ? "none" : "flex",
                      }}
                    >
                      <FormControl
                        required
                        fullWidth
                        error={!values?.profileIdForKpi}
                      >
                        <Button
                          fullWidth
                          color="primary"
                          variant="outlined"
                          style={{ height: 40 }}
                          disabled={e.profileId === null}
                          startIcon={
                            typeof values?.profileIdForKpi === "number" &&
                            values?.profileIdForKpi === e.profileId ? (
                              <CheckBox />
                            ) : (
                              <CheckBoxOutlineBlank />
                            )
                          }
                          onClick={() => {
                            setDetail(null);
                            props.getAllEmploymentContractByProfileId(
                              parseInt(e.profileId)
                            );
                            handleChangeForm(
                              "profileIdForKpi",
                              values?.profileIdForKpi !== e.profileId
                                ? parseInt(e.profileId)
                                : null
                            );
                          }}
                        >
                          Tính KPI
                        </Button>
                        {!values?.profileIdForKpi && (
                          <FormHelperText>
                            Phải chọn 1 người tính KPI
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                  {values?.purposeId === 99999999 && (
                    <Grid
                      item
                      xs={3}
                      style={{
                        display:
                          props.filter === "workingPaper" ? "none" : "flex",
                      }}
                    >
                      <FormControl
                        fullWidth
                        error={!values?.profileIdForReward}
                      >
                        <Button
                          fullWidth
                          color="primary"
                          variant="outlined"
                          style={{ height: 40 }}
                          disabled={e.profileId === null}
                          startIcon={
                            typeof values?.profileIdForReward === "number" &&
                            values?.profileIdForReward === e.profileId ? (
                              <CheckBox />
                            ) : (
                              <CheckBoxOutlineBlank />
                            )
                          }
                          onClick={() => {
                            setDetail(null);
                            handleChangeForm(
                              "profileIdForReward",
                              values?.profileIdForReward !== e.profileId
                                ? parseInt(e.profileId)
                                : null
                            );
                          }}
                        >
                          Tính thưởng
                        </Button>
                        {!values?.profileIdForReward && (
                          <FormHelperText>
                            Phải chọn 1 người tính thưởng
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={2}>
                    <Button
                      className="error-button"
                      fullWidth
                      color="secondary"
                      variant="contained"
                      style={{ height: 40 }}
                      onClick={() => handleRemove(index)}
                    >
                      Xóa
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12}>
              <Button fullWidth variant="outlined" onClick={handleInsert}>
                Thêm thành viên
              </Button>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            flexDirection: "column",
            display:
              values?.profileIdForKpi !== null && values?.profileIdForKpi >= 0
                ? "block"
                : "none",
          }}
        >
          <EmploymentContractTable
            contract={detail}
            contractList={contractList}
            kpiIdForKpi={values?.kpiIdForKpi}
            contractIdForKpi={values?.contractIdForKpi}
            setDetail={setDetail}
            values={values}
            setValues={setValues}
            handleChangeForm={handleChangeForm}
          />
          <EmploymentContractDetailTable contract={detail} kpi={detail} />
        </div>
      </DialogContent>
      <DialogActions style={{ padding: "0 24px 8px 24px" }}>
        <Grid container justifyContent={"space-between"}>
          <div>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleOpenUploadModal}
              style={{ marginRight: 4 }}
            >
              Upload file minh chứng
            </Button>
          </div>
          <div style={{ float: "right" }}>
            <Button
              variant="outlined"
              // color="secondary"
              onClick={() => handleClose()}
              style={{ marginRight: 4 }}
            >
              Huỷ
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                props.filter !== "workingPaper" && (
                  !(values?.numberMainAuthor > 0) 
                  || !(values?.numOfMember > 0)
                  || (
                    !!["journalInternational", "journalDomestic", "conference", "conferenceDomestic"].find((e) => props.filter === e)
                    && (!(values?.submissionMonth > 0) || !(values?.acceptedMonth > 0)))
                  || (values?.purposeId === 5 && !values?.timeResearchDetailCode)
                  || (!values?.doi && files.length === 0)
                )
                // (props.filter !== "workingPaper" && (!(values?.numberMainAuthor > 0) ||
                //   ((
                //     !!["journalInternational", "journalDomestic", "conference", "conferenceDomestic"].find((e) => props.filter === e)
                //   ) && (
                //     !(values?.submissionMonth > 0) ||
                //     !(values?.acceptedMonth > 0)
                //   )) ||
                //   !(values?.numOfMember > 0))) ||
                // (values?.purposeId === 5 && !values?.timeResearchDetailCode) ||
                // (!values?.doi && files.length === 0)
              }
              onClick={() => handleSubmit()}
            >
              Xác nhận
            </Button>
          </div>
        </Grid>
      </DialogActions>
      <FileUploadModal
        open={uploadModalOpen}
        handleClose={handleCloseUploadModal}
        handleUpload={handleUpload}
        defaultFiles={files}
      />
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  role: select(state, "userReducer", "role"),
  fileUrl: select(state, "fileReducer", "fileUrl"),
  profile: select(state, "profileReducer", "detail"),
  profileList: select(state, "profileReducer", "list"),
  employmentContractListById: select(
    state,
    "employmentContractReducer",
    "listById"
  ),
  timeResearchInformationList: select(
    state,
    "timeResearchInformationReducer",
    "list"
  ),
  isFetchingTimeResearchInformation: select(
    state,
    "timeResearchInformationReducer",
    "isFetching"
  ),
});

const mapDispatchToProps = (dispatch) => ({
  pushNoti: (payload) => dispatch(notification(payload)),
  resetDetail: () => dispatch(resetDetail()),
  uploadFile: (id, type, file) => dispatch(uploadFile(id, type, file)),
  statisticUnaccepted: (payload) => dispatch(statisticUnaccepted(payload)),
  insertScientificResearch: (payload) =>
    dispatch(insertScientificResearch(payload)),
  insertScientificResearchOther: (payload) =>
    dispatch(insertScientificResearchOther(payload)),
  getAllEmploymentContractByProfileId: (id) =>
    dispatch(getAllEmploymentContractByProfileId(id)),
  updateScientificResearch: (id, payload) =>
    dispatch(updateScientificResearch(id, payload)),
  getTimeResearchInformation: (profileId, year) =>
    dispatch(getTimeResearchInformation(profileId, year)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJs(SolutionInsertModal));
