import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from 'react-redux';
import {
  TextField,
  Card,
  Grid,
  Box,
  Typography,
  MenuItem,
  Divider,
  Button,
  Chip,
  Popover,
} from '@material-ui/core';
import { Search, Close, Add, Replay, Save } from '@material-ui/icons';
import { getAllProfile } from '../../reducer/profile/action';
import { getAllFaculties } from '../../reducer/department/action';

import {
  filterCouncil,
  insertCouncil,
  updateCouncil,
  deleteCouncil
} from '../../reducer/council/action';
import { KPTable, KPTextform, KPTextfield } from '../KPTComponents';

import select from "../../utils/select";
import theme from '../../theme';


const fieldKey = [
  { key: 'chairman', title: 'Chủ tịch' },
  { key: 'secretary', title: 'Thư ký' },
  { key: 'firstReviewer', title: 'Phản biện 1' },
  { key: 'secondReviewer', title: 'Phản biện 2' },
  // { key: 'commissioner', title: 'Uỷ viên' },
  { key: 'firstCommissioner', title: 'Uỷ viên 1' },
  { key: 'secondCommissioner', title: 'Uỷ viên 2' },
  { key: 'thirdCommissioner', title: 'Uỷ viên 3' },
  { key: 'fourthCommissioner', title: 'Uỷ viên 4' },
  { key: 'fifthCommissioner', title: 'Uỷ viên 5' },
  { key: 'sixthCommissioner', title: 'Uỷ viên 6' },
  { key: 'seventhCommissioner', title: 'Uỷ viên 7' },
]

function StudentProjectCouncil() {
  const dispatch = useDispatch();
  
  const _council = useSelector(state => select(state, 'councilReducer', 'council'));
  const council = [ ..._council ];
  const profileList = useSelector(state => select(state, 'profileReducer', 'list'));
  const faculties = useSelector(state => select(state, 'departmentReducer', 'faculties'));

  const [focus, setFocus] = useState(false);
  const [isEdit, setEdit] = useState(null);
  const [isInsert, setInsert] = useState(false);
  const [values, setValues] = useState(null);
  const [departmentIds, setDepartmentIds] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [anchorEl, setAnchorEl] = useState(null);

  const yearRange = [0];
  for (var i = parseInt(moment().format('YYYY')); i >= 2021; i--) yearRange.push(i);

  const handleChangeForm = (key, val) => {
    if (key === 'department') {
      setDepartmentIds(val.map((e) => e.id));
    } else if (typeof(val) === 'object') {
      const roleKey = key.replace('Name', '');
      setValues({
        ...values,
        [`${roleKey}Name`]: val?.name,
        [`${roleKey}Degree`]: val?.degree,
        [`${roleKey}Pid`]: val?.id,
      });
    } else {
      setValues({ ...values, [key]: val });
    }
  }

  const handleInsert = () => {
    setInsert(true);
  }
  // setInsert

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isInsert) {
      dispatch(insertCouncil(
        { departmentIds, councilInfo: { ...values, schoolYear: year } },
        faculties.filter((faculty) => departmentIds.find((departmentId) => faculty.id === departmentId))
      ));
      setInsert(false);
      setDepartmentIds([]);
    } else if (isEdit) {
      dispatch(updateCouncil(
        { departmentIds, councilInfo: { ...values, schoolYear: year } },
        faculties.filter((faculty) => departmentIds.find((departmentId) => faculty.id === departmentId))
      ));
      setEdit(null);
      setDepartmentIds([]);
    } else {
      setInsert(true);
    }
  }

  const handleEdit = () => {
    if (isInsert) {
      dispatch(insertCouncil({ departmentIds, newCouncil: { ...values, schoolYear: year } }));
      setInsert(false);
      // insertCouncil
    }
  }

  const cancel = () => {
    setEdit(null);
    setInsert(false);
    setDepartmentIds([]);
  }

  const handleReload = () => {
    dispatch(getAllProfile());
    dispatch(getAllFaculties());
    dispatch(filterCouncil({ schoolYear: year }));
  }

  const handleClick = (event, id) => {
    setValues({ id })
    setAnchorEl(event.currentTarget);
  };


  useEffect(() => handleReload(), []);

  if (isInsert) council.push({
    type: 'insert',
    name: values?.name,
  });

  // useEffect(() => {
  //   dispatch(getAllCouncil());
    
  // }, []);

  return (
    <Card style={{ padding: '8px 16px', height: 'calc(100vh - 108px)', minHeight: 'calc(100vh - 108px)', maxHeight: 'calc(100vh - 108px)' }}>
      <form id='submit-form' onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Typography variant='h5' style={{ lineHeight: '40px' }}>
              <b>Hội đồng các tiểu ban Hội nghị NCKH cấp trường</b>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              startIcon={isInsert || isEdit ? <Save /> : <Add />}
              style={{ float: 'right', marginLeft: 8, width: 160, height: 40 }}
            >
              {isInsert || isEdit ? 'Lưu' : 'Thêm'} tiểu ban
            </Button>
            <Button
              variant='outlined'
              onClick={isInsert || isEdit ? cancel : handleReload}
              style={{ float: 'right', marginLeft: 8, width: 40, height: 40 }}
            >
              {isInsert || isEdit ? <Close /> : <Replay />}
            </Button>
            <TextField
              select
              size='small'
              variant='outlined'
              SelectProps={{
                MenuProps: {
                  getContentAnchorEl: null,
                  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                  transformOrigin: { vertical: 'top', horizontal: 'center' },
                }
              }}
              label='Năm học'
              value={year}
              onChange={(event) => setYear(event.target.value)}
              style={{ float: 'right'}}
            >
              {yearRange.map((year) => <MenuItem key={`year-${year}`} value={year}>{year === 0 ? 'Tất cả' : `${year} - ${year + 1}`}</MenuItem>)}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <Box border='solid 1px #bcbcbc' borderRadius={4}>
              <KPTable
                disablePagination={true}
                columns={[
                  {
                    title: <b>Tiểu ban</b>,
                    key: 'name',
                    width: '60%',
                    searchable: true,
                    render: (value, row) => <Box
                      width='100%'
                      style={{ cursor: isInsert ? 'auto' : 'pointer' }}
                      onMouseEnter={() => setFocus(row.id)}
                      // onMouseLeave={() => setFocus(null)}
                    >
                      {(isInsert && row?.type === 'insert') || (isEdit === row.id) ? (
                        <TextField
                          required
                          fullWidth
                          size='small'
                          variant='outlined'
                          value={values?.name}
                          inputProps={{ style: { padding: 8, borderRadius: 4 } }}
                          onChange={(e) => handleChangeForm('name', e.target.value)}
                        />
                      ) : (
                        <>
                          <b>{value || 'N/A'}</b>
                          {(focus === row.id && !isInsert) && (
                            <>
                              <Typography variant='subtitle2' style={{ fontSize: 12 }}>
                                <span
                                  onClick={() => setValues({ ...values, ...row })}
                                  style={{ color: theme.palette.primary.main }}
                                >
                                  Chi tiết
                                </span>
                                <span> | </span>
                                <span
                                  style={{ color: theme.palette.secondary.main }}
                                  onClick={() => {
                                    setValues({ ...values, ...row });
                                    setEdit(row.id);
                                    setDepartmentIds(row.departments.map((e) => e.id))
                                  }}
                                >
                                  Chỉnh sửa
                                </span>
                                <span> | </span>
                                <span
                                  style={{ color: theme.palette.error.main }}
                                  onClick={(event) => handleClick(event, row.id)}
                                >
                                  Xoá
                                </span>
                              </Typography>
                              
                            </>
                          )}
                        </>
                      )}
                    </Box>
                  },
                  {
                    title: <b>Khoa</b>,
                    key: 'departments',
                    width: '40%',
                    searchable: true,
                    render: (value, row) => <Box width='100%'>
                      {(isInsert && row?.type === 'insert') || (isEdit === row.id) ? (
                        <Autocomplete
                          multiple
                          disableClearable
                          value={faculties.filter((faculty) => departmentIds.find((departmentId) => faculty.id === departmentId))}
                          options={faculties?.filter((e) => e.id > 0)}
                          getOptionLabel={(option) => option.name}
                          onChange={(e, value) =>  handleChangeForm('department', value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size='small'
                              variant="outlined"
                              inputProps={{ ...params.inputProps, style: { ...params.inputProps.style, padding: "2px" } }}
                            />
                          )}
                        />
                        // <KPTextfield
                        //   multiple={true}
                        //   type='autoComplete'
                        //   required={true}
                        //   options={faculties?.filter((e) => e.id > 0)}
                        //   // value={faculties?.filter((e) => e.id > 0).find((e) => e.id === (member?.departmentId))}
                        //   getOptionLabel={(option) => option?.name || ''}
                        //   handleChangeForm={handleChangeForm}
                        // />
                        // <TextField
                        //   fullWidth
                        //   size='small'
                        //   variant='outlined'
                        //   value={row?.value}
                        //   onChange={(e) => handleChangeForm('name', e.target.value)}
                        // />
                      ) : (
                        <Box minHeight={40} display='flex' flexDirection='column' justifyContent='center'>
                          {value?.map((e, id) => (
                            <Chip
                              id={`cc-${e.id}-${row.id}`}
                              size='small'
                              color='primary'
                              variant='outlined'
                              label={e.name}
                              style={{
                                marginTop: id > 0 ? 8 : 0,
                                width: '100%'
                              }}
                            >
                              {e.name}
                            </Chip>
                          ))}
                        </Box>
                      )}
                    </Box>
                  },
                  // {
                  //   title: <b>Tác vụ</b>,
                  //   key: 'actions',
                  //   width: '20%',
                  //   searchable: true,
                  //   render: (_, row) => (
                  //     <Typography
                  //       className='table-action-buton'
                  //       variant='caption'
                  //       color='primary'
                  //       onClick={() => setDetail(row?.councilMemberInfo[0])}
                  //     >
                  //       Chi tiết
                  //     </Typography>
                  //   )
                  // },
                ]}
                rows={council}
              />

{/* <Popover
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Box padding={1} width={180} style={{
          boxShadow: 'rgba(60, 64, 67, 0.75) 0px 1px 2px 0px, rgba(60, 64, 67, 0.6) 0px 2px 6px 2px !important'
        }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>Xác nhận xóa tiểu ban?</Grid>
            <Grid item xs={6}>
              <Button fullWidth variant='outlined'>hủy</Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color='primary'
                variant='contained'
                // onClick={() => dispatch( deleteCouncil(row.id))}
              >
                OK
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Popover> */}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box border='solid 1px #bcbcbc' borderRadius={4}>
              <Grid
                container
                spacing={1}
                style={{
                  width: 'calc(100% - 2px)',
                  margin: 1,
                  padding: '8px 0',
                  backgroundColor: '#fafafa'
                }}
              >
                <Grid item xs={7}>
                  <Typography variant='body1' style={{ marginLeft: 162 }}><b>Tên thành viên</b></Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant='body1'><b>Học hàm, học vị</b></Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography variant='body1'><b>Vai trò</b></Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={1}>
                <KPTextform
                  id='studentCouncilForm'
                  textForm={[
                    {key: 'box4', xs: 12, type: 'render', render: () => (
                      <Grid container spacing={1} style={{ padding: '12px 0', borderBottom: 'solid 1px #e0e0e0' }}>
                        {fieldKey.map((role) => {
                          const { key, title } = role;
                          const isPhenikaa = values?.[`${key}IsPhenikaa`] || !!values?.[`${key}Pid`];

                          return (
                            <React.Fragment key={key}>
                              <Grid item xs={7}>
                                <Box display='flex'>
                                  <Box width='170px' marginRight={1}>
                                    <KPTextfield
                                      value={isPhenikaa || false}
                                      id={`${key}IsPhenikaa`}
                                      type='checkbox'
                                      name='GV trong trường'
                                      disabled={!isInsert && !isEdit}
                                      handleChangeForm={handleChangeForm}
                                    />
                                  </Box>
                                  <Box width='calc(100% - 170px)'>
                                    <KPTextfield
                                      type={isPhenikaa ? 'autoComplete' : null}
                                      options={isPhenikaa ? profileList : null}
                                      getOptionLabel={(option) => option.name}
                                      value={isPhenikaa
                                        ? profileList.find((e) => e.id === values?.[`${key}Pid`])
                                        : values?.[`${key}Name`]
                                      }
                                      id={`${key}Name`}
                                      disabled={!isInsert && !isEdit}
                                      handleChangeForm={handleChangeForm}
                                      // required={true}
                                    />
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={3}>
                                <KPTextfield
                                  value={values?.[`${key}Degree`]}
                                  disabled={isPhenikaa || (!isInsert && !isEdit)}
                                  id={`${key}Degree`}
                                  handleChangeForm={handleChangeForm}
                                  // required={true}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <KPTextfield value={title} disabled={true} />
                              </Grid>
                            </React.Fragment>
                          )
                        })}
                      </Grid>
                    )}
                  ]}
                  values={values}
                  setValues={setValues}
                  handleSubmit={handleSubmit}
                />
              </Grid>
            </Box>
          </Grid>
          {/* <StudentProjectCouncilButton /> */}
          {/* <StudentProjectTable type={props.type} filterObj={filterObj} /> */}
        </Grid>
      </form>

      <Popover
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Box padding={1} width={180} style={{
          boxShadow: 'rgba(60, 64, 67, 0.75) 0px 1px 2px 0px, rgba(60, 64, 67, 0.6) 0px 2px 6px 2px !important'
        }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>Xác nhận xóa tiểu ban?</Grid>
            <Grid item xs={6}>
              <Button fullWidth variant='outlined'>hủy</Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color='primary'
                variant='contained'
                onClick={() => {
                  setAnchorEl(null);
                  dispatch(deleteCouncil(values.id));
                }}
              >
                OK
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </Card>
  );
}

export default StudentProjectCouncil;
