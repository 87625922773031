import { connect } from 'react-redux';
import { Button, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import toJs from '../../../hoc/toJS';
import { deleteUserById } from '../../../reducer/humanResource/action';

const useStyles = makeStyles((theme) => {
  return {
    popup: {
      padding: theme.spacing(1),
    },
    popupButtonGroup: {
      float: 'right',
      padding: theme.spacing(1, 0),
    },
    btnCancel: {
      marginRight: 4,
    },
  };
});

function HumanResourceDeleteModal(props) {
  const { deleting, anchorEl, setAnchorEl, deleteUserById } = props;

  const classes = useStyles();

  const handleCloseHumanResourceDeleteModal = () => {
    setAnchorEl(null);
  }

  const handleDeleteProfile = () => {
    deleteUserById(deleting, handleCloseHumanResourceDeleteModal);
  };

  return (
    <Popover
      open={anchorEl !== null}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <div className={classes.popup}>
        <div>Xác nhận xoá?</div>
        <div className={classes.popupButtonGroup}>
          <Button
            size='small'
            variant='outlined'
            color='secondary'
            className={classes.btnCancel}
            onClick={handleCloseHumanResourceDeleteModal}
          >
            Huỷ
          </Button>
          <Button size='small' variant='outlined' color='primary' onClick={handleDeleteProfile}>
            Xác nhận
          </Button>
        </div>
      </div>
    </Popover>
  );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  deleteUserById: (profileId, cleanUp) => dispatch(deleteUserById(profileId, cleanUp))
});

export default connect(mapStateToProps, mapDispatchToProps)(toJs(HumanResourceDeleteModal));
