import React from 'react';
import { Card, Grid } from '@material-ui/core';

import './EmploymentContractDetailTable.css';

function EmploymentContractDetailTable(props) {
  const { kpi } = props;
  if (kpi === null || kpi === undefined) return null;

  const color = props.disabled ? '#bdbdbd' : 'black';

  return (
    <Card style={{ marginTop: 16 }}>
      <Grid container>
        <Grid className='KPT-grid-item KPT-gird-header' item xs={6}><b style={{ color: color }}>Số lượng bài báo</b></Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid className='KPT-grid-item KPT-gird-header' item xs={4}><b style={{ color: color }}>Loại Q</b></Grid>
            <Grid className='KPT-grid-item KPT-gird-header' item xs={4}><b style={{ color: color }}>Loại R</b></Grid>
            <Grid className='KPT-grid-item KPT-gird-header' item xs={4}><b style={{ color: color }}>Số lượng</b></Grid>
              {kpi?.kpiScientificResearch?.length > 0 ? (
                kpi?.kpiScientificResearch.map((e) => (
                  <Grid container key={`kpiScientificResearch - ${e.id}`}>
                    <Grid className='KPT-grid-item' item xs={4} style={{ color: color }}>{e?.typeQ}</Grid>
                    <Grid className='KPT-grid-item' item xs={4} style={{ color: color }}>{kpi?.type === 'R'? e?.typeR : '-'}</Grid>
                    <Grid className='KPT-grid-item' item xs={4} style={{ color: color }}>{e?.numFloat}</Grid>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} className='KPT-grid-item'>&nbsp;</Grid>
              )}
          </Grid>
        </Grid>
        {/* ----- oOo -- oOo ----- */}
        <Grid className='KPT-grid-item KPT-gird-header' item xs={6}><b style={{ color: color }}>Số lượng giải pháp hữu ích</b></Grid>
        <Grid item className='KPT-grid-item' xs={6} style={{ color: color }}>{kpi?.numSolution}</Grid>
        {/* ----- oOo -- oOo ----- */}
        <Grid className='KPT-grid-item KPT-gird-header' item xs={6}><b style={{ color: color }}>Số lượng bằng sáng chế</b></Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid className='KPT-grid-item KPT-gird-header' item xs={6}><b style={{ color: color }}>Loại bằng</b></Grid>
            <Grid className='KPT-grid-item KPT-gird-header' item xs={6}><b style={{ color: color }}>Số lượng</b></Grid>
              {kpi?.kpiPatent?.length > 0 ? (
                kpi?.kpiPatent.map((e) => (
                  <Grid container key={`kpiPatent - ${e.id}`}>
                    <Grid className='KPT-grid-item' item xs={6} style={{ color: color }}>{e?.type}</Grid>
                    <Grid className='KPT-grid-item' item xs={6} style={{ color: color }}>{e?.num}</Grid>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} className='KPT-grid-item'>&nbsp;</Grid>
              )}
          </Grid>
        </Grid>
        {/* ----- oOo -- oOo ----- */}
        {/* disabled product contract */}
        {/* <Grid className='KPT-grid-item KPT-gird-header' item xs={6}><b style={{ color: color }}>Số lượng hợp đồng chuyển giao</b></Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid className='KPT-grid-item KPT-gird-header' item xs={6}><b style={{ color: color }}>Giá trị</b></Grid>
            <Grid className='KPT-grid-item KPT-gird-header' item xs={6}><b style={{ color: color }}>Số lượng</b></Grid>
              {kpi?.kpiProductContract?.length > 0 ? (
                kpi?.kpiProductContract.map((e) => (
                  <Grid container key={`kpiProductContract - ${e.id}`}>
                    <Grid className='KPT-grid-item' item xs={6} style={{ color: color }}>{e?.value}</Grid>
                    <Grid className='KPT-grid-item' item xs={6} style={{ color: color }}>{e?.num}</Grid>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} className='KPT-grid-item'>&nbsp;</Grid>
              )}
          </Grid>
        </Grid> */}
      </Grid>
    </Card>
  );
}

export default EmploymentContractDetailTable;
