import { apiCall } from '../../utils/api';

import { notification } from '../home/action';

import TYPE from '../../constants/type';
import { API_URLS } from '../../constants/api';
import { PREFIX } from '../../constants/enums';

export const getAllProject = () => async (dispatch) => {
  const api = API_URLS.PROJECT.getAllProject();
  dispatch({
    type: TYPE.PROJECT.GET_ALL_PROJECT_LOADING,
    meta: { prefix: [PREFIX.PROJECT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PROJECT.GET_ALL_PROJECT_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.PROJECT, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.PROJECT.GET_ALL_PROJECT_FAILURE,
      meta: { prefix: [PREFIX.PROJECT, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};

export const getFilteredProject = (page, pageSize, payload) => async (dispatch) => {
  const api = API_URLS.PROJECT.getFilteredProject(page, pageSize, payload);
  dispatch({
    type: TYPE.PROJECT.GET_FILTERED_PROJECT_LOADING,
    meta: { prefix: [PREFIX.PROJECT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PROJECT.GET_FILTERED_PROJECT_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.PROJECT, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.PROJECT.GET_FILTERED_PROJECT_FAILURE,
      meta: { prefix: [PREFIX.PROJECT, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};

export const getAllProjectByProfileId = (id) => async (dispatch) => {
  const api = API_URLS.PROJECT.getAllProjectByProfileId(id);
  dispatch({
    type: TYPE.PROJECT.GET_ALL_PROJECT_BY_PROFILE_ID_LOADING,
    meta: { prefix: [PREFIX.PROJECT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PROJECT.GET_ALL_PROJECT_BY_PROFILE_ID_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.PROJECT, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.PROJECT.GET_ALL_PROJECT_BY_PROFILE_ID_FAILURE,
      meta: { prefix: [PREFIX.PROJECT, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};

export const getProjectById = (exist, id) => async (dispatch) => {
  if (exist) {
    dispatch({
      type: TYPE.PROJECT.GET_PROJECT_BY_ID_SUCCESS,
      payload: id,
      meta: { prefix: [PREFIX.PROJECT, PREFIX.API_SUCCESS] },
    });
  } else {
    const api = API_URLS.PROJECT.getProjectById(id);
    dispatch({
      type: TYPE.PROJECT.GET_PROJECT_BY_ID_LOADING,
      meta: { prefix: [PREFIX.PROJECT, PREFIX.API_CALLING] },
    });
    const { response, error } = await apiCall({ ...api });
    if (!error && response.status === 200 && response.data.success) {
      dispatch({
        type: TYPE.PROJECT.GET_PROJECT_BY_ID_SUCCESS,
        payload: response.data.data,
        meta: { prefix: [PREFIX.PROJECT, PREFIX.API_SUCCESS] },
      });
    } else {
      dispatch({
        type: TYPE.PROJECT.GET_PROJECT_BY_ID_FAILURE,
        meta: { prefix: [PREFIX.PROJECT, PREFIX.API_FAILURE] },
      });
    }
  }
};

export const insertProject = (profileId, payload) => async (dispatch) => {
  const api = API_URLS.PROJECT.insertProject();
  dispatch({
    type: TYPE.PROJECT.INSERT_PROJECT_LOADING,
    meta: { prefix: [PREFIX.PROJECT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PROJECT.INSERT_PROJECT_SUCCESS,
      id: profileId,
      payload: response.data.data,
      meta: { prefix: [PREFIX.PROJECT, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Thêm dữ liệu thành công!'
    }));
    return({ success: true, data: response.data.data });
  } else {
    dispatch({
      type: TYPE.PROJECT.INSERT_PROJECT_FAILURE,
      meta: { prefix: [PREFIX.PROJECT, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Thêm dữ liệu thất bại!'
    }));
    return({ success: false, data: null });
  }
};


export const updateProject = (id, payload) => async (dispatch) => {
  const api = API_URLS.PROJECT.updateProject(id);
  dispatch({
    type: TYPE.PROJECT.UPDATE_PROJECT_LOADING,
    meta: { prefix: [PREFIX.PROJECT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PROJECT.UPDATE_PROJECT_SUCCESS,
      id,
      payload: response.data.data,
      meta: { prefix: [PREFIX.PROJECT, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Cập nhật dữ liệu thành công!'
    }));
    return(true);
  } else {
    dispatch({
      type: TYPE.PROJECT.UPDATE_PROJECT_FAILURE,
      meta: { prefix: [PREFIX.PROJECT, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Cập nhật dữ liệu thất bại!'
    }));
    return(false);
  }
};

export const deleteProject = (id) => async (dispatch) => {
  const api = API_URLS.PROJECT.deleteProject(id);
  dispatch({
    type: TYPE.PROJECT.DELETE_PROJECT_LOADING,
    meta: { prefix: [PREFIX.PROJECT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PROJECT.DELETE_PROJECT_SUCCESS,
      id,
      meta: { prefix: [PREFIX.PROJECT, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Xoá dữ liệu thành công!'
    }));
    return(true);
  } else {
    dispatch({
      type: TYPE.PROJECT.DELETE_PROJECT_FAILURE,
      meta: { prefix: [PREFIX.PROJECT, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Xoá dữ liệu thất bại!'
    }));
    return(false);
  }
};

export const acceptProject = (id, accept) => async (dispatch) => {
  const api = API_URLS.PROJECT.acceptProject(id, accept);
  dispatch({
    type: TYPE.PROJECT.ACCEPT_PROJECT_LOADING,
    meta: { prefix: [PREFIX.PROJECT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PROJECT.ACCEPT_PROJECT_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.PROJECT, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Xác nhận thành công!'
    }));
    return(true);
  } else {
    dispatch({
      type: TYPE.PROJECT.ACCEPT_PROJECT_FAILURE,
      meta: { prefix: [PREFIX.PROJECT, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Xác nhận thất bại!'
    }));
    return(false);
  }
};

export const getProfileInfoByProfileId = (profileId) => async (dispatch) => {
  const api = API_URLS.PROFILE.getProfileInfoByProfileId(profileId);
  dispatch({
    type: TYPE.PROJECT.GET_PROFILE_INFO_BY_PROFILE_ID_LOADING,
    meta: { prefix: [PREFIX.PROJECT, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PROJECT.GET_PROFILE_INFO_BY_PROFILE_ID_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.PROJECT, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Xác nhận thành công!'
    }));
  } else {
    dispatch({
      type: TYPE.PROJECT.GET_PROFILE_INFO_BY_PROFILE_ID_FAILURE,
      meta: { prefix: [PREFIX.PROJECT, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Xác nhận thất bại!'
    }));
  }
};