import { useSelector } from "react-redux";
import { GetApp } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Box, Grid, Tooltip, Divider, IconButton } from "@material-ui/core";
import { SupervisorAccount, Settings, VerifiedUser } from "@material-ui/icons";

import { rowRender } from "../utils/render";
import { KPTable } from "../../../components/KPTComponents";
import { STUDENT_RESEARCH } from "../../../constants/selectOptions";

import select from "../../../utils/select";
import ActionButton from "../utils/ActionButton";
import StudentProjectModal from "../StudentProjectModal";
import ROUTER from "../../../constants/router";
import { AcademicRankAbbrv, DegreeAbbrv } from "../../../utils/abbreviation";

const sortFunc = (a, b) => {
  if (a.researchRole === "main") return -1;
  if (b.researchRole === "main") return 1;
  return a.id - b.id;
};

const filterLeavers = (members) => members?.filter((member) => member.leaveReason === "");

function StudentProjectTable(props) {
  const history = useHistory();
  const { type } = props;

  const [open, setOpen] = useState(null);
  const [detail, setDetail] = useState(null);
  const [datasource, setDatasource] = useState([]);

  const list = useSelector((state) => select(state, "studentResearchReducer", "list")) || [];
  const isFetching = useSelector((state) => select(state, "studentResearchReducer", "isFetching"));

  var columns = [
    {
      title: <b>Tên đề tài</b>,
      key: "name",
      width: "calc(100% - 810px)",
      searchable: true,
      render: (value, row) =>
        !row.status.find((e) => e.value === "report_submitted") ? value : row.parentResearch?.name,
    },
    {
      title: <b>Giảng viên hướng dẫn</b>,
      key: "instructors",
      width: 180,
      render: (_, row) => {
        const value = !row.status.find((e) => e.value === "report_submitted")
          ? row.instructors
          : row.parentResearch?.instructors;
        return (
          <Box margin="-6px -16px" height={row.maxHeight} width="calc(100% + 32px)">
            {value?.sort(sortFunc)?.map((e, id) => (
              <Box
                key={`column-instructor-${id}`}
                height={`calc(${100 / value?.length}% - ${id === 0 ? 0 : 1}px)`}
                borderTop={id !== 0 ? "solid 1px #e0e0e0" : null}
                display="flex"
                alignItems="center"
                padding="0 16px"
                minHeight={32}
                fontWeight={e.researchRole === "main" ? "bold" : "auto"}
              >
                {`${AcademicRankAbbrv(e?.academicRank)}
                ${DegreeAbbrv(e?.degree)}
                ${e?.name}`}
              </Box>
            ))}
          </Box>
        );
      },
    },
    {
      title: <b>Sinh viên</b>,
      key: "researchMembers",
      width: 170,
      render: (_, row) => {
        const members = filterLeavers(
          !row.status.find((e) => e.value === "report_submitted")
            ? row.researchMembers
            : row.parentResearch?.researchMembers
        );
        return (
          <Box margin="-6px -16px" height={row.maxHeight} width="calc(100% + 32px)">
            {members?.sort(sortFunc)?.map((e, id) => (
              <Box
                key={`column-member-${id}`}
                width="calc(100% - 32px)"
                height={`calc(${100 / members?.length}% - ${id === 0 ? 0 : 1}px)`}
                borderTop={id !== 0 ? "solid 1px #e0e0e0" : null}
                display="flex"
                alignItems="center"
                minHeight={id === 0 ? 32 : 31}
                padding="0 16px"
                fontWeight={e.researchRole === "main" ? "bold" : "auto"}
              >
                {`${e?.name}`}
              </Box>
            ))}
          </Box>
        );
      },
    },
    {
      title: <b>Vai trò</b>,
      key: "researchMembersRole",
      width: 80,
      align: "right",
      render: (_, row) => {
        const members = filterLeavers(
          !row.status.find((e) => e.value === "report_submitted")
            ? row.researchMembers
            : row.parentResearch?.researchMembers
        );
        return (
          <Box margin="-6px -16px" width="calc(100% + 32px)" height={row.maxHeight}>
            {members?.sort(sortFunc)?.map((e, id) => (
              <Box
                key={`column-member-role-${id}`}
                width="calc(100% - 32px)"
                height={`calc(${100 / members?.length}% - ${id === 0 ? 0 : 1}px)`}
                borderTop={id !== 0 ? "solid 1px #e0e0e0" : null}
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                minHeight={id === 0 ? 32 : 31}
                padding="0 16px"
                fontWeight={e.researchRole === "main" ? "bold" : "auto"}
              >
                {STUDENT_RESEARCH.researchRoleMemberOptions.find((role) => role.value === e.researchRole)?.title}
              </Box>
            ))}
          </Box>
        );
      },
    },
    {
      title: <b>Trạng thái</b>,
      key: "status",
      width: 276,
      filters: [
        {
          text: "Chưa gửi báo cáo tiến độ",
          value: "report_default",
        },
        {
          text: "Đã gửi đơn xin dừng",
          value: "report_stop_default",
        },
        {
          text: "Đã duyệt đơn xin dừng",
          value: "report_stop_accepted",
        },
        {
          text: "Đề tài đã gửi báo cáo tiến độ",
          value: "report_submitted",
        },
        {
          text: "Đảm bảo tiến độ",
          value: "report_ensure",
        },
      ],
      onFilter: (row, checklist) => {
        for (const property in checklist) {
          if (checklist[property] && row.status.find((e) => e.value.includes(property))) return true;
        }
        return false;
      },
      render: (value, row) => (
        <>
          {value.map((e, index) => (
            <Box
              key={`status-${row.id}-${index}`}
              bgcolor={`${e.bgcolor}16`}
              border={`solid 1px ${e.bgcolor}`}
              color={e.bgcolor}
              fontSize={12}
              borderRadius={16}
              padding="2px 0"
              width="calc(100% - 32px)"
              margin="auto"
              textAlign="center"
              marginTop={index !== 0 ? 1 : 0}
            >
              {e.label}
            </Box>
          ))}
        </>
      ),
    },
    {
      title: <b>Tác vụ</b>,
      key: "actions",
      width: 104,
      render: (value, row) => (
        <ActionButton
          row={row}
          actions={value}
          setOpen={setOpen}
          setDetail={setDetail}
          parent={list?.find((e) => row.parent === e.id)}
        />
      ),
    },
  ];

  useEffect(() => {
    const fullRows = list.map((e) => ({ ...e, status: e?.status.split(",") }));
    const _datasource = fullRows.map((row) => {
      const { status, buttons } = rowRender(row.status, type);

      const instructorsLength = !status.find((e) => e.value === "report_submitted")
        ? row?.instructors?.length
        : row?.parentResearch?.instructors?.length;
      const researchMembersLength = !status.find((e) => e.value === "report_submitted")
        ? row?.researchMembers?.length
        : row?.parentResearch?.researchMembers?.length;
      const maxHeight = Math.max(instructorsLength, researchMembersLength) * 32;

      return {
        ...row,
        status,
        actions: buttons,
        maxHeight: maxHeight,
      };
    });

    if (list) setDatasource(_datasource);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  return (
    <Grid item xs={12} style={{ position: "relative" }}>
      <KPTable
        rows={datasource}
        columns={columns}
        border={true}
        rowsPerPage={10}
        loading={isFetching}
        filterObj={props.filterObj}
        maxHeight="calc(100vh - 208px)"
      />

      {(props.type === "admin" || props.type === "mod" || props.type === "conference") && (
        <Box bottom={6} marginLeft={0} position="absolute" display="flex">
          {(props.type === "conference" || props.type === "admin") && (
            <>
              <IconButton
                onClick={() =>
                  setOpen({
                    export: true,
                    rows: datasource,
                  })
                }
              >
                <Tooltip title="Xuất file báo cáo">
                  <GetApp />
                </Tooltip>
              </IconButton>
            </>
          )}
          {props.type === "conference" && (
            <>
              <Tooltip title="Hội đồng các tiểu ban">
                <IconButton onClick={() => history.push(`${ROUTER.NCKH_SINH_VIEN}/council`)}>
                  <SupervisorAccount />
                </IconButton>
              </Tooltip>
              <Divider orientation="vertical" style={{ height: 32, margin: "8px" }} />
              <IconButton>
                <Tooltip title="Check trùng đề tài">
                  <VerifiedUser />
                </Tooltip>
              </IconButton>
              <IconButton onClick={() => setOpen({ setting: true })}>
                <Tooltip title="Cấu hình">
                  <Settings />
                </Tooltip>
              </IconButton>
            </>
          )}
        </Box>
      )}

      <StudentProjectModal
        rows={list}
        open={open}
        detail={detail}
        type={props.type}
        year={props.year}
        chosenDepartmentId={props.chosenDepartmentId}
        setOpen={setOpen}
      />
    </Grid>
  );
}

export default StudentProjectTable;
