import { fromJS } from "immutable";

import TYPE from "../../constants/type";

const initialState = fromJS({
  list: [],
  isFetching: false,
});

const sortFunc = (a, b) => a.id - b.id;

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.STUDENT_RESEARCH.GET_ALL_STUDENT_RESEARCH_LOADING:
    case TYPE.STUDENT_RESEARCH.INSERT_STUDENT_RESEARCH_LOADING:
    case TYPE.STUDENT_RESEARCH.UPDATE_STUDENT_RESEARCH_LOADING:
    case TYPE.STUDENT_RESEARCH.UPDATE_STUDENT_RESEARCH_STATUS_LOADING:
    case TYPE.STUDENT_RESEARCH.FILTER_STUDENT_RESEARCH_LOADING:
    case TYPE.STUDENT_RESEARCH.DELETE_STUDENT_RESEARCH_LOADING:
      return state.merge({
        isFetching: true,
      });

    case TYPE.STUDENT_RESEARCH.GET_ALL_STUDENT_RESEARCH_FAILURE:
    case TYPE.STUDENT_RESEARCH.INSERT_STUDENT_RESEARCH_FAILURE:
    case TYPE.STUDENT_RESEARCH.UPDATE_STUDENT_RESEARCH_FAILURE:
    case TYPE.STUDENT_RESEARCH.UPDATE_STUDENT_RESEARCH_STATUS_FAILURE:
    case TYPE.STUDENT_RESEARCH.FILTER_STUDENT_RESEARCH_FAILURE:
    case TYPE.STUDENT_RESEARCH.DELETE_STUDENT_RESEARCH_FAILURE:
      return state.merge({
        isFetching: false,
      });

    case TYPE.STUDENT_RESEARCH.GET_ALL_STUDENT_RESEARCH_SUCCESS:
      return state.merge({
        isFetching: false,
        list: [...action.payload].sort(sortFunc),
      });

    case TYPE.STUDENT_RESEARCH.INSERT_STUDENT_RESEARCH_SUCCESS: {
      return state.merge({
        isFetching: false,
        list: [...state.get('list'), action.payload].sort(sortFunc),
      });
    }

    case TYPE.STUDENT_RESEARCH.UPDATE_STUDENT_RESEARCH_SUCCESS:
      return state.merge({
        isFetching: false,
        list: [
          ...state.get('list').filter((e) => e?.id !== action.payload?.id),
          action.payload
        ].sort(sortFunc),
      });

    case TYPE.STUDENT_RESEARCH.UPDATE_STUDENT_RESEARCH_STATUS_SUCCESS:
      return state.merge({
        isFetching: false,
        list: [...state.get('list').map((e) => ({
          ...e,
          status: e?.id === action?.id
            ? action.status
            : e.status
        }))].sort(sortFunc),
      });
  
    case TYPE.STUDENT_RESEARCH.FILTER_STUDENT_RESEARCH_SUCCESS:
      return state.merge({
        isFetching: false,
        list: [...action.payload || []].sort(sortFunc),
      });

    case TYPE.STUDENT_RESEARCH.DELETE_STUDENT_RESEARCH_SUCCESS:
      return state.merge({
        isFetching: false,
        list: [...state.get('list')].filter((e) => e.id !== action.id),
      });

    default:
      return state;
  }
}
