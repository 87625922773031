import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import toJs from '../../../../hoc/toJS';
import select from '../../../../utils/select';

import { deleteTimeResearchInformation } from '../../../../reducer/timeResearchInformation/action';
import { filterYear, getTimeResearchInformation } from '../../../../reducer/timeResearchInformation/action';

function ResearchTimeYearDeleteModal(props) {
  const { deleting, setDeleting  } = props;
  const [year, setYear] = useState(props.selectedYear);

  useEffect(() => {
    if (deleting) setYear(props.selectedYear);
  }, [deleting]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const id = props.timeResearchInformationList.find((e) => e.year === year).id;
    props.deleteTimeResearchInformation(id).then(() => {
      props.filterYear(new Date().getFullYear());
    });
    setYear(props.selectedYear);
    setDeleting(false);
  }

  const yearList = [];
  props.timeResearchInformationList.sort((a, b) => (b.year - a.year)).forEach((e) => {
    yearList.push(e.year);
  });

  return (
    <Dialog
      fullWidth
      keepMounted
      maxWidth='xs'
      open={deleting}
      onClose={() => setDeleting(false)}
    >
      <DialogTitle>Xoá dữ liệu để tính giờ nghiên cứu</DialogTitle>
      <DialogContent style={{ overflow: 'hidden' }}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              size='small'
              variant='outlined'
              value={year || ''}
              label='Xoá dữ liệu của năm học'
              onChange={(event) =>  setYear(parseInt(event.target.value))}
              SelectProps={{ native: true }}
              InputLabelProps={{ shrink: true }}
            >
              {yearList.map((e, index) => <option  key={index} value={parseInt(e)}>{e} - {e+1}</option>)}
            </TextField>
          </Grid> 
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: '0 24px 8px 24px' }}>
        <div style={{ float: 'right' }}>
          <Button variant="outlined" color="secondary" onClick={() => setDeleting(false)} style={{ marginRight: 4 }}>Huỷ</Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>Xác nhận</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  selectedYear: select(state, 'timeResearchInformationReducer', 'year'),
  timeResearchInformationList: select(state, 'timeResearchInformationReducer', 'list'),
});

const mapDispatchToProps = (dispatch) => ({
  filterYear: (year) => dispatch(filterYear(year)),
  deleteTimeResearchInformation: (id) => dispatch(deleteTimeResearchInformation(id)),
  getTimeResearchInformation: (profileId) => dispatch(getTimeResearchInformation(profileId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(ResearchTimeYearDeleteModal));
