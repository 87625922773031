import TYPE from '../../constants/type';
import { apiCall } from '../../utils/api';
import { API_URLS } from '../../constants/api';
import { PREFIX } from '../../constants/enums';
import { notification } from '../home/action';

export const getAllJournal = () => async (dispatch) => {
  const api = API_URLS.JOURNAL.getAllJournal();
  dispatch({
    type: TYPE.JOURNAL.GET_ALL_JOURNAL_LOADING,
    meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.JOURNAL.GET_ALL_JOURNAL_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.JOURNAL.GET_ALL_JOURNAL_FAILURE,
      meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_FAILURE] },
    });
  }
};

export const getAllJournalV2 = () => async (dispatch) => {
  const api = API_URLS.JOURNAL.getAllJournalV2();
  dispatch({
    type: TYPE.JOURNAL.GET_ALL_JOURNAL_V2_LOADING,
    meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.JOURNAL.GET_ALL_JOURNAL_V2_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.JOURNAL.GET_ALL_JOURNAL_V2_FAILURE,
      meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_FAILURE] },
    });
  }
};

export const updateAllJournal = () => async (dispatch) => {
  const api = API_URLS.JOURNAL.updateAllJournal();
  dispatch({
    type: TYPE.JOURNAL.UPDATE_ALL_JOURNAL_LOADING,
    meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.JOURNAL.UPDATE_ALL_JOURNAL_SUCCESS,
      payload: response.data,
      meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.JOURNAL.UPDATE_ALL_JOURNAL_FAILURE,
      meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_FAILURE] },
    });
  }
};

export const getFilteredJournal = (page, pageSize, payload) => async (dispatch) => {
  const api = API_URLS.JOURNAL.getFilteredJournal(page, pageSize);
  dispatch({
    type: TYPE.JOURNAL.GET_FILTERED_JOURNAL_LOADING,
    meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_CALLING] },
  })
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    if (response.data.data){
      dispatch({
        type: TYPE.JOURNAL.GET_FILTERED_JOURNAL_SUCCESS,
        payload: response.data,
        meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_SUCCESS] },
      });
    } else {
      dispatch({
        type: TYPE.JOURNAL.GET_FILTERED_JOURNAL_FAILURE,
        meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_FAILURE] },
      });
      dispatch(notification({
        type: 'error',
        message: 'Không tìm thấy kết quả nào'
      }));
    }
  } else {
    dispatch({
      type: TYPE.JOURNAL.GET_FILTERED_JOURNAL_FAILURE,
      meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};

export const getSearchFilteredJournal = (page, pageSize, payload) => async (dispatch) => {
  const api = API_URLS.JOURNAL.getFilteredJournal(page, pageSize);
  dispatch({
    type: TYPE.JOURNAL.GET_SEARCH_FILTERED_JOURNAL_LOADING,
    meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_CALLING] },
  })
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.JOURNAL.GET_SEARCH_FILTERED_JOURNAL_SUCCESS,
      id: payload.title,
      payload: response.data.data,
      meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.JOURNAL.GET_SEARCH_FILTERED_JOURNAL_FAILURE,
      meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_FAILURE] },
    });
  }
};

export const getSearchFilteredJournalAfterDOI = (page, pageSize, payload, doiInfo) => async (dispatch) => {
  const api = API_URLS.JOURNAL.getFilteredJournal(page, pageSize);
  dispatch({
    type: TYPE.JOURNAL.GET_SEARCH_FILTERED_JOURNAL_AFTER_DOI_LOADING,
    meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_CALLING] },
  })

  // Fix lại lấy theo issn trước rồi nếu không được thì mới lấy theo title
  const payloadISSN = {
    ...payload,
    title: undefined,
  }

  const { response, error } = await apiCall({ ...api, payload: payloadISSN });
  if (!error && response.status === 200 && response.data.success && response?.data?.data) {
    dispatch({
      type: TYPE.JOURNAL.GET_SEARCH_FILTERED_JOURNAL_AFTER_DOI_SUCCESS,
      payload: response.data.data,
      doiInfo: doiInfo,
      meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_SUCCESS] },
    });
  } else {
    const payloadTitle = {
      ...payload,
      issn: undefined,
    }

    const { response, errorTitle } = await apiCall({ ...api, payload: payloadTitle });
    if (!errorTitle && response.status === 200 && response.data.success) {
      dispatch({
        type: TYPE.JOURNAL.GET_SEARCH_FILTERED_JOURNAL_AFTER_DOI_SUCCESS,
        payload: response.data.data,
        doiInfo: doiInfo,
        meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_SUCCESS] },
      });
    } else {
      dispatch({
        type: TYPE.JOURNAL.GET_SEARCH_FILTERED_JOURNAL_AFTER_DOI_FAILURE,
        meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_FAILURE] },
      });
    }
  }
};

export const getJournalById = (id) => async (dispatch) => {
  const api = API_URLS.JOURNAL.getJournalById(id);
  dispatch({
    type: TYPE.JOURNAL.GET_JOURNAL_BY_ID_LOADING,
    meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.JOURNAL.GET_JOURNAL_BY_ID_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.JOURNAL.GET_JOURNAL_BY_ID_FAILURE,
      meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};

export const getJournalByIdOnScientificResearch = (id) => async (dispatch) => {
  const api = API_URLS.JOURNAL.getJournalById(id);
  dispatch({
    type: TYPE.JOURNAL.GET_JOURNAL_BY_ID_ON_SCIENTIFIC_RESEARCH_LOADING,
    meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.JOURNAL.GET_JOURNAL_BY_ID_ON_SCIENTIFIC_RESEARCH_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.JOURNAL.GET_JOURNAL_BY_ID_ON_SCIENTIFIC_RESEARCH_FAILURE,
      meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};

export const changeFilterData = (data) => async (dispatch) => {
  dispatch({
    type: TYPE.JOURNAL.CHANGE_FILTER_DATA,
    payload: data,
    meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_CALLING] },
  });
};

export const resetDetail = (data) => async (dispatch) => {
  dispatch({
    type: 'RESET_DETAIL_ON_SR',
    payload: data,
    meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_CALLING] },
  });
};


export const importJournalByYear = (year, file) => async (dispatch) => {
  const api = API_URLS.JOURNAL.importJournalByYear(year);
  dispatch({
    type: TYPE.JOURNAL.IMPORT_JOURNAL_BY_YEAR_LOADING,
    meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_CALLING] },
  });
  var formData = new FormData();
  formData.append("journalFile", file[0]);
  const { response, error } = await apiCall({ ...api, payload: formData });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.JOURNAL.IMPORT_JOURNAL_BY_YEAR_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Import dữ liệu thành công!'
    }));
  } else {
    dispatch({
      type: TYPE.JOURNAL.IMPORT_JOURNAL_BY_YEAR_FAILURE,
      meta: { prefix: [PREFIX.JOURNAL, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: response.data.message
    }));
  }
};