import React from 'react';
import { Box, Grid } from '@material-ui/core';

import KPTextfield from '../KPTextfield';

function KPTextform(props) {
  const { values, setValues } = props;

  const handleChangeForm = (key, val, type) => {
    if (props.handleChangeForm) {
      props.handleChangeForm(key, val, type);
    } else {
      switch (type) {
        case 'file':
          if (val?.[0]) setValues({ ...values, [key]: val[0] });
          break;
        default:
          setValues({ ...values, [key]: val });
          break;
      }
    }
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    props.handleSubmit(event);
  }

  return (
    <Box
      id={props.id}
      component={props.component || 'form'}
      onSubmit={handleSubmit}
    >
      <Grid container spacing={2} justifyContent='center'>
        {props.textForm.map((elm) => (
          <Grid item key={elm.key} xs={elm.xs}>
            <KPTextfield
              id={elm.key}
              value={values?.[elm.key]}
              handleChangeForm={handleChangeForm}
              {...elm}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default KPTextform;
