import dayjs from "dayjs";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export const exportFile = (name, columns, dataSource) => {
  console.log(dataSource);
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(name);

  worksheet.columns = columns;

  worksheet.addRow(columns.map((c) => c.title));
  const rows = worksheet.addRows(dataSource.map((row) => columns.map((c) => row[c.key])));

  rows.forEach((row) => {
    row._cells.forEach((_cell) => {
      const cell = worksheet.getCell(_cell._address)
      cell.alignment = { vertical: "top", wrapText: true };
    });
  })

  workbook.xlsx.writeBuffer().then(function(buffer) {
    saveAs(new Blob([buffer], { type: "application/octet-stream" }), name + ".xlsx");
  });
}