import dayjs from 'dayjs';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Divider,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Close, DoubleArrow, Save } from '@material-ui/icons';

import { apiCall } from '../../../../utils/api';
import { API_URLS } from '../../../../constants/api';
import { valueFormat } from '../../../../utils/valueFormat';
import { KPTextform } from '../../../../components/KPTComponents';

function SettingForm(props) {
  const [values, setValues] = useState(null);

  const textForm = [
    {
      key: 'year',
      label: 'Năm học',
      xs: 12,
      disabled: true,
      value: `${props.year} - ${props.year + 1}`,
    },
    {
      key: 'divider',
      xs: 12,
      type: 'render',
      render: () => <Divider />
    },
    {
      xs: 4,
      key: 'text1',
      type: 'render',
      render: () => <Typography variant='body1' style={{ marginTop: 25.5, lineHeight: '35px' }}><b>Đăng ký hồ sơ:</b></Typography>
    },
    { xs: 4, key: 'date1Start', type: 'date', label: 'Ngày bắt đầu', required: true },
    // {
    //   xs: 4,
    //   key: 'icon1',
    //   type: 'render',
    //   render: () => (
    //     <Box width='100%' style={{ marginTop: 30 }}>
    //       <DoubleArrow color='disabled' style={{ margin: '0 calc((100% - 24px) / 2)' }} />
    //     </Box>
    //   )
    // },
    { xs: 4, key: 'date1End', type: 'date', label: 'Ngày kết thúc', required: true },
    {
      xs: 4,
      key: 'text2',
      type: 'render',
      render: () => <Typography variant='body1' style={{ marginTop: 25.5, lineHeight: '35px' }}><b>Báo cáo tiến độ:</b></Typography>
    },
    { xs: 4, key: 'date2Start', type: 'date', label: 'Ngày bắt đầu', required: true },
    // {
    //   xs: 4,
    //   key: 'icon2',
    //   type: 'render',
    //   render: () => (
    //     <Box width='100%' style={{ marginTop: 30 }}>
    //       <DoubleArrow color='disabled' style={{ margin: '0 calc((100% - 24px) / 2)' }} />
    //     </Box>
    //   )
    // },
    { xs: 4, key: 'date2End', type: 'date', label: 'Ngày kết thúc', required: true },
    {
      xs: 4,
      key: 'text3',
      type: 'render',
      render: () => <Typography variant='body1' style={{ marginTop: 25.5, lineHeight: '35px' }}><b>Nghiệm thu cấp khoa:</b></Typography>
    },
    { xs: 4, key: 'date3Start', type: 'date', label: 'Ngày bắt đầu', required: true },
    // {
    //   xs: 4,
    //   key: 'icon3',
    //   type: 'render',
    //   render: () => (
    //     <Box width='100%' style={{ marginTop: 30 }}>
    //       <DoubleArrow color='disabled' style={{ margin: '0 calc((100% - 24px) / 2)' }} />
    //     </Box>
    //   )
    // },
    { xs: 4, key: 'date3End', type: 'date', label: 'Ngày kết thúc', required: true },
    {
      xs: 4,
      key: 'text3',
      type: 'render',
      render: () => <Typography variant='body1' style={{ marginTop: 25.5, lineHeight: '35px' }}><b>Hội nghị cấp trường:</b></Typography>
    },
    { xs: 4, key: 'date3Start', type: 'date', label: 'Ngày bắt đầu', required: true },
    // {
    //   xs: 4,
    //   key: 'icon3',
    //   type: 'render',
    //   render: () => (
    //     <Box width='100%' style={{ marginTop: 30 }}>
    //       <DoubleArrow color='disabled' style={{ margin: '0 calc((100% - 24px) / 2)' }} />
    //     </Box>
    //   )
    // },
    { xs: 4, key: 'date3End', type: 'date', label: 'Ngày kết thúc', required: true },
  ];

  const getData = async () => {
    const api = API_URLS.YEARLY_DUE_DATE.filterYearlyDueDate();
    const { response, error } = await apiCall({ ...api, payload: {
      type: 'student',
      schoolYear: props.year,
    }});
    if (!error && response.status === 200 && response.data.success) {
      const res = response.data.data[0];
      setValues({
        ...res,
        date1Start: dayjs(res?.regStartTime).format('DD/MM/YYYY'),
        date2Start: dayjs(res?.reportStartTime).format('DD/MM/YYYY'),
        date3Start: dayjs(res?.acceptStartTime).format('DD/MM/YYYY'),
        date4Start: dayjs(res?.conferenceStartTime).format('DD/MM/YYYY'),
        date1End: dayjs(res?.regEndTime).format('DD/MM/YYYY'),
        date2End: dayjs(res?.reportEndTime).format('DD/MM/YYYY'),
        date3End: dayjs(res?.acceptEndTime).format('DD/MM/YYYY'),
        date4End: dayjs(res?.conferenceEndTime).format('DD/MM/YYYY'),
      })
    }
  }

  const updateData = async (payload) => {
    props.handleClose();
    const api = API_URLS.YEARLY_DUE_DATE.updateYearlyDueDate();
    await apiCall({ ...api, payload });
  }

  useEffect(() => getData(), [props.year]);

  const handleSubmit = () => {
    var date1Start = valueFormat.stringToDate(values?.date1Start);
    var date2Start = valueFormat.stringToDate(values?.date2Start);
    var date3Start = valueFormat.stringToDate(values?.date3Start);
    var date4Start = valueFormat.stringToDate(values?.date4Start);
    var date1End = valueFormat.stringToDate(values?.date1End);
    var date2End = valueFormat.stringToDate(values?.date2End);
    var date3End = valueFormat.stringToDate(values?.date3End);
    var date4End = valueFormat.stringToDate(values?.date4End);

    updateData({
      ...values,
      year: props.year,
      regStartTime: date1Start,
      reportStartTime: date2Start,
      acceptStartTime: date3Start,
      conferenceStartTime: date4Start,
      regEndTime: date1End,
      reportEndTime: date2End,
      acceptEndTime: date3End,
      conferenceEndTime: date4End,
    })
  }
  
  return (
    <KPTextform
      id={props.id}
      textForm={textForm}
      values={values}
      setValues={setValues}
      handleSubmit={handleSubmit}
    />
  );
}

export default SettingForm;
