import TYPE from '../../constants/type';
import { apiCall } from '../../utils/api';
import { API_URLS } from '../../constants/api';
import { PREFIX } from '../../constants/enums';
import axios from 'axios';
import { notification } from '../home/action';

export const getAllScientificResearchOther = () => async (dispatch) => {
  const api = API_URLS.SCIENTIFIC_RESEARCH_OTHER.getAllScientificResearchOther();
  dispatch({
    type: TYPE.SCIENTIFIC_RESEARCH_OTHER.GET_ALL_SCIENTIFIC_RESEARCH_OTHER_LOADING,
    meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.SCIENTIFIC_RESEARCH_OTHER.GET_ALL_SCIENTIFIC_RESEARCH_OTHER_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.SCIENTIFIC_RESEARCH_OTHER.GET_ALL_SCIENTIFIC_RESEARCH_OTHER_FAILURE,
      meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};

export const getAllScientificResearchOtherByProfileId = (profileId) => async (dispatch) => {
  const api = API_URLS.SCIENTIFIC_RESEARCH_OTHER.getAllScientificResearchOtherByProfileId(profileId);
  dispatch({
    type: TYPE.SCIENTIFIC_RESEARCH_OTHER.GET_ALL_SCIENTIFIC_RESEARCH_OTHER_BY_PROFILE_ID_LOADING,
    meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.SCIENTIFIC_RESEARCH_OTHER.GET_ALL_SCIENTIFIC_RESEARCH_OTHER_BY_PROFILE_ID_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.SCIENTIFIC_RESEARCH_OTHER.GET_ALL_SCIENTIFIC_RESEARCH_OTHER_BY_PROFILE_ID_FAILURE,
      meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};

export const insertScientificResearchOther = (payload) => async (dispatch) => {
  const api = API_URLS.SCIENTIFIC_RESEARCH_OTHER.insertScientificResearchOther();
  dispatch({
    type: TYPE.SCIENTIFIC_RESEARCH_OTHER.INSERT_SCIENTIFIC_RESEARCH_OTHER_LOADING,
    meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.SCIENTIFIC_RESEARCH_OTHER.INSERT_SCIENTIFIC_RESEARCH_OTHER_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Thêm dữ liệu thành công!'
    }));
    return ({success: true, data: response.data.data});
  } else {
    dispatch({
      type: TYPE.SCIENTIFIC_RESEARCH_OTHER.INSERT_SCIENTIFIC_RESEARCH_OTHER_FAILURE,
      meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Thêm dữ liệu thất bại hoặc bài báo đã tồn tại!'
    }));
    return ({success: false, data: null});
  }
};

export const updateScientificResearchOther = (id, payload) => async (dispatch) => {
  const api = API_URLS.SCIENTIFIC_RESEARCH_OTHER.updateScientificResearchOther(id);
  dispatch({
    type: TYPE.SCIENTIFIC_RESEARCH_OTHER.UPDATE_SCIENTIFIC_RESEARCH_OTHER_LOADING,
    meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.SCIENTIFIC_RESEARCH_OTHER.UPDATE_SCIENTIFIC_RESEARCH_OTHER_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Điều chỉnh dữ liệu thành công!'
    }));
  } else {
    dispatch({
      type: TYPE.SCIENTIFIC_RESEARCH_OTHER.UPDATE_SCIENTIFIC_RESEARCH_OTHER_FAILURE,
      meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Điều chỉnh dữ liệu thất bại!'
    }));
  }
};

export const deleteScientificResearchOther = (id) => async (dispatch) => {
  const api = API_URLS.SCIENTIFIC_RESEARCH_OTHER.deleteScientificResearchOther(id);
  dispatch({
    type: TYPE.SCIENTIFIC_RESEARCH_OTHER.DELETE_SCIENTIFIC_RESEARCH_OTHER_LOADING,
    meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.SCIENTIFIC_RESEARCH_OTHER.DELETE_SCIENTIFIC_RESEARCH_OTHER_SUCCESS,
      id,
      meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Xóa dữ liệu thành công!'
    }));
  } else {
    dispatch({
      type: TYPE.SCIENTIFIC_RESEARCH_OTHER.DELETE_SCIENTIFIC_RESEARCH_OTHER_FAILURE,
      meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Xóa dữ liệu thất bại!'
    }));
  }
};

export const acceptScientificResearchOther = (id, accept) => async (dispatch) => {
  const api = API_URLS.SCIENTIFIC_RESEARCH_OTHER.acceptScientificResearchOther(id, accept);
  dispatch({
    type: TYPE.SCIENTIFIC_RESEARCH_OTHER.ACCEPT_SCIENTIFIC_RESEARCH_OTHER_LOADING,
    meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.SCIENTIFIC_RESEARCH_OTHER.ACCEPT_SCIENTIFIC_RESEARCH_OTHER_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Xác nhận thành công!'
    }));
  } else {
    dispatch({
      type: TYPE.SCIENTIFIC_RESEARCH_OTHER.ACCEPT_SCIENTIFIC_RESEARCH_OTHER_FAILURE,
      meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Xác nhận thất bại!'
    }));
  }
};

export const getDOI = (url) => async (dispatch) => {
  dispatch({
    type: TYPE.SCIENTIFIC_RESEARCH_OTHER.DOI_LOADING,
    meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_CALLING] },
  });
  let response = null
  let error = null
  try{
    const result = await axios({
      method: 'GET',
      url,
      headers:{
        'Accept': 'application/json; charset=UTF-8',
      }
    })
    response = result
  } catch (e) {
    error = e.request
  }
  if (!error && response?.status === 200 && response?.data?.success) {
    dispatch({
      type: TYPE.SCIENTIFIC_RESEARCH_OTHER.DOI_SUCCESS,
      doi: response.data,
      meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.SCIENTIFIC_RESEARCH_OTHER.DOI_FAILURE,
      meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};

export const getAllPurpose = () => async (dispatch) => {
  const api = API_URLS.SCIENTIFIC_RESEARCH_OTHER.getAllPurpose();
  dispatch({
    type: TYPE.SCIENTIFIC_RESEARCH_OTHER.GET_ALL_PURPOSE_LOADING,
    meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.SCIENTIFIC_RESEARCH_OTHER.GET_ALL_PURPOSE_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.SCIENTIFIC_RESEARCH_OTHER.GET_ALL_PURPOSE_FAILURE,
      meta: { prefix: [PREFIX.SCIENTIFIC_RESEARCH_OTHER, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
}
