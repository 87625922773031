import React, { useEffect, useState } from "react";
import moment from 'moment';
import { connect } from "react-redux";
import {
  TextField,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  Chip,
  Box,
  MenuItem
} from "@material-ui/core";
import {
  Autocomplete,
  Skeleton,
} from "@material-ui/lab";

import toJs from "../../../hoc/toJS";
import styles from './index.module.css';
import select from "../../../utils/select";

import { getSearchFilteredJournal, getJournalById } from '../../../reducer/journal/action';

const getCurrentYear = () => {
  return parseInt(moment().format('YYYY'))
}

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay]
  );

  return debouncedValue;
}

function AutoRankingCard(props) {
  const { getSearchFilteredJournal, getJournalById } = props;
  const [ chosenYear, setChosenYear ] = useState(getCurrentYear())
    
  const handleSearch = (e, val) => {
    e.preventDefault();
    getJournalById(val.id)
  };

  const getInitial = (title) => {
    const type = title.split('|')
    let res = ''
    type.forEach((type) => {
      switch (type.trim()) {
        case 'Science Citation Index Expanded':
          if (res !== '') res += ' | SCIE'
          else res += 'SCIE'
          break;
        case 'Social Sciences Citation Index':
          if (res !== '') res += ' | SSCI'
          else res += 'SSCI'
          break;
        case 'Arts & Humanities Citation Index':
          if (res !== '') res += ' | AHCI'
          else res += 'AHCI'
          break;
        case 'Emerging Sources Citation Index':
          if (res !== '') res += ' | ESCI'
          else res += 'ESCI'
          break;
        default:
          if (res !== '') res += ' | Scopus'
          else res += 'Scopus'
          break;
      }
    })
    return res
  }

  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 300);

  useEffect(() => {
    if (debouncedSearchText !== 0)
      getSearchFilteredJournal(1, 100, {title: debouncedSearchText, year: chosenYear})
  }, [debouncedSearchText, getSearchFilteredJournal]);

  const renderYears = () => {
    var years = [];
    for (var i=2015; i<=getCurrentYear() + 2; i++)
      years.push(
        <MenuItem key={i} value={i}>{i}</MenuItem>
      )
    return years
  }

  return (
    <Box display='flex' justifyContent='center' alignItems='center' height='calc(100vh - 158px)'>
      <Card
        style={{
          height: 400,
          minWidth: 850,
          maxWidth: 1000,
          color: '#fff',
          backgroundColor: '#243474'
        }}
      >
        <CardHeader title={<Typography variant="h5"><b>Tra cứu xếp hạng tạp chí</b></Typography>} />
        <CardContent>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={9}>
              <form onSubmit={(e) => e.preventDefault()}>
                <Typography color='secondary'><b>Tên tạp chí:</b></Typography>
                <Autocomplete
                  disableClearable
                  options={props.listJournalFiltered}
                  getOptionLabel={(option) => `${option?.title} (${option?.year})`}
                  onChange={handleSearch}
                  onInputChange={(e) => setSearchText(e?.target?.value)}
                  style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}
                  renderInput={(params) => <TextField
                    {...params}
                    size='small'
                    placeholder="Tên tạp chí"
                    variant='outlined'
                    style={{ borderRadius: 4, backgroundColor: 'white' }}
                  />}
                  autoHighlight
                  filterOptions={(options, state) => options}
                />
              </form>
            </Grid>
            <Grid item xs={3}>
              <Typography color='secondary'><b>Năm:</b></Typography>
              <TextField
                fullWidth
                select
                variant={'outlined'}
                size={'small'}
                defaultValue={chosenYear}
                style={{ borderRadius: 4, backgroundColor: 'white' }}
                onChange={(event) => setChosenYear(event.target.value)}
              >
                {renderYears()}
              </TextField>
            </Grid>
          </Grid>
          {props.isFetchingJournalDetail? (
            <Box marginTop={2}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Box>
          ) : (
            <Box marginTop={2}>
              <Typography variant="h5" component="h2" color='secondary'>
                <b>
                  {
                    props.journalDetail?.title
                    ? `${props.journalDetail?.title} (${props.journalDetail?.year})`
                    : "Chưa tra cứu tạp chí"
                  }
                </b>
              </Typography>
              <Grid container justifyContent="flex-start" spacing={4} style={{ marginTop: 1 }}>
                <Grid item xs={4}>
                  <Box className={styles.box} display='flex'>
                    <Box className={styles.text} flexGrow={1}>ISI/Scopus:</Box>
                    <Box className={styles.num}>
                      {props.journalDetail?.coreCollection
                        ? getInitial(props.journalDetail.coreCollection)
                          : "ISI/Scopus"}
                    </Box>
                  </Box>
                  <Box className={styles.box} display='flex'>
                    <Box className={styles.text} flexGrow={1}>Xếp hạng Q:</Box>
                    <Box className={styles.num}>{props.journalDetail?.sjrBestQuartile || "Q1/Q2/Q3/Q4"}</Box>
                  </Box>
                  <Box className={styles.box} display='flex'>
                    <Box className={styles.text} flexGrow={1}>Thời gian chấp nhận:</Box>
                    <Box className={styles.num}>{props.journalDetail?.submissionToAcceptance || "N/A"}</Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className={styles.box} display='flex'>
                    <Box className={styles.text} flexGrow={1}>Impact factor:</Box>
                    <Box className={styles.num}>{props.journalDetail?.impactFactor || 'N/A'}</Box>
                  </Box>
                  <Box className={styles.box} display='flex'>
                    <Box className={styles.text} flexGrow={1}>Xếp hạng R:</Box>
                    <Box className={styles.num}>{props.journalDetail?.rank || "R1/R2/R3"}</Box>
                  </Box>
                  
                  <Box className={styles.box} display='flex'>
                    <Box className={styles.text} flexGrow={1}>Thời gian review vòng 1:</Box>
                    <Box className={styles.num}>{props.journalDetail?.submissionToFirstDecision || "N/A"}</Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box className={styles.box} display='flex'>
                    <Box className={styles.text} flexGrow={1}>Impact factor 5 years:</Box>
                    <Box className={styles.num}>{props.journalDetail?.impactFactorFiveYear || 'N/A'}</Box>
                  </Box>
                  <Box className={styles.box} display='flex'>
                    <Box className={styles.text} flexGrow={1}>Top Ngành:</Box>
                    <Box className={styles.num}>
                      {
                        props.journalDetail?.topRank && props.journalDetail?.topRank !== 'none'
                        ? props.journalDetail.topRank
                        : "Không"
                      }
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

const mapStateToProps = (state) => ({
    listJournalFiltered: select(state, 'journalReducer', 'listSearch'),
    isFetchingJournalDetail: select(state, 'journalReducer', 'isFetchingDetail'),
    journalDetail: select(state, 'journalReducer', 'detail'),
});

const mapDispatchToProps = (dispatch) => ({
  getSearchFilteredJournal: (page, pageSize, payload) => dispatch(getSearchFilteredJournal(page, pageSize, payload)),
  getJournalById: (id) => dispatch(getJournalById(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJs(AutoRankingCard));
