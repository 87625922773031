import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Autocomplete } from "@material-ui/lab";
import { matchSorter } from 'match-sorter';
import dayjs from 'dayjs';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import KPTimePicker from '../../../components/KPTimePicker';
import { insertConference } from '../../../reducer/conference/action';

const dateFormat = (value) => {
  const dateForm = value?.replaceAll('/', '');
  const date = dateForm?.substr(0, 2);
  const month = dateForm?.substr(2, 2);
  const year = dateForm?.substr(-4);
  return(value ? dayjs(`${month}/${date}/${year}`).format('YYYY-MM-DDTHH:mm:ssZ') : null);
}

function ConferenceInsertModal(props) {
  const { inserting, setInserting } = props;

  const [values, setValues] = useState(null);

  const handleChangeForm = (name, value) => {
    setValues({ ...values, [name]: value })
  };

  const handleSubmit = () => {
    setInserting(false);
    props.insertConference({
      ...values,
      level: props.level,
      organizeAt: dateFormat(values.organizeAt),
      year: dateFormat(values.organizeAt)? parseInt(dateFormat(values.organizeAt).split('-')[0]) : 1999,
    })
    setValues(null);
  }

  return (
    <Dialog
      fullWidth
      keepMounted
      maxWidth='md'
      open={inserting}
      onClose={() => setInserting(false)}
    >
      <DialogTitle>Thêm hội thảo</DialogTitle>
      <DialogContent style={{ overflow: 'visible' }}>
        <Grid container spacing={2} style={{marginBottom: 10}}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              label='Tên hội thảo'
              value={values?.title || ''}
              style={{ paddingBottom: 0 }}
              onChange={(event) => handleChangeForm('title', event.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              label='Điểm GS-PGS'
              value={values?.type || ''}
              onChange={(event) => handleChangeForm('type', event.target.value)}
            />
          </Grid>
          {/* <Grid item xs={4}>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              label='Nguồn'
              value={values?.source || ''}
              onChange={(event) => handleChangeForm('source', event.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              label='Xếp hạng'
              value={values?.rank || ''}
              onChange={(event) => handleChangeForm('rank', event.target.value)}
            />
          </Grid> */}
          {/* <Grid item xs={4}>
            <TextField
              fullWidth
              type='date'
              size='small'
              variant='outlined'
              label='Thời gian tổ chức'
              value={values?.organizeAt || '1900-01-01'}
              onChange={(event) => handleChangeForm('organizeAt', event.target.value)}
            />
          </Grid> */}
          <Grid item xs={4}>
            <KPTimePicker
              fullWidth
              label='Thời gian tổ chức'
              value={values?.organizeAt || 'dd/mm/yyyy'}
              setValue={(newValue) => handleChangeForm('organizeAt', newValue)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              size='small'
              variant='outlined'
              label='Đơn vị tổ chức'
              value={values?.organizeBy || ''}
              onChange={(event) => handleChangeForm('organizeBy', event.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: '0 24px 8px 24px' }}>
        <div style={{ float: 'right' }}>
          <Button variant="outlined" color="secondary" onClick={() => setInserting(false)} style={{ marginRight: 4 }}>Huỷ</Button>
          <Button variant="contained" color="primary" onClick={() => handleSubmit()}>Xác nhận</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  insertConference: (payload) => dispatch(insertConference(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(ConferenceInsertModal));
