import React, { useState, useEffect } from 'react'
import moment from 'moment';
import { connect } from 'react-redux';
import {
  Card,
  Button,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  CircularProgress,
  Typography,
  Box,
  Divider,
} from '@material-ui/core';
import { useLocation } from "react-router-dom";
import { Autocomplete } from '@material-ui/lab';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import toJS from '../../../hoc/toJS';
import select from '../../../utils/select';
import theme from '../../../theme';
import { getAllProfile } from '../../../reducer/profile/action';
import { getAllDepartments } from '../../../reducer/department/action';
import { sendTimerNotification, sendEmail } from '../../../reducer/notification/action';
import { ViewArraySharp } from '@material-ui/icons';


function SendNotificationCard(props) {
  const location = useLocation();
  const {
    profile,
    getAllProfile,
    getAllDepartments,
    sendEmail,
    sendTimerNotification,
  } = props;

  const [values, setValues] = useState({
    title: 'Tiêu đề',
    sendTo: 'department',
    type: 'email',
    content: 'Nội dung thông báo',
    chosenDepartments: [],
    chosenProfiles: [],
  })
  const [editorReady, setEditorReady] = useState(false)

  const getContent = (data, dataType) => {
    switch (dataType) {
      case ('scientificResearch'): return(`<p>Thông báo sai sót trong Công bố khoa học: ${data.title}</p>`)
      case ('solution'): return(`<p>Thông báo sai sót trong Giải pháp hữu ích: ${data.title}</p>`)
      case ('patent'): return(`<p>Thông báo sai sót trong Bằng sáng chế: ${data.title}</p>`)
      case ('monograph'): return(`<p>Thông báo sai sót trong Sách chuyên khảo/tham khảo: ${data.title}</p>`)
      case ('project'): return(`<p>Thông báo sai sót trong Đề tài nghiên cứu: ${data.title}</p>`)
      default: return(`<p>Nội dung thông báo</p>`)
    }
  }

  useEffect(() => {
    if (location.state && props.allProfiles.length > 0) {
      setValues({
        ...values,
        sendTo: location.state?.sendTo,
        title: location.state?.title,
        content: getContent(location.state?.data, location.state?.dataType),
        chosenProfiles: props.allProfiles.filter((e) => location.state?.chosenProfiles.find((propfiledId) => e.id === propfiledId)),
      });
    }
  }, [props.allProfiles]);

  useEffect(() => {
    getAllProfile();
  }, [getAllProfile]);

  useEffect(() => {
    getAllDepartments();
  }, [getAllDepartments]);

  const handleChangeForm = (name, value) =>  setValues({ ...values, [name]: value });

  const handleSendNotification = () => {
    if (values.sendTo === 'personal' && values.type === 'email'){
      if (values.chosenProfiles.length !== 0){
        var recipient = []
        values.chosenProfiles.map(item => recipient.push(item.mail))
        sendEmail({
          isNoti: profile.mail.includes('hoang.haminh'),
          content: values.content,
          subject: values.title,
          recipient,
        })
      } else {
        alert('Hãy chọn thành viên để gửi email thông báo!')
      }
    }

    if (values.sendTo === 'department' && values.type === 'email'){
      if (values.chosenDepartments.length !== 0){
        var recipient = []
        values.chosenDepartments.map(department => {
          if (department.id === 0){
            props.allProfiles.forEach(profile => recipient.push(profile.mail))
          } else {
            department.departmentProfile.map(profile => {
              var matchProfile = props.allProfiles.filter(item => item.id === profile.profileId)[0]
              if (matchProfile) recipient.push(matchProfile.mail)
            })
          }
        })
        sendEmail({
          isNoti: profile.mail.includes('hoang.haminh'),
          content: values.content,
          subject: values.title,
          recipient,
        })
      } else {
        alert('Hãy chọn đơn vị để gửi email thông báo!')
      }
    }

    if (values.sendTo === 'personal' && values.type === 'timer'){
      if (values.chosenProfiles.length !== 0){
        var listTimerNoti = []
        values.chosenProfiles.map(profile => {
          listTimerNoti.push({
            content: values.content,
            profileId: profile.id,
            sendDate: moment(values.sendDate).format('YYYY-MM-DDThh:mm:ss') + 'Z',
            status: 'not_send',
            title: values.title,
          })
        })
        sendTimerNotification({
          listTimerNoti
        })
      } else {
        alert('Hãy chọn thành viên để gửi email thông báo!')
      }
    }

    if (values.sendTo === 'department' && values.type === 'timer'){
      if (values.chosenDepartments.length !== 0){
        var listTimerNoti = []
        values.chosenDepartments.map(department => {
          if (department.id === 0){
            props.allProfiles.forEach(profile => listTimerNoti.push({
              content: values.content,
              profileId: profile.id,
              sendDate: moment(values.sendDate).format('YYYY-MM-DDThh:mm:ss') + 'Z',
              status: 'not_send',
              title: values.title,
            }))
          } else {
            department.departmentProfile.map(profile => {
              var matchProfile = props.allProfiles.filter(item => item.id === profile.profileId)[0]
              if (matchProfile) listTimerNoti.push({
                content: values.content,
                profileId: matchProfile.id,
                sendDate: moment(values.sendDate).format('YYYY-MM-DDThh:mm:ss') + 'Z',
                status: 'not_send',
                title: values.title,
              })
            })
          }
        })
        sendTimerNotification({
          listTimerNoti
        })
      } else {
        alert('Hãy chọn đơn vị để gửi email thông báo!')
      }
    }
  }

  return (
    <Grid container justifyContent='center'>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h5' style={{ color: theme.palette.primary.main }}>
              <b>Gửi email thông báo</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={values?.title || ''}
              label={'Tiêu đề'}
              onChange={(event) => handleChangeForm('title', event.target.value)}
              variant='outlined'
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            {!editorReady &&
              <div style={{ marginBottom: 10, flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
                <CircularProgress size={20} />
                <div>Loading editor!</div>
                <>Vui lòng reload lại trang nếu chờ quá lâu</>
              </div>
            }
            <CKEditor
              editor={ ClassicEditor }
              data={getContent(location.state?.data, location.state?.dataType) ?? values?.content}
              onReady={() => setEditorReady(true) }
              onChange={( event, editor ) => {
                const data = editor.getData();
                handleChangeForm('content', data)
              }}
            />
          </Grid>
            <Grid item xs={12} md={4}>
              <FormControl size='small' variant={'outlined'} style={{ width: '100%' }}>
                <InputLabel>Phương thức thông báo</InputLabel>
                <Select
                  fullWidth
                  size='small'
                  label={'Phương thưc thông báo'}
                  value={values?.type || 'email'}
                  onChange={(event) => handleChangeForm('type', event.target.value)}
                >
                  <MenuItem key={0} value={'email'}>Gửi Email</MenuItem>
                  <MenuItem key={1} value={'timer'}>Hẹn lịch</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl size='small' variant={'outlined'} style={{ width: '100%' }}>
                <InputLabel>Gửi thông báo cho</InputLabel>
                <Select
                  fullWidth
                  label={'Gửi thông báo cho'}
                  value={values?.sendTo || 'department'}
                  onChange={(event) => handleChangeForm('sendTo', event.target.value)}
                >
                  <MenuItem key={0} value={'department'}>Đơn vị</MenuItem>
                  <MenuItem key={1} value={'personal'}>Cá nhân</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={values?.type !== 'timer'}
                fullWidth
                size='small'
                type='date'
                variant='outlined'
                label='Ngày gửi thông báo'
                value={values?.sendDate || moment().format('YYYY-MM-DD')}
                onChange={(event) => handleChangeForm('sendDate', event.target.value)}
              />
            </Grid>
            {values?.sendTo === 'personal'? (
              <Grid item xs={12}>
                <form>
                  <Autocomplete
                    multiple
                    size='small'
                    options={props.allProfiles}
                    getOptionLabel={(option) => `${option.name} (${option.mail})`}
                    filterSelectedOptions
                    onChange={(event, val) => handleChangeForm('chosenProfiles', val)}
                    value={values?.chosenProfiles || []}
                    renderInput={(params) => <TextField {...params} variant='outlined' label='Người nhận thông báo' />}
                  />
                </form>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <form>
                  <Autocomplete
                    multiple
                    size='small'
                    options={props.allDepartments}
                    getOptionLabel={(option) => option.name}
                    filterSelectedOptions
                    onChange={(event, val) => handleChangeForm('chosenDepartments', val)}
                    value={values?.chosenDepartments || []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Đơn vị nhận thông báo"
                      />
                    )}
                  />
                </form>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => handleSendNotification()}
                  disabled={props.isSending}
                  style={{ float: 'right', paddingLeft: 20, paddingRight: 20 }}
                >
                  {props.isSending && <CircularProgress size={24} />}
                  {!props.isSending && (values?.type === 'timer'? 'Hẹn thông báo':'Gửi email')}
                </Button>
              </Box>
            </Grid>
        </Grid>
      </Grid>
    </Grid>
    // <Card
    //   style={{
    //     marginLeft: "auto",
    //     marginRight: "auto",
    //     minWidth: 200,
    //     maxWidth: 900,
    //     border: 'none'
    //   }}
    //   variant="outlined"
    // >
    //   <CardHeader
    //     // style={{ backgroundColor: '#e8e8e8' }}
    //     title={
    //       <Box display='flex'>
    //         <Box flexGrow={1}>
    //           <Typography variant='h5' style={{ color: theme.palette.primary.main }}>
    //             <b>Gửi email thông báo</b>
    //           </Typography>
    //         </Box>
    //         <Box>

    //         </Box>
    //       </Box>
    //     }
    //   />
    //   <Divider />
    //   <CardContent>
    //     <Grid container justifyContent="flex-start" spacing={2}>
          // <Grid item xs={12}>
          //   <TextField
          //     fullWidth
          //     size='small'
          //     value={values?.title || ''}
          //     label={'Tiêu đề'}
          //     onChange={(event) => handleChangeForm('title', event.target.value)}
          //     variant='outlined'
          //   />
          // </Grid>
          // <Grid item xs={12} >
          //   {!editorReady &&
          //     <div style={{ marginBottom: 10, flexDirection: 'column', display: 'flex', alignItems: 'center' }}>
          //       <CircularProgress size={20} />
          //       <div>Loading editor!</div>
          //       <>Vui lòng reload lại trang nếu chờ quá lâu</>
          //     </div>
          //   }
          //   <CKEditor
          //     editor={ ClassicEditor }
          //     data={getContent(location.state?.data, location.state?.dataType) ?? values?.content}
          //     onReady={() => setEditorReady(true) }
          //     onChange={( event, editor ) => {
          //       const data = editor.getData();
          //       handleChangeForm('content', data)
          //     }}
          //   />
          // </Grid>
          // <Grid item xs={4}>
          //   <FormControl size='small' variant={'outlined'} style={{ width: '100%' }}>
          //     <InputLabel>Phương thức thông báo</InputLabel>
          //     <Select
          //       fullWidth
          //       size='small'
          //       label={'Phương thưc thông báo'}
          //       value={values?.type || 'email'}
          //       onChange={(event) => handleChangeForm('type', event.target.value)}
          //     >
          //       <MenuItem key={0} value={'email'}>Gửi Email</MenuItem>
          //       <MenuItem key={1} value={'timer'}>Hẹn lịch</MenuItem>
          //     </Select>
          //   </FormControl>
          // </Grid>
          // <Grid item xs={4}>
          //   <FormControl size='small' variant={'outlined'} style={{ width: '100%' }}>
          //     <InputLabel>Gửi thông báo cho</InputLabel>
          //     <Select
          //       fullWidth
          //       label={'Gửi thông báo cho'}
          //       value={values?.sendTo || 'department'}
          //       onChange={(event) => handleChangeForm('sendTo', event.target.value)}
          //     >
          //       <MenuItem key={0} value={'department'}>Đơn vị</MenuItem>
          //       <MenuItem key={1} value={'personal'}>Cá nhân</MenuItem>
          //     </Select>
          //   </FormControl>
          // </Grid>
          // <Grid item xs={4}>
          //   <TextField
          //     disabled={values?.type !== 'timer'}
          //     fullWidth
          //     size='small'
          //     type='date'
          //     variant='outlined'
          //     label='Ngày gửi thông báo'
          //     value={values?.sendDate || moment().format('YYYY-MM-DD')}
          //     onChange={(event) => handleChangeForm('sendDate', event.target.value)}
          //   />
          // </Grid>
          // {values?.sendTo === 'personal'? (
          //   <Grid item xs={12}>
          //     <form>
          //       <Autocomplete
          //         multiple
          //         size='small'
          //         options={props.allProfiles}
          //         getOptionLabel={(option) => `${option.name} (${option.mail})`}
          //         filterSelectedOptions
          //         onChange={(event, val) => handleChangeForm('chosenProfiles', val)}
          //         value={values?.chosenProfiles || []}
          //         renderInput={(params) => <TextField {...params} variant='outlined' label='Người nhận thông báo' />}
          //       />
          //     </form>
          //   </Grid>
          // ) : (
          //   <Grid item xs={12}>
          //     <form>
          //       <Autocomplete
          //         multiple
          //         size='small'
          //         options={props.allDepartments}
          //         getOptionLabel={(option) => option.name}
          //         filterSelectedOptions
          //         onChange={(event, val) => handleChangeForm('chosenDepartments', val)}
          //         value={values?.chosenDepartments || []}
          //         renderInput={(params) => (
          //           <TextField
          //             {...params}
          //             variant="outlined"
          //             label="Đơn vị nhận thông báo"
          //           />
          //         )}
          //       />
          //     </form>
          //   </Grid>
          // )}
          // <Grid container justifyContent={'flex-end'}>
          //   <Grid item xs={3} style={{marginRight: 8, marginTop: 5}}>
          //     <Button
          //       fullWidth
          //       variant="contained"
          //       color="primary"
          //       onClick={() => handleSendNotification()}
          //       disabled={props.isSending}
          //       style={{ float: 'right', paddingLeft: 20, paddingRight: 20 }}
          //     >
          //       {props.isSending && <CircularProgress size={24} />}
          //       {!props.isSending && (values?.type === 'timer'? 'Hẹn thông báo':'Gửi email')}
          //     </Button>
          //   </Grid>
          // </Grid>
    //     </Grid>
    //   </CardContent>
    // </Card>
  )
}

const mapStateToProps = (state) => ({
  profile: select(state, 'profileReducer', 'detail'),
  allProfiles: select(state, 'profileReducer', 'list'),
  allDepartments: select(state, 'departmentReducer', 'departments'),
  isSending: select(state, 'notificationReducer', 'isFetching'),
});
const mapDispatchToProps = (dispatch) => ({
  getAllProfile: () => dispatch(getAllProfile()),
  getAllDepartments: () => dispatch(getAllDepartments()),
  sendTimerNotification: (payload) => dispatch(sendTimerNotification(payload)),
  sendEmail: (payload) => dispatch(sendEmail(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(toJS(SendNotificationCard));