import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  TextField,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
  Chip,
  CircularProgress,
  Box,
} from "@material-ui/core";
import {
  Autocomplete,
  Skeleton,
} from "@material-ui/lab";
import { Add, Replay } from '@material-ui/icons';
import { matchSorter } from 'match-sorter';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import { getAllConference } from '../../../reducer/conference/action';

import ConferenceInsertModal from '../ConferenceInsertModal';

function ConferenceSearchTab(props) {
  const { getAllConference } = props;

  const [conference, setConference] = useState(null);

  useEffect(() => {
    if (props.list.length === 0) getAllConference();
  }, [getAllConference, props.list.length]);

  const filterOptions = (options, { inputValue }) => {
    return matchSorter(options, inputValue, {keys: ['search']}).slice(0,100);
  }

  const handleChange = (event, value) => {
    event.preventDefault();
    setConference(value);
  }

  return (
    <Card
      style={{ marginLeft: "auto", marginRight: "auto", minWidth: 200, maxWidth: 700 }}
      variant="outlined"
    >
      <CardHeader title="Tra cứu hội thảo" />
      <form onSubmit={(e) => e.preventDefault()}>
        <Autocomplete
          disableClearable
          options={props.list.map(e =>({...e, search: e.acronym + e.title}))}
          getOptionLabel={(option) => {return(option?.acronym ? `(${option?.acronym}) ${option?.title}` : option?.title)}}
          onChange={handleChange}
          filterOptions={filterOptions}
          style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
          renderInput={(params) => <TextField {...params} size='small' label='Tên hội thảo' variant='outlined' />}
        />
      </form>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Tên hội thảo
        </Typography>
        {props.isFetchingJournalDetail? (
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        ) : (
          <div>
            <Typography variant="h5" component="h2">
              {conference?.title || "Tên hội thảo"}
            </Typography>
            <Grid container justifyContent='center' spacing={2} style={{ marginTop: 8 }}>
              <Grid item xs={8}>
                <Box flexWrap="wrap" flexDirection="row" display='flex'> 
                  <Typography variant="body2" component="p" style={{ margin: 5 }}>
                    Cấp:
                  </Typography>
                  <Chip
                    label={conference?.level === 'international' ? 'Quốc tế' : 'Trong nước'}
                    style={{wordWrap: 'break-word'}}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box flexWrap="wrap" flexDirection="row" display='flex'> 
                  <Typography variant="body2" component="p" style={{ margin: 5 }}>
                    Điểm GS-PGS:
                  </Typography>
                  <Chip label={conference?.type || 'Không có'} />
                </Box>
              </Grid>
            </Grid>
            <Grid container justifyContent='center' spacing={2}>
              <Grid item xs={8}>
                <Box flexWrap="wrap" flexDirection="row" display='flex'> 
                  <Typography variant="body2" component="p" style={{ margin: 5 }}>
                    Nguồn:
                  </Typography>
                  <Chip label={conference?.source || 'Không rõ'} />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box flexWrap="wrap" flexDirection="row" display='flex'> 
                  <Typography variant="body2" component="p" style={{ margin: 5 }}>
                    Xếp hạng: 
                  </Typography>
                  <Chip label={conference?.rank || 'Không rõ'} />
                </Box>
              </Grid>
            </Grid>
          </div>
        )}
      </CardContent>
    </Card>
      // /* <Grid container justifyContent='center'>
      //   <Grid item xs={6}>
      //     <Autocomplete
      //       fullWidthx
      //       freeSolo
      //       id="free-solo-2-demo"
      //       disableClearable
      //       options={props.list}
      //       getOptionLabel={(option) => option?.title || ""}
      //       onChange={handleChange}
      //       filterOptions={filterOptions}
      //       autoHighlight
      //       renderInput={(params) => (
      //         <TextField
      //           {...params}
      //           label="Tên hội thảo"
      //           margin="normal"
      //           variant="outlined"
      //           InputProps={{ ...params.InputProps, type: "search" }}
      //         />
      //       )}
      //     />
      //   </Grid>
      //   {!conference ? null : (
      //     <>
      //       <Grid item xs={12}>
      //         <Grid container justifyContent='center'>
      //           <Grid item xs={6}>
      //             <b style={{display: 'inline-block'}}>Tổ chức: </b>
      //             <Typography style={{display: 'inline-block'}}>{conference?.level}</Typography>
      //           </Grid>
      //         </Grid>
      //       </Grid>
      //       <Grid item xs={12}>
      //         <Grid container justifyContent='center'>
      //           <Grid item xs={2}>
      //             <b style={{display: 'inline-block'}}>Xếp loại: </b>
      //             <Typography style={{display: 'inline-block'}}>{conference?.type}</Typography>
      //           </Grid>
      //           <Grid item xs={2}>
      //             <span style={{ flexGrow: 1, float: 'center' }}>
      //               <b style={{display: 'inline-block'}}>Nguồn: </b>
      //               <Typography style={{display: 'inline-block'}}>{conference?.source}</Typography>
      //             </span>
      //           </Grid>
      //           <Grid item xs={2}>
      //             <span style={{ float: 'right' }}>
      //               <b style={{display: 'inline-block'}}>Xếp hạng: </b>
      //               <Typography style={{display: 'inline-block'}}>{conference?.rank}</Typography>
      //             </span>
      //           </Grid>
      //         </Grid>
      //       </Grid>
      //       <Grid item xs={12}>
      //         <Grid container justifyContent='center'>
      //           <Grid item xs={3}>
      //             <b style={{display: 'inline-block'}}>Thời gian tổ chức: </b>
      //             <Typography style={{display: 'inline-block'}}>{conference?.organizeAt}</Typography>
      //           </Grid>
      //           <Grid item xs={3}>
      //             <b style={{display: 'inline-block'}}>Đơn vị tổ chức: </b>
      //             <Typography style={{display: 'inline-block'}}>{conference?.organizeBy}</Typography>
      //           </Grid>
      //         </Grid>
      //       </Grid>
      //     </>
      //   )}
      // </Grid> 
  );
}

const mapStateToProps = (state) => ({
  list: select(state, 'conferenceReducer', 'list'),
});

const mapDispatchToProps = (dispatch) => ({
  getAllConference: () => dispatch(getAllConference()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(ConferenceSearchTab));
