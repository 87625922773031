import React from 'react';
import {
  Tab,
  Card,
  Grid,
  Tabs,
  Menu,
  Button,
  Divider,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ROUTER from '../../constants/router';

import Journal from '../Journal';
import JournalDomestic from '../JournalDomestic';
import AutoRankingCard from '../../containers/Search/AutoRankingCard';

function Search() {
    const [value, setValue] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleChange = (event, newValue) => setValue(newValue);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const StyledMenu = withStyles({
      paper: {
        border: '1px solid #d3d4d5',
        width: anchorEl?.offsetWidth,
      },
    })((props) => (
      <Menu
      {...props}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    ));

    function TabPanel(props) {
      const { children, value, index, ...other } = props;
    
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
          style={{ height: '100%', padding: 16, paddingTop: 0 }}
        >
          {value === index && (children)}
        </div>
      );
    }

  return (
    <Card style={{ minHeight: 'calc(100vh - 92px)', maxHeight: 'calc(100vh - 92px)' }}>
      <Grid container style={{ marginBottom: 8}}>
        <Grid item xs={7}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor={"secondary"}
            textColor='secondary'
          >
            <Tab label="Tra cứu xếp hạng tạp chí" />
            <Tab label="Tạp chí quốc tế" />
            <Tab label="Tạp chí trong nước" />
          </Tabs>
        </Grid>
        <Grid item xs={5}>
          <Button
            color='primary'
            variant='contained'
            onClick={handleClick}
            style={{ minWidth: 180, float: 'right', marginTop: 4, marginRight: 16 }}
          >
            Tra cứu tạp chí
            <ExpandMoreIcon />
          </Button>
          <StyledMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={handleClose}>Tra cứu tạp chí</MenuItem>
            <Link to={ROUTER.CONFERENCE} style={{ color: 'black', textDecoration:'none' }}>
              <MenuItem>Tra cứu hội thảo</MenuItem>
            </Link>
          </StyledMenu>
        </Grid>
        <Grid item xs={12}><Divider /></Grid>
      </Grid>
      
      <TabPanel value={value} index={0}><AutoRankingCard /></TabPanel>
      <TabPanel value={value} index={1}><Journal /></TabPanel>
      <TabPanel value={value} index={2}><JournalDomestic /></TabPanel>
    </Card>
  );
}

export default Search;
