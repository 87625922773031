import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Card, Grid, Divider } from '@material-ui/core';

import { apiCall } from '../../utils/api';
import { API_URLS } from '../../constants/api';
import StudentProjectTable from '../../containers/StudentProject/StudentProjectTable';
import StudentProjectButton from '../../containers/StudentProject/StudentProjectButton';

function StudentProject(props) {
  const [filterObj, setFilterObj] = useState(null);
  const [yearlyValue, setYearlyValue] = useState(null);
  // const [year, setYear] = useState(new Date().getFullYear());
  const [year, setYear] = useState(2023);
  const [chosenDepartmentId, setChosenDepartmentId] = useState(0);

  const now = new Date();
  const isRegister = (new Date(yearlyValue?.regStartTime) <= now)
    && (now <= new Date(yearlyValue?.regEndTime));
  const isReport = (new Date(yearlyValue?.reportStartTime) <= now)
    && (now <= new Date(yearlyValue?.reportEndTime));
  const isAccept = (new Date(yearlyValue?.acceptStartTime) <= now)
    && (now <= new Date(yearlyValue?.acceptEndTime));

  const getData = async () => {
    const api = API_URLS.YEARLY_DUE_DATE.filterYearlyDueDate();
    const { response, error } = await apiCall({
      ...api,
      payload: { type: 'student', schoolYear: year }
    });
    if (!error && response.status === 200 && response.data.success) {
      if (response.data.data.length > 0) {
        const res = response.data.data[0];
        setYearlyValue({
          ...res,
          regStartTimeString: dayjs(res.regStartTime).format('DD/MM/YYYY'),
          reportStartTimeString: dayjs(res.reportStartTime).format('DD/MM/YYYY'),
          acceptStartTimeString: dayjs(res.acceptStartTime).format('DD/MM/YYYY'),
          regEndTimeString: dayjs(res.regEndTime).format('DD/MM/YYYY'),
          reportEndTimeString: dayjs(res.reportEndTime).format('DD/MM/YYYY'),
          acceptEndTimeString: dayjs(res.acceptEndTime).format('DD/MM/YYYY'),
        });
      }
    }
  }

  useEffect(() => getData(), []);

  return (
    <Card style={{ padding: '8px 16px', height: 'calc(100vh - 108px)', minHeight: 'calc(100vh - 108px)', maxHeight: 'calc(100vh - 108px)' }}>
      <Grid container spacing={1}>
        <StudentProjectButton
          year={year}
          setYear={setYear}
          type={props.type}
          setFilterObj={setFilterObj}
          chosenDepartmentId={chosenDepartmentId}
          setChosenDepartmentId={setChosenDepartmentId}
        />
        <Grid item xs={12}><Divider /></Grid>
        <StudentProjectTable
          year={year}
          type={props.type}
          filterObj={filterObj}
          isRegister={isRegister}
          isReport={isReport}
          isAccept={isAccept}
          chosenDepartmentId={chosenDepartmentId}
        />
      </Grid>
    </Card>
  );
}

export default StudentProject;
