import moment from 'moment';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { Box, CircularProgress } from '@material-ui/core';

import toJs from '../../../hoc/toJS';
import select from '../../../utils/select';

import { getFilteredProject } from '../../../reducer/project/action';

function ListProject(props) {
  useEffect (() => {
    props.getFilteredProject(1, -1, { 
      // accepted: true,
      dashboardYear: props.chosenYear,
      departmentId: props.chosenDepartmentId,
      acceptedOne: true,
    })
  }, [props.chosenYear])

  const getProjectLevel = (level) => {
    switch (level) {
      case 'school': return('Cơ sở')
      case 'ministry' : return('Bộ')
      case 'keyMinistry' : return('Trọng điểm của bộ')
      case 'nafosted' : return('Nafosted')
      case 'domestic' : return('Nhà nước')
      case 'international' : return('Quốc gia')
      default: return('Không xác định')
    }
  }

  const getProjectTitle = (info, index) => {
    return(
      <div key={`project-${index}`} style={{ marginTop: 16 }}>
        <div>
          <b style={{color: 'gray'}}>{index + 1}. Đề tài: </b>
          <i style={{color: 'red'}}> {info.researchProject.title}</i>
        </div>
        <div style={{ marginLeft: 20 }}>
          <b>- Thời gian thực hiện: </b>{moment(info.researchProject.startAt).format('DD/MM/YYYY')} - {moment(info.researchProject.finishAt).format('DD/MM/YYYY')}<br/>
          <b>- Loại hình: </b>{(info.researchProject.type === 'basic') ? 'Cơ bản' : (info.researchProject.type === 'application' ? 'Ứng dụng' : '')}<br/>
          <b>- Cấp đề tài: </b>{getProjectLevel(info.researchProject.level)}<br/>
          <b>- Giá trị: </b>{info.researchProject.value || ''} VNĐ<br/>
        </div>
      </div>
    )
  }

  return(
    props.isFetching? (
      <div style={{textAlign: 'center'}}>
        <CircularProgress />
      </div>
    ) : (
      <Box paddingRight={1} height='calc(100vh - 214px)' minWidth='calc(100% + 8px)' style={{ overflowY: 'auto' }}>
        {props.listProjectAccepted.map((item, index) => getProjectTitle(item, index))}
      </Box>
    )
  )
}

const mapStateToProps = (state) => ({
  listProjectAccepted: select(state, 'projectReducer', 'listFiltered'),
  isFetching: select(state, 'projectReducer', 'isFetching'),
});

const mapDispatchToProps = (dispatch) => ({
  getFilteredProject: (page, pageSize, payload) => dispatch(getFilteredProject(page, pageSize, payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(ListProject));