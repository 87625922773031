const TYPE = {
  HOME: {
    CREATE_HISTORY: "CREATE_HISTORY",
    NOTIFICATION: "NOTIFICATION",

    GET_FILTERED_SCIENTIFIC_RESEARCH_ON_HOME_LOADING:
      "GET_FILTERED_SCIENTIFIC_RESEARCH_ON_HOME_LOADING",
    GET_FILTERED_SCIENTIFIC_RESEARCH_ON_HOME_SUCCESS:
      "GET_FILTERED_SCIENTIFIC_RESEARCH_ON_HOME_SUCCESS",
    GET_FILTERED_SCIENTIFIC_RESEARCH_ON_HOME_FAILURE:
      "GET_FILTERED_SCIENTIFIC_RESEARCH_ON_HOME_FAILURE",
  },

  FILE: {
    UPLOAD_FILE_LOADING: "UPLOAD_FILE_LOADING",
    UPLOAD_FILE_SUCCESS: "UPLOAD_FILE_SUCCESS",
    UPLOAD_FILE_FAILURE: "UPLOAD_FILE_FAILURE",

    UPLOAD_MULTIPLE_FILE_LOADING: "UPLOAD_MULTIPLE_FILE_LOADING",
    UPLOAD_MULTIPLE_FILE_SUCCESS: "UPLOAD_MULTIPLE_FILE_SUCCESS",
    UPLOAD_MULTIPLE_FILE_FAILURE: "UPLOAD_MULTIPLE_FILE_FAILURE",
  },

  AUTO_RANKING_LOADING: "AUTO_RANKING_LOADING",
  AUTO_RANKING_SUCCESS: "AUTO_RANKING_SUCCESS",
  AUTO_RANKING_FAILURE: "AUTO_RANKING_FAILURE",

  SCIENTIFIC_RESEARCH: {
    GET_ALL_SCIENTIFIC_RESEARCH_LOADING: "GET_ALL_SCIENTIFIC_RESEARCH_LOADING",
    GET_ALL_SCIENTIFIC_RESEARCH_SUCCESS: "GET_ALL_SCIENTIFIC_RESEARCH_SUCCESS",
    GET_ALL_SCIENTIFIC_RESEARCH_FAILURE: "GET_ALL_SCIENTIFIC_RESEARCH_FAILURE",

    GET_FILTERED_SCIENTIFIC_RESEARCH_LOADING:
      "GET_FILTERED_SCIENTIFIC_RESEARCH_LOADING",
    GET_FILTERED_SCIENTIFIC_RESEARCH_SUCCESS:
      "GET_FILTERED_SCIENTIFIC_RESEARCH_SUCCESS",
    GET_FILTERED_SCIENTIFIC_RESEARCH_FAILURE:
      "GET_FILTERED_SCIENTIFIC_RESEARCH_FAILURE",

    GET_ALL_SCIENTIFIC_RESEARCH_BY_ID_LOADING:
      "GET_ALL_SCIENTIFIC_RESEARCH_BY_ID_LOADING",
    GET_ALL_SCIENTIFIC_RESEARCH_BY_ID_SUCCESS:
      "GET_ALL_SCIENTIFIC_RESEARCH_BY_ID_SUCCESS",
    GET_ALL_SCIENTIFIC_RESEARCH_BY_ID_FAILURE:
      "GET_ALL_SCIENTIFIC_RESEARCH_BY_ID_FAILURE",

    INSERT_SCIENTIFIC_RESEARCH_LOADING: "INSERT_SCIENTIFIC_RESEARCH_LOADING",
    INSERT_SCIENTIFIC_RESEARCH_SUCCESS: "INSERT_SCIENTIFIC_RESEARCH_SUCCESS",
    INSERT_SCIENTIFIC_RESEARCH_FAILURE: "INSERT_SCIENTIFIC_RESEARCH_FAILURE",

    UPDATE_SCIENTIFIC_RESEARCH_LOADING: "UPDATE_SCIENTIFIC_RESEARCH_LOADING",
    UPDATE_SCIENTIFIC_RESEARCH_SUCCESS: "UPDATE_SCIENTIFIC_RESEARCH_SUCCESS",
    UPDATE_SCIENTIFIC_RESEARCH_FAILURE: "UPDATE_SCIENTIFIC_RESEARCH_FAILURE",

    DELETE_SCIENTIFIC_RESEARCH_LOADING: "DELETE_SCIENTIFIC_RESEARCH_LOADING",
    DELETE_SCIENTIFIC_RESEARCH_SUCCESS: "DELETE_SCIENTIFIC_RESEARCH_SUCCESS",
    DELETE_SCIENTIFIC_RESEARCH_FAILURE: "DELETE_SCIENTIFIC_RESEARCH_FAILURE",

    GET_SCIENTIFIC_RESEARCH_BY_ID_LOADING:
      "GET_SCIENTIFIC_RESEARCH_BY_ID_LOADING",
    GET_SCIENTIFIC_RESEARCH_BY_ID_SUCCESS:
      "GET_SCIENTIFIC_RESEARCH_BY_ID_SUCCESS",
    GET_SCIENTIFIC_RESEARCH_BY_ID_FAILURE:
      "GET_SCIENTIFIC_RESEARCH_BY_ID_FAILURE",

    ACCEPT_SCIENTIFIC_RESEARCH_LOADING: "ACCEPT_SCIENTIFIC_RESEARCH_LOADING",
    ACCEPT_SCIENTIFIC_RESEARCH_SUCCESS: "ACCEPT_SCIENTIFIC_RESEARCH_SUCCESS",
    ACCEPT_SCIENTIFIC_RESEARCH_FAILURE: "ACCEPT_SCIENTIFIC_RESEARCH_FAILURE",

    DOI_LOADING: "DOI_LOADING",
    DOI_SUCCESS: "DOI_SUCCESS",
    DOI_FAILURE: "DOI_FAILURE",

    GET_ALL_PURPOSE_LOADING: "GET_ALL_PURPOSE_LOADING",
    GET_ALL_PURPOSE_SUCCESS: "GET_ALL_PURPOSE_SUCCESS",
    GET_ALL_PURPOSE_FAILURE: "GET_ALL_PURPOSE_FAILURE",

    GET_DUPLICATE_SCIENTIFIC_RESEARCH_LOADING:
      "GET_DUPLICATE_SCIENTIFIC_RESEARCH_LOADING",
    GET_DUPLICATE_SCIENTIFIC_RESEARCH_SUCCESS:
      "GET_DUPLICATE_SCIENTIFIC_RESEARCH_SUCCESS",
    GET_DUPLICATE_SCIENTIFIC_RESEARCH_FAILURE:
      "GET_DUPLICATE_SCIENTIFIC_RESEARCH_FAILURE",
  },
  SCIENTIFIC_RESEARCH_OTHER: {
    GET_ALL_SCIENTIFIC_RESEARCH_OTHER_LOADING:
      "GET_ALL_SCIENTIFIC_RESEARCH_OTHER_LOADING",
    GET_ALL_SCIENTIFIC_RESEARCH_OTHER_SUCCESS:
      "GET_ALL_SCIENTIFIC_RESEARCH_OTHER_SUCCESS",
    GET_ALL_SCIENTIFIC_RESEARCH_OTHER_FAILURE:
      "GET_ALL_SCIENTIFIC_RESEARCH_OTHER_FAILURE",

    GET_ALL_SCIENTIFIC_RESEARCH_OTHER_BY_PROFILE_ID_LOADING:
      "GET_ALL_SCIENTIFIC_RESEARCH_OTHER_BY_PROFILE_ID_LOADING",
    GET_ALL_SCIENTIFIC_RESEARCH_OTHER_BY_PROFILE_ID_SUCCESS:
      "GET_ALL_SCIENTIFIC_RESEARCH_OTHER_BY_PROFILE_ID_SUCCESS",
    GET_ALL_SCIENTIFIC_RESEARCH_OTHER_BY_PROFILE_ID_FAILURE:
      "GET_ALL_SCIENTIFIC_RESEARCH_OTHER_BY_PROFILE_ID_FAILURE",

    INSERT_SCIENTIFIC_RESEARCH_OTHER_LOADING:
      "INSERT_SCIENTIFIC_RESEARCH_OTHER_LOADING",
    INSERT_SCIENTIFIC_RESEARCH_OTHER_SUCCESS:
      "INSERT_SCIENTIFIC_RESEARCH_OTHER_SUCCESS",
    INSERT_SCIENTIFIC_RESEARCH_OTHER_FAILURE:
      "INSERT_SCIENTIFIC_RESEARCH_OTHER_FAILURE",

    UPDATE_SCIENTIFIC_RESEARCH_OTHER_LOADING:
      "UPDATE_SCIENTIFIC_RESEARCH_OTHER_LOADING",
    UPDATE_SCIENTIFIC_RESEARCH_OTHER_SUCCESS:
      "UPDATE_SCIENTIFIC_RESEARCH_OTHER_SUCCESS",
    UPDATE_SCIENTIFIC_RESEARCH_OTHER_FAILURE:
      "UPDATE_SCIENTIFIC_RESEARCH_OTHER_FAILURE",

    DELETE_SCIENTIFIC_RESEARCH_OTHER_LOADING:
      "DELETE_SCIENTIFIC_RESEARCH_OTHER_LOADING",
    DELETE_SCIENTIFIC_RESEARCH_OTHER_SUCCESS:
      "DELETE_SCIENTIFIC_RESEARCH_OTHER_SUCCESS",
    DELETE_SCIENTIFIC_RESEARCH_OTHER_FAILURE:
      "DELETE_SCIENTIFIC_RESEARCH_OTHER_FAILURE",

    ACCEPT_SCIENTIFIC_RESEARCH_OTHER_LOADING:
      "ACCEPT_SCIENTIFIC_RESEARCH_OTHER_LOADING",
    ACCEPT_SCIENTIFIC_RESEARCH_OTHER_SUCCESS:
      "ACCEPT_SCIENTIFIC_RESEARCH_OTHER_SUCCESS",
    ACCEPT_SCIENTIFIC_RESEARCH_OTHER_FAILURE:
      "ACCEPT_SCIENTIFIC_RESEARCH_OTHER_FAILURE",
  },
  SCIENTIFIC_COUNCLI: {
    GET_SCIENTIFIC_COUNCLI_LOADING: "GET_SCIENTIFIC_COUNCLI_LOADING",
    GET_SCIENTIFIC_COUNCLI_SUCCESS: "GET_SCIENTIFIC_COUNCLI_SUCCESS",
    GET_SCIENTIFIC_COUNCLI_FAILURE: "GET_SCIENTIFIC_COUNCLI_FAILURE",

    CREATE_SCIENTIFIC_COUNCLI_LOADING: "CREATE_SCIENTIFIC_COUNCLI_LOADING",
    CREATE_SCIENTIFIC_COUNCLI_SUCCESS: "CREATE_SCIENTIFIC_COUNCLI_SUCCESS",
    CREATE_SCIENTIFIC_COUNCLI_FAILURE: "CREATE_SCIENTIFIC_COUNCLI_FAILURE",

    UPDATE_SCIENTIFIC_COUNCLI_LOADING: "UPDATE_SCIENTIFIC_COUNCLI_LOADING",
    UPDATE_SCIENTIFIC_COUNCLI_SUCCESS: "UPDATE_SCIENTIFIC_COUNCLI_SUCCESS",
    UPDATE_SCIENTIFIC_COUNCLI_FAILURE: "UPDATE_SCIENTIFIC_COUNCLI_FAILURE",

    DELETE_SCIENTIFIC_COUNCLI_LOADING: "DELETE_SCIENTIFIC_COUNCLI_LOADING",
    DELETE_SCIENTIFIC_COUNCLI_SUCCESS: "DELETE_SCIENTIFIC_COUNCLI_SUCCESS",
    DELETE_SCIENTIFIC_COUNCLI_FAILURE: "DELETE_SCIENTIFIC_COUNCLI_FAILURE",
  },
  JOURNAL: {
    GET_ALL_JOURNAL_LOADING: "GET_ALL_JOURNAL_LOADING",
    GET_ALL_JOURNAL_SUCCESS: "GET_ALL_JOURNAL_SUCCESS",
    GET_ALL_JOURNAL_FAILURE: "GET_ALL_JOURNAL_FAILURE",

    GET_ALL_JOURNAL_V2_LOADING: "GET_ALL_JOURNAL_V2_LOADING",
    GET_ALL_JOURNAL_V2_SUCCESS: "GET_ALL_JOURNAL_V2_SUCCESS",
    GET_ALL_JOURNAL_V2_FAILURE: "GET_ALL_JOURNAL_V2_FAILURE",

    INSERT_JOURNAL_LOADING: "INSERT_JOURNAL_LOADING",
    INSERT_JOURNAL_SUCCESS: "INSERT_JOURNAL_SUCCESS",
    INSERT_JOURNAL_FAILURE: "INSERT_JOURNAL_FAILURE",

    UPDATE_JOURNAL_LOADING: "UPDATE_JOURNAL_LOADING",
    UPDATE_JOURNAL_SUCCESS: "UPDATE_JOURNAL_SUCCESS",
    UPDATE_JOURNAL_FAILURE: "UPDATE_JOURNAL_FAILURE",

    UPDATE_ALL_JOURNAL_LOADING: "UPDATE_ALL_JOURNAL_LOADING",
    UPDATE_ALL_JOURNAL_SUCCESS: "UPDATE_ALL_JOURNAL_SUCCESS",
    UPDATE_ALL_JOURNAL_FAILURE: "UPDATE_ALL_JOURNAL_FAILURE",

    DELETE_JOURNAL_LOADING: "DELETE_JOURNAL_LOADING",
    DELETE_JOURNAL_SUCCESS: "DELETE_JOURNAL_SUCCESS",
    DELETE_JOURNAL_FAILURE: "DELETE_JOURNAL_FAILURE",

    GET_FILTERED_JOURNAL_LOADING: "GET_FILTERED_JOURNAL_LOADING",
    GET_FILTERED_JOURNAL_SUCCESS: "GET_FILTERED_JOURNAL_SUCCESS",
    GET_FILTERED_JOURNAL_FAILURE: "GET_FILTERED_JOURNAL_FAILURE",

    GET_SEARCH_FILTERED_JOURNAL_LOADING: "GET_SEARCH_FILTERED_JOURNAL_LOADING",
    GET_SEARCH_FILTERED_JOURNAL_SUCCESS: "GET_SEARCH_FILTERED_JOURNAL_SUCCESS",
    GET_SEARCH_FILTERED_JOURNAL_FAILURE: "GET_SEARCH_FILTERED_JOURNAL_FAILURE",

    GET_SEARCH_FILTERED_JOURNAL_AFTER_DOI_LOADING:
      "GET_SEARCH_FILTERED_JOURNAL_AFTER_DOI_LOADING",
    GET_SEARCH_FILTERED_JOURNAL_AFTER_DOI_SUCCESS:
      "GET_SEARCH_FILTERED_JOURNAL_AFTER_DOI_SUCCESS",
    GET_SEARCH_FILTERED_JOURNAL_AFTER_DOI_FAILURE:
      "GET_SEARCH_FILTERED_JOURNAL_AFTER_DOI_FAILURE",

    GET_JOURNAL_BY_ID_LOADING: "GET_JOURNAL_BY_ID_LOADING",
    GET_JOURNAL_BY_ID_SUCCESS: "GET_JOURNAL_BY_ID_SUCCESS",
    GET_JOURNAL_BY_ID_FAILURE: "GET_JOURNAL_BY_ID_FAILURE",

    GET_JOURNAL_BY_ID_ON_SCIENTIFIC_RESEARCH_LOADING:
      "GET_JOURNAL_BY_ID_ON_SCIENTIFIC_RESEARCH_LOADING",
    GET_JOURNAL_BY_ID_ON_SCIENTIFIC_RESEARCH_SUCCESS:
      "GET_JOURNAL_BY_ID_ON_SCIENTIFIC_RESEARCH_SUCCESS",
    GET_JOURNAL_BY_ID_ON_SCIENTIFIC_RESEARCH_FAILURE:
      "GET_JOURNAL_BY_ID_ON_SCIENTIFIC_RESEARCH_FAILURE",

    IMPORT_JOURNAL_BY_YEAR_LOADING: "IMPORT_JOURNAL_BY_YEAR_LOADING",
    IMPORT_JOURNAL_BY_YEAR_SUCCESS: "IMPORT_JOURNAL_BY_YEAR_SUCCESS",
    IMPORT_JOURNAL_BY_YEAR_FAILURE: "IMPORT_JOURNAL_BY_YEAR_FAILURE",

    CHANGE_FILTER_DATA: "CHANGE_FILTER_DATA",
  },

  JOURNAL_DOMESTIC: {
    GET_ALL_JOURNAL_DOMESTIC_LOADING: "GET_ALL_JOURNAL_DOMESTIC_LOADING",
    GET_ALL_JOURNAL_DOMESTIC_SUCCESS: "GET_ALL_JOURNAL_DOMESTIC_SUCCESS",
    GET_ALL_JOURNAL_DOMESTIC_FAILURE: "GET_ALL_JOURNAL_DOMESTIC_FAILURE",

    INSERT_JOURNAL_DOMESTIC_LOADING: "INSERT_JOURNAL_DOMESTIC_LOADING",
    INSERT_JOURNAL_DOMESTIC_SUCCESS: "INSERT_JOURNAL_DOMESTIC_SUCCESS",
    INSERT_JOURNAL_DOMESTIC_FAILURE: "INSERT_JOURNAL_DOMESTIC_FAILURE",

    UPDATE_JOURNAL_DOMESTIC_LOADING: "UPDATE_JOURNAL_DOMESTIC_LOADING",
    UPDATE_JOURNAL_DOMESTIC_SUCCESS: "UPDATE_JOURNAL_DOMESTIC_SUCCESS",
    UPDATE_JOURNAL_DOMESTIC_FAILURE: "UPDATE_JOURNAL_DOMESTIC_FAILURE",

    DELETE_JOURNAL_DOMESTIC_LOADING: "DELETE_JOURNAL_DOMESTIC_LOADING",
    DELETE_JOURNAL_DOMESTIC_SUCCESS: "DELETE_JOURNAL_DOMESTIC_SUCCESS",
    DELETE_JOURNAL_DOMESTIC_FAILURE: "DELETE_JOURNAL_DOMESTIC_FAILURE",
  },

  PATENT: {
    GET_ALL_PATENT_LOADING: "GET_ALL_PATENT_LOADING",
    GET_ALL_PATENT_SUCCESS: "GET_ALL_PATENT_SUCCESS",
    GET_ALL_PATENT_FAILURE: "GET_ALL_PATENT_FAILURE",

    GET_FILTERED_PATENT_LOADING: "GET_FILTERED_PATENT_LOADING",
    GET_FILTERED_PATENT_SUCCESS: "GET_FILTERED_PATENT_SUCCESS",
    GET_FILTERED_PATENT_FAILURE: "GET_FILTERED_PATENT_FAILURE",

    GET_PATENT_BY_ID_LOADING: "GET_PATENT_BY_ID_LOADING",
    GET_PATENT_BY_ID_SUCCESS: "GET_PATENT_BY_ID_SUCCESS",
    GET_PATENT_BY_ID_FAILURE: "GET_PATENT_BY_ID_FAILURE",

    GET_ALL_PATENT_BY_PROFILE_ID_LOADING:
      "GET_ALL_PATENT_BY_PROFILE_ID_LOADING",
    GET_ALL_PATENT_BY_PROFILE_ID_SUCCESS:
      "GET_ALL_PATENT_BY_PROFILE_ID_SUCCESS",
    GET_ALL_PATENT_BY_PROFILE_ID_FAILURE:
      "GET_ALL_PATENT_BY_PROFILE_ID_FAILURE",

    INSERT_PATENT_LOADING: "INSERT_PATENT_LOADING",
    INSERT_PATENT_SUCCESS: "INSERT_PATENT_SUCCESS",
    INSERT_PATENT_FAILURE: "INSERT_PATENT_FAILURE",

    UPDATE_PATENT_LOADING: "UPDATE_PATENT_LOADING",
    UPDATE_PATENT_SUCCESS: "UPDATE_PATENT_SUCCESS",
    UPDATE_PATENT_FAILURE: "UPDATE_PATENT_FAILURE",

    DELETE_PATENT_LOADING: "DELETE_PATENT_LOADING",
    DELETE_PATENT_SUCCESS: "DELETE_PATENT_SUCCESS",
    DELETE_PATENT_FAILURE: "DELETE_PATENT_FAILURE",

    ACCEPT_PATENT_LOADING: "ACCEPT_PATENT_LOADING",
    ACCEPT_PATENT_SUCCESS: "ACCEPT_PATENT_SUCCESS",
    ACCEPT_PATENT_FAILURE: "ACCEPT_PATENT_FAILURE",
  },

  SOLUTION: {
    GET_ALL_SOLUTION_LOADING: "GET_ALL_SOLUTION_LOADING",
    GET_ALL_SOLUTION_SUCCESS: "GET_ALL_SOLUTION_SUCCESS",
    GET_ALL_SOLUTION_FAILURE: "GET_ALL_SOLUTION_FAILURE",

    GET_FILTERED_SOLUTION_LOADING: "GET_FILTERED_SOLUTION_LOADING",
    GET_FILTERED_SOLUTION_SUCCESS: "GET_FILTERED_SOLUTION_SUCCESS",
    GET_FILTERED_SOLUTION_FAILURE: "GET_FILTERED_SOLUTION_FAILURE",

    GET_ALL_SOLUTION_BY_ID_LOADING: "GET_ALL_SOLUTION_BY_ID_LOADING",
    GET_ALL_SOLUTION_BY_ID_SUCCESS: "GET_ALL_SOLUTION_BY_ID_SUCCESS",
    GET_ALL_SOLUTION_BY_ID_FAILURE: "GET_ALL_SOLUTION_BY_ID_FAILURE",

    GET_SOLUTION_BY_ID_LOADING: "GET_SOLUTION_BY_ID_LOADING",
    GET_SOLUTION_BY_ID_SUCCESS: "GET_SOLUTION_BY_ID_SUCCESS",
    GET_SOLUTION_BY_ID_FAILURE: "GET_SOLUTION_BY_ID_FAILURE",

    INSERT_SOLUTION_LOADING: "INSERT_SOLUTION_LOADING",
    INSERT_SOLUTION_SUCCESS: "INSERT_SOLUTION_SUCCESS",
    INSERT_SOLUTION_FAILURE: "INSERT_SOLUTION_FAILURE",

    UPDATE_SOLUTION_LOADING: "UPDATE_SOLUTION_LOADING",
    UPDATE_SOLUTION_SUCCESS: "UPDATE_SOLUTION_SUCCESS",
    UPDATE_SOLUTION_FAILURE: "UPDATE_SOLUTION_FAILURE",

    DELETE_SOLUTION_LOADING: "DELETE_SOLUTION_LOADING",
    DELETE_SOLUTION_SUCCESS: "DELETE_SOLUTION_SUCCESS",
    DELETE_SOLUTION_FAILURE: "DELETE_SOLUTION_FAILURE",

    ACCEPT_SOLUTION_LOADING: "ACCEPT_SOLUTION_LOADING",
    ACCEPT_SOLUTION_SUCCESS: "ACCEPT_SOLUTION_SUCCESS",
    ACCEPT_SOLUTION_FAILURE: "ACCEPT_SOLUTION_FAILURE",
  },

  CONTRACT: {
    GET_ALL_CONTRACT_LOADING: "GET_ALL_CONTRACT_LOADING",
    GET_ALL_CONTRACT_SUCCESS: "GET_ALL_CONTRACT_SUCCESS",
    GET_ALL_CONTRACT_FAILURE: "GET_ALL_CONTRACT_FAILURE",

    GET_FILTERED_CONTRACT_LOADING: "GET_FILTERED_CONTRACT_LOADING",
    GET_FILTERED_CONTRACT_SUCCESS: "GET_FILTERED_CONTRACT_SUCCESS",
    GET_FILTERED_CONTRACT_FAILURE: "GET_FILTERED_CONTRACT_FAILURE",

    GET_ALL_CONTRACT_BY_ID_LOADING: "GET_ALL_CONTRACT_BY_ID_LOADING",
    GET_ALL_CONTRACT_BY_ID_SUCCESS: "GET_ALL_CONTRACT_BY_ID_SUCCESS",
    GET_ALL_CONTRACT_BY_ID_FAILURE: "GET_ALL_CONTRACT_BY_ID_FAILURE",

    GET_ALL_CONTRACT_BY_PROFILE_ID_LOADING:
      "GET_ALL_CONTRACT_BY_PROFILE_ID_LOADING",
    GET_ALL_CONTRACT_BY_PROFILE_ID_SUCCESS:
      "GET_ALL_CONTRACT_BY_PROFILE_ID_SUCCESS",
    GET_ALL_CONTRACT_BY_PROFILE_ID_FAILURE:
      "GET_ALL_CONTRACT_BY_PROFILE_ID_FAILURE",

    GET_CONTRACT_BY_ID_LOADING: "GET_CONTRACT_BY_ID_LOADING",
    GET_CONTRACT_BY_ID_SUCCESS: "GET_CONTRACT_BY_ID_SUCCESS",
    GET_CONTRACT_BY_ID_FAILURE: "GET_CONTRACT_BY_ID_FAILURE",

    INSERT_CONTRACT_LOADING: "INSERT_CONTRACT_LOADING",
    INSERT_CONTRACT_SUCCESS: "INSERT_CONTRACT_SUCCESS",
    INSERT_CONTRACT_FAILURE: "INSERT_CONTRACT_FAILURE",

    UPDATE_CONTRACT_LOADING: "UPDATE_CONTRACT_LOADING",
    UPDATE_CONTRACT_SUCCESS: "UPDATE_CONTRACT_SUCCESS",
    UPDATE_CONTRACT_FAILURE: "UPDATE_CONTRACT_FAILURE",

    DELETE_CONTRACT_LOADING: "DELETE_CONTRACT_LOADING",
    DELETE_CONTRACT_SUCCESS: "DELETE_CONTRACT_SUCCESS",
    DELETE_CONTRACT_FAILURE: "DELETE_CONTRACT_FAILURE",

    ACCEPT_CONTRACT_LOADING: "ACCEPT_CONTRACT_LOADING",
    ACCEPT_CONTRACT_SUCCESS: "ACCEPT_CONTRACT_SUCCESS",
    ACCEPT_CONTRACT_FAILURE: "ACCEPT_CONTRACT_FAILURE",
  },

  PROJECT: {
    GET_ALL_PROJECT_LOADING: "GET_ALL_PROJECT_LOADING",
    GET_ALL_PROJECT_SUCCESS: "GET_ALL_PROJECT_SUCCESS",
    GET_ALL_PROJECT_FAILURE: "GET_ALL_PROJECT_FAILURE",

    GET_FILTERED_PROJECT_LOADING: "GET_FILTERED_PROJECT_LOADING",
    GET_FILTERED_PROJECT_SUCCESS: "GET_FILTERED_PROJECT_SUCCESS",
    GET_FILTERED_PROJECT_FAILURE: "GET_FILTERED_PROJECT_FAILURE",

    GET_ALL_PROJECT_BY_PROFILE_ID_LOADING:
      "GET_ALL_PROJECT_BY_PROFILE_ID_LOADING",
    GET_ALL_PROJECT_BY_PROFILE_ID_SUCCESS:
      "GET_ALL_PROJECT_BY_PROFILE_ID_SUCCESS",
    GET_ALL_PROJECT_BY_PROFILE_ID_FAILURE:
      "GET_ALL_PROJECT_BY_PROFILE_ID_FAILURE",

    GET_PROJECT_BY_ID_LOADING: "GET_PROJECT_BY_ID_LOADING",
    GET_PROJECT_BY_ID_SUCCESS: "GET_PROJECT_BY_ID_SUCCESS",
    GET_PROJECT_BY_ID_FAILURE: "GET_PROJECT_BY_ID_FAILURE",

    INSERT_PROJECT_LOADING: "INSERT_PROJECT_LOADING",
    INSERT_PROJECT_SUCCESS: "INSERT_PROJECT_SUCCESS",
    INSERT_PROJECT_FAILURE: "INSERT_PROJECT_FAILURE",

    UPDATE_PROJECT_LOADING: "UPDATE_PROJECT_LOADING",
    UPDATE_PROJECT_SUCCESS: "UPDATE_PROJECT_SUCCESS",
    UPDATE_PROJECT_FAILURE: "UPDATE_PROJECT_FAILURE",

    DELETE_PROJECT_LOADING: "DELETE_PROJECT_LOADING",
    DELETE_PROJECT_SUCCESS: "DELETE_PROJECT_SUCCESS",
    DELETE_PROJECT_FAILURE: "DELETE_PROJECT_FAILURE",

    ACCEPT_PROJECT_LOADING: "ACCEPT_PROJECT_LOADING",
    ACCEPT_PROJECT_SUCCESS: "ACCEPT_PROJECT_SUCCESS",
    ACCEPT_PROJECT_FAILURE: "ACCEPT_PROJECT_FAILURE",

    GET_PROFILE_INFO_BY_PROFILE_ID_LOADING:
      "V2_GET_PROFILE_INFO_BY_PROFILE_ID_LOADING",
    GET_PROFILE_INFO_BY_PROFILE_ID_SUCCESS:
      "V2_GET_PROFILE_INFO_BY_PROFILE_ID_SUCCESS",
    GET_PROFILE_INFO_BY_PROFILE_ID_FAILURE:
      "V2_GET_PROFILE_INFO_BY_PROFILE_ID_FAILURE",
  },

  MONOGRAPH: {
    GET_ALL_MONOGRAPH_LOADING: "GET_ALL_MONOGRAPH_LOADING",
    GET_ALL_MONOGRAPH_SUCCESS: "GET_ALL_MONOGRAPH_SUCCESS",
    GET_ALL_MONOGRAPH_FAILURE: "GET_ALL_MONOGRAPH_FAILURE",

    GET_FILTERED_MONOGRAPH_LOADING: "GET_FILTERED_MONOGRAPH_LOADING",
    GET_FILTERED_MONOGRAPH_SUCCESS: "GET_FILTERED_MONOGRAPH_SUCCESS",
    GET_FILTERED_MONOGRAPH_FAILURE: "GET_FILTERED_MONOGRAPH_FAILURE",

    GET_ALL_MONOGRAPH_BY_PROFILE_ID_LOADING:
      "GET_ALL_MONOGRAPH_BY_PROFILE_ID_LOADING",
    GET_ALL_MONOGRAPH_BY_PROFILE_ID_SUCCESS:
      "GET_ALL_MONOGRAPH_BY_PROFILE_ID_SUCCESS",
    GET_ALL_MONOGRAPH_BY_PROFILE_ID_FAILURE:
      "GET_ALL_MONOGRAPH_BY_PROFILE_ID_FAILURE",

    GET_MONOGRAPH_BY_ID_LOADING: "GET_MONOGRAPH_BY_ID_LOADING",
    GET_MONOGRAPH_BY_ID_SUCCESS: "GET_MONOGRAPH_BY_ID_SUCCESS",
    GET_MONOGRAPH_BY_ID_FAILURE: "GET_MONOGRAPH_BY_ID_FAILURE",

    INSERT_MONOGRAPH_LOADING: "INSERT_MONOGRAPH_LOADING",
    INSERT_MONOGRAPH_SUCCESS: "INSERT_MONOGRAPH_SUCCESS",
    INSERT_MONOGRAPH_FAILURE: "INSERT_MONOGRAPH_FAILURE",

    UPDATE_MONOGRAPH_LOADING: "UPDATE_MONOGRAPH_LOADING",
    UPDATE_MONOGRAPH_SUCCESS: "UPDATE_MONOGRAPH_SUCCESS",
    UPDATE_MONOGRAPH_FAILURE: "UPDATE_MONOGRAPH_FAILURE",

    DELETE_MONOGRAPH_LOADING: "DELETE_MONOGRAPH_LOADING",
    DELETE_MONOGRAPH_SUCCESS: "DELETE_MONOGRAPH_SUCCESS",
    DELETE_MONOGRAPH_FAILURE: "DELETE_MONOGRAPH_FAILURE",

    ACCEPT_MONOGRAPH_LOADING: "ACCEPT_MONOGRAPH_LOADING",
    ACCEPT_MONOGRAPH_SUCCESS: "ACCEPT_MONOGRAPH_SUCCESS",
    ACCEPT_MONOGRAPH_FAILURE: "ACCEPT_MONOGRAPH_FAILURE",
  },

  EMPLOYMENT_CONTRACT: {
    GET_ALL_EMPLOYMENT_CONTRACT_LOADING: "GET_ALL_EMPLOYMENT_CONTRACT_LOADING",
    GET_ALL_EMPLOYMENT_CONTRACT_SUCCESS: "GET_ALL_EMPLOYMENT_CONTRACT_SUCCESS",
    GET_ALL_EMPLOYMENT_CONTRACT_FAILURE: "GET_ALL_EMPLOYMENT_CONTRACT_FAILURE",

    GET_EMPLOYMENT_CONTRACT_BY_LEADER_PROFILE_ID_LOADING:
      "GET_EMPLOYMENT_CONTRACT_BY_LEADER_PROFILE_ID_LOADING",
    GET_EMPLOYMENT_CONTRACT_BY_LEADER_PROFILE_ID_SUCCESS:
      "GET_EMPLOYMENT_CONTRACT_BY_LEADER_PROFILE_ID_SUCCESS",
    GET_EMPLOYMENT_CONTRACT_BY_LEADER_PROFILE_ID_FAILURE:
      "GET_EMPLOYMENT_CONTRACT_BY_LEADER_PROFILE_ID_FAILURE",

    GET_EMPLOYMENT_CONTRACT_BY_ID_LOADING:
      "GET_EMPLOYMENT_CONTRACT_BY_ID_LOADING",
    GET_EMPLOYMENT_CONTRACT_BY_ID_SUCCESS:
      "GET_EMPLOYMENT_CONTRACT_BY_ID_SUCCESS",
    GET_EMPLOYMENT_CONTRACT_BY_ID_FAILURE:
      "GET_EMPLOYMENT_CONTRACT_BY_ID_FAILURE",

    GET_EMPLOYMENT_CONTRACT_INFO_BY_ID_LOADING:
      "GET_EMPLOYMENT_CONTRACT_INFO_BY_ID_LOADING",
    GET_EMPLOYMENT_CONTRACT_INFO_BY_ID_SUCCESS:
      "GET_EMPLOYMENT_CONTRACT_INFO_BY_ID_SUCCESS",
    GET_EMPLOYMENT_CONTRACT_INFO_BY_ID_FAILURE:
      "GET_EMPLOYMENT_CONTRACT_INFO_BY_ID_FAILURE",

    GET_ALL_EMPLOYMENT_CONTRACT_BY_ID_LOADING:
      "GET_ALL_EMPLOYMENT_CONTRACT_BY_ID_LOADING",
    GET_ALL_EMPLOYMENT_CONTRACT_BY_ID_SUCCESS:
      "GET_ALL_EMPLOYMENT_CONTRACT_BY_ID_SUCCESS",
    GET_ALL_EMPLOYMENT_CONTRACT_BY_ID_FAILURE:
      "GET_ALL_EMPLOYMENT_CONTRACT_BY_ID_FAILURE",

    INSERT_EMPLOYMENT_CONTRACT_LOADING: "INSERT_EMPLOYMENT_CONTRACT_LOADING",
    INSERT_EMPLOYMENT_CONTRACT_SUCCESS: "INSERT_EMPLOYMENT_CONTRACT_SUCCESS",
    INSERT_EMPLOYMENT_CONTRACT_FAILURE: "INSERT_EMPLOYMENT_CONTRACT_FAILURE",

    UPDATE_EMPLOYMENT_CONTRACT_LOADING: "UPDATE_EMPLOYMENT_CONTRACT_LOADING",
    UPDATE_EMPLOYMENT_CONTRACT_SUCCESS: "UPDATE_EMPLOYMENT_CONTRACT_SUCCESS",
    UPDATE_EMPLOYMENT_CONTRACT_V2_SUCCESS:
      "UPDATE_EMPLOYMENT_CONTRACT_V2_SUCCESS",
    UPDATE_EMPLOYMENT_CONTRACT_FAILURE: "UPDATE_EMPLOYMENT_CONTRACT_FAILURE",

    DELETE_EMPLOYMENT_CONTRACT_LOADING: "DELETE_EMPLOYMENT_CONTRACT_LOADING",
    DELETE_EMPLOYMENT_CONTRACT_SUCCESS: "DELETE_EMPLOYMENT_CONTRACT_SUCCESS",
    DELETE_EMPLOYMENT_CONTRACT_FAILURE: "DELETE_EMPLOYMENT_CONTRACT_FAILURE",

    ACTIVATE_EMPLOYMENT_CONTRACT_LOADING:
      "ACTIVATE_EMPLOYMENT_CONTRACT_LOADING",
    ACTIVATE_EMPLOYMENT_CONTRACT_SUCCESS:
      "ACTIVATE_EMPLOYMENT_CONTRACT_SUCCESS",
    ACTIVATE_EMPLOYMENT_CONTRACT_FAILURE:
      "ACTIVATE_EMPLOYMENT_CONTRACT_FAILURE",

    UPDATE_KPI_COMPLETED_EMPLOYMENT_CONTRACT_LOADING:
      "UPDATE_KPI_COMPLETED_EMPLOYMENT_CONTRACT_LOADING",
    UPDATE_KPI_COMPLETED_EMPLOYMENT_CONTRACT_SUCCESS:
      "UPDATE_KPI_COMPLETED_EMPLOYMENT_CONTRACT_SUCCESS",
    UPDATE_KPI_COMPLETED_EMPLOYMENT_CONTRACT_FAILURE:
      "UPDATE_KPI_COMPLETED_EMPLOYMENT_CONTRACT_FAILURE",

    UPDATE_RECEIVE_NOTI_EMPLOYMENT_CONTRACT_LOADING:
      "UPDATE_RECEIVE_NOTI_EMPLOYMENT_CONTRACT_LOADING",
    UPDATE_RECEIVE_NOTI_EMPLOYMENT_CONTRACT_SUCCESS:
      "UPDATE_RECEIVE_NOTI_EMPLOYMENT_CONTRACT_SUCCESS",
    UPDATE_RECEIVE_NOTI_EMPLOYMENT_CONTRACT_FAILURE:
      "UPDATE_RECEIVE_NOTI_EMPLOYMENT_CONTRACT_FAILURE",

    // KPI

    CREATE_KPI_LOADING: "CREATE_KPI_LOADING",
    CREATE_KPI_SUCCESS: "CREATE_KPI_SUCCESS",
    CREATE_KPI_FAILURE: "CREATE_KPI_FAILURE",

    DELETE_KPI_LOADING: "DELETE_KPI_LOADING",
    DELETE_KPI_SUCCESS: "DELETE_KPI_SUCCESS",
    DELETE_KPI_FAILURE: "DELETE_KPI_FAILURE",

    UPDATE_KPI_LOADING: "UPDATE_KPI_LOADING",
    UPDATE_KPI_SUCCESS: "UPDATE_KPI_SUCCESS",
    UPDATE_KPI_FAILURE: "UPDATE_KPI_FAILURE",

    UPDATE_KPI_COMPLETED_LOADING: "UPDATE_KPI_COMPLETED_LOADING",
    UPDATE_KPI_COMPLETED_SUCCESS: "UPDATE_KPI_COMPLETED_SUCCESS",
    UPDATE_KPI_COMPLETED_FAILURE: "UPDATE_KPI_COMPLETED_FAILURE",

    GET_KPI_REWARD_INFO_LOADING: "GET_KPI_REWARD_INFO_LOADING",
    GET_KPI_REWARD_INFO_SUCCESS: "GET_KPI_REWARD_INFO_SUCCESS",
    GET_KPI_REWARD_INFO_FAILURE: "GET_KPI_REWARD_INFO_FAILURE",
  },

  HUMAN_RESOURCE: {
    GET_ALL_USERS_LOADING: "GET_ALL_USERS_LOADING",
    GET_ALL_USERS_SUCCESS: "GET_ALL_USERS_SUCCESS",
    GET_ALL_USERS_FAILURE: "GET_ALL_USERS_FAILURE",

    CREATE_USER_LOADING: "CREATE_USER_LOADING",
    CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
    CREATE_USER_FAILURE: "CREATE_USER_FAILURE",

    UPDATE_ROLE_LOADING: "UPDATE_ROLE_LOADING",
    UPDATE_ROLE_SUCCESS: "UPDATE_ROLE_SUCCESS",
    UPDATE_ROLE_FAILURE: "UPDATE_ROLE_FAILURE",

    DELETE_USER_LOADING: "DELETE_USER_LOADING",
    DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
    DELETE_USER_FAILURE: "DELETE_USER_FAILURE",
  },

  USER: {
    LOGIN_LOADING: "LOGIN_LOADING",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILURE: "LOGIN_FAILURE",

    LOGIN_WITH_GOOGLE_LOADING: "LOGIN_WITH_GOOGLE_LOADING",
    LOGIN_WITH_GOOGLE_SUCCESS: "LOGIN_WITH_GOOGLE_SUCCESS",
    LOGIN_WITH_GOOGLE_FAILURE: "LOGIN_WITH_GOOGLE_FAILURE",

    LOGOUT: "LOGOUT",

    REFRESH_TOKEN_LOADING: "REFRESH_TOKEN_LOADING",
    REFRESH_TOKEN_SUCCESS: "REFRESH_TOKEN_SUCCESS",
    REFRESH_TOKEN_FAILURE: "REFRESH_TOKEN_FAILURE",

    UPDATE_PASSWORD_LOADING: "UPDATE_PASSWORD_LOADING",
    UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
    UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",
  },

  PROFILE: {
    GET_ALL_PROFILE_LOADING: "GET_ALL_PROFILE_LOADING",
    GET_ALL_PROFILE_SUCCESS: "GET_ALL_PROFILE_SUCCESS",
    GET_ALL_PROFILE_FAILURE: "GET_ALL_PROFILE_FAILURE",

    GET_PROFILE_FROM_USER_ID_LOADING: "GET_PROFILE_FROM_USER_ID_LOADING",
    GET_PROFILE_FROM_USER_ID_SUCCESS: "GET_PROFILE_FROM_USER_ID_SUCCESS",
    GET_PROFILE_FROM_USER_ID_FAILURE: "GET_PROFILE_FROM_USER_ID_FAILURE",

    UPDATE_PROFILE_LOADING: "UPDATE_PROFILE_LOADING",
    UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
    UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",

    GET_TIME_RESEARCH_BY_PROFILE_ID_LOADING:
      "GET_TIME_RESEARCH_BY_PROFILE_ID_LOADING",
    GET_TIME_RESEARCH_BY_PROFILE_ID_SUCCESS:
      "GET_TIME_RESEARCH_BY_PROFILE_ID_SUCCESS",
    GET_TIME_RESEARCH_BY_PROFILE_ID_FAILURE:
      "GET_TIME_RESEARCH_BY_PROFILE_ID_FAILURE",

    FILTER_TIME_RESEARCH_LOADING: "FILTER_TIME_RESEARCH_LOADING",
    FILTER_TIME_RESEARCH_SUCCESS: "FILTER_TIME_RESEARCH_SUCCESS",
    FILTER_TIME_RESEARCH_FAILURE: "FILTER_TIME_RESEARCH_FAILURE",

    FILTER_TIME_RESEARCH_LOADINGUPDATE_TIME_RESEARCH_INFORMATION_LOADING:
      "UPDATE_TIME_RESEARCH_INFORMATION_LOADING",
    UPDATE_TIME_RESEARCH_INFORMATION_SUCCESS:
      "UPDATE_TIME_RESEARCH_INFORMATION_SUCCESS",
    UPDATE_TIME_RESEARCH_INFORMATION_FAILURE:
      "UPDATE_TIME_RESEARCH_INFORMATION_FAILURE",

    GET_PROFILE_INFO_BY_PROFILE_ID_LOADING:
      "GET_PROFILE_INFO_BY_PROFILE_ID_LOADING",
    GET_PROFILE_INFO_BY_PROFILE_ID_SUCCESS:
      "GET_PROFILE_INFO_BY_PROFILE_ID_SUCCESS",
    GET_PROFILE_INFO_BY_PROFILE_ID_FAILURE:
      "GET_PROFILE_INFO_BY_PROFILE_ID_FAILURE",

    GET_CONTRACT_BY_PROFILE_ID_LOADING: "GET_CONTRACT_BY_PROFILE_ID_LOADING",
    GET_CONTRACT_BY_PROFILE_ID_SUCCESS: "GET_CONTRACT_BY_PROFILE_ID_SUCCESS",
    GET_CONTRACT_BY_PROFILE_ID_FAILURE: "GET_CONTRACT_BY_PROFILE_ID_FAILURE",

    STATISTIC_UNACCEPTED_LOADING: "STATISTIC_UNACCEPTED_LOADING",
    STATISTIC_UNACCEPTED_SUCCESS: "STATISTIC_UNACCEPTED_SUCCESS",
    STATISTIC_UNACCEPTED_FAILURE: "STATISTIC_UNACCEPTED_FAILURE",

    GET_PROF_SCORE_LOADING: "GET_PROF_SCORE_LOADING",
    GET_PROF_SCORE_SUCCESS: "GET_PROF_SCORE_SUCCESS",
    GET_PROF_SCORE_FAILURE: "GET_PROF_SCORE_FAILURE",
  },

  KPI: {
    UPDATE_KPI_BY_ID_LOADING: "UPDATE_KPI_BY_ID_LOADING",
    UPDATE_KPI_BY_ID_SUCCESS: "UPDATE_KPI_BY_ID_SUCCESS",
    UPDATE_KPI_BY_ID_FAILURE: "UPDATE_KPI_BY_ID_FAILURE",
  },

  CONFERENCE: {
    GET_ALL_CONFERENCE_LOADING: "GET_ALL_CONFERENCE_LOADING",
    GET_ALL_CONFERENCE_SUCCESS: "GET_ALL_CONFERENCE_SUCCESS",
    GET_ALL_CONFERENCE_FAILURE: "GET_ALL_CONFERENCE_FAILURE",

    INSERT_CONFERENCE_LOADING: "INSERT_CONFERENCE_LOADING",
    INSERT_CONFERENCE_SUCCESS: "INSERT_CONFERENCE_SUCCESS",
    INSERT_CONFERENCE_FAILURE: "INSERT_CONFERENCE_FAILURE",

    UPDATE_CONFERENCE_LOADING: "UPDATE_CONFERENCE_LOADING",
    UPDATE_CONFERENCE_SUCCESS: "UPDATE_CONFERENCE_SUCCESS",
    UPDATE_CONFERENCE_FAILURE: "UPDATE_CONFERENCE_FAILURE",

    DELETE_CONFERENCE_LOADING: "DELETE_CONFERENCE_LOADING",
    DELETE_CONFERENCE_SUCCESS: "DELETE_CONFERENCE_SUCCESS",
    DELETE_CONFERENCE_FAILURE: "DELETE_CONFERENCE_FAILURE",

    IMPORT_CONFERENCE_BY_YEAR_LOADING: "IMPORT_CONFERENCE_BY_YEAR_LOADING",
    IMPORT_CONFERENCE_BY_YEAR_SUCCESS: "IMPORT_CONFERENCE_BY_YEAR_SUCCESS",
    IMPORT_CONFERENCE_BY_YEAR_FAILURE: "IMPORT_CONFERENCE_BY_YEAR_FAILURE",
  },

  DEPARTMENT: {
    GET_ALL_DEPARTMENTS_LOADING: "GET_ALL_DEPARTMENTS_LOADING",
    GET_ALL_DEPARTMENTS_SUCCESS: "GET_ALL_DEPARTMENTS_SUCCESS",
    GET_ALL_DEPARTMENTS_FAILURE: "GET_ALL_DEPARTMENTS_FAILURE",

    GET_ALL_FACULTIES_LOADING: "GET_ALL_FACULTIES_LOADING",
    GET_ALL_FACULTIES_SUCCESS: "GET_ALL_FACULTIES_SUCCESS",
    GET_ALL_FACULTIES_FAILURE: "GET_ALL_FACULTIES_FAILURE",

    GET_ALL_VISIBLE_DEPARMENTS_BY_PROFILE_ID_LOADING:
      "GET_ALL_VISIBLE_DEPARMENTS_BY_PROFILE_ID_LOADING",
    GET_ALL_VISIBLE_DEPARMENTS_BY_PROFILE_ID_SUCCESS:
      "GET_ALL_VISIBLE_DEPARMENTS_BY_PROFILE_ID_SUCCESS",
    GET_ALL_VISIBLE_DEPARMENTS_BY_PROFILE_ID_FAILURE:
      "GET_ALL_VISIBLE_DEPARMENTS_BY_PROFILE_ID_FAILURE",

    INSERT_DEPARTMENT_LOADING: "INSERT_DEPARTMENT_LOADING",
    INSERT_DEPARTMENT_SUCCESS: "INSERT_DEPARTMENT_SUCCESS",
    INSERT_DEPARTMENT_FAILURE: "INSERT_DEPARTMENT_FAILURE",

    UPDATE_DEPARTMENT_LOADING: "UPDATE_DEPARTMENT_LOADING",
    UPDATE_DEPARTMENT_SUCCESS: "UPDATE_DEPARTMENT_SUCCESS",
    UPDATE_DEPARTMENT_FAILURE: "UPDATE_DEPARTMENT_FAILURE",

    DELETE_DEPARTMENT_LOADING: "DELETE_DEPARTMENT_LOADING",
    DELETE_DEPARTMENT_SUCCESS: "DELETE_DEPARTMENT_SUCCESS",
    DELETE_DEPARTMENT_FAILURE: "DELETE_DEPARTMENT_FAILURE",

    GET_ALL_PROFILE_LOADING: "GET_ALL_PROFILE_LOADING",
    GET_ALL_PROFILE_SUCCESS: "GET_ALL_PROFILE_SUCCESS",
    GET_ALL_PROFILE_FAILURE: "GET_ALL_PROFILE_FAILURE",

    GET_PROFILE_BY_DEPARTMENT_ID_LOADING:
      "GET_PROFILE_BY_DEPARTMENT_ID_LOADING",
    GET_PROFILE_BY_DEPARTMENT_ID_SUCCESS:
      "GET_PROFILE_BY_DEPARTMENT_ID_SUCCESS",
    GET_PROFILE_BY_DEPARTMENT_ID_FAILURE:
      "GET_PROFILE_BY_DEPARTMENT_ID_FAILURE",

    INSERT_DEPARTMENT_PROFILE_LOADING: "INSERT_DEPARTMENT_PROFILE_LOADING",
    INSERT_DEPARTMENT_PROFILE_SUCCESS: "INSERT_DEPARTMENT_PROFILE_SUCCESS",
    INSERT_DEPARTMENT_PROFILE_FAILURE: "INSERT_DEPARTMENT_PROFILE_FAILURE",

    DELETE_DEPARTMENT_PROFILE_LOADING: "DELETE_DEPARTMENT_PROFILE_LOADING",
    DELETE_DEPARTMENT_PROFILE_SUCCESS: "DELETE_DEPARTMENT_PROFILE_SUCCESS",
    DELETE_DEPARTMENT_PROFILE_FAILURE: "DELETE_DEPARTMENT_PROFILE_FAILURE",

    UPDATE_DEPARTMENT_PROFILE_LOADING: "UPDATE_DEPARTMENT_PROFILE_LOADING",
    UPDATE_DEPARTMENT_PROFILE_SUCCESS: "UPDATE_DEPARTMENT_PROFILE_SUCCESS",
    UPDATE_DEPARTMENT_PROFILE_FAILURE: "UPDATE_DEPARTMENT_PROFILE_FAILURE",
  },
  TIME_RESEARCH_INFORMATION: {
    GET_ALL_TIME_RESEARCH_INFORMATION_LOADING:
      "GET_ALL_TIME_RESEARCH_INFORMATION_LOADING",
    GET_ALL_TIME_RESEARCH_INFORMATION_SUCCESS:
      "GET_ALL_TIME_RESEARCH_INFORMATION_SUCCESS",
    GET_ALL_TIME_RESEARCH_INFORMATION_FAILURE:
      "GET_ALL_TIME_RESEARCH_INFORMATION_FAILURE",

    GET_TIME_RESEARCH_INFORMATION_LOADING:
      "GET_TIME_RESEARCH_INFORMATION_LOADING",
    GET_TIME_RESEARCH_INFORMATION_SUCCESS:
      "GET_TIME_RESEARCH_INFORMATION_SUCCESS",
    GET_TIME_RESEARCH_INFORMATION_FAILURE:
      "GET_TIME_RESEARCH_INFORMATION_FAILURE",

    GET_TIME_RESEARCH_STATISTIC_LOADING: "GET_TIME_RESEARCH_STATISTIC_LOADING",
    GET_TIME_RESEARCH_STATISTIC_SUCCESS: "GET_TIME_RESEARCH_STATISTIC_SUCCESS",
    GET_TIME_RESEARCH_STATISTIC_FAILURE: "GET_TIME_RESEARCH_STATISTIC_FAILURE",

    GET_TIME_RESEARCH_INFORMATION_BY_ID_LOADING:
      "GET_TIME_RESEARCH_INFORMATION_BY_ID_LOADING",
    GET_TIME_RESEARCH_INFORMATION_BY_ID_SUCCESS:
      "GET_TIME_RESEARCH_INFORMATION_BY_ID_SUCCESS",
    GET_TIME_RESEARCH_INFORMATION_BY_ID_FAILURE:
      "GET_TIME_RESEARCH_INFORMATION_BY_ID_FAILURE",

    INSERT_TIME_RESEARCH_INFORMATION_LOADING:
      "INSERT_TIME_RESEARCH_INFORMATION_LOADING",
    INSERT_TIME_RESEARCH_INFORMATION_SUCCESS:
      "INSERT_TIME_RESEARCH_INFORMATION_SUCCESS",
    INSERT_TIME_RESEARCH_INFORMATION_FAILURE:
      "INSERT_TIME_RESEARCH_INFORMATION_FAILURE",

    UPDATE_TIME_RESEARCH_INFORMATION_LOADING:
      "TIME_RESEARCH__UPDATE_TIME_RESEARCH_INFORMATION_LOADING",
    UPDATE_TIME_RESEARCH_INFORMATION_SUCCESS:
      "TIME_RESEARCH__UPDATE_TIME_RESEARCH_INFORMATION_SUCCESS",
    UPDATE_TIME_RESEARCH_INFORMATION_FAILURE:
      "TIME_RESEARCH__UPDATE_TIME_RESEARCH_INFORMATION_FAILURE",

    DELETE_TIME_RESEARCH_INFORMATION_LOADING:
      "DELETE_TIME_RESEARCH_INFORMATION_LOADING",
    DELETE_TIME_RESEARCH_INFORMATION_SUCCESS:
      "DELETE_TIME_RESEARCH_INFORMATION_SUCCESS",
    DELETE_TIME_RESEARCH_INFORMATION_FAILURE:
      "DELETE_TIME_RESEARCH_INFORMATION_FAILURE",

    CALCULATE_TIME_RESEARCH_LOADING: "CALCULATE_TIME_RESEARCH_LOADING",
    CALCULATE_TIME_RESEARCH_SUCCESS: "CALCULATE_TIME_RESEARCH_SUCCESS",
    CALCULATE_TIME_RESEARCH_FAILURE: "CALCULATE_TIME_RESEARCH_FAILURE",

    CALCULATE_TIME_RESEARCH_ALL_PROFILE_LOADING:
      "CALCULATE_TIME_RESEARCH_ALL_PROFILE_LOADING",
    CALCULATE_TIME_RESEARCH_ALL_PROFILE_SUCCESS:
      "CALCULATE_TIME_RESEARCH_ALL_PROFILE_SUCCESS",
    CALCULATE_TIME_RESEARCH_ALL_PROFILE_FAILURE:
      "CALCULATE_TIME_RESEARCH_ALL_PROFILE_FAILURE",

    CREATE_TIME_RESEARCH_INFORMATION_FROM_SAMPLE_LOADING:
      "CREATE_TIME_RESEARCH_INFORMATION_FROM_SAMPLE_LOADING",
    CREATE_TIME_RESEARCH_INFORMATION_FROM_SAMPLE_SUCCESS:
      "CREATE_TIME_RESEARCH_INFORMATION_FROM_SAMPLE_SUCCESS",
    CREATE_TIME_RESEARCH_INFORMATION_FROM_SAMPLE_FAILURE:
      "CREATE_TIME_RESEARCH_INFORMATION_FROM_SAMPLE_FAILURE",

    LOCK_TIME_RESEARCH_LOADING: "LOCK_TIME_RESEARCH_LOADING",
    LOCK_TIME_RESEARCH_SUCCESS: "LOCK_TIME_RESEARCH_SUCCESS",
    LOCK_TIME_RESEARCH_FAILURE: "LOCK_TIME_RESEARCH_FAILURE",

    LOCK_ALL_TIME_RESEARCH_LOADING: "LOCK_ALL_TIME_RESEARCH_LOADING",
    LOCK_ALL_TIME_RESEARCH_SUCCESS: "LOCK_ALL_TIME_RESEARCH_SUCCESS",
    LOCK_ALL_TIME_RESEARCH_FAILURE: "LOCK_ALL_TIME_RESEARCH_FAILURE",
  },
  REWARD_INFORMATION: {
    GET_REWARD_INFORMATION_LOADING: "GET_REWARD_INFORMATION_LOADING",
    GET_REWARD_INFORMATION_SUCCESS: "GET_REWARD_INFORMATION_SUCCESS",
    GET_REWARD_INFORMATION_FAILURE: "GET_REWARD_INFORMATION_FAILURE",

    UPDATE_REWARD_INFORMATION_LOADING: "UPDATE_REWARD_INFORMATION_LOADING",
    UPDATE_REWARD_INFORMATION_SUCCESS: "UPDATE_REWARD_INFORMATION_SUCCESS",
    UPDATE_REWARD_INFORMATION_FAILURE: "UPDATE_REWARD_INFORMATION_FAILURE",

    GET_REWARD_INFORMATION_V2_LOADING: "GET_REWARD_INFORMATION_V2_LOADING",
    GET_REWARD_INFORMATION_V2_SUCCESS: "GET_REWARD_INFORMATION_V2_SUCCESS",
    GET_REWARD_INFORMATION_V2_FAILURE: "GET_REWARD_INFORMATION_V2_FAILURE",

    UPDATE_REWARD_INFORMATION_V2_LOADING:
      "UPDATE_REWARD_INFORMATION_V2_LOADING",
    UPDATE_REWARD_INFORMATION_V2_SUCCESS:
      "UPDATE_REWARD_INFORMATION_V2_SUCCESS",
    UPDATE_REWARD_INFORMATION_V2_FAILURE:
      "UPDATE_REWARD_INFORMATION_V2_FAILURE",
  },
  RANK_TABLE: {
    GET_RANK_TABLE_LOADING: "GET_RANK_TABLE_LOADING",
    GET_RANK_TABLE_SUCCESS: "GET_RANK_TABLE_SUCCESS",
    GET_RANK_TABLE_FAILURE: "GET_RANK_TABLE_FAILURE",

    UPDATE_RANK_TABLE_LOADING: "UPDATE_RANK_TABLE_LOADING",
    UPDATE_RANK_TABLE_SUCCESS: "UPDATE_RANK_TABLE_SUCCESS",
    UPDATE_RANK_TABLE_FAILURE: "UPDATE_RANK_TABLE_FAILURE",
  },
  EARNINGS_TABLE: {
    GET_EARNINGS_TABLE_LOADING: "GET_EARNINGS_TABLE_LOADING",
    GET_EARNINGS_TABLE_SUCCESS: "GET_EARNINGS_TABLE_SUCCESS",
    GET_EARNINGS_TABLE_FAILURE: "GET_EARNINGS_TABLE_FAILURE",

    UPDATE_EARNINGS_TABLE_LOADING: "UPDATE_EARNINGS_TABLE_LOADING",
    UPDATE_EARNINGS_TABLE_SUCCESS: "UPDATE_EARNINGS_TABLE_SUCCESS",
    UPDATE_EARNINGS_TABLE_FAILURE: "UPDATE_EARNINGS_TABLE_FAILURE",
  },
  STATISTIC: {
    GET_FINISH_CONTRACT_STATISTIC_LOADING:
      "GET_FINISH_CONTRACT_STATISTIC_LOADING",
    GET_FINISH_CONTRACT_STATISTIC_SUCCESS:
      "GET_FINISH_CONTRACT_STATISTIC_SUCCESS",
    GET_FINISH_CONTRACT_STATISTIC_FAILURE:
      "GET_FINISH_CONTRACT_STATISTIC_FAILURE",

    GET_REVIEW_CONTRACT_STATISTIC_LOADING:
      "GET_REVIEW_CONTRACT_STATISTIC_LOADING",
    GET_REVIEW_CONTRACT_STATISTIC_SUCCESS:
      "GET_REVIEW_CONTRACT_STATISTIC_SUCCESS",
    GET_REVIEW_CONTRACT_STATISTIC_FAILURE:
      "GET_REVIEW_CONTRACT_STATISTIC_FAILURE",

    GET_SCIENTIFIC_RESEARCH_STATISTIC_LOADING:
      "GET_SCIENTIFIC_RESEARCH_STATISTIC_LOADING",
    GET_SCIENTIFIC_RESEARCH_STATISTIC_SUCCESS:
      "GET_SCIENTIFIC_RESEARCH_STATISTIC_SUCCESS",
    GET_SCIENTIFIC_RESEARCH_STATISTIC_FAILURE:
      "GET_SCIENTIFIC_RESEARCH_STATISTIC_FAILURE",

    GET_CTKH_STATISTIC_LOADING: "GET_CTKH_STATISTIC_LOADING",
    GET_CTKH_STATISTIC_SUCCESS: "GET_CTKH_STATISTIC_SUCCESS",
    GET_CTKH_STATISTIC_FAILURE: "GET_CTKH_STATISTIC_FAILURE",

    GET_REWARD_CONTRACT_STATISTIC_LOADING:
      "GET_REWARD_CONTRACT_STATISTIC_LOADING",
    GET_REWARD_CONTRACT_STATISTIC_SUCCESS:
      "GET_REWARD_CONTRACT_STATISTIC_SUCCESS",
    GET_REWARD_CONTRACT_STATISTIC_FAILURE:
      "GET_REWARD_CONTRACT_STATISTIC_FAILURE",

    UPDATE_KPI_COMPLETED_LOADING: "UPDATE_KPI_COMPLETED_LOADING",
    UPDATE_KPI_COMPLETED_SUCCESS: "UPDATE_KPI_COMPLETED_SUCCESS",
    UPDATE_KPI_COMPLETED_FAILURE: "UPDATE_KPI_COMPLETED_FAILURE",

    GET_REWARD_CONTRACT_BY_YEAR_LOADING: "GET_REWARD_CONTRACT_BY_YEAR_LOADING",
    GET_REWARD_CONTRACT_BY_YEAR_SUCCESS: "GET_REWARD_CONTRACT_BY_YEAR_SUCCESS",
    GET_REWARD_CONTRACT_BY_YEAR_FAILURE: "GET_REWARD_CONTRACT_BY_YEAR_FAILURE",
  },

  NOTIFICATION: {
    SEND_TIMER_NOTIFICATION_LOADING: "SEND_TIMER_NOTIFICATION_LOADING",
    SEND_TIMER_NOTIFICATION_SUCCESS: "SEND_TIMER_NOTIFICATION_SUCCESS",
    SEND_TIMER_NOTIFICATION_FAILURE: "SEND_TIMER_NOTIFICATION_FAILURE",

    SEND_EMAIL_LOADING: "SEND_EMAIL_LOADING",
    SEND_EMAIL_SUCCESS: "SEND_EMAIL_SUCCESS",
    SEND_EMAIL_FAILURE: "SEND_EMAIL_FAILURE",
  },

  REWARD: {
    GET_FILTERED_PROFILE_REWARD_LOADING: "GET_FILTERED_PROFILE_REWARD_LOADING",
    GET_FILTERED_PROFILE_REWARD_SUCCESS: "GET_FILTERED_PROFILE_REWARD_SUCCESS",
    GET_FILTERED_PROFILE_REWARD_FAILURE: "GET_FILTERED_PROFILE_REWARD_FAILURE",

    CAL_PROFILE_REWARD_LOADING: "CAL_PROFILE_REWARD_LOADING",
    CAL_PROFILE_REWARD_SUCCESS: "CAL_PROFILE_REWARD_SUCCESS",
    CAL_PROFILE_REWARD_FAILURE: "CAL_PROFILE_REWARD_FAILURE",

    CAL_ALL_PROFILE_REWARD_LOADING: "CAL_ALL_PROFILE_REWARD_LOADING",
    CAL_ALL_PROFILE_REWARD_SUCCESS: "CAL_ALL_PROFILE_REWARD_SUCCESS",
    CAL_ALL_PROFILE_REWARD_FAILURE: "CAL_ALL_PROFILE_REWARD_FAILURE",

    DELETE_PROFILE_REWARD_LOADING: "DELETE_PROFILE_REWARD_LOADING",
    DELETE_PROFILE_REWARD_SUCCESS: "DELETE_PROFILE_REWARD_SUCCESS",
    DELETE_PROFILE_REWARD_FAILURE: "DELETE_PROFILE_REWARD_FAILURE",

    ACCEPT_PROFILE_REWARD_LOADING: "ACCEPT_PROFILE_REWARD_LOADING",
    ACCEPT_PROFILE_REWARD_SUCCESS: "ACCEPT_PROFILE_REWARD_SUCCESS",
    ACCEPT_PROFILE_REWARD_FAILURE: "ACCEPT_PROFILE_REWARD_FAILURE",

    LOCK_PROFILE_REWARD_LOADING: "LOCK_PROFILE_REWARD_LOADING",
    LOCK_PROFILE_REWARD_SUCCESS: "LOCK_PROFILE_REWARD_SUCCESS",
    LOCK_PROFILE_REWARD_FAILURE: "LOCK_PROFILE_REWARD_FAILURE",

    LOCK_ALL_PROFILE_REWARD_LOADING: "LOCK_ALL_PROFILE_REWARD_LOADING",
    LOCK_ALL_PROFILE_REWARD_SUCCESS: "LOCK_ALL_PROFILE_REWARD_SUCCESS",
    LOCK_ALL_PROFILE_REWARD_FAILURE: "LOCK_ALL_PROFILE_REWARD_FAILURE",
  },

  STUDENT_RESEARCH: {
    GET_ALL_STUDENT_RESEARCH_LOADING: "GET_ALL_STUDENT_RESEARCH_LOADING",
    GET_ALL_STUDENT_RESEARCH_SUCCESS: "GET_ALL_STUDENT_RESEARCH_SUCCESS",
    GET_ALL_STUDENT_RESEARCH_FAILURE: "GET_ALL_STUDENT_RESEARCH_FAILURE",

    INSERT_STUDENT_RESEARCH_LOADING: "INSERT_STUDENT_RESEARCH_LOADING",
    INSERT_STUDENT_RESEARCH_SUCCESS: "INSERT_STUDENT_RESEARCH_SUCCESS",
    INSERT_STUDENT_RESEARCH_FAILURE: "INSERT_STUDENT_RESEARCH_FAILURE",

    INSERT_STUDENT_GUIDANCE_LOADING: "INSERT_STUDENT_GUIDANCE_LOADING",
    INSERT_STUDENT_GUIDANCE_SUCCESS: "INSERT_STUDENT_GUIDANCE_SUCCESS",
    INSERT_STUDENT_GUIDANCE_FAILURE: "INSERT_STUDENT_GUIDANCE_FAILURE",

    UPDATE_STUDENT_GUIDANCE_LOADING: "UPDATE_STUDENT_GUIDANCE_LOADING",
    UPDATE_STUDENT_GUIDANCE_SUCCESS: "UPDATE_STUDENT_GUIDANCE_SUCCESS",
    UPDATE_STUDENT_GUIDANCE_FAILURE: "UPDATE_STUDENT_GUIDANCE_FAILURE",

    DELETE_STUDENT_GUIDANCE_LOADING: "DELETE_STUDENT_GUIDANCE_LOADING",
    DELETE_STUDENT_GUIDANCE_SUCCESS: "DELETE_STUDENT_GUIDANCE_SUCCESS",
    DELETE_STUDENT_GUIDANCE_FAILURE: "DELETE_STUDENT_GUIDANCE_FAILURE",

    UPDATE_STUDENT_RESEARCH_STATUS_LOADING:
      "UPDATE_STUDENT_RESEARCH_STATUS_LOADING",
    UPDATE_STUDENT_RESEARCH_STATUS_SUCCESS:
      "UPDATE_STUDENT_RESEARCH_STATUS_SUCCESS",
    UPDATE_STUDENT_RESEARCH_STATUS_FAILURE:
      "UPDATE_STUDENT_RESEARCH_STATUS_FAILURE",

    UPDATE_STUDENT_RESEARCH_LOADING: "UPDATE_STUDENT_RESEARCH_LOADING",
    UPDATE_STUDENT_RESEARCH_SUCCESS: "UPDATE_STUDENT_RESEARCH_SUCCESS",
    UPDATE_STUDENT_RESEARCH_FAILURE: "UPDATE_STUDENT_RESEARCH_FAILURE",

    FILTER_STUDENT_RESEARCH_LOADING: "FILTER_STUDENT_RESEARCH_LOADING",
    FILTER_STUDENT_RESEARCH_SUCCESS: "FILTER_STUDENT_RESEARCH_SUCCESS",
    FILTER_STUDENT_RESEARCH_FAILURE: "FILTER_STUDENT_RESEARCH_FAILURE",

    DELETE_STUDENT_RESEARCH_LOADING: "DELETE_STUDENT_RESEARCH_LOADING",
    DELETE_STUDENT_RESEARCH_SUCCESS: "DELETE_STUDENT_RESEARCH_SUCCESS",
    DELETE_STUDENT_RESEARCH_FAILURE: "DELETE_STUDENT_RESEARCH_FAILURE",
  },

  COUNCIL: {
    GET_ALL_COUNCIL_LOADING: "GET_ALL_COUNCIL_LOADING",
    GET_ALL_COUNCIL_SUCCESS: "GET_ALL_COUNCIL_SUCCESS",
    GET_ALL_COUNCIL_FAILURE: "GET_ALL_COUNCIL_FAILURE",

    FILTER_COUNCIL_LOADING: "FILTER_COUNCIL_LOADING",
    FILTER_COUNCIL_SUCCESS: "FILTER_COUNCIL_SUCCESS",
    FILTER_COUNCIL_FAILURE: "FILTER_COUNCIL_FAILURE",

    INSERT_COUNCIL_LOADING: "INSERT_COUNCIL_LOADING",
    INSERT_COUNCIL_SUCCESS: "INSERT_COUNCIL_SUCCESS",
    INSERT_COUNCIL_FAILURE: "INSERT_COUNCIL_FAILURE",

    UPDATE_COUNCIL_LOADING: "UPDATE_COUNCIL_LOADING",
    UPDATE_COUNCIL_SUCCESS: "UPDATE_COUNCIL_SUCCESS",
    UPDATE_COUNCIL_FAILURE: "UPDATE_COUNCIL_FAILURE",

    DELETE_COUNCIL_LOADING: "DELETE_COUNCIL_LOADING",
    DELETE_COUNCIL_SUCCESS: "DELETE_COUNCIL_SUCCESS",
    DELETE_COUNCIL_FAILURE: "DELETE_COUNCIL_FAILURE",

    GET_ALL_INTERNAL_COUNCIL_LOADING: "GET_ALL_INTERNAL_COUNCIL_LOADING",
    GET_ALL_INTERNAL_COUNCIL_SUCCESS: "GET_ALL_INTERNAL_COUNCIL_SUCCESS",
    GET_ALL_INTERNAL_COUNCIL_FAILURE: "GET_ALL_INTERNAL_COUNCIL_FAILURE",

    FILTER_INTERNAL_COUNCIL_LOADING: "FILTER_INTERNAL_COUNCIL_LOADING",
    FILTER_INTERNAL_COUNCIL_SUCCESS: "FILTER_INTERNAL_COUNCIL_SUCCESS",
    FILTER_INTERNAL_COUNCIL_FAILURE: "FILTER_INTERNAL_COUNCIL_FAILURE",

    INSERT_INTERNAL_COUNCIL_LOADING: "INSERT_INTERNAL_COUNCIL_LOADING",
    INSERT_INTERNAL_COUNCIL_SUCCESS: "INSERT_INTERNAL_COUNCIL_SUCCESS",
    INSERT_INTERNAL_COUNCIL_FAILURE: "INSERT_INTERNAL_COUNCIL_FAILURE",

    UPDATE_ALL_INTERNAL_COUNCIL_LOADING: "UPDATE_ALL_INTERNAL_COUNCIL_LOADING",
    UPDATE_ALL_INTERNAL_COUNCIL_SUCCESS: "UPDATE_ALL_INTERNAL_COUNCIL_SUCCESS",
    UPDATE_ALL_INTERNAL_COUNCIL_FAILURE: "UPDATE_ALL_INTERNAL_COUNCIL_FAILURE",
  },

  INTERNAL_RESEARCH: {
    GET_ALL_INTERNAL_RESEARCH_LOADING: "GET_ALL_INTERNAL_RESEARCH_LOADING",
    GET_ALL_INTERNAL_RESEARCH_SUCCESS: "GET_ALL_INTERNAL_RESEARCH_SUCCESS",
    GET_ALL_INTERNAL_RESEARCH_FAILURE: "GET_ALL_INTERNAL_RESEARCH_FAILURE",

    INSERT_INTERNAL_RESEARCH_LOADING: "INSERT_INTERNAL_RESEARCH_LOADING",
    INSERT_INTERNAL_RESEARCH_SUCCESS: "INSERT_INTERNAL_RESEARCH_SUCCESS",
    INSERT_INTERNAL_RESEARCH_FAILURE: "INSERT_INTERNAL_RESEARCH_FAILURE",

    UPDATE_INTERNAL_RESEARCH_LOADING: "UPDATE_INTERNAL_RESEARCH_LOADING",
    UPDATE_INTERNAL_RESEARCH_SUCCESS: "UPDATE_INTERNAL_RESEARCH_SUCCESS",
    UPDATE_INTERNAL_RESEARCH_FAILURE: "UPDATE_INTERNAL_RESEARCH_FAILURE",

    UPDATE_INTERNAL_RESEARCH_STATUS_LOADING:
      "UPDATE_INTERNAL_RESEARCH_STATUS_LOADING",
    UPDATE_INTERNAL_RESEARCH_STATUS_SUCCESS:
      "UPDATE_INTERNAL_RESEARCH_STATUS_SUCCESS",
    UPDATE_INTERNAL_RESEARCH_STATUS_FAILURE:
      "UPDATE_INTERNAL_RESEARCH_STATUS_FAILURE",

    DELETE_INTERNAL_RESEARCH_LOADING: "DELETE_INTERNAL_RESEARCH_LOADING",
    DELETE_INTERNAL_RESEARCH_SUCCESS: "DELETE_INTERNAL_RESEARCH_SUCCESS",
    DELETE_INTERNAL_RESEARCH_FAILURE: "DELETE_INTERNAL_RESEARCH_FAILURE",

    FILTER_INTERNAL_RESEARCH_LOADING: "FILTER_INTERNAL_RESEARCH_LOADING",
    FILTER_INTERNAL_RESEARCH_SUCCESS: "FILTER_INTERNAL_RESEARCH_SUCCESS",
    FILTER_INTERNAL_RESEARCH_FAILURE: "FILTER_INTERNAL_RESEARCH_FAILURE",
  },

  INTELLECTUAL_PROPERTY: {
    GET_ALL_INTELLECTUAL_PROPERTY_LOADING:
      "GET_ALL_INTELLECTUAL_PROPERTY_LOADING",
    GET_ALL_INTELLECTUAL_PROPERTY_SUCCESS:
      "GET_ALL_INTELLECTUAL_PROPERTY_SUCCESS",
    GET_ALL_INTELLECTUAL_PROPERTY_FAILURE:
      "GET_ALL_INTELLECTUAL_PROPERTY_FAILURE",

    INSERT_INTELLECTUAL_PROPERTY_LOADING:
      "INSERT_INTELLECTUAL_PROPERTY_LOADING",
    INSERT_INTELLECTUAL_PROPERTY_SUCCESS:
      "INSERT_INTELLECTUAL_PROPERTY_SUCCESS",
    INSERT_INTELLECTUAL_PROPERTY_FAILURE:
      "INSERT_INTELLECTUAL_PROPERTY_FAILURE",

    UPDATE_INTELLECTUAL_PROPERTY_LOADING:
      "UPDATE_INTELLECTUAL_PROPERTY_LOADING",
    UPDATE_INTELLECTUAL_PROPERTY_SUCCESS:
      "UPDATE_INTELLECTUAL_PROPERTY_SUCCESS",
    UPDATE_INTELLECTUAL_PROPERTY_FAILURE:
      "UPDATE_INTELLECTUAL_PROPERTY_FAILURE",

    DELETE_INTELLECTUAL_PROPERTY_LOADING:
      "DELETE_INTELLECTUAL_PROPERTY_LOADING",
    DELETE_INTELLECTUAL_PROPERTY_SUCCESS:
      "DELETE_INTELLECTUAL_PROPERTY_SUCCESS",
    DELETE_INTELLECTUAL_PROPERTY_FAILURE:
      "DELETE_INTELLECTUAL_PROPERTY_FAILURE",

    GET_ALL_FEE_TYPE_LOADING: "GET_ALL_FEE_TYPE_LOADING",
    GET_ALL_FEE_TYPE_SUCCESS: "GET_ALL_FEE_TYPE_SUCCESS",
    GET_ALL_FEE_TYPE_FAILURE: "GET_ALL_FEE_TYPE_FAILURE",

    UPDATE_APPROVE_INTELLECTUAL_PROPERTY_LOADING:
      "UPDATE_APPROVE_INTELLECTUAL_PROPERTY_LOADING",
    UPDATE_APPROVE_INTELLECTUAL_PROPERTY_SUCCESS:
      "UPDATE_APPROVE_INTELLECTUAL_PROPERTY_SUCCESS",
    UPDATE_APPROVE_INTELLECTUAL_PROPERTY_FAILURE:
      "UPDATE_INTELLECTUAL_PROPERTY_FAILURE",
  },
};

export default TYPE;
