import { fromJS } from "immutable";

import TYPE from "../../constants/type";

const initialState = fromJS({
  list: [],
  typeFilter: 1,
  isFetching: false,
});

export default function earningsTableReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.EARNINGS_TABLE.GET_EARNINGS_TABLE_LOADING:
    case TYPE.EARNINGS_TABLE.UPDATE_EARNINGS_TABLE_LOADING:
      return state.merge({
        isFetching: true,
      });

    case TYPE.EARNINGS_TABLE.GET_EARNINGS_TABLE_FAILURE:
    case TYPE.EARNINGS_TABLE.UPDATE_EARNINGS_TABLE_FAILURE:
      return state.merge({
        isFetching: false,
      });

    case TYPE.EARNINGS_TABLE.GET_EARNINGS_TABLE_SUCCESS:
      return state.merge({
        list: [...action.payload],
        isFetching: false,
      });

    case TYPE.EARNINGS_TABLE.UPDATE_EARNINGS_TABLE_SUCCESS: {
      const temp = state.get('list');
      const index = temp.find((e) => e.id === action.id);
      temp.splice(index, 1, action.payload);
      return state.merge({
        list: [...temp],
        isFetching: false,
      });
    }

    default:
      return state;
  }
}
