import { createContext, useState } from 'react';

/**
 *
 * @typedef {Object} Profile
 * @property {number?} Profile.id
 * @property {number} Profile.userId
 * @property {string} Profile.name
 * @property {string} Profile.code
 * @property {string} Profile.birth
 * @property {string} Profile.gender
 * @property {string} Profile.identityNumber
 * @property {string} Profile.address
 * @property {string} Profile.phone
 * @property {string} Profile.mail
 * @property {string} Profile.degree
 * @property {number} Profile.graduatedYearPhd
 * @property {string} Profile.profession
 * @property {string} Profile.major
 * @property {string} Profile.unit
 * @property {string} Profile.numGuideStudentSchool
 * @property {string} Profile.numGuideStudentMinistry
 *
 * @typedef {Object} EmploymentContract
 * @property {number} EmploymentContract.profileId
 * @property {boolean} EmploymentContract.active
 * @property {number} EmploymentContract.duration
 * @property {string} EmploymentContract.startAt
 * @property {string} EmploymentContract.finishAt
 * @property {Object} EmploymentContract.kpi
 * @property {number} EmploymentContract.kpi.id
 * @property {number} EmploymentContract.kpi.contractId
 * @property {number} EmploymentContract.kpi.numIsiQ1
 * @property {number} EmploymentContract.kpi.numIsiQ2
 * @property {number} EmploymentContract.kpi.numIsiQ3
 * @property {number} EmploymentContract.kpi.numIsiQ4
 * @property {number} EmploymentContract.kpi.numMonographEnglish
 * @property {number} EmploymentContract.kpi.numMonographOther
 * @property {number} EmploymentContract.kpi.numPatent
 * @property {number} EmploymentContract.kpi.numProductContract
 * @property {number} EmploymentContract.kpi.numScopus
 * @property {number} EmploymentContract.kpi.numSolution
 *
 * @typedef {Object} EmploymentContractInsertDataType
 * @property {number} EmploymentContractInsertDataType.currentStep
 * @property {React.SetStateAction<number>} EmploymentContractInsertDataType.setCurrentStep
 * @property {Profile} EmploymentContractInsertDataType.profile
 * @property {(profile: Profile | null) => any} EmploymentContractInsertDataType.setProfile
 */

/**
 * @type {React.Context<EmploymentContractInsertDataType | null | undefined>}
 */
export const EmploymentContractInsertContext = createContext();

/**
 *
 * @param {React.PropsWithChildren<{}>} props
 */
export const EmploymentContractInsertProvider = (props) => {
  /**
   * @type {[number, React.SetStateAction<number>]}
   */
  const [currentStep, setCurrentStep] = useState(0);

  /**
   * @type {[Profile | null | undefined, React.SetStateAction<Profile | null | undefined>]}
   */
  const [profile, setProfile] = useState(null);

  return (
    <EmploymentContractInsertContext.Provider
      value={{
        currentStep: currentStep,
        setCurrentStep: setCurrentStep,
        profile: profile,
        setProfile: setProfile,
      }}
    >
      {props.children}
    </EmploymentContractInsertContext.Provider>
  );
};
