import TYPE from '../../constants/type';
import { apiCall } from '../../utils/api';
import { notification } from '../home/action';
import { API_URLS } from '../../constants/api';
import { PREFIX } from '../../constants/enums';

export const getAllMonograph = () => async (dispatch) => {
  const api = API_URLS.MONOGRAPH.getAllMonograph();
  dispatch({
    type: TYPE.MONOGRAPH.GET_ALL_MONOGRAPH_LOADING,
    meta: { prefix: [PREFIX.MONOGRAPH, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.MONOGRAPH.GET_ALL_MONOGRAPH_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.MONOGRAPH, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.MONOGRAPH.GET_ALL_MONOGRAPH_FAILURE,
      meta: { prefix: [PREFIX.MONOGRAPH, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};

export const getFilteredMonograph = (page, pageSize, payload) => async (dispatch) => {
  const api = API_URLS.MONOGRAPH.getFilteredMonograph(page, pageSize);
  dispatch({
    type: TYPE.MONOGRAPH.GET_FILTERED_MONOGRAPH_LOADING,
    meta: { prefix: [PREFIX.MONOGRAPH, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.MONOGRAPH.GET_FILTERED_MONOGRAPH_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.MONOGRAPH, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.MONOGRAPH.GET_FILTERED_MONOGRAPH_FAILURE,
      meta: { prefix: [PREFIX.MONOGRAPH, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};

export const getAllMonographByProfileId = (id) => async (dispatch) => {
  const api = API_URLS.MONOGRAPH.getAllMonographByProfileId(id);
  dispatch({
    type: TYPE.MONOGRAPH.GET_ALL_MONOGRAPH_BY_PROFILE_ID_LOADING,
    meta: { prefix: [PREFIX.MONOGRAPH, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.MONOGRAPH.GET_ALL_MONOGRAPH_BY_PROFILE_ID_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.MONOGRAPH, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.MONOGRAPH.GET_ALL_MONOGRAPH_BY_PROFILE_ID_FAILURE,
      meta: { prefix: [PREFIX.MONOGRAPH, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Lấy dữ liệu thất bại!'
    }));
  }
};

export const insertMonograph = (profileId, payload) => async (dispatch) => {
  const api = API_URLS.MONOGRAPH.insertMonograph();
  dispatch({
    type: TYPE.MONOGRAPH.INSERT_MONOGRAPH_LOADING,
    meta: { prefix: [PREFIX.MONOGRAPH, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.MONOGRAPH.INSERT_MONOGRAPH_SUCCESS,
      id: profileId,
      payload: response.data.data,
      meta: { prefix: [PREFIX.MONOGRAPH, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Thêm dữ liệu thành công!'
    }));
    return ({success: true, data: response.data.data});
  } else {
    dispatch({
      type: TYPE.MONOGRAPH.INSERT_MONOGRAPH_FAILURE,
      meta: { prefix: [PREFIX.MONOGRAPH, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Thêm dữ liệu thất bại!'
    }));
    return ({success: false, data: null});
  }
};

export const updateMonograph = (id, payload) => async (dispatch) => {
  const api = API_URLS.MONOGRAPH.updateMonograph(id);
  dispatch({
    type: TYPE.MONOGRAPH.UPDATE_MONOGRAPH_LOADING,
    meta: { prefix: [PREFIX.MONOGRAPH, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.MONOGRAPH.UPDATE_MONOGRAPH_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.MONOGRAPH, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Cập nhật dữ liệu thành công!'
    }));
    return true;
  } else {
    dispatch({
      type: TYPE.MONOGRAPH.UPDATE_MONOGRAPH_FAILURE,
      meta: { prefix: [PREFIX.MONOGRAPH, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Cập nhật dữ liệu thất bại!'
    }));
    return false;
  }
};

export const deleteMonograph = (id) => async (dispatch) => {
  const api = API_URLS.MONOGRAPH.deleteMonograph(id);
  dispatch({
    type: TYPE.MONOGRAPH.DELETE_MONOGRAPH_LOADING,
    meta: { prefix: [PREFIX.MONOGRAPH, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.MONOGRAPH.DELETE_MONOGRAPH_SUCCESS,
      id,
      meta: { prefix: [PREFIX.MONOGRAPH, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Xoá dữ liệu thành công!'
    }));
    return true;
  } else {
    dispatch({
      type: TYPE.MONOGRAPH.DELETE_MONOGRAPH_FAILURE,
      meta: { prefix: [PREFIX.MONOGRAPH, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Xoá dữ liệu thất bại!'
    }));
    return false;
  }
};

export const acceptMonograph = (id, accept) => async (dispatch) => {
  const api = API_URLS.MONOGRAPH.acceptMonograph(id, accept);
  dispatch({
    type: TYPE.MONOGRAPH.ACCEPT_MONOGRAPH_LOADING,
    meta: { prefix: [PREFIX.MONOGRAPH, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.MONOGRAPH.ACCEPT_MONOGRAPH_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.MONOGRAPH, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({
      type: 'success',
      message: 'Xác nhận thành công!'
    }));
    return true;
  } else {
    dispatch({
      type: TYPE.MONOGRAPH.ACCEPT_MONOGRAPH_FAILURE,
      meta: { prefix: [PREFIX.MONOGRAPH, PREFIX.API_FAILURE] },
    });
    dispatch(notification({
      type: 'error',
      message: 'Xác nhận thất bại!'
    }));
    return false;
  }
};
