import { API_URLS } from "../../constants/api"
import TYPE from "../../constants/type"
import { apiCall } from "../../utils/api";

const getAllScientificCouncli = () => async dispatch => {
  dispatch({
    type: TYPE.SCIENTIFIC_COUNCLI.GET_SCIENTIFIC_COUNCLI_LOADING
  })

  const api = API_URLS.SCIENTIFIC_COUNCIL.getScientificCouncil();
  const { response, error } = await apiCall({ ...api });
  if(!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.SCIENTIFIC_COUNCLI.GET_SCIENTIFIC_COUNCLI_SUCCESS,
      payload: response.data.data
    });
  } else {
    dispatch({ type: TYPE.SCIENTIFIC_COUNCLI.GET_SCIENTIFIC_COUNCLI_FAILURE });
  }
}

const createAllScientificCouncli = (scientificCouncil) => async dispatch => {
  dispatch({
    type: TYPE.SCIENTIFIC_COUNCLI.CREATE_SCIENTIFIC_COUNCLI_LOADING
  })

  const api = API_URLS.SCIENTIFIC_COUNCIL.createScientificCouncil();
  const { response, error } = await apiCall({ ...api, payload: scientificCouncil });
  if(!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.SCIENTIFIC_COUNCLI.CREATE_SCIENTIFIC_COUNCLI_SUCCESS,
      payload: response.data.data
    });
    return true;
  } else {
    dispatch({ type: TYPE.SCIENTIFIC_COUNCLI.CREATE_SCIENTIFIC_COUNCLI_FAILURE });
  }
}

const updateAllScientificCouncli = (scientificCouncil) => async dispatch => {
  dispatch({
    type: TYPE.SCIENTIFIC_COUNCLI.UPDATE_SCIENTIFIC_COUNCLI_LOADING
  })

  const api = API_URLS.SCIENTIFIC_COUNCIL.updateScientificCouncil();
  const { response, error } = await apiCall({ ...api, payload: scientificCouncil });
  if(!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.SCIENTIFIC_COUNCLI.UPDATE_SCIENTIFIC_COUNCLI_SUCCESS,
      payload: response.data.data
    });
  } else {
    dispatch({ type: TYPE.SCIENTIFIC_COUNCLI.UPDATE_SCIENTIFIC_COUNCLI_FAILURE });
  }
}

const deleteScientificCouncli = (id) => async dispatch => {
  dispatch({
    type: TYPE.SCIENTIFIC_COUNCLI.DELETE_SCIENTIFIC_COUNCLI_LOADING
  })

  const api = API_URLS.SCIENTIFIC_COUNCIL.deleteScientificCouncil(id);
  const { response, error } = await apiCall({ ...api });
  if(!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.SCIENTIFIC_COUNCLI.DELETE_SCIENTIFIC_COUNCLI_SUCCESS,
      payload: response.data.data
    });
    return true;
  } else {
    dispatch({ type: TYPE.SCIENTIFIC_COUNCLI.DELETE_SCIENTIFIC_COUNCLI_FAILURE });
    return false;
  }
}

export const scientificCouncilAction = {
  getAllScientificCouncli,
  createAllScientificCouncli,
  updateAllScientificCouncli,
  deleteScientificCouncli,
}