const STUDENT_RESEARCH_STATUS = [
  'info_default',           // Chưa được duyệt thông tin đề tài
  'info_accepted',          // Đã được duyệt thông tin đề tài
  'rejected',               // Đề tài đã bị huỷ 
  'member_default',         // Chưa được duyệt thông tin thành viên
  'member_accepted',        // Đã được duyệt thông tin thành viên
  'conducted',              // Được duyệt thực hiện

  'report_default',         // Chưa gửi báo cáo tiến độ
  'report_stop_default',    // Đã gửi đơn xin dừng 
  'report_stop_accepted',   // Đã duyệt đơn xin dừng

  'report_submitted',       // Đã gửi báo cáo tiến độ
  // 'report_adjust_accepted', // Có đề xuất điều chỉnh bổ sung
  'report_ensure',          // Đảm bảo tiến độ
  'report_not_ensure',      // Không đảm bảo tiến độ

  'default',                // Chưa nộp báo cáo
  'council_default',        // Chưa gán hội đồng
  'submitted',              //Đã nộp báo cáo

  'council_accepted',       // Đã gán hội đồng
  'adjust_request',         // Yêu cầu điều chỉnh

  'accepted',               // Đã nghiệm thu
  'eligible',               // Được tham dự hội nghị KHCN cấp trường
  'not_eligible',           // Không được tham dự hội nghị KHCN cấp trường
];

export {
  STUDENT_RESEARCH_STATUS,
};