import { fromJS } from "immutable";

import TYPE from "../../constants/type";

const initialState = fromJS({
  list: [],
  listV2: [],
  typeFilter: 3,
  isFetching: false,
});

export default function rewardReducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.REWARD_INFORMATION.GET_REWARD_INFORMATION_LOADING:
    case TYPE.REWARD_INFORMATION.UPDATE_REWARD_INFORMATION_LOADING:
    case TYPE.REWARD_INFORMATION.GET_REWARD_INFORMATION_V2_LOADING:
    case TYPE.REWARD_INFORMATION.UPDATE_REWARD_INFORMATION_V2_LOADING:
      return state.merge({
        isFetching: true,
      });

    case TYPE.REWARD_INFORMATION.GET_REWARD_INFORMATION_FAILURE:
    case TYPE.REWARD_INFORMATION.UPDATE_REWARD_INFORMATION_FAILURE:
    case TYPE.REWARD_INFORMATION.GET_REWARD_INFORMATION_V2_FAILURE:
    case TYPE.REWARD_INFORMATION.UPDATE_REWARD_INFORMATION_V2_FAILURE:
      return state.merge({
        isFetching: false,
      });

    case TYPE.REWARD_INFORMATION.GET_REWARD_INFORMATION_SUCCESS:
      return state.merge({
        list: [...action.payload],
        isFetching: false,
      });

    case TYPE.REWARD_INFORMATION.UPDATE_REWARD_INFORMATION_SUCCESS: {
      const temp = state.get('list');
      const index = temp.find((e) => e.id === action.id);
      temp.splice(index, 1, action.payload);
      return state.merge({
        list: [...temp],
        isFetching: false,
      });
    }

    case TYPE.REWARD_INFORMATION.GET_REWARD_INFORMATION_V2_SUCCESS:
      return state.merge({
        listV2: action.payload ?? [],
        isFetching: false,
      });

    case TYPE.REWARD_INFORMATION.UPDATE_REWARD_INFORMATION_V2_SUCCESS: {
      let temp = state.get('listV2');
      temp = temp.map(item => {
        if (item.id === action.id)
          return action.payload
        else
          return item
      })

      return state.merge({
        listV2: temp ?? [],
        isFetching: false,
      });
    }

    case 'UPDATE_TYPE_FILTER':
      return state.merge({
        typeFilter: action.payload,
      });

    default:
      return state;
  }
}
