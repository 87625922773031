import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Button,
  Divider,
  Typography,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { Replay } from '@material-ui/icons';

import toJs from '../../../../hoc/toJS';
import select from '../../../../utils/select';

import { getEarningsTable } from '../../../../reducer/earningsTable/action';

function EarningTableButton(props) {
  const { earningsTableList, getEarningsTable } = props;

  const [year, setYear] = useState(2023);

  const yearList = useMemo(() => {
    const tempList = [];
    console.log(earningsTableList);
    earningsTableList.forEach((e) => {
      if (!tempList.find((year) => year === e.year)) {
        tempList.push(year);
      }
    });
    return tempList;
  }, [earningsTableList]);

  useEffect(() => {
    if (earningsTableList.length === 0) getEarningsTable();
  }, []);

  const handleReload = () => getEarningsTable();

  return (
    <>
      <Grid container>
        <Grid item xs={4}><Typography variant="h5" style={{ lineHeight: '33px' }}>Bảng tính lương</Typography></Grid>
        <Grid item xs={4}>
          <TextField
            select
            fullWidth
            size='small'
            variant='outlined'
            value={year}
            label='Chọn năm học'
            onChange={(e) => setYear(e)}
            SelectProps={{
              MenuProps: {
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                transformOrigin: { vertical: 'top', horizontal: 'center' },
                getContentAnchorEl: null,
              } 
            }}
          >
            {yearList.map((e, index) => <MenuItem key={index} value={parseInt(e)}>{e} - {e+1}</MenuItem>)}
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <Button variant='outlined' startIcon={<Replay />} onClick={handleReload} style={{ float: 'right' }}>Tải lại</Button>
        </Grid>
      </Grid>
      <Divider style={{ margin: '8px 0' }} />
    </>
  );
}

const mapStateToProps = (state) => ({
  earningsTableList: select(state, 'earningsTableReducer', 'list'),
});

const mapDispatchToProps = (dispatch) => ({
  getEarningsTable: (year) => dispatch(getEarningsTable(year)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(toJs(EarningTableButton));
