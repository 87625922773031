import { apiCall } from "../../utils/api";
import TYPE from "../../constants/type";
import { notification } from "../home/action";
import { API_URLS } from "../../constants/api";
import { PREFIX } from "../../constants/enums";

export const getAllProfile = () => async (dispatch) => {
  const api = API_URLS.DEPARTMENT.getAllProfiles();
  dispatch({
    type: TYPE.PROFILE.GET_ALL_PROFILE_LOADING,
    meta: { prefix: [PREFIX.PROFILE, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PROFILE.GET_ALL_PROFILE_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.PROFILE, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.PROFILE.GET_ALL_PROFILE_FAILURE,
      meta: { prefix: [PREFIX.PROFILE, PREFIX.API_FAILURE] },
    });
  }
};

export const getProfileFromUserId = (id) => async (dispatch) => {
  const api = API_URLS.PROFILE.getProfileFromUserId(id);
  dispatch({
    type: TYPE.PROFILE.GET_PROFILE_FROM_USER_ID_LOADING,
    meta: { prefix: [PREFIX.PROFILE, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PROFILE.GET_PROFILE_FROM_USER_ID_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.PROFILE, PREFIX.API_SUCCESS] },
    });
    return { success: true, profileId: response.data.data.id };
  } else {
    dispatch({
      type: TYPE.PROFILE.GET_PROFILE_FROM_USER_ID_FAILURE,
      meta: { prefix: [PREFIX.PROFILE, PREFIX.API_FAILURE] },
    });
    return { success: false, profileId: null };
  }
};

export const updateProfile = (id, payload) => async (dispatch) => {
  const api = API_URLS.PROFILE.updateProfile(id);
  dispatch({
    type: TYPE.PROFILE.UPDATE_PROFILE_LOADING,
    meta: { prefix: [PREFIX.PROFILE, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });
  if (!error && response.status === 200 && response.data.success) {
    // window.location.reload();
    dispatch({
      type: TYPE.PROFILE.UPDATE_PROFILE_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.PROFILE, PREFIX.API_SUCCESS] },
    });
    dispatch(
      notification({
        type: "success",
        message: "Cập nhật dữ liệu thành công!",
      })
    );
    return true;
  } else {
    dispatch({
      type: TYPE.PROFILE.UPDATE_PROFILE_FAILURE,
      meta: { prefix: [PREFIX.PROFILE, PREFIX.API_FAILURE] },
    });
    dispatch(
      notification({
        type: "error",
        message: "Cập nhật dữ liệu thất bại!",
      })
    );
    return false;
  }
};

export const getTimeResearchByProfileId = (profileId) => async (dispatch) => {
  const api = API_URLS.PROFILE.getTimeResearchByProfileId(profileId);
  dispatch({
    type: TYPE.PROFILE.GET_TIME_RESEARCH_BY_PROFILE_ID_LOADING,
    meta: { prefix: [PREFIX.PROFILE, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });

  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PROFILE.GET_TIME_RESEARCH_BY_PROFILE_ID_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.PROFILE, PREFIX.API_SUCCESS] },
    });
    dispatch(
      notification({
        type: "success",
        message: "Cập nhật dữ liệu thành công!",
      })
    );
  } else {
    dispatch({
      type: TYPE.PROFILE.GET_TIME_RESEARCH_BY_PROFILE_ID_FAILURE,
      meta: { prefix: [PREFIX.PROFILE, PREFIX.API_FAILURE] },
    });
    dispatch(
      notification({
        type: "error",
        message: "Cập nhật dữ liệu thất bại!",
      })
    );
  }
};

export const getTimeResearchByProfileIdV2 = (profileId, year) => async (dispatch) => {
  const api = API_URLS.PROFILE.getTimeResearchByProfileIdV2(profileId, year);
  dispatch({
    type: TYPE.PROFILE.GET_TIME_RESEARCH_BY_PROFILE_ID_LOADING,
    meta: { prefix: [PREFIX.PROFILE, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });

  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PROFILE.GET_TIME_RESEARCH_BY_PROFILE_ID_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.PROFILE, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({ type: "success", message: "Lấy dữ liệu thành công!" }));
  } else {
    dispatch({
      type: TYPE.PROFILE.GET_TIME_RESEARCH_BY_PROFILE_ID_FAILURE,
      meta: { prefix: [PREFIX.PROFILE, PREFIX.API_FAILURE] },
    });
    dispatch(notification({ type: "error", message: "Lấy dữ liệu thất bại!" }));
  }
};

export const filterTimeResearch = (payload) => async (dispatch) => {
  const api = API_URLS.PROFILE.filterTimeResearch();
  dispatch({
    type: TYPE.PROFILE.FILTER_TIME_RESEARCH_LOADING,
    meta: { prefix: [PREFIX.PROFILE, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });

  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PROFILE.FILTER_TIME_RESEARCH_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.PROFILE, PREFIX.API_SUCCESS] },
    });
    dispatch(notification({ type: "success", message: "Lấy dữ liệu thành công!" }));
  } else {
    dispatch({
      type: TYPE.PROFILE.FILTER_TIME_RESEARCH_FAILURE,
      meta: { prefix: [PREFIX.PROFILE, PREFIX.API_FAILURE] },
    });
    dispatch(notification({ type: "error", message: "Lấy dữ liệu thất bại!" }));
  }
};

export const updateTimeResearchInformation =
  (timeResearchInformationId, payload) => async (dispatch) => {
    const api = API_URLS.PROFILE.updateTimeResearchInformation(
      timeResearchInformationId
    );
    dispatch({
      type: TYPE.PROFILE.UPDATE_TIME_RESEARCH_INFORMATION_LOADING,
      meta: { prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_CALLING] },
    });
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200 && response.data.success) {
      dispatch({
        type: TYPE.PROFILE.UPDATE_TIME_RESEARCH_INFORMATION_SUCCESS,
        payload: response.data.data,
        meta: {
          prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_SUCCESS],
        },
      });
      dispatch(
        notification({
          type: "success",
          message: "Cập nhật thời gian nghiên cứu thành công!",
        })
      );
    } else {
      dispatch({
        type: TYPE.PROFILE.UPDATE_TIME_RESEARCH_INFORMATION_FAILURE,
        meta: {
          prefix: [PREFIX.TIME_RESEARCH_INFORMATION, PREFIX.API_FAILURE],
        },
      });
      dispatch(
        notification({
          type: "error",
          message: "Cập nhật thời gian nghiên cứu thất bại",
        })
      );
    }
  };

export const getProfileInfoByProfileId = (profileId) => async (dispatch) => {
  const api = API_URLS.PROFILE.getProfileInfoByProfileId(profileId);
  dispatch({
    type: TYPE.PROFILE.GET_PROFILE_INFO_BY_PROFILE_ID_LOADING,
    meta: { prefix: [PREFIX.PROFILE, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });

  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PROFILE.GET_PROFILE_INFO_BY_PROFILE_ID_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.PROFILE, PREFIX.API_SUCCESS] },
    });
    return response;
  } else {
    dispatch({
      type: TYPE.PROFILE.GET_PROFILE_INFO_BY_PROFILE_ID_FAILURE,
      meta: { prefix: [PREFIX.PROFILE, PREFIX.API_FAILURE] },
    });
    dispatch(
      notification({
        type: "error",
        message: "Lấy dữ liệu thất bại!",
      })
    );
    return response;
  }
};

export const getAllContractsByProfileId = (profileId) => async (dispatch) => {
  const api =
    API_URLS.EMPLOYMENT_CONTRACT.getAllEmploymentContractByProfileId(profileId);
  dispatch({
    type: TYPE.PROFILE.STATISTIC_UNACCEPTED_LOADING,
    meta: {
      prefix: [PREFIX.PROFILE, PREFIX.EMPLOYMENT_CONTRACT, PREFIX.API_CALLING],
    },
  });
  const { response, error } = await apiCall({ ...api });

  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PROFILE.STATISTIC_UNACCEPTED_SUCCESS,
      payload: response.data.data,
      meta: {
        prefix: [
          PREFIX.PROFILE,
          PREFIX.EMPLOYMENT_CONTRACT,
          PREFIX.API_SUCCESS,
        ],
      },
    });
    return response.data.data || [];
  } else {
    dispatch({
      type: TYPE.PROFILE.STATISTIC_UNACCEPTED_FAILURE,
      meta: {
        prefix: [
          PREFIX.PROFILE,
          PREFIX.EMPLOYMENT_CONTRACT,
          PREFIX.API_FAILURE,
        ],
      },
    });
    dispatch(
      notification({
        type: "error",
        message: "Lấy dữ liệu thất bại!",
      })
    );
    return [];
  }
};

export const setDepartment = (id) => (dispatch) => {
  dispatch({
    type: "SET_DEPARTMENT",
    id: id,
    meta: { prefix: [PREFIX.PROFILE, PREFIX.API_CALLING] },
  });
};

export const setStatisticUnaccepted = (filterId, accept) => (dispatch) => {
  dispatch({
    type: "SET_STATISTIC_UNACCEPTED",
    id: filterId,
    payload: accept,
    meta: { prefix: [PREFIX.PROFILE, PREFIX.API_CALLING] },
  });
};

export const statisticUnaccepted = (payload) => async (dispatch) => {
  const api = API_URLS.PROFILE.statisticUnaccepted();
  dispatch({
    type: TYPE.PROFILE.STATISTIC_UNACCEPTED_LOADING,
    meta: { prefix: [PREFIX.PROFILE, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api, payload });

  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PROFILE.STATISTIC_UNACCEPTED_SUCCESS,
      id: payload?.other ? "other" : payload.profileId !== 0 ? "all" : "filter",
      payload: response.data.data,
      meta: { prefix: [PREFIX.PROFILE, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.PROFILE.STATISTIC_UNACCEPTED_FAILURE,
      meta: { prefix: [PREFIX.PROFILE, PREFIX.PROFILE, PREFIX.API_FAILURE] },
    });
    // dispatch(notification({
    //   type: 'error',
    //   message: 'Lấy dữ liệu thất bại!'
    // }));
  }
};

export const getProfScore = (id) => async (dispatch) => {
  const api = API_URLS.PROFILE.getProfScore(id);
  dispatch({
    type: TYPE.PROFILE.GET_PROF_SCORE_LOADING,
    meta: { prefix: [PREFIX.PROFILE, PREFIX.API_CALLING] },
  });
  const { response, error } = await apiCall({ ...api });
  if (!error && response.status === 200 && response.data.success) {
    dispatch({
      type: TYPE.PROFILE.GET_PROF_SCORE_SUCCESS,
      payload: response.data.data,
      meta: { prefix: [PREFIX.PROFILE, PREFIX.API_SUCCESS] },
    });
  } else {
    dispatch({
      type: TYPE.PROFILE.GET_PROF_SCORE_FAILURE,
      meta: { prefix: [PREFIX.PROFILE, PREFIX.API_FAILURE] },
    });
  }
};
