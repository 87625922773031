import React from 'react';
import { Group, RateReview } from '@material-ui/icons';
import { Box, Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { KPTextfield, KPTextform } from '../../../../components/KPTComponents';
import { updateInternalResearch } from '../../../../reducer/internalResearch/action';
import theme from '../../../../theme';

function InstitutionalProjectCouncilForm(props) {
  const dispatch = useDispatch();

  const {
    values, setValues,
    council, setCouncil,
    councilApp, setCouncilApp
  } = props;

  const disabled = props.id === 'council-detail-form';

  const handleChangeForm = (key, val) => {
    const keyList = key.split('.');
    const check = keyList[0] === 'council';
    const realKey = keyList[1];
    if (check) {
      setCouncil({ ...council, [realKey]: val });
    } else {
      setCouncilApp({ ...councilApp, [realKey]: val });
    }
  }

  const textForm = [
    { key: 'box1', xs: 12, type: 'render', render: () => (
      <Box
        // maxHeight='calc(100vh - 216px)'
      >
        <Box border='solid 1px #bcbcbc' borderRadius={4}>
          <Box
            display='flex'
            bgcolor='#ececec'
            padding='8px 16px'
            alignItems='center'
            borderBottom='solid 1px #bcbcbc' 
            style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
          >
            <Group style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }} />
            <Typography variant='h6' style={{ color: theme.palette.primary.main }}>
              <b>{`DANH SÁCH HỘI ĐỒNG NGHIỆM THU ${values?.researchType === 'applicational' ? 'SẢN PHẨM' : ''}`}</b>
            </Typography>
          </Box>
          <Box padding={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2} alignItems='center' style={{ padding: '12px 0', backgroundColor: '#fafafa', borderBottom: 'solid 1px #e0e0e0' }}>
                  <Grid item xs={4}>
                    <Typography variant='body1'><b>Tên thành viên</b></Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant='body1'><b>Học hàm, học vị</b></Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant='body1'><b>Đơn vị</b></Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant='body1'><b>Vai trò</b></Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <KPTextfield id='council.chairmanName' disabled={disabled} value={council?.chairmanName} handleChangeForm={handleChangeForm} required={true} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.chairmanDegree' disabled={disabled} value={council?.chairmanDegree} handleChangeForm={handleChangeForm} required={true} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.chairmanUnit' disabled={disabled} value={council?.chairmanUnit} handleChangeForm={handleChangeForm} required={true} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Chủ tịch' disabled={true} required={true} />
                  </Grid>
                  <Grid item xs={4}>
                    <KPTextfield id='council.secretaryName' disabled={disabled} value={council?.secretaryName} handleChangeForm={handleChangeForm} required={true} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.secretaryDegree' disabled={disabled} value={council?.secretaryDegree} handleChangeForm={handleChangeForm} required={true} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.secretaryUnit' disabled={disabled} value={council?.secretaryUnit} handleChangeForm={handleChangeForm} required={true} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Thư ký' disabled={true} required={true} />
                  </Grid>
                  
                  {/* Ủy viên 1 */}
                  <Grid item xs={4}>
                    <KPTextfield id='council.firstCommissionerName' value={council?.firstCommissionerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.firstCommissionerDegree' value={council?.firstCommissionerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.firstCommissionerUnit' value={council?.firstCommissionerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Uỷ viên 1' disabled={true} />
                  </Grid>
                  {/* Ủy viên 2 */}
                  <Grid item xs={4}>
                    <KPTextfield id='council.secondCommissionerName' value={council?.secondCommissionerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.secondCommissionerDegree' value={council?.secondCommissionerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.secondCommissionerUnit' value={council?.secondCommissionerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Uỷ viên 2' disabled={true} />
                  </Grid>
                  {/* Ủy viên 3 */}
                  <Grid item xs={4}>
                    <KPTextfield id='council.thirdCommissionerName' value={council?.thirdCommissionerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.thirdCommissionerDegree' value={council?.thirdCommissionerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.thirdCommissionerUnit' value={council?.thirdCommissionerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Uỷ viên 3' disabled={true} />
                  </Grid>
                  {/* Ủy viên 4 */}
                  <Grid item xs={4}>
                    <KPTextfield id='council.fourthCommissionerName' value={council?.fourthCommissionerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.fourthCommissionerDegree' value={council?.fourthCommissionerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.fourthCommissionerUnit' value={council?.fourthCommissionerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Uỷ viên 4' disabled={true} />
                  </Grid>
                  {/* Ủy viên 5 */}
                  <Grid item xs={4}>
                    <KPTextfield id='council.fifthCommissionerName' value={council?.fifthCommissionerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.fifthCommissionerDegree' value={council?.fifthCommissionerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.fifthCommissionerUnit' value={council?.fifthCommissionerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Uỷ viên 5' disabled={true} />
                  </Grid>
                  {/* Ủy viên 6 */}
                  <Grid item xs={4}>
                    <KPTextfield id='council.sixthCommissionerName' value={council?.sixthCommissionerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.sixthCommissionerDegree' value={council?.sixthCommissionerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.sixthCommissionerUnit' value={council?.sixthCommissionerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Uỷ viên 6' disabled={true} />
                  </Grid>
                  {/* Ủy viên 7 */}
                  <Grid item xs={4}>
                    <KPTextfield id='council.seventhCommissionerName' value={council?.seventhCommissionerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.seventhCommissionerDegree' value={council?.seventhCommissionerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.seventhCommissionerUnit' value={council?.seventhCommissionerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Uỷ viên 7' disabled={true} />
                  </Grid>
                  {/* Ủy viên 8 */}
                  <Grid item xs={4}>
                    <KPTextfield id='council.eighthCommissionerName' value={council?.eighthCommissionerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.eighthCommissionerDegree' value={council?.eighthCommissionerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.eighthCommissionerUnit' value={council?.eighthCommissionerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Uỷ viên 8' disabled={true} />
                  </Grid>
                  {/* Ủy viên 9 */}
                  <Grid item xs={4}>
                    <KPTextfield id='council.ninthCommissionerName' value={council?.ninthCommissionerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.ninthCommissionerDegree' value={council?.ninthCommissionerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.ninthCommissionerUnit' value={council?.ninthCommissionerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Uỷ viên 9' disabled={true} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        
        <Box border='solid 1px #bcbcbc' marginTop={4} borderRadius={4}>
          <Box
            display='flex'
            bgcolor='#ececec'
            padding='8px 16px'
            alignItems='center'
            borderBottom='solid 1px #bcbcbc' 
            style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
          >
            <RateReview style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }} />
            <Typography variant='h6' style={{ color: theme.palette.primary.main }}>
              <b>{`DANH SÁCH PHẢN BIỆN ${values?.researchType === 'applicational' ? '(HỘI ĐỒNG NGHIỆM THU SẢN PHẨM)' : ''}`}</b>
            </Typography>
          </Box>
          <Box padding={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2} style={{ padding: '12px 0', backgroundColor: '#fafafa', borderBottom: 'solid 1px #e0e0e0' }}>
                  <Grid item xs={4}>
                    <Typography variant='body1'><b>Tên thành viên</b></Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant='body1'><b>Học hàm, học vị</b></Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant='body1'><b>Đơn vị</b></Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant='body1'><b>Vai trò</b></Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <KPTextfield id='council.firstReviewerName' disabled={disabled} value={council?.firstReviewerName} handleChangeForm={handleChangeForm} required={true} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.firstReviewerDegree' disabled={disabled} value={council?.firstReviewerDegree} handleChangeForm={handleChangeForm} required={true} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.firstReviewerUnit' disabled={disabled} value={council?.firstReviewerUnit} handleChangeForm={handleChangeForm} required={true} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Phản biện 1' disabled={true} required={true} />
                  </Grid>
                  <Grid item xs={4}>
                    <KPTextfield id='council.secondReviewerName' disabled={disabled} value={council?.secondReviewerName} handleChangeForm={handleChangeForm}/>
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.secondReviewerDegree' disabled={disabled} value={council?.secondReviewerDegree} handleChangeForm={handleChangeForm}/>
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.secondReviewerUnit' disabled={disabled} value={council?.secondReviewerUnit} handleChangeForm={handleChangeForm}/>
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Phản biện 2' disabled={true} />
                  </Grid>
                  <Grid item xs={4}>
                    <KPTextfield id='council.thirdReviewerName' disabled={disabled} value={council?.thirdReviewerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.thirdReviewerDegree' disabled={disabled} value={council?.thirdReviewerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='council.thirdReviewerUnit' disabled={disabled} value={council?.thirdReviewerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Phản biện 3' disabled={true} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    )},
  ];

  if (values?.researchType === 'applicational') {
    textForm.push({ key: 'box3', xs: 12, type: 'render', render: () => (
      <Box
        // maxHeight='calc(100vh - 216px)'
      >
        <Box border='solid 1px #bcbcbc' borderRadius={4} marginTop={2}>
          <Box
            display='flex'
            bgcolor='#ececec'
            padding='8px 16px'
            alignItems='center'
            borderBottom='solid 1px #bcbcbc' 
            style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
          >
            <Group style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }} />
            <Typography variant='h6' style={{ color: theme.palette.primary.main }}>
              <b>DANH SÁCH HỘI ĐỒNG NGHIỆM THU ĐỀ TÀI</b>
            </Typography>
          </Box>
          <Box padding={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2} alignItems='center' style={{ padding: '12px 0', backgroundColor: '#fafafa', borderBottom: 'solid 1px #e0e0e0' }}>
                  <Grid item xs={4}>
                    <Typography variant='body1'><b>Tên thành viên</b></Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant='body1'><b>Học hàm, học vị</b></Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant='body1'><b>Đơn vị</b></Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant='body1'><b>Vai trò</b></Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <KPTextfield id='councilApp.chairmanName' disabled={disabled} value={councilApp?.chairmanName} handleChangeForm={handleChangeForm} required={true} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='councilApp.chairmanDegree' disabled={disabled} value={councilApp?.chairmanDegree} handleChangeForm={handleChangeForm} required={true} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='councilApp.chairmanUnit' disabled={disabled} value={councilApp?.chairmanUnit} handleChangeForm={handleChangeForm} required={true} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Chủ tịch' disabled={true} required={true} />
                  </Grid>
                  <Grid item xs={4}>
                    <KPTextfield id='councilApp.secretaryName' disabled={disabled} value={councilApp?.secretaryName} handleChangeForm={handleChangeForm} required={true} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='councilApp.secretaryDegree' disabled={disabled} value={councilApp?.secretaryDegree} handleChangeForm={handleChangeForm} required={true} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='councilApp.secretaryUnit' disabled={disabled} value={councilApp?.secretaryUnit} handleChangeForm={handleChangeForm} required={true} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Thư ký' disabled={true} required={true} />
                  </Grid>
                  <Grid item xs={4}>
                    <KPTextfield id='councilApp.commissionerName' disabled={disabled} value={councilApp?.commissionerName} handleChangeForm={handleChangeForm} required={true} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='councilApp.commissionerDegree' disabled={disabled} value={councilApp?.commissionerDegree} handleChangeForm={handleChangeForm} required={true} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='councilApp.commissionerUnit' disabled={disabled} value={councilApp?.commissionerUnit} handleChangeForm={handleChangeForm} required={true} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Uỷ viên' disabled={true} required={true} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        
        <Box border='solid 1px #bcbcbc' marginTop={4} borderRadius={4}>
          <Box
            display='flex'
            bgcolor='#ececec'
            padding='8px 16px'
            alignItems='center'
            borderBottom='solid 1px #bcbcbc' 
            style={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}
          >
            <RateReview style={{ marginRight: 12, marginTop: 2, color: theme.palette.primary.main }} />
            <Typography variant='h6' style={{ color: theme.palette.primary.main }}><b>DANH SÁCH PHẢN BIỆN (HỘI ĐỒNG NGHIỆM THU ĐỀ TÀI)</b></Typography>
          </Box>
          <Box padding={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2} style={{ padding: '12px 0', backgroundColor: '#fafafa', borderBottom: 'solid 1px #e0e0e0' }}>
                  <Grid item xs={4}>
                    <Typography variant='body1'><b>Tên thành viên</b></Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant='body1'><b>Học hàm, học vị</b></Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant='body1'><b>Đơn vị</b></Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant='body1'><b>Vai trò</b></Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <KPTextfield id='councilApp.firstCommissionerName' disabled={disabled} value={councilApp?.firstCommissionerName} handleChangeForm={handleChangeForm} required={true} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='councilApp.firstCommissionerDegree' disabled={disabled} value={councilApp?.firstCommissionerDegree} handleChangeForm={handleChangeForm} required={true} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='councilApp.firstCommissionerUnit' disabled={disabled} value={councilApp?.firstCommissionerUnit} handleChangeForm={handleChangeForm} required={true} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Phản biện 1' disabled={true} required={true} />
                  </Grid>
                  <Grid item xs={4}>
                    <KPTextfield id='councilApp.secondCommissionerName' disabled={disabled} value={councilApp?.secondCommissionerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='councilApp.secondCommissionerDegree' disabled={disabled} value={councilApp?.secondCommissionerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='councilApp.secondCommissionerUnit' disabled={disabled} value={councilApp?.secondCommissionerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Phản biện 2' disabled={true} />
                  </Grid>
                  <Grid item xs={4}>
                    <KPTextfield id='councilApp.thirdCommissionerName' disabled={disabled} value={councilApp?.thirdCommissionerName} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='councilApp.thirdCommissionerDegree' disabled={disabled} value={councilApp?.thirdCommissionerDegree} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={3}>
                    <KPTextfield id='councilApp.thirdCommissionerUnit' disabled={disabled} value={councilApp?.thirdCommissionerUnit} handleChangeForm={handleChangeForm} />
                  </Grid>
                  <Grid item xs={2}>
                    <KPTextfield value='Phản biện 3' disabled={true} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    )})
  }

  const handleSubmit = () => {
    props.handleClose();
    var statusList = props.detail.status.map((e) => e.value);
    statusList[1] = 'council_accepted';

    dispatch(updateInternalResearch({
      ...props.detail,
      internalCouncilInfos: props.detail.researchType === 'basic' ? [
          ...props.detail.internalCouncilInfos?.filter((e) => e?.name !== 'project' && e?.name !== 'application'),
          { ...council, name: 'accept-project' },
        ] : [
          ...props.detail.internalCouncilInfos?.filter((e) => e?.name !== 'project' && e?.name !== 'application'),
          { ...council, name: 'accept-project' },
          { ...councilApp, name: 'accept-application' },
        ],
      status: statusList.join(','),
    }));
  }

  return (
    <KPTextform
      id={props.id}
      textForm={textForm}
      values={values}
      setValues={setValues}
      handleChangeForm={handleChangeForm}
      handleSubmit={handleSubmit}
    />
  );
}

export default InstitutionalProjectCouncilForm;
