export const removeAccents = (str) => {
  if (typeof str === 'string' || str instanceof String) {
    return (
      str.normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd').replace(/Đ/g, 'D')
      .toLowerCase()
    );
  } else if (typeof str === 'number' || str instanceof Number) {
    return (
      str.toString().normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/đ/g, 'd').replace(/Đ/g, 'D')
      .toLowerCase()
    );
  }
  return '';
}