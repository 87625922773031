export const textFormsToDisplay = (textForm, statuses) => {
  let data = [];

  switch (statuses[0].value) {
    case "conducted":
    case "report_default":
    case "report_stop_default":
    case "report_submitted":
      data.push(textForm[0]);
      break;
    case "report_stop_accepted":
      data.push(textForm[0], textForm[1]);
      break;
    case "report_ensure":
      data.push(textForm[0], textForm[2]);
      break;
    case "default":
    case "submitted":
      if (statuses[1].value) {
        switch (statuses[1].value) {
          case "council_default":
            data.push(textForm[0], textForm[2]);
            break;
          case "council_accepted":
            data.push(textForm[0], textForm[2], textForm[3]);
          default:
            break;
        }
      }
      break;
    case "info_adjust_request":
    case "content_adjust_request":
    case "info_adjusted":
    case "content_adjusted":
      data.push(
        textForm[0],
        textForm[2],
        textForm[3],
        textForm[4],
        textForm[5]
      );
      break;
    case "council":
      data.push(textForm[0], textForm[2], textForm[3], textForm[4]);
      break;
    case "accepted":
      data.push(
        textForm[0],
        textForm[2],
        textForm[3],
        textForm[4],
        textForm[5],
        textForm[6]
      );
      break;
    default:
      break;
  }

  return data;
};
