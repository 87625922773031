import {
  Button, Dialog, Divider,
  DialogActions, DialogContent, Grid,
  DialogTitle, IconButton, Typography, TextField
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import styles from "./index.module.css";
import select from "../../../../utils/select";

import { KPTextform } from "../../../../components/KPTComponents";
import { scientificCouncilAction } from "../../../../reducer/scientificCouncil/action";
import { insertStudentGuidance, updateStudentGuidance } from "../../../../reducer/studentResearch/action";
import { uploadMultiFile } from "../../../../reducer/file/action";
import { Autocomplete } from "@material-ui/lab";

const StudentGuidance = (props) => {
  const { open, handleClose } = props;

  const dispatch = useDispatch();
  const [ values, setValues] = useState(null);

  const role = useSelector((state) => select(state, "userReducer", "role"));
  const departments = useSelector((state) => select(state, "departmentReducer", "departments"));
  const profileDetail = useSelector((state) => select(state, "profileReducer", "detail"));

  const TEXT_FORM = [
    { key: "title", disabled: props.viewValues, xs: 12, required: true, label: "Tên đề tài" },
    {
      xs: 8,
      disabled: props.viewValues,
      key: "department",
      required: true,
      label: "Khoa/Viện",
      type: "autoComplete",
      getOptionLabel: (option) => option.name,
      options: departments.filter((item) => item.id !== 0) ?? [],
    },
    { xs: 4, key: 'numberInstructor', disabled: props.viewValues, type: 'number', label: 'Số lượng người hướng dẫn', min: 0, max: 100, step: 1, required: true },
    { xs: 12, key: 'instructors', disabled: props.viewValues, label: 'Danh sách người hướng dẫn', required: true },
    { xs: 4, key: 'year', disabled: props.viewValues, type: 'number', label: 'Năm thực hiện', min: 0, max: 10000, step: 1, required: true },
    { 
      xs: 8, 
      key: 'type', 
      label: 'Phân loại', 
      disabled: props.viewValues,
      required: true,
      type: 'select',
      options: [
        ...[
          {title: "Mentor khởi nghiệp (Nộp hồ sơ đăng ký tham gia)", value: "startup_mentor_register"},
          {title: "Mentor khởi nghiệp (Hồ sơ được chọn vào vòng bán kết và chung kết cấp Trường)", value: "startup_mentor_school"},
          {title: "Mentor khởi nghiệp (Hồ sơ tham gia và đạt giải cấp quốc gia)", value: "startup_mentor_national"},
          {title: "Hướng dẫn sinh viên thi NCKH quốc gia, quốc tế/ Các cuộc thi sáng tạo KHCN", value: "student_guidance"},
        ].map((d, i) => ({
          key: `opt${i}`,
          title: d.title,
          value: d.value
        }))
      ],
      // value: 'Hướng dẫn sinh viên thi NCKH quốc gia/ Các cuộc thi sáng tạo KHCN'
    },
    { xs: 12, key: 'member', disabled: props.viewValues, label: 'Tên sinh viên tham gia', required: true },
    { xs: 12, key: 'fileUrl', disabled: props.viewValues, type: 'dragzone', label: 'Minh chứng', required: true },
  ];

  const insert = () => {
    dispatch(uploadMultiFile(profileDetail?.id, { file: values?.fileUrl })).then((res) => {
      if (res.success) {
        const files = res.data;
  
        dispatch(insertStudentGuidance({
          ...values,
          fileUrl: files?.[0],
          year: 2022,
          departmentId: values.department.id,
          profileId: profileDetail.id,
        })).then(() => {
          props.reload();
          setValues(null);
        });
      }
    });
  }

  const accept = (accepted) => dispatch(updateStudentGuidance({
    accepted,
    id: props.viewValues.id,
  })).then(() => props.reload());

  const handleSubmit = () => {
    if (!!props.viewValues) {
      if (role === 'sudo-admin') {
        accept(!props.viewValues.accepted);
      }
    } else {
      insert();
    }
    handleClose();
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
    >
      <DialogTitle classes={{ root: styles["dialog-title-container"] }}>
        <Grid container>
          <Grid item xs={7} classes={{ root: styles["dialog-title"] }}>
            <Typography variant="h5">
              <b>Khai báo Hướng dẫn sinh viên nghiên cứu khoa học</b>
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <IconButton
              onClick={() => {
                handleClose();
                setValues(null);
              }}
              // disabled={isFetching}
              style={{ float: "right" }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <KPTextform
          id='scientificCouncil'
          textForm={TEXT_FORM}
          values={props.viewValues || values}
          setValues={!props.viewValues && setValues}
          handleSubmit={handleSubmit}
          // handleSubmit={!props.viewValues ? handleSubmit : handleClose}
        />
      </DialogContent>
      <DialogActions style={{ padding: "8px 24px" }}>
        <Button
          type="submit"
          form="scientificCouncil"
          variant="contained"
          color="primary"
          disabled={!props.viewValues && !values?.fileUrl}
        >
          {(() => {
            if (!!props.viewValues) {
              if (role === 'sudo-admin') {
                return props.viewValues.accepted ? "Huỷ duyệt" : "Duyệt";
              } else {
                return "Đóng";
              }
            } else {
              return "Thêm";
            }
          })()}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default StudentGuidance;