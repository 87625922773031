import { createTheme } from '@material-ui/core/styles';

const theme = createTheme ({
  palette: {
    primary: { main: '#243474' },
    secondary: { main: '#f46423' },
    error: { main: '#b52f2f' },
  },
  typography: { fontFamily: 'SFProDisplay' },
  overrides: {
    MuiListItem: {
      root: {
        width: 'auto',
        margin: 8,
        borderRadius: 4,
        '&$selected': {
          color: '#007fff',
          backgroundColor: '#f0f7ff'
        }
      }
    },
    MuiPaper: {
      root: {
        '&$elevation1': {
          boxShadow:
            '0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%), 0px 1px 8px 0px rgb(0 0 0 / 4%)'
        },
        '&$elevation8': {
          boxShadow:
            '0px 3px 3px -2px rgb(0 0 0 / 6%), 0px 3px 4px 0px rgb(0 0 0 / 4%), 0px 1px 8px 0px rgb(0 0 0 / 4%)'
        }
      }
    },
    MuiTableCell: { sizeSmall: { padding: '6px 16px' } }
  }
});

export default theme;
