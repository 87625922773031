import React from 'react';
import { Card } from '@material-ui/core';

import ManagementTab from '../../containers/WorkTab/ManagementTab';

const tabList = [
  { key: 'scientificResearch', title: 'Bài báo/Chương sách' },
  { key: 'solution', title: 'Giải pháp hữu ích' },
  { key: 'patent', title: 'Bằng sáng chế' },
  { key: 'project', title: 'Đề tài nghiên cứu' },
  { key: 'monograph', title: 'Sách' }
]

const height = 'calc(100vh - 92px)'

function Management() {
  return (
    <Card style={{
      height: height,
      minHeight: height,
      maxHeight: height,
      position: 'relative',
    }}>
      <ManagementTab tabList={tabList} />
    </Card>
  );
}

export default Management;
